export const PERMISSIONS = {
  ARCHIVE_USER: 'ARCHIVE_USER',
  DELETE_USER_FROM_DB: 'DELETE_USER_FROM_DB',
  USER_ADMINISTRATOR: 'USER_ADMINISTRATOR',
  ADD_USER: 'ADD_USER',
  VIEW_TEMPLATES_PAGE: 'VIEW_TEMPLATES_PAGE',
  ADD_AND_PRESAVE_TEMPLATE: 'ADD_AND_PRESAVE_TEMPLATE',
  RESTORE_USER: 'RESTORE_USER',
  ADD_AND_SAVE_CONNECT_TEMPLATE: 'ADD_AND_SAVE_CONNECT_TEMPLATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  CREATE_INSPECTION: 'CREATE_INSPECTION',
  DELETE_INSPECTION: 'DELETE_INSPECTION',
  VIEW_ALL_INSPECTIONS: 'VIEW_ALL_INSPECTIONS',
  VIEW_ALL_REPORTS_IN_INSPECTIONS: 'VIEW_ALL_REPORTS_IN_INSPECTIONS',
  ADD_EDIT_REPORT: 'ADD_EDIT_REPORT',
  EDIT_COMPLETED_REPORT: 'EDIT_COMPLETED_REPORT',
  EDIT_COMPLETED_INSPECTION: 'EDIT_COMPLETED_INSPECTION',
  UPLOAD_REPORT: 'UPLOAD_REPORT',
  CREATE_INSPECTION_HOLDER: 'CREATE_INSPECTION_HOLDER',
  DELETE_INSPECTION_HOLDER: 'DELETE_INSPECTION_HOLDER',
  // db permissions
  VIEW_INSPECTION_TYPE: 'VIEW_INSPECTION_TYPE',
  CREATE_INSPECTION_TYPE: 'CREATE_INSPECTION_TYPE',
  UPDATE_INSPECTION_TYPE: 'UPDATE_INSPECTION_TYPE',
  DELETE_INSPECTION_TYPE: 'DELETE_INSPECTION_TYPE',
  VIEW_POOL: 'VIEW_POOL',
  CREATE_POOL: 'CREATE_POOL',
  UPDATE_POOL: 'UPDATE_POOL',
  DELETE_POOL: 'DELETE_POOL',
  VIEW_REFERRAL: 'VIEW_REFERRAL',
  CREATE_REFERRAL: 'CREATE_REFERRAL',
  UPDATE_REFERRAL: 'UPDATE_REFERRAL',
  DELETE_REFERRAL: 'DELETE_REFERRAL',
  VIEW_SECURITY_SYSTEM: 'VIEW_SECURITY_SYSTEM',
  CREATE_SECURITY_SYSTEM: 'CREATE_SECURITY_SYSTEM',
  UPDATE_SECURITY_SYSTEM: 'UPDATE_SECURITY_SYSTEM',
  DELETE_SECURITY_SYSTEM: 'DELETE_SECURITY_SYSTEM',
  VIEW_SERVICE: 'VIEW_SERVICE',
  CREATE_SERVICE: 'CREATE_SERVICE',
  UPDATE_SERVICE: 'UPDATE_SERVICE',
  DELETE_SERVICE: 'DELETE_SERVICE',
};
export const viewDbPermissions = [
  PERMISSIONS.VIEW_INSPECTION_TYPE,
  PERMISSIONS.VIEW_POOL,
  PERMISSIONS.VIEW_REFERRAL,
  PERMISSIONS.VIEW_SECURITY_SYSTEM,
  PERMISSIONS.VIEW_SERVICE,
];
export const editDbPermissions = [
  PERMISSIONS.UPDATE_INSPECTION_TYPE,
  PERMISSIONS.UPDATE_POOL,
  PERMISSIONS.UPDATE_REFERRAL,
  PERMISSIONS.UPDATE_SECURITY_SYSTEM,
  PERMISSIONS.UPDATE_SERVICE,
];
export const createDbPermissions = [
  PERMISSIONS.CREATE_INSPECTION_TYPE,
  PERMISSIONS.CREATE_POOL,
  PERMISSIONS.CREATE_REFERRAL,
  PERMISSIONS.CREATE_SECURITY_SYSTEM,
  PERMISSIONS.CREATE_SERVICE,
];
export const deleteDbPermissions = [
  PERMISSIONS.DELETE_INSPECTION_TYPE,
  PERMISSIONS.DELETE_POOL,
  PERMISSIONS.DELETE_REFERRAL,
  PERMISSIONS.DELETE_SECURITY_SYSTEM,
  PERMISSIONS.DELETE_SERVICE,
];
