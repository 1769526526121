export const MODALS = {
  USER_MENU: 'USER_MENU',
  ADD_SECTION: 'ADD_SECTION',
  ADD_ITEM: 'ADD_ITEM',
  ADD_COMMENT: 'ADD_COMMENT',
  CONFIRMATION: 'CONFIRMATION',
  SECTION_INFO: 'SECTION_INFO',
  ITEM_INFO: 'ITEM_INFO',
  COMMENT_INFO: 'COMMENT_INFO',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_DEFICIENCIES_COMMENT: 'ADD_DEFICIENCIES_COMMENT',
  REPLACE_ITEM: 'REPLACE_ITEM',
  GALLERY: 'GALLERY',
  PREVIEW: 'PREVIEW',
  CALENDAR_INSPECTIONS: 'CALENDAR_INSPECTIONS',
  ADD_BLOCKER: 'ADD_BLOCKER',
  EDIT_BLOCKER: 'EDIT_BLOCKER',
  ADD_HOLDER: 'ADD_HOLDER',
  DEFAULT_TEXT: 'DEFAULT_TEXT',
  IMAGE_EDITOR: 'IMAGE_EDITOR',
  EDIT_LOCATIONS: 'EDIT_LOCATIONS',
  FEEDBACK: 'FEEDBACK',
  TEMPLATE_HEADER: 'TEMPLATE_HEADER',
};
