import React from 'react';
import { Field, useFormikContext } from 'formik';
import { MenuItem } from '@material-ui/core';

import TextField from 'Components/TextField';
import TextEditor from 'Components/TextEditor';
import SelectField from 'Components/SelectField';
import SelectType from '../SelectType';
import {
  typesWithCalendar,
  typesWithOptions,
  typesWithOptionsAndDefault,
  typesWithOptionsRange,
  typesWithText,
} from '../../options';
import Options from '../../answerTypes/Options';
import Calendar from '../../answerTypes/Calendar';
import Text from '../../answerTypes/Text';
import useStyles from './useStyles';

const AddCommentForm = ({ isReport }) => {
  const classes = useStyles();
  const { touched, values, errors, setFieldValue } = useFormikContext();
  return (
    <>
      <div className={classes.formRow}>
        <Field name='commentName'>
          {({ field, meta }) => (
            <TextField
              {...field}
              error={touched.commentName && !!meta.error}
              helperText={touched.commentName && meta.error}
              variant='outlined'
              type='text'
              label='COMMENT TITLE'
              spellCheck='true'
              data-cy-input='comment-name'
            />
          )}
        </Field>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formItem}>
          <SelectType />
        </div>
      </div>
      <div className={classes.formRow}>
        {typesWithOptions.includes(values.commentType) && <Options optionValues={values.values || ['']} />}
        {typesWithCalendar.includes(values.commentType) && (
          <Calendar
            onDayClick={(day, { selected }) => {
              setFieldValue('values', selected ? undefined : day);
              setFieldValue('defaultValue', selected ? undefined : day);
            }}
            selectedDate={new Date(values.defaultValue)}
          />
        )}
        {typesWithOptionsAndDefault.includes(values.commentType) && (
          <>
            <div className={classes.numberOptionsWrapper}>
              <Options optionValues={values.values || ['']} />
              <div className={classes.formItem}>
                <Field name='defaultValue'>
                  {({ field, meta }) => (
                    <SelectField
                      {...field}
                      variant='outlined'
                      error={touched.defaultValue && !!meta.error}
                      helperText={touched.defaultValue && meta.error}
                      type='text'
                      multiple
                      label='DEFAULT VALUE'
                      data-cy-select='default-value'
                    >
                      {values?.values &&
                        values.values.map((option) => (
                          <MenuItem key={option} value={option} data-cy-item={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </SelectField>
                  )}
                </Field>
              </div>
            </div>
          </>
        )}
        {typesWithText.includes(values.commentType) && (
          <div className={classes.formItem}>
            <Text touched={touched.values} error={errors.values} value={values.values} />
          </div>
        )}
        {typesWithOptionsRange.includes(values.commentType) && (
          <Options optionValues={values.values || [{ from: '', to: '' }]} isRange />
        )}
      </div>
      <div className={classes.formRow}>
        <div className={classes.formItem}>
          <TextEditor fieldName='notes' placeholder='Notes' id='notes' spellCheck='true' />
          <Field name='notes' type='text' spellCheck='true' hidden />
        </div>
      </div>
      {!isReport && (
        <div className={classes.formRow}>
          <div className={classes.formItem}>
            <TextEditor fieldName='reminders' placeholder='Reminders' spellCheck='true' id='reminders' />
            <Field name='reminders' type='text' spellCheck='true' hidden />
          </div>
        </div>
      )}
    </>
  );
};

export default AddCommentForm;
