import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import _ from 'lodash';
import InspectionsAPI from 'api/inspections';
import mainInstance from 'api/mainInstance';
import { PDF_TYPES } from './options';

export const useResultInspection = () => {
  const { token, reportId, section } = useParams();

  const [inspectionResult, setInspectionResult] = useState(null);
  const report = (inspectionResult?.templates || []).find((template) => template.id === +reportId);
  const [isLoading, setIsLoading] = useState(false);
  const clients = (inspectionResult?.contacts || [])?.filter((contact) => contact.type === 'CLIENT');

  const history = useHistory();

  const [selectedSection, setSelectedSection] = useState();
  const [isTokenExpired, setTokenExpired] = useState(false);

  const hasPdf = !isEmpty(report?.pdf);
  const hasTermite = report?.pattern === 'TERMITE_REPORT';

  const selectSection = (idx) => setSelectedSection(idx);

  const inactiveSection = () => setSelectedSection('');

  const formReportTitle = (type) => {
    let title = '';
    if (report?.name) title += `${report?.name} `;
    switch (type) {
      case 'FULL_REPORT':
        title += 'Full Report - ';
        break;
      case 'MAJOR':
        title += 'Major Concerns - ';
        break;
      case 'SUMMARY':
        title += 'Summary - ';
        break;
      case 'THERMITE':
        title += 'Thermite report - ';
        break;
      default:
        break;
    }
    if (clients?.[0]?.type === 'CLIENT')
      title += `${clients[0].firstName.toUpperCase()} ${clients[0].lastName.toUpperCase()} - `;
    if (inspectionResult?.addressFirstLine)
      title += `${inspectionResult?.addressFirstLine} ${`${inspectionResult?.addressSecondLine || ''} `}- `;
    if (inspectionResult?.inspectionAt)
      title += `${moment.utc(inspectionResult.inspectionAt).local().format('YYYY-DD-MM – hh-mm A')}`;
    return `${title}.pdf`;
  };

  const downloadPdf =
    (type = PDF_TYPES[section]) =>
    async () => {
      try {
        mainInstance
          .get(`/public/inspections/pdf/${type}/${token}/${reportId}`, { responseType: 'arraybuffer' })
          .then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = formReportTitle(type);
            link.click();
          });
      } catch (e) {
        setTokenExpired(true);
      }
    };

  const downloadThermitePdf = async () => {
    try {
      mainInstance
        .get(`/public/inspections/pdf/TERMITE_REPORT/${token}/${reportId}`, { responseType: 'arraybuffer' })
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = formReportTitle('THERMITE');
          link.click();
        });
    } catch (e) {
      setTokenExpired(true);
    }
  };

  const fetchInspection = async () => {
    const inspection = await InspectionsAPI.getInspectionResult(token);
    setInspectionResult({
      ...inspection.data,
      templates: inspection.data.templates.sort((a, b) => {
        if (b.userId !== inspection.data.primaryInspectorId && a.userId === inspection.data.primaryInspectorId)
          return -1;
        if (b.userId === inspection.data.primaryInspectorId && a.userId !== inspection.data.primaryInspectorId)
          return 1;
        return 0;
      }),
    });
    return inspection;
  };

  const sortedReports = _.sortBy(inspectionResult?.templates, 'parentId');

  const intervalRef = useRef(null);

  useAsyncEffect(async () => {
    try {
      setIsLoading(true);
      const inspection = await fetchInspection();
      intervalRef.current = setInterval(fetchInspection, 30000);
      if (document.location.href.includes('/0/full-report'))
        history.push(`/result-inspection/${token}/${inspection.data.templates[0].id}/${section}`);
    } catch (e) {
      setTokenExpired(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return {
    selectSection,
    inactiveSection,
    downloadPdf,
    isTokenExpired,
    inspectionResult,
    report,
    selectedSection,
    clients,
    reportId,
    reportSection: section,
    isLoading,
    hasPdf,
    hasTermite,
    downloadThermitePdf,
    sortedReports,
  };
};
