import React from 'react';
import { Button, IconButton, Slide } from '@material-ui/core';
import { Form, Formik } from 'formik';
import cls from 'classnames';
import moment from 'moment';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useBlockerModal } from './useBlockerModal';
import useStyles from './useStyles';
import BlockerFormEdit from './BlockerFormEdit';

const BlockerModal = ({ calendarFetch, isEdit, blocker, setCalendarDataRaw }) => {
  const classes = useStyles();
  const { onModalClose, addBlocker, isModalOpen, initialValues, handleEditBlocker, isFullDay, setIsFullDay } =
    useBlockerModal({
      calendarFetch,
      isEdit,
      blocker,
      setCalendarDataRaw,
    });

  const getDateEnd = ({ recurrencePattern }) => {
    if (recurrencePattern?.includes('UNTIL=2')) {
      return ''.concat(
        recurrencePattern.substr(-16, 4),
        '-',
        recurrencePattern.substr(-12, 2),
        '-',
        recurrencePattern.substr(-10, 2),
        'T07:00:00.000Z',
      );
    }
    return undefined;
  };
  const getDateStart = ({ recurrencePattern, blockedAt }) => {
    if (recurrencePattern?.includes('DTSTART:2')) {
      return moment(
        ''.concat(
          recurrencePattern.substr(8, 4),
          '-',
          recurrencePattern.substr(12, 2),
          '-',
          recurrencePattern.substr(14, 2),
          'T00:00:00.000Z',
        ),
      );
    }
    return moment(blockedAt);
  };

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.blockerModal}>
        <div className={classes.blockerModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Not Available</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik
              onSubmit={isEdit ? handleEditBlocker : addBlocker}
              initialValues={
                isEdit
                  ? {
                      ...blocker,
                      date: getDateStart(blocker),
                      duration: blocker?.duration,
                      time: moment(blocker?.blockedAt),
                      dateEnd: getDateEnd(blocker),
                    }
                  : initialValues
              }
            >
              <Form>
                <BlockerFormEdit isFullDay={isFullDay} setIsFullDay={setIsFullDay} />
                <div className={classes.modalFooter}>
                  <Button type='submit' className={cls(classes.actionButton, classes.addInspectionButton)}>
                    {isEdit ? 'Edit' : 'Add'}
                  </Button>
                  <Button onClick={onModalClose} className={cls(classes.actionButton, classes.addBlockerButton)}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default BlockerModal;
