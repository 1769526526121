import React from 'react';
import cls from 'classnames';

import { noop } from 'utils/noop';
import { useFileUpload } from './useFileUpload';
import useStyles from './useStyles';

const FileUpload = ({
  local,
  uploadCallback,
  onFileUploaded,
  onFileRejected,
  uploadRoute,
  uploadField,
  onStartUploading = noop,
  onEndUploading = noop,
  acceptedTypes = 'image/*',
  sizeLimit = 40000000,
  isDisabled = false,
  dataCy = '',
  updateReportPhoto,
  updateReportAndInspectionPhoto,
  children,
  style = {},
  multiple,
  limit = 1,
  clientSide,
  galleryUpload,
}) => {
  const classes = useStyles();
  const { getInputProps, getRootProps, loading } = useFileUpload(
    local,
    uploadCallback,
    acceptedTypes,
    sizeLimit,
    onFileUploaded,
    onFileRejected,
    onStartUploading,
    onEndUploading,
    uploadRoute,
    uploadField,
    isDisabled,
    updateReportPhoto,
    updateReportAndInspectionPhoto,
    multiple,
    limit,
    clientSide,
    galleryUpload,
  );

  return (
    <div
      style={style}
      {...getRootProps({ className: cls('dropzone', classes.previewWrapper), 'data-cy-container': dataCy })}
    >
      {children && children(loading)}
      <input {...getInputProps()} multiple={multiple} />
    </div>
  );
};

export default FileUpload;
