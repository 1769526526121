import React, { createContext } from 'react';
import { noop } from 'utils/noop';

export const GlobalLoaderContext = createContext({
  isLoading: false,
  setIsGlobalLoading: noop,
});

export const GlobalLoaderContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const setIsGlobalLoading = React.useCallback(setIsLoading, []);

  return (
    <GlobalLoaderContext.Provider value={{ isLoading, setIsGlobalLoading }}>{children}</GlobalLoaderContext.Provider>
  );
};
