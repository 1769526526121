import React from 'react';
import { IconButton, Slide } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { closeModal } from 'redux/reducers/modals';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './useStyles';
import { getModalProps, getModalType } from 'redux/selectors/modals';

const CommentInfoModal = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { comment } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.commentInfoModal}>
        <div className={classes.commentInfoModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Additional Information</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <h3 className={classes.commentName}>{comment.name}</h3>
            <div>
              <h5 className={classes.infoTitle}>REMINDERS</h5>
              <div dangerouslySetInnerHTML={{ __html: comment?.reminders }} />
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default CommentInfoModal;
