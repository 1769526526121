/* eslint-disable */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, withStyles } from '@material-ui/core';

import { styles } from './styles';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log("Caught error:");
    console.log(error, info);
    console.log('1.err mess: ', error?.message);
    console.log('2.err info: ', info);
    console.log('3.err string: ', error?.toString());
    if (
      error?.toString().includes('ChunkLoadError')
    ) {
      console.log('4.err chunkload caught');
      setTimeout(() => {
        window.location.reload(true);
      });
      // TODO proper handling
    }

    this.setState({ hasError: true });
  }

  render() {
    const { children, classes } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.wrapper}>
          <h1 className={classes.title}>Something went wrong.</h1>
          <Button onClick={() => window.location.reload()} className={classes.goBack} disableRipple>
            Reload
          </Button>
        </div>
      );
    }

    return children;
  }
}

export default withRouter(withStyles(styles)(ErrorBoundary));
