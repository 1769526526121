import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';

import reducers from './reducers';
import { crashReporter } from './crashReporter';

const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        logger,
      })
    : compose;

const reducer = combineReducers({
  ...reducers,
});
const store = createStore(reducer, composeEnhancers(applyMiddleware(...[thunk, crashReporter])));

export { store, history };
