import React from 'react';
import { fileUrl } from 'config/config';
import { useImageEditor } from './useImageEditor';
import FilerobotImageEditor from 'react-filerobot-image-editor';

const ImageEditor = ({ imageName, handleCancel, handleSubmitImage, handleBeforeSubmitImage }) => {
  const { defaultSettings, onChangeSettings } = useImageEditor();

  return (
    <FilerobotImageEditor
      source={`${fileUrl}${imageName}`}
      onClose={(e) => {
        handleCancel();
      }}
      onModify={onChangeSettings}
      onBeforeSave={handleBeforeSubmitImage}
      onSave={handleSubmitImage}
      // annotationsCommon={defaultSettings}
      Text={defaultSettings?.Text || {}}
      Image={defaultSettings?.Image || {}}
      Rect={defaultSettings?.Rect || {}}
      Polygon={defaultSettings?.Polygon || {}}
      Ellipse={defaultSettings?.Ellipse || {}}
      Pen={defaultSettings?.Pen || {}}
      Line={defaultSettings?.Line || {}}
      Arrow={defaultSettings?.Arrow || {}}
    />
  );
};

export default ImageEditor;
