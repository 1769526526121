import { useRef } from 'react';

import mainInstance from 'api/mainInstance';
import { useLocation } from 'react-router-dom';

export const useMakeScreenshot = (galleryUpload) => {
  const screenRef = useRef(null);
  const loc = useLocation();
  const id = loc.pathname.split('/')?.[3];
  const reportId = loc.pathname.split('/')?.[5];

  const readCanvas = async (imgSrc) => {
    // const canvasURL = canvas.toDataURL();
    const blob = await fetch(imgSrc).then((res) => res.blob());
    const file = new File([blob], 'editedImage.png', { type: 'image/png' });
    const data = new FormData();
    data.append(!galleryUpload ? 'image' : 'file', file);
    if (galleryUpload) data.append('templateId', (reportId || id).toString());
    const uploadUrl = !galleryUpload ? '/admin/images' : '/template-files';
    const uploadResponse = await mainInstance.post(uploadUrl, data);
    return uploadResponse.data;
  };

  return { readCanvas, screenRef };
};
