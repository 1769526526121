import * as yup from 'yup';

export const AddCommentValidation = yup.object().shape({
  commentName: yup.string().trim().required('Comment name is required field'),
  commentType: yup.string().required('Comment type is required field'),
  notes: yup.string(),
  reminders: yup.string(),
  values: yup.lazy((val) =>
    Array.isArray(val)
      ? yup
          .array()
          .of(
            yup.lazy((arrayValue) =>
              typeof arrayValue === 'object'
                ? yup.object().shape({
                    from: yup.string().min(1).required('From field is required'),
                    to: yup.string().min(1).required('To field is required'),
                  })
                : yup.string().min(1).required('Option field is required'),
            ),
          )
          .required()
      : yup.string().min(1).required('Is required field'),
  ),
  defaultValue: yup
    .mixed()
    .when(['commentType'], (commentType) =>
      commentType === 'NUMBER' ? yup.array().min(1, 'At least one default value should be selected') : yup.mixed(),
    ),
});
