import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet } from 'styles/colors';

export default makeStyles({
  headerTopWrapper: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 2,
  },
  closeButton: {
    padding: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    color: Comet,
    margin: 0,
  },
});
