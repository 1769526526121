/* eslint-disable */
import axios from 'axios';
import { apiUrl } from 'config/config';
import { store } from 'redux/store';
import { clearAuthData, setAuth } from 'redux/user/reducer';
import { parseJwt } from 'utils/parseJwt';

const mainInstance = axios.create({
  baseURL: apiUrl,
});

mainInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().user.tokenData;
    // eslint-disable-next-line
    if (accessToken) config.headers.Authorization = accessToken;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

mainInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const { refreshToken } = store.getState().user.tokenData;
      if (refreshToken) {
        const originalRequest = error.config;
        const { accessToken } = store.getState().user.tokenData;
        const { id } = parseJwt(accessToken);
        return axios
          .post(`${apiUrl}/auth-token-refresh`, {
            refreshToken,
            userId: id,
          })
          .then((res) => {
            if (res.status === 200) {
              const { tokenData } = res.data;
              store.dispatch(setAuth({ tokenData }));
              originalRequest.headers.Authorization = tokenData.accessToken;
              return axios(originalRequest);
            }
          })
          .catch(() => {
            store.dispatch(clearAuthData());
          });
      }
    }
    return Promise.reject(error);
  },
);
export default mainInstance;
/* eslint-disable */
