import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, Jaffa, Red, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  defaultTextModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
  },
  defaultTextModalWrapper: {
    maxWidth: '100%',
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginTop: 40,
  },
  sectionTitle: {
    fontSize: 18,
    color: rgba(Comet, 0.7),
    margin: '16px 0',
  },
  categoriesWrapper: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 16,
    },
  },
  minorSubtitle: {
    fontSize: 16,
    color: RoyalBlue,
    margin: 0,
    marginBottom: 8,
  },
  mediumSubtitle: {
    fontSize: 16,
    color: Jaffa,
    margin: '16px 0 8px 0',
  },
  majorSubtitle: {
    fontSize: 16,
    color: Red,
    margin: '16px 0 8px 0',
  },
  submitButton: {
    background: BlueGradient,
    textTransform: 'none',
    fontWeight: 700,
    padding: '4px 16px',
    width: '100%',
    marginTop: 16,
    '&:hover': {
      background: BlueGradient,
      opacity: '0.7',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      display: 'block',
      width: 'auto',
      marginTop: 24,
    },
  },
}));
