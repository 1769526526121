import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet } from 'styles/colors';

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    flex: 1,
    padding: '0 24px',
    marginTop: ({ hasPdf }) => (hasPdf ? 62 : 124),
    [theme.breakpoints.up('sm')]: {
      marginTop: () => 62,
    },
    [theme.breakpoints.up('md')]: {
      margin: '62px 54px 0 54px',
      padding: '62px 54px 0 54px',
      borderLeft: `1px solid ${rgba(Comet, 0.1)}`,
      borderRight: `1px solid ${rgba(Comet, 0.1)}`,
    },
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: '16px 0 8px 0',
  },
}));
