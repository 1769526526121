import React from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Button, ButtonGroup } from '@material-ui/core';
import PermMediaIcon from '@material-ui/icons/PermMedia';
// import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CameraComponent from 'Components/Camera';
import CircularLoader from 'Components/CircularLoader';
import FileUpload from 'Components/FileUpload';
import { useCameraComponent } from 'Components/Camera/useCameraComponent';
import { useDispatch } from 'react-redux';
import { openGalleryModal } from 'redux/reducers/modals';

import useStyles from './useStyles';

const ATTACHMENT_LIMIT = 12;

const noBorderRadiusLeft = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
};
const noBorderRadiusRight = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
};

const MediaButtons = ({
  onToggleImageLoading,
  onToggleVideoLoading,
  onSavePhoto,
  onSaveVideo,
  photos,
  videos,
  isReport,
}) => {
  const classes = useStyles();
  const { reportId } = useParams();
  const dispatch = useDispatch();
  const { open } = useDropzone();
  const { setFieldValue } = useFormikContext();
  const { uploadPhotos, loadingCamera, cameraProgress } = useCameraComponent({
    onFileUploaded: onSavePhoto,
    onFileRejected: () => {},
    uploadField: 'image',
    uploadRoute: 'images',
    galleryUpload: isReport,
  });

  return (
    <ButtonGroup className={classes.mediaButtonsWrapper}>
      <FileUpload
        style={isReport ? { width: '200%' } : { width: '200%' }}
        onFileUploaded={onSaveVideo}
        onStartUploading={onToggleVideoLoading}
        onEndUploading={onToggleVideoLoading}
        acceptedTypes='video/*'
        uploadField='video'
        uploadRoute='videos'
        sizeLimit={50000000}
        isDisabled={videos.length >= ATTACHMENT_LIMIT}
        dataCy='comment-view-mode-video'
        galleryUpload={isReport}
      >
        {(loading) => (
          <div style={{ display: 'flex' }}>
            <CameraComponent
              childrenWrapClassname={classes.mediaButton}
              childrenWrapStyle={{ display: 'flex', borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
              onSubmit={uploadPhotos}
              onCancel={() => {}}
              limit={12 - photos.length}
              disabled={loading || photos.length >= ATTACHMENT_LIMIT}
            >
              <Button
                disabled={loading || photos.length >= ATTACHMENT_LIMIT}
                style={{ color: '#5B5F83', ...noBorderRadiusRight }}
              >
                {loadingCamera ? (
                  <>
                    <CircularLoader size={20} isWhite /> {cameraProgress.from}/{cameraProgress.to}
                  </>
                ) : (
                  <CameraAltIcon className={classes.mediaButtonIcon} />
                )}
              </Button>
            </CameraComponent>
            <Button
              style={{ ...noBorderRadiusLeft, ...noBorderRadiusRight }}
              className={classes.mediaButton}
              onClick={open}
              disabled={loading || videos.length >= ATTACHMENT_LIMIT}
              data-cy-button='upload-video-view-mode'
            >
              {loading && <CircularLoader size={20} />}
              {!loading && <VideoCallIcon className={classes.mediaButtonIcon} />}
            </Button>
          </div>
        )}
      </FileUpload>
      <FileUpload
        onFileUploaded={onSavePhoto}
        onStartUploading={onToggleImageLoading}
        onEndUploading={onToggleImageLoading}
        multiple
        limit={12 - photos.length}
        uploadField='image'
        uploadRoute='images'
        isDisabled={photos.length >= ATTACHMENT_LIMIT}
        dataCy='comment-view-mode-image'
        galleryUpload={isReport}
      >
        {(loading) => (
          <div style={{ display: 'flex' }}>
            <Button
              style={{ ...noBorderRadiusLeft, ...(isReport && noBorderRadiusRight) }}
              className={classes.mediaButton}
              onClick={open}
              disabled={loading || loadingCamera || photos.length >= ATTACHMENT_LIMIT}
              data-cy-button='upload-image-view-mode'
            >
              {loading && <CircularLoader size={20} />}
              {!loading && <AddPhotoAlternateIcon className={classes.mediaButtonIcon} />}
            </Button>
          </div>
        )}
      </FileUpload>
      {isReport && (
        <Button
          className={classes.mediaButton}
          style={noBorderRadiusLeft}
          onClick={() =>
            dispatch(
              openGalleryModal({
                id: reportId,
                setFieldValue,
                currentCommentPhotos: photos,
                currentCommentVideos: videos,
                limitPhotos: ATTACHMENT_LIMIT - photos.length,
                limitVideos: ATTACHMENT_LIMIT - videos.length,
              }),
            )
          }
          disabled={photos.length >= ATTACHMENT_LIMIT && videos.length >= ATTACHMENT_LIMIT}
        >
          <PermMediaIcon className={classes.mediaButtonIcon} />
        </Button>
      )}
    </ButtonGroup>
  );
};

export default MediaButtons;
