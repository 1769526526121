import React from 'react';
import moment from 'moment';

import { fileUrl } from 'config/config';
import { ReactComponent as DefaultAvatarIcon } from '../../assets/defaultAvatar.svg';
import useStyles from './useStyles';

const BlockerCard = ({ blocker }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardWrapper}>
      <span className={classes.time}>{moment(blocker.blockedAt).utc().format('hh:mm A')}</span>
      <div className={classes.card}>
        <div className={classes.contentLine}>
          <span className={classes.title}>Not Available</span>
          <span className={classes.timeRange}>
            {`${moment(blocker.blockedAt).utc().format('hh:mm A')} - ${moment(blocker.blockedAt)
              .utc()
              .add(blocker.duration, 'hours')
              .format('hh:mm A')}`}
          </span>
        </div>
        <div className={classes.contentLine}>
          <div className={classes.userWrapper}>
            <span>Schedule Modifier:</span>
            {blocker.user?.avatar ? (
              <img className={classes.userAvatar} src={`${fileUrl}${blocker.user.avatar}`} alt='' />
            ) : (
              <DefaultAvatarIcon className={classes.defaultAvatar} />
            )}
            <span>{`${blocker.user?.firstName} ${blocker.user?.lastName}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockerCard;
