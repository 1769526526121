import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';
import { Comet } from '../../../../../styles/colors';

export default makeStyles(() => ({
  filePreviewWrapper: {
    width: 200,
    height: 80,
    borderRadius: 5,
    border: `1px solid ${rgba(Comet, 0.5)}`,
  },
  fileDragButton: {
    marginRight: 10,
  },
  filePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  fileListItemTitle: {
    fontSize: 12,
    color: Comet,
    display: 'inline-block',
    textOverflow: 'ellipsis',
    width: 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileListItem: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      marginTop: 16,
    },
  },
}));
