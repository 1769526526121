import React, { useContext, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import cls from 'classnames';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import { ReactComponent as ExpertIcon } from '../../assets/expert.svg';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import Slider from '../../Slider';
import useStyles from './useStyles';
import { ConstantsContext } from 'shared/contexts/ConstantsContext';

const Comment = ({ comment }) => {
  const classes = useStyles();

  const { estimations, estimationsTypes, recommendedExperts } = useContext(ConstantsContext);

  const expert = recommendedExperts.find((recommendedExpert) => recommendedExpert.id === comment.recommendationId);
  const estimation = estimations.find((estimation) => estimation.id === comment.estimationId);
  const estimationType = estimationsTypes.find((estimationType) => estimationType.id === comment.estimationTypeId);

  const commentClass = useMemo(() => {
    if (comment.type === DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR) return classes.minorComment;
    if (comment.type === DEFICIENCIES_COMMENT_TYPES.ATTANCHENED_NEEDED) return classes.moderateComment;
    if (comment.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) return classes.majorComment;
    return '';
  }, []);

  const iconClass = useMemo(() => {
    if (comment.type === DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR) return classes.minorIcon;
    if (comment.type === DEFICIENCIES_COMMENT_TYPES.ATTANCHENED_NEEDED) return classes.moderateIcon;
    if (comment.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) return classes.majorIcon;
    return '';
  }, []);

  return (
    <div className={classes.commentWrapper}>
      <Accordion className={classes.accordionWrapper}>
        <AccordionSummary expandIcon={<ArrowIcon className={iconClass} />} className={classes.commentSummary}>
          {comment.type === DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR && <KeyIcon className={iconClass} />}
          {comment.type === DEFICIENCIES_COMMENT_TYPES.ATTANCHENED_NEEDED && <MinusIcon className={iconClass} />}
          {comment.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && <WarningIcon className={iconClass} />}
          <span className={cls(classes.commentTitle, commentClass)}>{comment.name}</span>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Slider photos={comment?.photos || []} videos={comment?.videos || []} />
          {comment.location && (
            <div className={classes.additionalInfoWrapper}>
              <span className={classes.additionalInfoTitle}>
                Locations: <span className={classes.additionalInfoValue}>{comment.location}</span>
              </span>
            </div>
          )}
          {comment.notes && (
            <div className={classes.additionalInfoWrapper}>
              <span className={classes.additionalInfoTitle}>
                Notes: <div dangerouslySetInnerHTML={{ __html: comment.notes }} />
              </span>
            </div>
          )}
          {comment.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && comment.estimationId !== 1 && (
            <div className={classes.additionalInfoWrapper}>
              <span className={classes.additionalInfoTitle}>
                Estimation:{' '}
                <span className={classes.additionalInfoValue}>
                  {`${estimation?.name} ${
                    comment.estimationId === 2
                      ? `$${comment.estimationValue?.from} to $${comment.estimationValue?.to} ${estimationType?.name}`
                      : ''
                  }`}
                </span>
              </span>
            </div>
          )}
          {expert?.id !== 1 && (
            <div className={classes.additionalInfoWrapper}>
              <ExpertIcon className={classes.expertIcon} />
              <span className={classes.additionalInfoTitle}>
                Recommended expert: <span className={classes.additionalInfoValue}>{expert?.name}</span>
              </span>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Comment;
