import { makeStyles } from '@material-ui/core';

import { Comet, White } from 'styles/colors';

export default makeStyles({
  notificationText: {
    color: Comet,
  },
  container: {
    zIndex: '9999999999',
    maxWidth: 300,
    '& .MuiPaper-root': {
      backgroundColor: White,
      color: Comet,
      border: '1px solid',
      borderRadius: 5,
      borderColor: (props) => props.borderColor,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '& .MuiSnackbarContent-message': {
      order: 1,
    },

    '& .MuiSnackbarContent-action': {
      padding: 0,
      margin: '0 !important',
      width: '100%',
      '& button': {
        margin: '0 !important',
        padding: 0,
      },
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: 0,
  },
  title: {
    fontSize: 16,
    color: Comet,
    fontWeight: 700,
  },
});
