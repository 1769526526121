import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, Jaffa, Red } from 'styles/colors';

export default makeStyles((theme) => ({
  detailsModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      width: '100%',
      maxWidth: 600,
      height: 'fit-content',
      borderRadius: 16,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 750,
    },
  },
  detailsModalWrapper: {
    maxHeight: '100%',
    minHeight: '30vh',
    height: 'fit-content',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80vh',
    },
  },
  modalHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    zIndex: 999,
    borderRadius: '16px 16px 0 0',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    margin: '73px 0 124px',
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px 0',
  },
  emptyTitle: {
    textAlign: 'center',
    color: rgba(Comet, 0.5),
    padding: '24px 0',
  },
  modalFooter: {
    padding: '24px 0',
    marginTop: 24,
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'absolute',
    left: 24,
    right: 24,
    bottom: 0,
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 700,
    flexBasis: '49%',
    padding: '12px 4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addBlockerButton: {
    flexBasis: ({ hasCreateHolderPermission }) => hasCreateHolderPermission && '100%',
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    marginTop: ({ hasCreateHolderPermission }) => hasCreateHolderPermission && 8,
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  addInspectionButton: {
    background: BlueGradient,
  },
  addHolderButton: {
    backgroundColor: rgba(Jaffa, 0.1),
    color: Jaffa,
    '&:hover': {
      backgroundColor: rgba(Jaffa, 0.3),
    },
  },
  plusIcon: {
    marginRight: 8,
  },
}));
