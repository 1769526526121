import React, { useMemo } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { fileUrl } from 'config/config';
import { ReactComponent as DefaultAvatarIcon } from '../../assets/defaultAvatar.svg';
import InspectionIdLine from '../InspectionIdLine';
import useStyles from './useStyles';

const DetailsCard = ({ inspection, onClose }) => {
  const inspectorColor = inspection?.primaryInspector?.calendarColor || '#004278';

  const classes = useStyles({ inspectorColor });
  const client = useMemo(() => inspection?.contacts.find((contact) => contact.type === 'CLIENT'), [inspection.id]);

  const primaryInspectorData = inspection?.primaryInspector;
  const inspectorDetails = primaryInspectorData?.avatar ? (
    <div className={classes.inspectorsWrapper}>
      <img
        key={primaryInspectorData?.id}
        className={classes.inspectorAvatar}
        src={`${fileUrl}${primaryInspectorData?.avatar}`}
        alt=''
      />
      <p className={classes.inspectorsPrimaryName}>
        {primaryInspectorData.firstName} {primaryInspectorData.lastName}
      </p>
    </div>
  ) : (
    <div className={classes.inspectorsWrapper}>
      <DefaultAvatarIcon key={primaryInspectorData?.id} className={classes.defaultAvatar} />
      <p className={classes.inspectorsPrimaryName}>
        {primaryInspectorData?.firstName} {primaryInspectorData?.lastName}
      </p>
    </div>
  );

  return (
    <div className={classes.cardWrapper}>
      <span className={classes.time}>{moment(inspection.inspectionAt).utc().local().format('hh:mm A')}</span>
      <Link
        onClick={onClose}
        to={`/system/inspections/${inspection.id}/view/property-information`}
        className={classes.card}
      >
        <div className={classes.contentLine}>
          <span className={classes.address}>
            {`${inspection.addressFirstLine}, ${inspection.addressSecondLine}, ${inspection.city}, ${inspection.state} ${inspection.zipCode}`}
          </span>

          <span className={classes.timeRange}>
            {`${moment(inspection.inspectionAt).utc().local().format('hh:mm A')} - ${moment(inspection.inspectionAt)
              .utc()
              .local()
              .add(inspection.duration, 'hours')
              .format('hh:mm A')}`}
          </span>
        </div>
        <div className={classes.contentLine}>
          <span className={classes.client}>
            Client: {client?.firstName} {client?.lastName}
          </span>
          <div className={classes.rightInfoWrapper}>
            <div className={classes.inspectorsWrapper}>
              <span className={classes.inspectorsTitle}>Inspectors:</span>
              {inspection?.primaryInspector ? inspectorDetails : <p className={classes.inspectorsTitle}>0</p>}
            </div>
            <span className={classes.reports}>Reports: {inspection.templatesCount}</span>
          </div>
        </div>
        <div className={classes.contentLine}>
          {inspection?.isnOid ? (
            <InspectionIdLine isnOid={inspection?.isnOid} inspectorColor={inspectorColor} />
          ) : (
            <InspectionIdLine id={inspection.id} inspectorColor={inspectorColor} />
          )}
        </div>
      </Link>
    </div>
  );
};

export default DetailsCard;
