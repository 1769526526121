import { makeStyles } from '@material-ui/core';

export default makeStyles({
  container: {
    zIndex: '9999999999',
    '& .MuiPaper-root': {
      backgroundColor: (props) => props.bgc,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiSnackbarContent-action': {
      maxWidth: 40,
      margin: '0 !important',
      '& button': {
        width: 'initial !important',
        margin: '0 !important',
      },
    },
  },
});
