import React, { useMemo } from 'react';
import { Link } from 'react-scroll';
import cls from 'classnames';

import { ICONS } from 'constants/icons';
import { ReactComponent as DefaultIcon } from 'assets/icons/defaultIcon.svg';
import { REPORT_SECTION } from '../options';
import useStyles from './useStyles';

const Sidebar = ({ onSelectSection, onInactiveSection, selectedSection, sections, reportSection, reportId }) => {
  const classes = useStyles();

  const renderSidebarItems = useMemo(() => {
    return sections?.map((section, idx) => {
      let commentCount = 0;
      section?.items?.forEach((item) => {
        if (item?.isDeficiencies) {
          if (reportSection === REPORT_SECTION.MAJOR_CONCERN) {
            item?.deficiencies?.forEach((comment) => {
              if (comment.type === 'ACTION_NECESSARY') commentCount += 1;
            });
          } else {
            commentCount += item?.deficiencies?.length;
          }
        }
      });

      const isDisabled = reportSection !== REPORT_SECTION.FULL_REPORTS && commentCount === 0;
      const Icon = ICONS[section?.icon] || DefaultIcon;
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          key={`${section.name}${idx + 1}`}
          className={cls(classes.listItem, { [classes.disabledItem]: isDisabled })}
          activeClass={classes.activeListItem}
          onSetActive={onSelectSection}
          onSetInactive={onInactiveSection}
          offset={-100}
          to={isDisabled ? '' : `${idx}`}
          spy
          smooth
          isDynamic
        >
          <Icon />
          <span className={classes.listItemTitle}>{section.name}</span>
          {commentCount > 0 && <span className={classes.commentCount}>{commentCount}</span>}
        </Link>
      );
    });
  }, [selectedSection, reportSection, reportId]);

  return <div className={classes.list}>{renderSidebarItems}</div>;
};

export default Sidebar;
