import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Jaffa } from 'styles/colors';

export default makeStyles({
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  time: {
    fontSize: 12,
    color: rgba(Comet, 0.5),
    fontFamily: 'RobotoMono, sans-serif',
  },
  card: {
    position: 'relative',
    minHeight: 120,
    flex: 1,
    marginLeft: 16,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderLeft: `1px solid ${Jaffa}`,
    backgroundColor: rgba(Jaffa, 0.05),
    cursor: 'pointer',
    '&::after': {
      position: 'absolute',
      content: '""',
      width: '100%',
      bottom: -16,
      left: 0,
      borderBottom: `1px dashed ${rgba(Comet, 0.15)}`,
    },
  },
  title: {
    color: Jaffa,
    fontSize: 14,
    fontWeight: 600,
  },
  contentLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  address: {
    color: Jaffa,
    fontSize: 14,
    fontWeight: 600,
    flex: 1,
  },
  timeRange: {
    color: Jaffa,
    fontSize: 12,
    fontFamily: 'RobotoMono, sans-serif',
  },
  client: {
    color: Jaffa,
    fontSize: 14,
    fontWeight: 600,
    flex: 1,
  },
  inspectorsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  inspectorsAvatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  inspectorsTitle: {
    color: Jaffa,
    fontSize: 14,
    fontWeight: 600,
    paddingRight: 8,
  },
  inspectorAvatar: {
    border: `1px solid ${Jaffa}`,
    borderRadius: '50%',
    width: 48,
    height: 48,
    objectFit: 'cover',
    marginLeft: 4,
  },
  defaultAvatar: {
    width: 26,
    height: 26,
    marginLeft: 4,
  },
  reports: {
    color: Jaffa,
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 24,
  },
});
