import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import cls from 'classnames';

import useStyled from './useStyles';

const TextField = ({
  value = null,
  required,
  helperText: message,
  error,
  className = false,
  disabled = false,
  children,
  multiline,
  ...props
}) => {
  const helperText = message || (required ? '* validation.required' : null);
  const classes = useStyled(!!error)();

  return (
    <MuiTextField
      id='outlined-basic'
      label='Outlined'
      variant='outlined'
      value={value}
      required={required}
      helperText={helperText}
      error={!!error}
      className={cls({ [className]: className }, classes.textField, { [classes.validTextField]: !error })}
      inputProps={{
        className: classes.input,
        autocomplete: 'chrome-off',
      }}
      spellCheck='true'
      disabled={disabled}
      multiline={multiline}
      {...props}
    >
      {children}
    </MuiTextField>
  );
};

export default TextField;
