import React from 'react';
import { Field, useFormikContext } from 'formik';
import { MenuItem, useTheme } from '@material-ui/core';

import TextEditor from 'Components/TextEditor';
import TextField from 'Components/TextField';
import SelectField from 'Components/SelectField';
import { ICONS, ICONS_LIST } from 'constants/icons';
import useStyles from './useStyles';
import Loader from 'Components/Loader';

const AddSectionForm = ({ availableSections, isReport, hasFetchedSections, isMasterTemplate }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { touched, values, setFieldValue } = useFormikContext();

  const renderSelectOptions = () =>
    ICONS_LIST.map((option) => {
      const Icon = option.icon;

      return (
        <MenuItem
          value={option.name}
          data-cy-item={option.name}
          style={{ background: theme.palette.primary.main, marginTop: -8, paddingTop: 14 }}
        >
          <Icon className={classes.selectMenuIcon} />
        </MenuItem>
      );
    });

  console.log(values?.selectedSections, values.filteredSectionName);

  const filteredAvailableSections = (availableSections || []).filter(
    (sect) => sect.name.toLowerCase().indexOf((values.filteredSectionName || '').trim().toLowerCase()) > -1,
  );

  return (
    <>
      {isReport || isMasterTemplate ? (
        <div className={classes.formRow}>
          <div className={classes.nameField}>
            <Field name='sectionName'>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  error={touched.sectionName && !!meta.error}
                  helperText={touched.sectionName && meta.error}
                  variant='outlined'
                  type='text'
                  label='SECTION NAME'
                  data-cy-input='section-name'
                />
              )}
            </Field>
          </div>
          <div className={classes.iconSelectField}>
            <Field name='sectionIcon'>
              {({ field, meta }) => (
                <SelectField
                  {...field}
                  error={touched.sectionIcon && !!meta.error}
                  helperText={touched.sectionIcon && meta.error}
                  variant='outlined'
                  type='text'
                  label='SECTION ICON'
                  data-cy-select='section-icon'
                  renderValue={(icon) => {
                    const Icon = ICONS[icon];
                    return <Icon />;
                  }}
                  autoWidth
                >
                  {renderSelectOptions()}
                </SelectField>
              )}
            </Field>
          </div>
        </div>
      ) : (
        <div className={classes.formRow}>
          <div className={classes.nameField}>
            <Field name='filteredSectionName'>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  error={touched.filteredSectionName && !!meta.error}
                  helperText={touched.filteredSectionName && meta.error}
                  variant='outlined'
                  type='text'
                  label='SECTION NAME'
                />
              )}
            </Field>
          </div>
          <div className={classes.iconSelectField}>
            <Field name={`selectedSections`}>
              {({ field, meta }) => (
                <SelectField
                  {...field}
                  multiple
                  error={touched.selectedSections && !!meta.error}
                  helperText={touched.selectedSections && meta.error}
                  className={classes.selectField}
                  label='SECTIONS'
                  labelId='templates'
                  renderValue={() => {
                    if (values?.selectedSections?.[0]) {
                      return (availableSections || [])
                        .find((s) => s.id === values?.selectedSections?.[0])
                        .name.split(' ')[0];
                    }
                    return '';
                  }}
                  placeholder='SECTIONS'
                  onChange={(e) => setFieldValue('selectedSections', e.target.value)}
                >
                  {!hasFetchedSections
                    ? [<Loader />]
                    : filteredAvailableSections.map((section) => (
                        <MenuItem key={section.id} value={section.id}>
                          {section.name}
                        </MenuItem>
                      ))}
                </SelectField>
              )}
            </Field>
          </div>
        </div>
      )}
      <div className={classes.formRow}>
        <div className={classes.formItem}>
          <TextEditor fieldName='standarts' placeholder='Standards of Practice' id='standards' />
          <Field name='standarts' type='text' hidden />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formItem}>
          <TextEditor fieldName='notes' placeholder='Reminders' id='notes' />
          <Field name='notes' type='text' hidden />
        </div>
      </div>
    </>
  );
};

export default AddSectionForm;
