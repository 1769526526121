import { useMemo } from 'react';

import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import { REPORT_SECTION } from '../options';

export const useDeficienciesComments = (section, reportSection) => {
  const deficienciesComments = useMemo(() => {
    const comments = [];
    section?.items?.forEach(
      (item) =>
        item?.isDeficiencies &&
        item?.deficiencies?.forEach((comment) => {
          if (reportSection === REPORT_SECTION.MAJOR_CONCERN) {
            if (comment.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) comments.push(comment);
          } else {
            comments.push(comment);
          }
        }),
    );
    return comments;
  }, [reportSection]);

  return { deficienciesComments };
};
