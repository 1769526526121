import React from 'react';
import { MenuItem } from '@material-ui/core';

import DropdownMenu from 'Components/DorpdownMenu';
import { useDispatch } from 'react-redux';
import { closeModal, openConfirmationModal } from 'redux/reducers/modals';
import { ReactComponent as PenIcon } from '../assets/pen.svg';
import { ReactComponent as CopyIcon } from '../assets/copy.svg';
import { ReactComponent as TrashIcon } from '../assets/trash.svg';
import { ReactComponent as ReplaceIcon } from '../assets/replase.svg';
import useStyles from './useStyles';

const ActionsMenu = ({
  onClose,
  onEdit,
  onDelete,
  onCopy,
  anchorEl,
  confirmMessage,
  onReplace = null,
  dataCy = '',
  showEdit = true,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onEditClick = () => {
    onEdit();
    onClose();
  };

  const onCopyClick = () => {
    onCopy();
    onClose();
  };

  const onReplaceClick = () => {
    onReplace();
    onClose();
  };
  const onSubmitDelete = () => {
    onDelete();
    onClose();
    dispatch(closeModal());
  };

  return (
    <DropdownMenu onClose={onClose} anchorEl={anchorEl}>
      {showEdit && (
        <MenuItem onClick={onEditClick} className={classes.actionButton} data-cy-item={`${dataCy}-edit`}>
          <PenIcon className={classes.actionIcon} />
          <span>Edit</span>
        </MenuItem>
      )}
      <MenuItem onClick={onCopyClick} className={classes.actionButton} data-cy-item={`${dataCy}-copy`}>
        <CopyIcon className={classes.actionIcon} />
        <span>Copy</span>
      </MenuItem>
      {onReplace && (
        <MenuItem onClick={onReplaceClick} className={classes.actionButton} data-cy-item={`${dataCy}-move`}>
          <ReplaceIcon className={classes.actionIcon} />
          <span>Move</span>
        </MenuItem>
      )}
      <MenuItem
        onClick={() =>
          dispatch(
            openConfirmationModal({
              onConfirm: onSubmitDelete,
              message: confirmMessage,
            }),
          )
        }
        className={classes.deleteButton}
        data-cy-item={`${dataCy}-delete`}
      >
        <TrashIcon className={classes.actionIcon} />
        <span>Trash</span>
      </MenuItem>
    </DropdownMenu>
  );
};

export default ActionsMenu;
