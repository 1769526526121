import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loader from 'Components/Loader';
import ErrorBoundary from 'Components/ErrorBoundary';
import { usePermissions } from 'hooks/usePermissions';
import Header from './Header';
import { useUploadEditedReport } from './useUploadEditedReport';
import useStyles from './useStyles';

const MainLayout = () => {
  const allowedRoutes = usePermissions();
  const { isLoading } = useUploadEditedReport();
  const classes = useStyles();
  return (
    <div className={classes.container} data-cy-container='loggedIn'>
      {isLoading && <Loader isSendingData={isLoading} />}
      {!isLoading && (
        <>
          <Header />
          <ErrorBoundary>
            <div id='scrollableDiv' className={classes.contentWrapper}>
              <Suspense fallback={<Loader />}>
                <Switch>
                  {allowedRoutes?.map(({ path, exact, Component }) => (
                    <Route exact={exact} path={path} key={path}>
                      <Component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </ErrorBoundary>
        </>
      )}
    </div>
  );
};

export default MainLayout;
