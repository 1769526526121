import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import TextField from 'Components/TextField';
import TextEditor from 'Components/TextEditor';
import useStyles from './useStyles';

const AddItemForm = ({ onToggleDeficiencies, isDeficiencies }) => {
  const classes = useStyles();
  const { touched } = useFormikContext();
  return (
    <>
      <div className={classes.formRow}>
        <div className={classes.formItem}>
          <Field name='itemName'>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={touched.itemName && !!meta.error}
                helperText={touched.itemName && meta.error}
                variant='outlined'
                type='text'
                label='ELEMENTS INSPECTED'
                data-cy-input='item-name'
              />
            )}
          </Field>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.checkboxWrapper}>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <Checkbox
                checked={isDeficiencies}
                onChange={onToggleDeficiencies}
                color='primary'
                data-cy-checkbox='deficiencies'
              />
            }
            label='Reportable Conditions'
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formItem}>
          <TextEditor fieldName='reminders' placeholder='Reminders' />
          <Field name='reminders' type='text' hidden />
        </div>
      </div>
    </>
  );
};

export default AddItemForm;
