import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Button, ButtonGroup, MenuItem } from '@material-ui/core';
import cls from 'classnames';

import TextField from 'Components/TextField';
import SelectField from 'Components/SelectField';
import TextEditor from 'Components/TextEditor';
import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { INSPECTOR_ESTIMATION_ID } from '../options';
import useStyles from './useStyles';

const AddDeficienciesCommentForm = ({ recommendedExperts, estimations, estimationTypes, isNotMobile, isReport }) => {
  const classes = useStyles();
  const { setFieldValue, touched, values } = useFormikContext();
  return (
    <>
      <Field name='commentName'>
        {({ field, meta }) => (
          <TextField
            {...field}
            error={touched.commentName && !!meta.error}
            helperText={touched.commentName && meta.error}
            variant='outlined'
            type='text'
            label='QUESTION TITLE'
            data-cy-input='comment-name'
          />
        )}
      </Field>
      <ButtonGroup className={classes.typeButtonsWrapper}>
        <Button
          className={cls(classes.typeButton, {
            [classes.actionNecessaryButton]: values.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY,
          })}
          onClick={() => setFieldValue('type', DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY)}
          data-cy-button='action-necessary'
        >
          <div className={classes.typeButtonTitleWrapper}>
            <WarningIcon
              className={cls({
                [classes.typeButtonSelectedIcon]: values.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY,
                [classes.typoButtonIcon]: values.type !== DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY,
              })}
            />
            {isNotMobile && <span className={classes.typeButtonTitle}>Significant Concern</span>}
          </div>
        </Button>
        <Button
          className={cls(classes.typeButton, {
            [classes.attachedButton]: values.type === DEFICIENCIES_COMMENT_TYPES.ATTANCHENED_NEEDED,
          })}
          onClick={() => setFieldValue('type', DEFICIENCIES_COMMENT_TYPES.ATTANCHENED_NEEDED)}
          data-cy-button='attached-needed'
        >
          <div className={classes.typeButtonTitleWrapper}>
            <MinusIcon
              className={cls({
                [classes.typeButtonSelectedIcon]: values.type === DEFICIENCIES_COMMENT_TYPES.ATTANCHENED_NEEDED,
                [classes.typoButtonIcon]: values.type !== DEFICIENCIES_COMMENT_TYPES.ATTANCHENED_NEEDED,
              })}
            />
            {isNotMobile && <span className={classes.typeButtonTitle}>Maintain/Minor Concern</span>}
          </div>
        </Button>
        <Button
          className={cls(classes.typeButton, {
            [classes.maintainButton]: values.type === DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR,
          })}
          onClick={() => setFieldValue('type', DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR)}
          data-cy-button='maintain-monitor'
        >
          <div className={classes.typeButtonTitleWrapper}>
            <KeyIcon
              className={
                values.type === DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR
                  ? classes.typeButtonSelectedIcon
                  : classes.typoButtonIcon
              }
            />
            {isNotMobile && <span className={classes.typeButtonTitle}>Recommendation</span>}
          </div>
        </Button>
      </ButtonGroup>
      <div className={classes.formItem}>
        <Field name='recommendedExpert'>
          {({ field, meta }) => (
            <SelectField
              {...field}
              error={touched.recommendedExpert && !!meta.error}
              helperText={touched.recommendedExpert && meta.error}
              labelId='recommendedExpert'
              label='RECOMMENDED EXPERT'
              placeholder='RECOMMENDED EXPERT'
              data-cy-select='recommended-expert'
            >
              {recommendedExperts?.map((expert) => (
                <MenuItem key={expert.id} value={expert.id} data-cy-item={expert.name}>
                  {expert.name}
                </MenuItem>
              ))}
            </SelectField>
          )}
        </Field>
      </div>
      {values.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && (
        <>
          <div className={classes.formItem}>
            <Field name='estimation'>
              {({ field, meta }) => (
                <SelectField
                  {...field}
                  error={touched.estimation && !!meta.error}
                  helperText={touched.estimation && meta.error}
                  labelId='estimation'
                  label='ESTIMATION'
                  placeholder='ESTIMATION'
                  data-cy-select='estimation'
                >
                  {estimations?.map((estimation) => (
                    <MenuItem key={estimation.id} value={estimation.id} data-cy-item={estimation.name}>
                      {estimation.name}
                    </MenuItem>
                  ))}
                </SelectField>
              )}
            </Field>
          </div>
          {values.estimation === INSPECTOR_ESTIMATION_ID && (
            <>
              <div className={classes.estimationValuesWrapper}>
                <div className={classes.estimationValueWrapper}>
                  <Field name='estimationFrom'>
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        error={touched.estimationFrom && !!meta.error}
                        helperText={touched.estimationFrom && meta.error}
                        variant='outlined'
                        type='text'
                        label='FROM'
                        placeholder='FROM'
                        data-cy-input='estimation-from'
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.estimationValueWrapper}>
                  <Field name='estimationTo'>
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        error={touched.estimationTo && !!meta.error}
                        helperText={touched.estimationTo && meta.error}
                        variant='outlined'
                        type='text'
                        label='TO'
                        placeholder='TO'
                        data-cy-input='estimation-to'
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={classes.formItem}>
                <Field name='estimationType'>
                  {({ field, meta }) => (
                    <SelectField
                      {...field}
                      error={touched.estimationType && !!meta.error}
                      helperText={touched.estimationType && meta.error}
                      labelId='estimationType'
                      label='ESTIMATION TYPE'
                      placeholder='ESTIMATION TYPE'
                      data-cy-select='estimation-type'
                    >
                      {estimationTypes?.map((estimationType) => (
                        <MenuItem key={estimationType.id} value={estimationType.id} data-cy-item={estimationType.name}>
                          {estimationType.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                  )}
                </Field>
              </div>
            </>
          )}
        </>
      )}

      <div className={classes.formItem}>
        <Field name='location'>
          {({ field, meta }) => (
            <TextField
              {...field}
              error={touched.location && !!meta.error}
              helperText={touched.location && meta.error}
              variant='outlined'
              type='text'
              label='LOCATION'
              placeholder='Specify the location'
              data-cy-input='location'
            />
          )}
        </Field>
      </div>

      <div className={classes.formItem}>
        <TextEditor fieldName='notes' placeholder='Notes' id='notes' />
        <Field name='notes' type='text' hidden />
      </div>
      {!isReport && (
        <div className={classes.formItem}>
          <TextEditor fieldName='reminders' placeholder='Reminders' id='reminders' />
          <Field name='reminders' type='text' hidden />
        </div>
      )}
    </>
  );
};

export default AddDeficienciesCommentForm;
