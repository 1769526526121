import React from 'react';
import { CircularProgress } from '@material-ui/core';

import useStyles from './useStyles';

const CircularLoader = ({ isWhite = false, ...restProps }) => {
  const classes = useStyles({ isWhite });
  return <CircularProgress className={classes.loader} {...restProps} />;
};

export default CircularLoader;
