import React from 'react';
import { Formik, Form } from 'formik';
import cls from 'classnames';
import { Button, Slide, IconButton, useMediaQuery, useTheme } from '@material-ui/core';

import CircularLoader from 'Components/CircularLoader';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { AddDeficienciesCommentValidation } from './validation';
import { useAddDeficienciesComment } from './useAddDeficienciesComment';
import CommentFilesUpload from '../CommentFilesUpload';
import { initialValues } from './initialValues';
import useStyles from './useStyles';
import AddDeficienciesCommentForm from './AddDeficienciesCommentForm';

const AddDeficienciesCommentModal = () => {
  const classes = useStyles();
  const {
    submit,
    onModalClose,
    recommendedExperts,
    estimations,
    estimationsTypes,
    comment,
    isModalOpen,
    isCreating,
    hasIndex,
    isReport,
  } = useAddDeficienciesComment();

  const theme = useTheme();

  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.addCommentModal} data-cy-container='add-deficiencies-comment'>
        <div className={classes.addCommentModalWrapper} data-cy-container='add-deficiencies-comment-wrapper'>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{isCreating ? 'Add a New Comment' : 'Edit comment'}</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik
              initialValues={hasIndex ? comment : initialValues}
              validationSchema={AddDeficienciesCommentValidation}
              onSubmit={submit}
            >
              {({ handleSubmit }) => (
                <Form>
                  <AddDeficienciesCommentForm
                    recommendedExperts={recommendedExperts}
                    estimations={estimations}
                    estimationTypes={estimationsTypes}
                    isNotMobile={isNotMobile}
                    isReport={isReport}
                  />
                  <CommentFilesUpload />
                  <div className={classes.modalFooter}>
                    <Button
                      onClick={onModalClose}
                      className={cls(classes.actionButton, classes.cancelButton)}
                      data-cy-button='cancel-add-comment'
                    >
                      Cancel
                    </Button>
                    <Button
                      className={`${classes.actionButton} ${classes.submitButton}`}
                      onClick={() => setTimeout(handleSubmit, 100)}
                      data-cy-button='submit-add-comment'
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default AddDeficienciesCommentModal;
