import React from 'react';
import { Formik, Field } from 'formik';
import { Button } from '@material-ui/core';

import TextField from 'Components/TextField';
import { ResetPasswordSchema } from './validation';
import useStyles from './useStyles';

const ResetPassword = ({ onCancel, onReset }) => {
  const classes = useStyles();

  return (
    <>
      <h2 className={classes.title}>Reset Password</h2>
      <Formik
        initialValues={{ password: '', passwordConfirm: '' }}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => onReset(values)}
      >
        {({ touched, handleSubmit }) => (
          <div className={classes.formWrapper}>
            <Field name='password'>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  error={touched.password && !!meta.error}
                  helperText={touched.password && meta.error}
                  variant='outlined'
                  type='password'
                  label='New password'
                  data-cy-input='new-password'
                />
              )}
            </Field>
            <Field name='passwordConfirm'>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  className={classes.input}
                  error={touched.passwordConfirm && !!meta.error}
                  helperText={touched.passwordConfirm && meta.error}
                  variant='outlined'
                  type='password'
                  label='Confirm Password'
                  data-cy-input='confirm-password'
                />
              )}
            </Field>

            <div className={classes.buttonWrapper}>
              <Button onClick={handleSubmit} className={classes.resetButton} data-cy-button='reset'>
                Reset
              </Button>
              <Button onClick={onCancel} className={classes.cancelButton} data-cy-button='cancel'>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
