import { makeStyles } from '@material-ui/core';

import { Comet, RoyalBlue } from 'styles/colors';
import { rgba } from 'polished';

export default makeStyles((theme) => ({
  userMenu: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    minWidth: 293,
    backgroundColor: theme.palette.primary.main,
    padding: '24px 0',
    overflowY: 'scroll',
  },
  userMenuHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: `0 1px 0 0 ${rgba(Comet, 0.1)}`,
    padding: '0 24px 16px 24px',
  },
  userMenuTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
  },
  closeButton: {
    padding: 0,
  },
  dropAccordionSummaryStyle: {
    '&.MuiAccordionSummary-root': {
      minHeight: 0,
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0,
    },
    '&>.MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '&>.MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
    },
  },
  accordionTitle: {
    padding: '24px',
    boxShadow: `0 1px 0 0 ${rgba(Comet, 0.1)}`,
    textTransform: 'none',
  },
  userInfo: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  avatarWrapper: {
    width: 40,
    height: 40,
  },
  userName: {
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: 400,
    color: Comet,
  },
  userAccordion: {
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '&.MuiAccordion-root': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-root:before': {
      content: 'none',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  userAccordionList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    boxShadow: `0 1px 0 0 ${rgba(Comet, 0.1)}`,
  },
  userAccordionItem: {
    fontSize: 14,
    color: Comet,
    cursor: 'pointer',
    '&:not(:first-child)': {
      paddingTop: 16,
    },
  },
  notification: {
    padding: '26px 24px',
    boxShadow: `0 1px 0 0 ${rgba(Comet, 0.1)}`,
  },
  notificationButton: {
    padding: 0,
  },
  notificationTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: Comet,
    paddingLeft: 10,
  },
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
  },
  navigationItem: {
    color: Comet,
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    flex: 1,
    '&:not(:first-child)': {
      paddingTop: 24,
    },
  },
  selected: {
    color: RoyalBlue,
    fontWeight: 700,
  },
  navigationAccordionTitle: {
    padding: 0,
  },
  notificationItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 300,
    padding: '8px 0',
    textTransform: 'none',
    textAlign: 'left',
    fontSize: 12,
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    },
  },
  notificationItemMessage: {
    color: Comet,
  },
  emptyNotificationsTitle: {
    color: Comet,
    fontSize: 14,
    fontWeight: 400,
  },
  notificationLoadingProggress: {
    backgroundColor: RoyalBlue,
    height: 4,
    position: 'absolute',
    width: 0,
    bottom: 0,
    left: 0,
  },
}));
