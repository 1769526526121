import { noop } from 'utils/noop';

export const NOTIFICATION_TYPES = {
  INSPECTION_CREATED: {
    type: 'INSPECTION_CREATED',
    link: ({ inspectionId }) => `/system/inspections/${inspectionId}/view/subject-property`,
  },
  ISN_INSPECTION_CREATED: {
    type: 'ISN_INSPECTION_CREATED',
    link: ({ inspectionId }) => `/system/inspections/${inspectionId}/view/subject-property`,
  },
  INSPECTION_STARTS_IN_NEXT_24_HOURS: {
    type: 'INSPECTION_STARTS_IN_NEXT_24_HOURS',
    link: ({ inspectionId }) => `/system/inspections/${inspectionId}/view/subject-property`,
  },
  INSPECTION_STARTS_IN_NEXT_HOUR: {
    type: 'INSPECTION_STARTS_IN_NEXT_HOUR',
    link: ({ inspectionId }) => `/system/inspections/${inspectionId}/view/subject-property`,
  },
  INSPECTION_CHANGE_DATE_AND_TIME: {
    type: 'INSPECTION_CHANGE_DATE_AND_TIME',
    link: ({ inspectionId }) => `/system/inspections/${inspectionId}/view/subject-property`,
  },
  REPORT_COMPLETED: {
    type: 'REPORT_COMPLETED',
    link: ({ inspectionId, additionalParams: { reportId } }) =>
      `/system/inspections/${inspectionId}/reports/${reportId}`,
  },
  INSPECTION_DELETED: {
    type: 'INSPECTION_DELETED',
    link: noop,
  },
  PASSWORD_CHANGED: {
    type: 'PASSWORD_CHANGED',
    link: noop,
  },
  HOLDER_CREATED: {
    type: 'HOLDER_CREATED',
    link: noop,
  },
  ISN_INSPECTION_CANCELLED: {
    type: 'ISN_INSPECTION_CANCELLED',
    link: noop,
  },
  REPORT_FILE_CREATED: {
    type: 'REPORT_FILE_CREATED',
    link: noop,
  },
  JOB_STATUS_UPDATED: {
    type: 'JOB_STATUS_UPDATED',
    link: noop,
  },
  REPORT_MEDIA_ARCHIVED: {
    type: 'REPORT_MEDIA_ARCHIVED',
    link: noop,
  },
};
