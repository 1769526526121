import React, { useState, useContext, useRef, useEffect } from 'react';
import cls from 'classnames';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Button, IconButton, useTheme } from '@material-ui/core';
import { AlertContext } from 'Components/Alert/alertContext';
import { saveAs } from 'file-saver';
import useStyles from './useStyles';

import 'react-html5-camera-photo/build/css/index.css';

import CameraIcon from '@material-ui/icons/Camera';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import { FlashOff, FlashOn } from '@material-ui/icons';
import Slider from '@material-ui/core/Slider';
import dataURItoBlob from 'utils/dataURIToBlob';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const CameraComponent = ({
  visible,
  disabled,
  onSubmit,
  onCancel,
  children,
  childrenWrapClassname = '',
  childrenWrapStyle,
  limit = 99,
}) => {
  const [photos, setPhotos] = useState([]);
  const [isOpen, setIsOpen] = useState(visible);
  const [cameraTrack, setCameraTrack] = useState();
  const [flash, setFlash] = useState();
  const [previewIndex, setPreviewIndex] = useState(-1);
  const [flashError, setFlashError] = useState(false);
  const { handleSetMessage } = useContext(AlertContext);
  const classes = useStyles();
  const scrollInto = useRef(null);
  const theme = useTheme();

  const { reportId } = useParams();

  useEffect(() => {
    if (scrollInto?.current?.scrollIntoView) {
      scrollInto.current.scrollIntoView();
    }
  });

  const toggleFlash = () => {
    if (cameraTrack) {
      setFlash((old) => !old);
      cameraTrack.applyConstraints({
        advanced: [{ torch: !flash }],
      });
    }
  };

  const changeZoom = (zoom) => {
    // max - 8
    const capabilities = cameraTrack.getCapabilities();
    // Check whether zoom is supported or not.
    if (!capabilities.zoom) {
      return;
    }
    cameraTrack.applyConstraints({ advanced: [{ zoom: Number(zoom) }] });
  };

  const addPhoto = (photo) => {
    if (photo !== 'data:,') {
      setPhotos((old) => [photo, ...old]);
    }
  };
  const removePhoto = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  };
  const handleDelete = (index) => () => {
    removePhoto(index);
    setPreviewIndex(index - 1);
  };
  const handlePreview = (index) => () => {
    setPreviewIndex(index);
  };
  const handleSubmit = () => {
    onSubmit(photos);
    setPhotos([]);
    setPreviewIndex(-1);
    setIsOpen(false);
  };
  const handleCancel = () => {
    setPhotos([]);
    setPreviewIndex(-1);
    setIsOpen(false);
  };

  const handleTakePhoto = (dataUri) => {};

  const handleTakePhotoAnimationDone = (dataUri) => {
    saveAs(dataURItoBlob(dataUri), `${reportId || 'photo'}_${moment(new Date()).format('MM/DD/YYYY-h:mm:ss')}.jpg`);
    if (photos.length < limit) addPhoto(dataUri);
    else handleSetMessage('Photo limit reached', 'error');
  };

  const handleCameraError = (error) => {
    console.log('handleCameraError', error);
  };

  const handleCameraStart = (stream) => {
    const track = stream.getVideoTracks()[0];
    try {
      const imageCapture = new ImageCapture(track);
      const photoCapabilities = imageCapture.getPhotoCapabilities().then(() => {
        setCameraTrack(track);
      });
    } catch (e) {
      setFlashError(true);
    }
  };

  const handleCameraStop = () => {};

  return isOpen ? (
    <div
      className={classes.cameraFullscreenWrap}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={classes.cameraFullscreenHeader} ref={scrollInto}>
        <Button variant='contained' onClick={handleCancel} startIcon={<CancelIcon />}>
          Cancel
        </Button>
        <Button
          disabled={previewIndex === -1}
          onClick={handleDelete(previewIndex)}
          variant='contained'
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>

        <Button variant='contained' disabled={photos?.length < 1} onClick={handleSubmit} endIcon={<SendIcon />}>
          Submit
        </Button>
      </div>
      <div style={{ height: 'calc(88vh - 50px)' }}>
        <IconButton
          className={classes.flashIcon}
          // variant='contained'
          onClick={
            flashError
              ? () => {
                  handleSetMessage('Camera flash not supported by your browser', 'error');
                }
              : toggleFlash
          }
        >
          {flash ? <FlashOn color='white' /> : <FlashOff color='white' />}
        </IconButton>
        {previewIndex === -1 ? (
          <Camera
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
            onTakePhotoAnimationDone={(dataUri) => {
              handleTakePhotoAnimationDone(dataUri);
            }}
            onCameraError={(error) => {
              handleCameraError(error);
            }}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            // idealResolution={{ width: 640, height: 480 }}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.97}
            isMaxResolution={true}
            isImageMirror={false}
            isSilentMode={false}
            isDisplayStartCameraError={true}
            isFullscreen={false}
            sizeFactor={1}
            onCameraStart={(stream) => {
              handleCameraStart(stream);
            }}
            onCameraStop={() => {
              handleCameraStop();
            }}
          />
        ) : (
          <img className={classes.fullscreenPhoto} src={photos[previewIndex]} alt='preview' />
        )}
        <div className={classes.galleryWrap}>
          <div className={classes.galleryCamera} onClick={handlePreview(-1)}>
            <CameraIcon />
          </div>
          {photos.map((photo, i) => (
            <div onClick={handlePreview(i)} className={classes.galleryPhoto} key={photo}>
              <img src={photo} alt='preview' />
            </div>
          ))}
        </div>
        <Slider
          step={1}
          defaultValue={1}
          marks
          min={1}
          max={8}
          onChange={(e, value) => changeZoom(value)}
          style={{ width: '70%', marginLeft: '15%', marginTop: 32, color: theme.palette.isDark ? '#fff' : '#14161a' }}
        />
      </div>
    </div>
  ) : (
    <div
      className={cls(childrenWrapClassname, disabled ? classes.cameraButtonDisabled : '')}
      style={childrenWrapStyle}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) setIsOpen(true);
      }}
    >
      {children}
    </div>
  );
};

export default CameraComponent;
