import React from 'react';
import { Formik, Form } from 'formik';
import { Button, IconButton, Slide } from '@material-ui/core';
import cls from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useAddLocation } from './useAddLocation';
import useStyles from './useStyles';
import AddLocationForm from './AddLocationForm';

const AddLocationModal = () => {
  const classes = useStyles();

  const { submit, locationSelect, onModalClose, location, isModalOpen } = useAddLocation();

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.addLocationModal}>
        <div className={classes.addLocationModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Locations</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik initialValues={{ location: location || '' }} onSubmit={submit}>
              <Form>
                <AddLocationForm onLocationSelect={locationSelect} />
                <div className={classes.modalFooter}>
                  <Button onClick={onModalClose} className={cls(classes.actionButton, classes.cancelButton)}>
                    Cancel
                  </Button>
                  <Button className={cls(classes.actionButton, classes.submitButton)} type='submit'>
                    Submit
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default AddLocationModal;
