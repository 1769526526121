import { makeStyles } from '@material-ui/core';

import { RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  columnWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 114,
    },
  },
  columnHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    margin: 0,
  },
  buttonAddNew: {
    color: RoyalBlue,
    fontSize: 12,
    borderBottom: `1px dashed ${RoyalBlue}`,
    textTransform: 'none',
    borderRadius: 0,
  },
  buttonAddNewMobile: {
    padding: 0,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 307px)',
      overflowY: 'auto',
    },
  },
}));
