import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@material-ui/core';

import Logo from 'Components/Logo';
import { ReactComponent as ArrowRight } from './assets/arrowRight.svg';
import { useLogin } from './useLogin';
import { LoginSchema } from './validation';
import useStyles from './useStyles';
import LoginForm from './LoginForm';

const Login = () => {
  const classes = useStyles();
  const { toggleChecked, login, shouldKeepLogin } = useLogin();

  return (
    <>
      <header className={classes.loginHeader} />
      <div className={classes.contentWrapper}>
        <Logo isLogin />
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={login}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form className={classes.formWrapper}>
            <LoginForm onToggleChecked={toggleChecked} shouldKeepLogin={shouldKeepLogin} />
            <Button type='submit' data-cy-button='login' className={classes.loginButton}>
              <span>Log In </span>
              <ArrowRight className={classes.iconWrapper} />
            </Button>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default Login;
