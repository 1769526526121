import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';

export const useAddItem = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { index, isCreating, onEditSection, section } = useSelector(getModalProps);
  const [isLoading, setIsLoading] = useState(false);
  const onModalClose = () => dispatch(closeModal());

  const hasIndex = index !== undefined;
  const [deficiencies, setDeficiencies] = useState(hasIndex ? section.items[index].isDeficiencies : false);

  const templateItem = hasIndex && {
    itemName: section.items[index].name,
    reminders: section.items[index].reminders,
  };

  const toggleDeficiencies = () => setDeficiencies(!deficiencies);

  const submit = async (values) => {
    const data = { name: values.itemName, reminders: values.reminders, isDeficiencies: deficiencies };
    const updatedSection = { ...section };
    if (isCreating) {
      if (!updatedSection.items) updatedSection.items = [];
      updatedSection.items.push(data);
    } else {
      updatedSection.items[index] = { ...updatedSection.items[index], ...data };
    }
    setIsLoading(true);
    await onEditSection(updatedSection);
    setIsLoading(false);

    onModalClose();
  };

  return {
    submit,
    toggleDeficiencies,
    onModalClose,
    templateItem,
    deficiencies,
    hasIndex,
    isModalOpen,
    isCreating,
    isLoading,
  };
};
