import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import cls from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { GALLERY_SECTIONS } from '../options';
import useStyles from './useStyles';
import GalleryControls from '../GalleryControls';

const Header = ({ onClose, onSectionSelect, selectedSection, fetchGallery }) => {
  const classes = useStyles();
  return (
    <div className={classes.headerTopWrapper}>
      <h2 className={classes.title}>Gallery</h2>
      <div className={classes.tabsWrapper}>
        <Button
          onClick={() => onSectionSelect(GALLERY_SECTIONS.PHOTO)}
          className={cls(classes.tabLink, { [classes.selectedTabLink]: selectedSection === GALLERY_SECTIONS.PHOTO })}
          disableRipple
        >
          Photos
        </Button>
        <Button
          onClick={() => onSectionSelect(GALLERY_SECTIONS.VIDEO)}
          className={cls(classes.tabLink, { [classes.selectedTabLink]: selectedSection === GALLERY_SECTIONS.VIDEO })}
          disableRipple
        >
          Videos
        </Button>
      </div>
      <GalleryControls fetchGallery={fetchGallery} onClose={onClose} />
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </div>
  );
};

export default Header;
