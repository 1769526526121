/* eslint-disable react/jsx-no-bind */
import { IconButton, Slide, Button, OutlinedInput, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import React, { useContext, useEffect, useState } from 'react';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import { AlertContext } from 'Components/Alert/alertContext';
import { closeModal } from 'redux/reducers/modals';
import { SortableItem } from './SortableItem';
import { Grid } from './Grid';
import useStyles, { useOutlinedInputStyles } from './useStyles';
import { ConstantsContext } from 'shared/contexts/ConstantsContext';
import OtherAPI from 'api/other';
import { getModalType } from 'redux/selectors/modals';

const EditLocationsModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { locations, changeConstant } = useContext(ConstantsContext);
  const { handleSetMessage } = useContext(AlertContext);
  const [items, setItems] = useState(locations[0].locations || null);
  const [locationVal, setLocationVal] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const sensors = useSensors(useSensor(PointerSensor));
  const onModalClose = () => dispatch(closeModal());

  const addNewLocationLocal = (location) => () => {
    location ? setItems((prev) => [location, ...prev]) : handleSetMessage('Location cannot be empty', 'error');
    setLocationVal('');
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((itemsArg) => {
        const oldIndex = itemsArg.indexOf(active.id);
        const newIndex = itemsArg.indexOf(over.id);

        return arrayMove(itemsArg, oldIndex, newIndex);
      });
    }
  }

  const handleOnChange = (e) => setLocationVal(e.target.value);

  const handleOnChangeText = (item) => (newText) => {
    const findItem = items.findIndex((x) => x === item);
    const copyState = items.slice();
    copyState[findItem] = newText;
    setItems(copyState);
    setLocationVal('');
  };

  const onSubmitToSaveChanges = (changes) => () => {
    OtherAPI.putLocations({ id: 1, locations: changes });
    changeConstant('locations', [{ id: 1, locations: changes }]);
    handleSetMessage('Locations was successfully edited!', 'success');
    setLocationVal('');
    onModalClose();
  };

  const onRemoveLocationLocal = (item) => () => setItems((prev) => prev.filter((loc) => loc !== item));

  useEffect(() => {
    setItems(locations[0].locations);
  }, [locations]);

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.addLocationModal}>
        <div className={classes.addLocationModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Locations</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <div className={classes.addNewLocationWrapper}>
              <OutlinedInput
                id='outlined-basic'
                onChange={handleOnChange}
                value={locationVal}
                className={`${classes.input} ${useOutlinedInputStyles}`}
                variant='outlined'
                type='text'
                placeholder='Location name'
              />
              <Button
                style={{ marginLeft: 8 }}
                className={classes.addLocationButton}
                type='button'
                disabled={!locationVal}
                onClick={addNewLocationLocal(locationVal)}
              >
                <AddIcon />
              </Button>
            </div>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={items} strategy={rectSortingStrategy}>
                <Grid columns={isMobile ? 1 : 2}>
                  {items &&
                    Array.isArray(items) &&
                    items.map((id) => (
                      <SortableItem
                        onRemoveLocationLocal={onRemoveLocationLocal(id)}
                        handleOnChangeText={handleOnChangeText(id)}
                        className={classes.locationOption}
                        key={id}
                        id={id}
                      />
                    ))}
                </Grid>
              </SortableContext>
            </DndContext>
            <div className={classes.modalFooter}>
              <Button
                onClick={onModalClose}
                className={cls(classes.actionButton, classes.cancelButton)}
                data-cy-button='cancel-move'
              >
                Cancel
              </Button>
              <Button
                className={cls(classes.actionButton, classes.submitButton)}
                type='submit'
                data-cy-button='submit-move'
                onClick={onSubmitToSaveChanges(items)}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default EditLocationsModal;
