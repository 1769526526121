import React, { useEffect } from 'react';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { useDropdownMenu } from 'Components/DorpdownMenu/useDropdownMenu';
import { useDispatch } from 'react-redux';
import { openAddItemModal, openReplaceItemModal } from 'redux/reducers/modals';
import ActionsMenu from '../ActionsMenu';
import useStyles from './useStyles';
import Item from './Item';
import { useRef } from 'react';

const ItemsColumn = ({
  items = [],
  onItemSelect,
  onEditSection,
  sections,
  selectedSection,
  selectedItem,
  isReport,
  shouldRenderNextButton = false,
  isOutlinedItem = false,
  onReplaceItem,
  onCopyItem,
  onDeleteItem,
  onReorderItems,
}) => {
  const section = sections?.[selectedSection];
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { delay: 300, tolerance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 300, tolerance: 5 } }),
  );

  const itemsColumn = useRef(null);

  useEffect(() => {
    if (itemsColumn.current) itemsColumn.current.scrollTo(0, 0);
  }, [selectedSection]);

  const hasSection = selectedSection !== null;

  const { onDropdownOpen, onDropdownClose, anchorEl } = useDropdownMenu();

  const isShown = (e) =>
    ((e?.isPublic !== false || !isReport) &&
      !document.location.href.includes('/templates/') &&
      e?.name !== 'Inspector’s Signature') ||
    document.location.href.includes('/templates/');

  return (
    <div className={classes.columnWrapper}>
      <div className={classes.columnHeader}>
        <h3 className={classes.title}>ELEMENTS INSPECTED</h3>
        {isDesktop && (
          <Button
            disabled={!hasSection}
            onClick={() =>
              dispatch(
                openAddItemModal({
                  onEditSection: (data) => onEditSection(data, selectedSection),
                  isCreating: true,
                  section: section,
                }),
              )
            }
            className={classes.buttonAddNew}
            data-cy-button='add-item'
          >
            + Add New
          </Button>
        )}
        {!isDesktop && hasSection && (
          <IconButton
            disabled={!hasSection}
            onClick={() =>
              dispatch(
                openAddItemModal({
                  onEditSection: (data) => onEditSection(data, selectedSection),
                  isCreating: true,
                  section: section,
                }),
              )
            }
            className={classes.buttonAddNewMobile}
          >
            <PlusIcon />
          </IconButton>
        )}
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(data) => onReorderItems(data, selectedSection)}
      >
        <div className={classes.column} ref={itemsColumn}>
          <SortableContext items={items.map((item, i) => i + 1)} strategy={verticalListSortingStrategy}>
            {items.map(
              (item, idx) =>
                isShown(item) && (
                  <Item
                    key={`${item.name}${idx + 1}`}
                    onItemSelect={onItemSelect}
                    onDropdownOpen={onDropdownOpen}
                    item={item}
                    selectedItem={selectedItem}
                    idx={idx}
                    isReport={isReport}
                    shouldRenderNextButton={shouldRenderNextButton}
                    isOutlinedItem={isOutlinedItem}
                  />
                ),
            )}
          </SortableContext>
        </div>
      </DndContext>
      <ActionsMenu
        onClose={onDropdownClose}
        onEdit={() =>
          dispatch(
            openAddItemModal({
              onEditSection: (data) => onEditSection(data, selectedSection),
              isCreating: false,
              index: +anchorEl?.dataset?.idx,
              section: section,
            }),
          )
        }
        onCopy={() => onCopyItem(+anchorEl?.dataset?.idx, selectedSection)}
        onDelete={() => onDeleteItem(+anchorEl?.dataset?.idx, selectedSection)}
        onReplace={() =>
          dispatch(
            openReplaceItemModal({
              onReplace: (sectionIdx, itemIdx) =>
                onReplaceItem(sectionIdx, itemIdx, selectedSection, +anchorEl?.dataset?.idx),
              index: +anchorEl?.dataset?.idx,
              selectedSection,
              sections,
            }),
          )
        }
        anchorEl={anchorEl}
        confirmMessage='Are you sure you want to delete item ?'
        dataCy='item'
      />
    </div>
  );
};

export default ItemsColumn;
