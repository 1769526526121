import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';

import ResetPassword from 'Components/ResetPassword';
import Logo from 'Components/Logo';
import useStyles from './useStyles';
import { useResetPasswordByToken } from './useResetPasswordByToken';

const ResetPasswordByToken = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { cancel, reset } = useResetPasswordByToken();

  return (
    <>
      <div className={classes.header}>
        <NavLink to='/login'>
          <Logo isMobile={isMobile} />
        </NavLink>
      </div>
      <div className={classes.contentWrapper}>
        <ResetPassword onCancel={cancel} onReset={reset} />
      </div>
    </>
  );
};

export default ResetPasswordByToken;
