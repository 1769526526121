import { makeStyles } from '@material-ui/core';

import { White } from 'styles/colors';

export default makeStyles(() => ({
  previewWrapper: {
    maxWidth: 'fit-content',
    maxHeight: '80vh',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    position: 'absolute',
  },
  imageWrapper: {
    position: 'relative',
    height: '100%',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    margin: ' 0 auto',
  },
  image: {
    height: '100%',
    objectFit: 'contain',
  },
  closeButton: {
    position: 'absolute',
    bottom: 0,
    right: 16,
  },
  closeIcon: {
    fill: White,
  },
}));
