import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { AlertContext } from 'Components/Alert/alertContext';
import Login from 'pages/Login';
import ResultInspection from 'pages/ResultInspection';
import ResetPasswordByToken from 'pages/ResetPasswordByToken';
import MainLayout from 'layouts/MainLayout';
import PrivateRoute from './PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/user';
import fetchCurrentUserThunk from 'redux/user/thunk/fetchCurrentUserThunk';
import { GlobalLoaderContext } from 'shared/contexts/GlobalLoaderContext';
import { store } from 'redux/store';
import { IsOnlineContext } from 'shared/IsOnlineContext';

const getReturnUrl = () => {
  const params = new URL(document.location).searchParams;
  return params.get('returnUrl');
};

const Routes = ({ children }) => {
  const { handleSetMessage } = useContext(AlertContext);
  const { setIsGlobalLoading } = useContext(GlobalLoaderContext);
  const { isOnline } = useContext(IsOnlineContext);

  const isAuth = useSelector(getCurrentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const hasAuthTokens = store.getState().user?.tokenData?.accessToken;
      if (hasAuthTokens) {
        setIsGlobalLoading(true);
        dispatch(fetchCurrentUserThunk()).finally(() => setIsGlobalLoading(false));
      }
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const alertMessage = (event) => {
    if (!isOnline) {
      handleSetMessage("Please don't reload page in offline mode!", 'error');
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = ''; // Legacy method for cross browser support
      }
      return ''; // Legacy method for cross browser support
    }
  };

  window.onbeforeunload = alertMessage;
  window.onunload = alertMessage;
  window.unload = alertMessage;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/result-inspection/:token/:reportId/:section'>
          <ResultInspection />
        </Route>
        <Route exact path='/'>
          <Redirect to='/login' />
        </Route>
        <Route
          exact
          path='/login'
          render={() => (isAuth ? <Redirect to={getReturnUrl() || '/system/dashboard/home'} /> : <Login />)}
        />
        <Route
          exact
          path='/reset-password/:token'
          render={() => (isAuth ? <Redirect to='/system/dashboard/home' /> : <ResetPasswordByToken />)}
        />
        <PrivateRoute exact isAuth={isAuth}>
          <MainLayout />
        </PrivateRoute>
        <Route path='*'>
          <Redirect to='/system/dashboard/home' />
        </Route>
      </Switch>
      {children}
    </BrowserRouter>
  );
};

export default Routes;
