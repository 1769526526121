import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Jaffa, JungleGreen, Red, RoyalBlue, BlueGradient } from 'styles/colors';

export default makeStyles((theme) => ({
  headerWrapper: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  leftContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  rightContentWrapper: {
    display: 'flex',
  },
  leftContentItem: {
    width: '100%',
    '&:not(:last-child)': {
      marginTop: 24,
    },

    [theme.breakpoints.up('md')]: {
      width: 'auto',

      '&:not(:last-child)': {
        marginTop: 0,
      },
      '&:not(:first-child)': {
        marginLeft: 22,
      },
    },
  },
  selectField: {
    // width: '100%',
    '& > .MuiSelect-select': {
      display: 'block',
    },

    '&:before': {
      content: 'none',
    },
    '& > .MuiSelect-icon': {
      top: 'calc(50% - 13px)',
      fill: RoyalBlue,
    },
    [theme.breakpoints.up('md')]: {
      '& > .MuiSelect-select': {
        color: Comet,
        // maxWidth: '10vw',
      },
    },
  },
  actionButton: {
    width: '100%',
    padding: '8px 16px',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.2,
    '&:not(:first-child)': {
      marginLeft: 8,
    },

    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
  templateIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  templateIconTitle: {
    paddingLeft: 8,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  connectButton: {
    color: JungleGreen,
    backgroundColor: rgba(JungleGreen, 0.1),
    '&:hover': {
      backgroundColor: rgba(JungleGreen, 0.3),
    },
  },
  disconnectButton: {
    color: Jaffa,
    backgroundColor: rgba(Jaffa, 0.1),
    '&:hover': {
      backgroundColor: rgba(Jaffa, 0.3),
    },
  },
  deleteButton: {
    color: Red,
    backgroundColor: rgba(Red, 0.1),
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  closeButton: {
    color: Comet,
    backgroundColor: rgba(Comet, 0.15),
    '&:hover': {
      backgroundColor: rgba(Comet, 0.3),
    },
  },
  checkboxLabel: {
    color: Comet,
  },
  selectMenuIcon: {
    width: 32,
    height: 32,
  },
  blockerModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    zIndex: 1000,

    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      width: '100%',
      maxWidth: 500,
      height: 'fit-content',
      borderRadius: 16,
    },
  },
  blockerModalWrapper: {
    maxHeight: '100%',
    minHeight: '20vh',
    height: 'fit-content',
    overflowY: 'scroll',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80vh',
    },
  },
  modalHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    zIndex: 999,
    borderRadius: '16px 16px 0 0',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  closeButtonModal: {
    padding: 0,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginTop: 73,
  },
  modalContentMain: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginTop: 10,
    alignItems: 'flex-start',
    '& > .leftContentItem': {
      marginLeft: 0,
    },
    // [theme.breakpoints.up('md')]: {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   justifyContent: 'space-evenly',
    //   alignItems: 'center',
    // },
  },
  modalContentItem: {
    width: '100%',
    '&:not(:last-child)': {
      marginTop: 24,
    },

    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        marginTop: 0,
      },
    },
  },
  modalFooter: {
    paddingTop: 24,
    marginTop: 24,
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtonModal: {
    fontSize: 12,
    fontWeight: 700,
    width: '48%',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addBlockerButton: {
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  addInspectionButton: {
    background: BlueGradient,
  },
}));
