import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Accordion, AccordionSummary, Button, IconButton, Slide, useMediaQuery, useTheme } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import cls from 'classnames';

import Avatar from 'Components/Avatar';
import { Comment } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { openFeedbackModal } from 'redux/reducers/modals';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { MENU_LINKS, NOTIFICATION_ACTIONS } from '../options';
import { ReactComponent as CrossIcon } from './assets/cross.svg';
import { useUserMenuModal } from './useUserMenuModal';
import useStyles from './useStyles';

const UserMenuModal = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    onModalClose,
    deleteNotification,
    onDeleteAllNotifications,
    fullName,
    user,
    hasUserAdministratorPermissions,
    hasTemplatePermissions,
    notifications,
    isModalOpen,
  } = useUserMenuModal();

  const renderAccordionItems = (item) => {
    if (!isEmpty(item.trigger)) {
      switch (item.trigger) {
        case 'FEEDBACK':
          return (
            <span
              key={item.title}
              onClick={() => dispatch(openFeedbackModal({}))}
              className={classes.userAccordionItem}
            >
              {item.title}
            </span>
          );
        default:
          break;
      }
    }
    if (isEmpty(item.link)) {
      return (
        <span key={item.title} onClick={item.action} className={classes.userAccordionItem}>
          {item.title}
        </span>
      );
    }
    return (
      <NavLink key={item.title} onClick={onModalClose} className={classes.userAccordionItem} to={item.link}>
        {item.title}
      </NavLink>
    );
  };

  const renderNotifications = (notification) => (
    <Button
      onClick={NOTIFICATION_ACTIONS[notification.type](notification, history, onModalClose)}
      className={classes.notificationItem}
      key={notification.id}
    >
      <span className={classes.notificationItemMessage}>{notification.message}</span>
      <IconButton onClick={(event) => deleteNotification(event, notification.id)}>
        <Trash />
      </IconButton>
      <div className={classes.notificationLoadingProggress} id={`notificationLoader-${notification.id}`} />
    </Button>
  );
  const handleDeleteAllNotifications = (event) => {
    onDeleteAllNotifications(event);
  };

  return (
    <Slide direction='left' in={isModalOpen}>
      <div className={classes.userMenu}>
        <div className={classes.userMenuHeader}>
          <span className={classes.userMenuTitle}>MENU</span>
          <IconButton className={classes.closeButton} onClick={onModalClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <Accordion className={classes.userAccordion}>
          <AccordionSummary className={cls(classes.accordionTitle, classes.dropAccordionSummaryStyle)}>
            <div className={classes.userInfo}>
              <div className={classes.avatarWrapper}>
                <Avatar path={user?.avatar} />
              </div>
              <span className={classes.userName}>{fullName}</span>
            </div>
            <ArrowDownIcon />
          </AccordionSummary>
          <div className={classes.userAccordionList}>{MENU_LINKS.user.map(renderAccordionItems)}</div>
        </Accordion>

        <Accordion className={classes.userAccordion}>
          <AccordionSummary
            className={cls(classes.accordionTitle, classes.dropAccordionSummaryStyle)}
            onClick={() => {
              onModalClose();
              setTimeout(() => dispatch(openFeedbackModal({})), 300);
            }}
          >
            <div className={classes.userInfo}>
              <Comment style={{ color: 'rgba(91, 95, 131,0.5)' }} />
              <span className={classes.notificationTitle}>FEEDBACK</span>
            </div>
            <ArrowDownIcon />
          </AccordionSummary>
          {/* <div className={classes.userAccordionList}>{MENU_LINKS.user.map(renderAccordionItems)}</div> */}
        </Accordion>

        <Accordion className={classes.userAccordion}>
          <AccordionSummary className={cls(classes.accordionTitle, classes.dropAccordionSummaryStyle)}>
            <div className={classes.userInfo}>
              <BellIcon />
              <span className={classes.notificationTitle}>NOTIFICATIONS</span>
            </div>
            <ArrowDownIcon />
          </AccordionSummary>
          <div className={classes.userAccordionList}>
            {notifications.length > 1 && (
              <Button
                style={{
                  color: ' #ffffff',
                  width: ' 100%',
                  padding: ' 4px 0',
                  background: 'linear-gradient(272.18deg, #004278 0%, #3E6FDF 100%)',
                  marginBottom: '16px',
                }}
                onClick={handleDeleteAllNotifications}
              >
                Delete all
              </Button>
            )}
            {notifications.length > 0 && notifications.map(renderNotifications)}
            {!notifications.length && <span className={classes.emptyNotificationsTitle}>No Notifications</span>}
          </div>
        </Accordion>
        {isMobile && (
          <div className={classes.navigation}>
            <Accordion
              className={cls(classes.userAccordion, classes.navigationItem, {
                [classes.selected]: location.pathname.includes('/system/dashboard'),
              })}
            >
              <AccordionSummary className={cls(classes.dropAccordionSummaryStyle, classes.navigationAccordionTitle)}>
                <span>DASHBOARD</span> <ArrowDownIcon />
              </AccordionSummary>
              <div className={classes.userAccordionList}>{MENU_LINKS.dashboards.map(renderAccordionItems)}</div>
            </Accordion>
            <Accordion
              className={cls(classes.userAccordion, classes.navigationItem, {
                [classes.selected]: location.pathname.includes('/system/inspections'),
              })}
            >
              <AccordionSummary className={cls(classes.dropAccordionSummaryStyle, classes.navigationAccordionTitle)}>
                <span>INSPECTIONS</span> <ArrowDownIcon />
              </AccordionSummary>
              <div className={classes.userAccordionList}>{MENU_LINKS.inspections.map(renderAccordionItems)}</div>
            </Accordion>
            {hasTemplatePermissions && (
              <Accordion
                className={cls(classes.userAccordion, classes.navigationItem, {
                  [classes.selected]: location.pathname.includes('/system/templates'),
                })}
              >
                <AccordionSummary className={cls(classes.dropAccordionSummaryStyle, classes.navigationAccordionTitle)}>
                  <span>TEMPLATES</span> <ArrowDownIcon />
                </AccordionSummary>
                <div className={classes.userAccordionList}>{MENU_LINKS.templates.map(renderAccordionItems)}</div>
              </Accordion>
            )}
            {hasUserAdministratorPermissions && (
              <NavLink
                onClick={onModalClose}
                className={classes.navigationItem}
                to='/system/user-administration/enabled-users'
                activeClassName={classes.selected}
              >
                USER ADMINISTRATION
              </NavLink>
            )}
          </div>
        )}
      </div>
    </Slide>
  );
};

export default UserMenuModal;
