import React from 'react';
import cls from 'classnames';

import { fileUrl } from 'config/config';
import useStyles from './useStyles';

const VideoPreview = ({ onSelectVideo, video, isSelected = false }) => {
  const classes = useStyles();

  return (
    <button
      onClick={() => onSelectVideo(video)}
      className={cls(classes.videoPreviewButton, { [classes.selectedVideo]: isSelected })}
      type='button'
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video className={classes.videoPreview} controls preload='metadata'>
        <source src={`${fileUrl}${video.uploadedFile}`} />
      </video>
    </button>
  );
};

export default VideoPreview;
