import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet } from 'styles/colors';

export default makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  message: {
    color: rgba(Comet, 0.5),
    fontSize: 24,
    fontWeight: 700,
  },
});
