import React from 'react';
import { noop } from 'utils/noop';

import ImageEditorModalContent from './ImageEditorModalContent';
import { useImageEditorModal } from './useImageEditorModal';

const ImageEditorModal = () => {
  const { onBeforeSubmit, onModalClose, photo, isModalOpen, screenRef } = useImageEditorModal();
  return (
    <ImageEditorModalContent
      onModalClose={onModalClose}
      isModalOpen={isModalOpen}
      photo={photo.uploadedFile?.name || photo.uploadedFile}
      stageRef={screenRef}
      onSubmit={noop}
      handleBeforeSubmitImage={onBeforeSubmit}
    />
  );
};

export default ImageEditorModal;
