import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import moment from 'moment';

import Avatar from 'Components/Avatar';
import { fileUrl } from 'config/config';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { ReactComponent as PinIcon } from '../assets/pin.svg';
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg';
import useStyles from './useStyles';

const InspectionInfo = ({ inspection, clients }) => {
  const classes = useStyles();

  return (
    <>
      {inspection?.photo && <img className={classes.image} src={`${fileUrl}${inspection?.photo}`} alt='Preview' />}
      {!inspection?.photo && (
        <div className={classes.imageDefault}>
          <HomeIcon />
        </div>
      )}
      <Accordion className={classes.accordionWrapper}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ArrowDownIcon />}>
          <h2 className={classes.title}>Inspection Details</h2>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionContent}>
          <div className={classes.accordionContentHeader}>
            <span className={classes.textWrapper}>
              <PinIcon />
              <span className={classes.text}>
                {`${inspection?.addressFirstLine}, ${inspection?.addressSecondLine}, ${inspection?.city}, ${inspection?.state} ${inspection?.zipCode}`}
              </span>
            </span>
            <span className={classes.textWrapper}>
              <CalendarIcon />
              <span className={classes.text}>{moment.utc(inspection?.inspectionAt).format('MM/DD/YYYY HH:mm')}</span>
            </span>
          </div>
          <div className={classes.houseInfoWrapper}>
            <span className={classes.houseInfoName}>
              House age: <span className={classes.houseInfo}>{inspection?.houseAge}</span>
            </span>
            <span className={classes.houseInfoName}>
              House area: <span className={classes.houseInfo}>{inspection?.squareFeet}</span>
            </span>
          </div>
          <div>
            <h4 className={classes.usersTitle}>INSPECTORS</h4>
            <div className={classes.usersWrapper}>
              {inspection?.inspectors?.map((inspector) => (
                <div key={inspector.id} className={classes.userWrapper}>
                  <div className={classes.userInfo}>
                    <div className={classes.avatarWrapper}>
                      <Avatar path={inspector.avatar} />
                    </div>
                    <span className={classes.userName}>{`${inspector.firstName} ${inspector.lastName}`}</span>
                  </div>
                  {!!inspector?.licenses.length && (
                    <div className={classes.license}>
                      <span className={classes.licenseName}>{inspector?.licenses[0]?.License?.name}</span>
                      <span className={classes.licenseNumber}>№ {inspector?.licenses[0]?.value}</span>
                    </div>
                  )}
                  {inspector?.certificates.map((certificate) => (
                    <div key={certificate.id} className={classes.license}>
                      <span className={classes.licenseName}>{certificate.Certificate.name}</span>
                      <span className={classes.licenseNumber}>№ {certificate.value}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div>
            <h4 className={classes.usersTitle}>CLIENTS</h4>
            <div className={classes.usersWrapper}>
              {clients.map((client) => (
                <div key={client.id} className={classes.userInfo}>
                  <div className={classes.avatarWrapper}>
                    <Avatar />
                  </div>
                  <span className={classes.userName}>{`${client.firstName} ${client.lastName}`}</span>
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default InspectionInfo;
