import { arrayMove } from '@dnd-kit/sortable';
import mainInstance from 'api/mainInstance';
import TemplatesAPI from 'api/templates';
import { AlertContext } from 'Components/Alert/alertContext';
import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { noop } from 'utils/noop';
import { TemplateContext } from './TemplateContext';

const sortFunc = (a, b) => {
  if (a.position === b.position) return a.id - b.id;
  return a.position - b.position;
};

export const TemplateSectionContext = createContext({
  sections: [],
  selectedSection: null,
  selectedItem: null,
  changeSections: noop,
  selectSection: noop,
  selectItem: noop,
  createSection: noop,
  copySection: noop,
  deleteSection: noop,
  moveSection: noop,
  editSection: noop,
  replaceComment: noop,
  replaceItem: noop,
  copyItem: noop,
  deleteItem: noop,
  reorderItems: noop,
  createOrUpdateSection: noop,
  assignSectionsToTemplate: noop,
  setSections: noop,
});

export const TemplateSectionContextProvider = ({ children }) => {
  const { id } = useParams();

  const { template, createTemplateIfEmpty, setTemplate } = useContext(TemplateContext);
  const { handleSetMessage } = useContext(AlertContext);

  const isEditDatabase = id === undefined && !document.location.href.includes('/templates/');
  const hasTemplate = !!template;

  const [sections, setSections] = React.useState([]);
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);

  console.log({
    sections1: sections,
    selectedSection,
    selectedItem,
  });

  const fetchSections = async () => {
    const sectionsPromises = (template?.sections || []).map(
      (section) =>
        new Promise((resolve) =>
          TemplatesAPI.getTemplateSection(section.id).then((res) => {
            console.log({ res });
            return resolve({ ...section, ...res.data });
          }),
        ),
    );
    const resolvedSections = await Promise.all(sectionsPromises);
    return resolvedSections.sort(sortFunc);
  };

  React.useEffect(() => {
    if (isEditDatabase)
      TemplatesAPI.getAllTemplateSections().then((res) => setSections(res.data.data.sort((a, b) => b.id - a.id)));
    else fetchSections(template?.sections).then(setSections);
  }, [isEditDatabase, template?.id]);

  const changeSections = React.useCallback(setSections, []);

  const selectSection = React.useCallback((indx) => {
    setSelectedSection(indx);
    selectItem(null);
  }, []);

  const selectItem = React.useCallback(setSelectedItem, []);

  const createSection = (values) =>
    TemplatesAPI.postTemplateSection(values).then((res) => setSections([...sections, res.data]));

  const createOrUpdateSection = (values, { sectionIndx = selectedSection, isCreating = false }) => {
    if (isCreating) {
      if (isEditDatabase) {
        const fakeId = Date.now();
        setSections([...sections, { ...values, id: fakeId }].sort((a, b) => b.id - a.id));
        TemplatesAPI.postTemplateSection(values).then((res) =>
          setSections((prevSections) =>
            [...prevSections.filter((sect) => sect.id !== fakeId), res.data].sort((a, b) => b.id - a.id),
          ),
        );
      } else {
        const fakeId = Date.now();
        setSections([...sections, { ...values, id: fakeId }]);
        TemplatesAPI.postTemplateSection(values).then((res) =>
          setSections((prevSections) => [...prevSections.filter((sect) => sect.id !== fakeId), res.data]),
        );
      }
    } else editSection(values, sectionIndx);
  };

  const editSection = (values, sectionIndex) => {
    const sectionIndx = sectionIndex === 0 || sectionIndex ? sectionIndex : selectedSection;
    let isPatchingSections = false;
    setSections((prevSections) => {
      const newSections = prevSections.map((sect) => {
        if (sect.id === sections[sectionIndx].id) {
          if (!isPatchingSections) {
            isPatchingSections = true;
            TemplatesAPI.patchTemplateSection({ ...sections[sectionIndx], ...values })
              .then((res) => {
                setSections((prevSections) =>
                  prevSections.map((sectx) => (sectx.id === res.data?.id ? res.data : sectx)),
                );
              })
              .catch((error) => handleSetMessage(error?.response?.data?.message, 'error'));
          }
          return { ...sections[sectionIndx], ...values };
        }
        return sect;
      });
      handleSetMessage('Changes saved', 'success');
      return newSections;
    });
  };

  const assignSectionsToTemplate = async (sections) => {
    let createdTemplate = template;
    let templateId = template?.id;
    if (!hasTemplate && !isEditDatabase) {
      createdTemplate = await createTemplateIfEmpty();
      setTemplate(createdTemplate);
      templateId = createdTemplate?.id;
    }
    const serverSections = await Promise.all(
      sections.map((sectionId) => TemplatesAPI.getTemplateSection(sectionId)),
    ).then((res) => res.map((x) => x.data));

    if (isEditDatabase)
      return setSections((prevSections) => [...prevSections, ...serverSections].sort((a, b) => b.id - a.id));

    return TemplatesAPI.assignSectionsToTemplate({ templateId, sectionIds: sections })
      .then(async (res) => {
        const newSections = res.data.data.map(({ sectionId, position }, index) => ({
          ...serverSections.find((x) => x.id === sections[index]),
          isClone: true,
          id: sectionId,
          position,
        }));

        setSections((prevSections) => [...prevSections, ...newSections].sort(sortFunc));
        handleSetMessage('Section(s) added', 'success');
      })
      .catch((error) => {
        console.error(error);
        handleSetMessage(error?.response?.data?.message, 'error');
      });
  };

  const copySection = async (indx) => {
    const id = sections[indx].id;
    TemplatesAPI.copyTemplateSection(id)
      .then((res) => assignSectionsToTemplate(res.data.id))
      .catch((error) => handleSetMessage(error?.response?.data?.message, 'error'));
  };

  const deleteSection = (indx) => {
    let templateId = template?.id;
    const id = sections[indx].id;
    if (isEditDatabase)
      TemplatesAPI.deleteTemplateSections(id).then(() => setSections(sections.filter((_, i) => i !== indx)));
    else
      TemplatesAPI.deleteSectionInTemplate({ templateId, sectionId: id })
        .then(() => setSections(sections.filter((_, i) => i !== indx)))
        .catch((error) => handleSetMessage(error?.response?.data?.message, 'error'));
  };

  const moveSection = ({ active, over }) => {
    if (over && active.id !== over.id) {
      const oldIndex = active.id - 1;
      const newIndex = over.id - 1;
      TemplatesAPI.moveSectionInTemplate({
        templateId: id,
        sectionId: sections[oldIndex].id,
        position: sections[newIndex].position,
      }).then(() =>
        TemplatesAPI.getTemplate(id).then((res) => {
          setSections((p) =>
            p.map((sect) => ({ ...sect, ...(res.data.sections || []).find((s) => s.id === sect.id) })),
          );
        }),
      );
      handleSetMessage('Section moved', 'success');

      const updatedSections = arrayMove(sections, oldIndex, newIndex);
      setSections(updatedSections);
      if (selectedSection !== null) setSelectedSection(newIndex);
    }
  };

  const replaceItem = async (sectionIdx, itemIdx, selectedSect, selectedIt) => {
    const updatedSections = [...sections];

    const SelectedSection = typeof selectedSect === 'number' ? selectedSect : selectedSection;

    const replacedItem = updatedSections[SelectedSection].items[itemIdx];

    updatedSections[SelectedSection].items = [
      ...updatedSections[SelectedSection].items.slice(0, itemIdx),
      ...updatedSections[SelectedSection].items.slice(itemIdx + 1),
    ];

    if (!updatedSections[sectionIdx]?.items) {
      const res = await mainInstance.get(`/admin/template-sections/${updatedSections?.[sectionIdx].id}`);

      updatedSections[sectionIdx] = { ...sections[sectionIdx], ...res.data };
    }

    updatedSections[sectionIdx].items.push(replacedItem);

    setSections(updatedSections);

    TemplatesAPI.patchTemplateSection(updatedSections[SelectedSection]);
    TemplatesAPI.patchTemplateSection(updatedSections[sectionIdx]);
  };

  const replaceComment = async (sectionIdx, itemIdx, commentIdx, isDeficiencies = false, selectedSect, selectedIt) => {
    const commentType = isDeficiencies ? 'deficiencies' : 'comments';
    const updatedSections = [...sections];

    const SelectedSection = typeof selectedSect === 'number' ? selectedSect : selectedSection;
    const SelectedItem = typeof selectedIt === 'number' ? selectedIt : selectedItem;

    const needToFetchItems = !updatedSections[sectionIdx]?.items;

    console.log({ needToFetchItems, updatedSections });

    if (needToFetchItems) {
      const res = await mainInstance.get(`/admin/template-sections/${updatedSections?.[sectionIdx].id}`);
      updatedSections[sectionIdx] = { ...updatedSections[sectionIdx], ...res.data };
    }

    const replacedComment = updatedSections[SelectedSection].items[SelectedItem][commentType][commentIdx];

    updatedSections[SelectedSection].items[SelectedItem][commentType] = [
      ...updatedSections[SelectedSection].items[SelectedItem][commentType].slice(0, commentIdx),
      ...updatedSections[SelectedSection].items[SelectedItem][commentType].slice(commentIdx + 1),
    ];

    updatedSections[sectionIdx].items[itemIdx][commentType] = [
      ...(updatedSections[sectionIdx].items[itemIdx][commentType] || []),
      replacedComment,
    ];

    setSections(updatedSections);

    TemplatesAPI.patchTemplateSection(updatedSections[SelectedSection]);
    TemplatesAPI.patchTemplateSection(updatedSections[sectionIdx]);
  };

  const copyItem = (idx, sectionIndx = selectedSection) => {
    const updatedSections = [...sections];
    const copiedItem = {
      ...updatedSections[sectionIndx].items[idx],
      name: `Copy of ${updatedSections[sectionIndx].items[idx].name}`,
    };

    updatedSections[sectionIndx].items.push(copiedItem);

    editSection(updatedSections[sectionIndx], sectionIndx);
  };

  const deleteItem = (idx, sectionIndx) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndx].items = [
      ...updatedSections[sectionIndx].items.slice(0, idx),
      ...updatedSections[sectionIndx].items.slice(idx + 1),
    ];
    editSection(updatedSections[sectionIndx], sectionIndx);
  };

  const reorderItems = ({ active, over }, sectionIndx = selectedSection) => {
    const updatedSections = [...sections];
    if (over && active.id !== over.id) {
      const oldIndex = active.id - 1;
      const newIndex = over.id - 1;
      const updatedItems = arrayMove(updatedSections[sectionIndx].items, oldIndex, newIndex);
      updatedSections[sectionIndx].items = updatedItems;
      editSection(updatedSections[sectionIndx], sectionIndx);
      if (selectedItem !== null) setSelectedItem(newIndex);
    }
  };

  console.log({ sections });

  const contextValue = {
    changeSections,
    sections,
    selectedSection,
    selectedItem,
    selectSection,
    selectItem,
    createSection,
    copySection,
    deleteSection,
    moveSection,
    editSection,
    replaceComment,
    replaceItem,
    copyItem,
    deleteItem,
    reorderItems,
    assignSectionsToTemplate,
    createOrUpdateSection,
    isTermite: template?.pattern === 'TERMITE_REPORT',
    setSections,
  };

  return <TemplateSectionContext.Provider value={contextValue}>{children}</TemplateSectionContext.Provider>;
};
