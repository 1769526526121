import { useMediaQuery } from '@material-ui/core';
import React, { createContext } from 'react';
import { noop } from 'utils/noop';

export const StyleContext = createContext({
  isDarkMode: false,
  toggleMode: noop,
});

export const StyleContextProvider = ({ children }) => {
  const isDarkModePrefered = useMediaQuery('(prefers-color-scheme: dark)');
  const savedMode = JSON.parse(localStorage.getItem('isDarkMode'));

  const [isDarkMode, setIsDarkMode] = React.useState(isDarkModePrefered);

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem('isDarkMode', JSON.stringify(!isDarkMode));
  };

  React.useEffect(() => {
    if (savedMode === null) {
      setIsDarkMode(isDarkModePrefered);
    } else {
      setIsDarkMode(savedMode);
    }
  }, [isDarkModePrefered]);

  return <StyleContext.Provider value={{ isDarkMode, toggleMode }}>{children}</StyleContext.Provider>;
};
