import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  IconButton,
  Slide,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import cls from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import SelectField from 'Components/SelectField';
import { ICONS, ICONS_LIST } from 'constants/icons';
import CircularLoader from 'Components/CircularLoader';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import useStyles from './useStyles';
import { getModalProps } from 'redux/selectors/modals';
import { ConstantsContext } from 'shared/contexts/ConstantsContext';
import { useEffect } from 'react';

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const patterns = [
  { name: 'Termite', value: 'TERMITE_REPORT' },
  { name: 'Default', value: null },
];

function TemplateHeaderModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));
  const { modalType } = useSelector((state) => state.reducerModal);
  const { isSelectOutlined, template, changeTemplate } = useSelector(getModalProps);
  const { inspectionTypes } = useContext(ConstantsContext);

  const [form, setForm] = useState({
    inspectionTypes: (template?.inspectionTypes || []).map((i) => (Number.isInteger(i) ? i : i?.id)),
    icon: template?.icon,
    pattern: template?.pattern || '',
    isLocked: template?.isLocked,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onModalClose = () => dispatch(closeModal());

  const isModalOpen = !!modalType;

  useEffect(() => {
    setForm({ ...form, icon: template?.icon, isLocked: template?.isLocked });
  }, [template?.icon, template?.isLocked]);

  const handleSaveChanges = async () => {
    setIsLoading(true);
    await changeTemplate(form);
    setIsLoading(false);
    onModalClose();
  };

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.blockerModal}>
        <div className={classes.blockerModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Configure</h2>
            <IconButton className={classes.closeButtonModal} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <div className={classes.modalContentMain}>
              <div className={classes.modalContentItem}>
                <SelectField
                  className={classes.selectField}
                  multiple
                  value={form.inspectionTypes}
                  labelId={isSelectOutlined ? 'inspectionType' : ''}
                  label={isSelectOutlined ? 'Inspection Type' : ''}
                  variant={isSelectOutlined ? 'outlined' : 'standard'}
                  displayEmpty={!isSelectOutlined}
                  onChange={(e) => setForm({ ...form, inspectionTypes: e.target.value })}
                  data-cy-select='inspection-type'
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return isTablet ? 'Type' : 'Inspection Type';
                    }
                    return selected
                      .map((type) => {
                        const selectedItem = inspectionTypes.find((item) => item.id === type);
                        return selectedItem?.name;
                      })
                      .join(', ');
                  }}
                >
                  {inspectionTypes.map((type) => {
                    if (!type.isArchived || form.inspectionTypes?.includes(type?.id)) {
                      return (
                        <MenuItem key={type.id} value={type.id} data-cy-item={type.name}>
                          {type.name}
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
                </SelectField>
              </div>

              <div className={classes.modalContentItem}>
                <SelectField
                  className={classes.selectField}
                  value={form.icon}
                  labelId={isSelectOutlined ? 'icon' : ''}
                  label={isSelectOutlined ? 'Template Icon' : ''}
                  variant={isSelectOutlined ? 'outlined' : 'standard'}
                  displayEmpty={!isSelectOutlined}
                  renderValue={(selectedIcon) => {
                    if (selectedIcon) {
                      const Icon = ICONS[selectedIcon];
                      return (
                        <span className={classes.templateIconWrapper}>
                          <Icon />
                          <span className={classes.templateIconTitle}>{isTablet ? 'Icon' : 'Template Icon'}</span>
                        </span>
                      );
                    }
                    return <span className={classes.templateIconTitle}>{isTablet ? 'Icon' : 'Template Icon'}</span>;
                  }}
                  onChange={(e) => setForm({ ...form, icon: e.target.value })}
                  autoWidth
                >
                  {ICONS_LIST.map((item) => {
                    const Icon = item.icon;
                    return (
                      <MenuItem
                        key={item.icon}
                        value={item.name}
                        style={{ background: theme.palette.primary.main, marginTop: -8, paddingTop: 14 }}
                      >
                        <Icon className={classes.selectMenuIcon} />
                      </MenuItem>
                    );
                  })}
                </SelectField>
              </div>

              <div className={classes.modalContentItem}>
                <SelectField
                  className={classes.selectField}
                  value={form.pattern || 'Default pattern'}
                  labelId={isSelectOutlined ? 'inspectionType' : ''}
                  label={isSelectOutlined ? 'Pattern' : ''}
                  variant={isSelectOutlined ? 'outlined' : 'standard'}
                  displayEmpty={!isSelectOutlined}
                  onChange={(e) => setForm({ ...form, pattern: e.target.value })}
                  data-cy-select='inspection-pattern'
                  renderValue={(patter) => {
                    console.log({ patter }, form.pattern);
                    return capitalize(patter?.toLowerCase()?.replace(/_/g, ' ')) || 'Default pattern';
                  }}
                >
                  {patterns.map((patternElement) => (
                    <MenuItem key={patternElement.name} value={patternElement.value} data-cy-item={patternElement.name}>
                      {patternElement.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </div>

              <div className={classes.modalContentItem}>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      checked={form.isLocked}
                      color='primary'
                      onChange={() => setForm({ ...form, isLocked: !form.isLocked })}
                      name='isLocked'
                      data-cy-checkbox='is-locked'
                    />
                  }
                  label='Prevent user editing'
                />
              </div>
            </div>
            <div className={classes.modalFooter}>
              <Button onClick={onModalClose} className={cls(classes.actionButtonModal, classes.addBlockerButton)}>
                Close
              </Button>
              <Button
                onClick={handleSaveChanges}
                className={cls(classes.actionButtonModal, classes.addInspectionButton)}
                disabled={isLoading}
              >
                {isLoading ? <CircularLoader size={20} /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default TemplateHeaderModal;
