import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const useTextEditor = (fieldName, valueForRerender) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  const blocksFromHtml = htmlToDraft(values[fieldName]?.length ? values[fieldName] : '');

  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const initialEditorState = EditorState.createWithContent(contentState);

  const [editorState, setEditorState] = useState(initialEditorState || EditorState.createEmpty);

  const changeEditorState = (newEditorState) => {
    setEditorState(newEditorState);
    const hasText = newEditorState.getCurrentContent().hasText();

    const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    setFieldValue(fieldName, hasText ? html : '');
    setFieldTouched(fieldName, true);
  };

  useEffect(() => {
    if (valueForRerender) {
      // const a = htmlToDraft(valueForRerender?.length ? valueForRerender : '');
      // const { contentBlocks: b, entityMap: c } = a;
      // const e = ContentState.createFromBlockArray(b, c);
      // const state = EditorState.createWithContent(e) || EditorState.createEmpty;
      // const fixedEditorState = EditorState.moveSelectionToEnd(state);
      // setEditorState(fixedEditorState);
    }
  }, [valueForRerender]);

  return { changeEditorState, editorState };
};
