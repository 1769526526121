import React from 'react';
import { Formik, Form } from 'formik';
import { Button, IconButton, Slide } from '@material-ui/core';
import cls from 'classnames';

import CircularLoader from 'Components/CircularLoader';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { initialValues } from './initialValues';
import { useAddSection } from './useAddSection';
import AddSectionForm from './AddSestionForm';
import useStyles from './useStyles';

const AddSectionModal = () => {
  const classes = useStyles();
  const {
    availableSections,
    submit,
    onModalClose,
    templateSection,
    hasIndex,
    isCreating,
    isModalOpen,
    isLoading,
    isReport,
    hasFetchedSections,
    isMasterTemplate,
  } = useAddSection();

  return (
    <Slide direction='up' in={isModalOpen}>
      <div data-cy-container='add-section' className={classes.addSectionModal}>
        <div className={classes.addSectionModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{isCreating ? 'Add a New Section' : 'Edit a Section'}</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik
              initialValues={hasIndex ? templateSection : initialValues}
              validate={(values) => {
                const { sectionName, sectionIcon, selectedSections } = values;
                const errors = {};
                if ((sectionName.trim() && sectionIcon) || selectedSections.length > 0) return {};
                else {
                  if (values.sectionName.trim().length === 0) errors.sectionName = 'Section name is required field';
                  if (values.sectionIcon.length === 0) errors.sectionIcon = 'Section icon is required field';
                  if (
                    values.sectionIcon.length === 0 &&
                    values.sectionName.trim().length === 0 &&
                    selectedSections.length === 0
                  ) {
                    errors.sectionName = 'At least one of these fields is required';
                    errors.sectionIcon = 'At least one of these fields is required';
                    errors.selectedSections = 'At least one of these fields is required';
                  }
                  return errors;
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={submit}
            >
              {({ handleSubmit }) => (
                <Form>
                  <AddSectionForm
                    availableSections={availableSections}
                    isReport={isReport}
                    hasFetchedSections={hasFetchedSections}
                    isMasterTemplate={isMasterTemplate}
                  />
                  <div className={classes.modalFooter}>
                    <Button
                      onClick={onModalClose}
                      className={cls(classes.actionButton, classes.cancelButton)}
                      data-cy-button='cancel-section-create'
                    >
                      Cancel
                    </Button>
                    <Button
                      className={cls(classes.actionButton, classes.submitButton)}
                      onClick={() => setTimeout(handleSubmit, 100)}
                      data-cy-button='submit-section-create'
                    >
                      {isLoading ? <CircularLoader size={20} isWhite /> : 'Submit'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default AddSectionModal;
