import React from 'react';
import { Modal as MaterialModal, Backdrop } from '@material-ui/core';

import UserMenuModal from 'layouts/MainLayout/Header/UserMenuModal';
import AddSectionModal from 'Components/template/SectionsColumn/AddSectionModal';
import AddItemModal from 'Components/template/ItemsColumn/AddItemModal';
import ConfirmationModal from 'Components/ConfirmationModal';
import SectionInfoModal from 'Components/template/SectionsColumn/SectionInfoModal';
import ItemInfoModal from 'Components/template/ItemsColumn/ItemInfoModal';
import CommentInfoModal from 'Components/template/CommentsColumn/CommentInfoModal';
import AddCommentModal from 'Components/template/CommentsColumn/AddCommentModal';
import AddLocationModal from 'Components/template/CommentsColumn/AddLocationModal';
import AddDeficienciesCommentModal from 'Components/template/CommentsColumn/AddDeficienciesCommentModal';
import FeedbackModal from 'layouts/MainLayout/Header/FeedbackModal';
import ReplaceModal from 'Components/template/ReplaceModal';
import Gallery from 'Components/Gallery';
import ImageEditorModal from 'Components/ImageEditorModal';
import DefaultTextModal from 'Components/template/TemplateHeader/DefaultTextModal';
import ImagePreview from 'pages/ResultInspection/ImagePreview';
import DetailsModal from 'pages/Calendar/DetailsModal';
import BlockerModal from 'pages/Calendar/BlockerModal';
import HolderModal from 'pages/Calendar/HolderModal';
import { MODALS } from 'constants/modals';
import EditLocationsModal from 'Components/EditLocationsModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import { useLocation } from 'react-router-dom';
import TemplateHeaderModal from 'Components/template/TemplateHeader/TemplateHeaderModal';

export const Modal = () => {
  const dispatch = useDispatch();
  const { modalType, props } = useSelector((state) => state.reducerModal);

  const isModalOpen = !!modalType;

  const onCloseModal = () => dispatch(closeModal());

  const location = useLocation();

  React.useEffect(() => {
    onCloseModal();
  }, [location]);

  return (
    <MaterialModal
      open={isModalOpen}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <>
        {modalType === MODALS.USER_MENU && <UserMenuModal />}
        {modalType === MODALS.ADD_SECTION && <AddSectionModal />}
        {modalType === MODALS.ADD_ITEM && <AddItemModal />}
        {modalType === MODALS.ADD_COMMENT && <AddCommentModal />}
        {modalType === MODALS.CONFIRMATION && <ConfirmationModal />}
        {modalType === MODALS.SECTION_INFO && <SectionInfoModal />}
        {modalType === MODALS.ITEM_INFO && <ItemInfoModal />}
        {modalType === MODALS.COMMENT_INFO && <CommentInfoModal />}
        {modalType === MODALS.ADD_LOCATION && <AddLocationModal />}
        {modalType === MODALS.ADD_DEFICIENCIES_COMMENT && <AddDeficienciesCommentModal />}
        {modalType === MODALS.REPLACE_ITEM && <ReplaceModal />}
        {modalType === MODALS.GALLERY && <Gallery />}
        {modalType === MODALS.PREVIEW && <ImagePreview />}
        {modalType === MODALS.CALENDAR_INSPECTIONS && <DetailsModal />}
        {modalType === MODALS.ADD_BLOCKER && (
          <BlockerModal calendarFetch={props?.calendarFetch} setCalendarDataRaw={props?.setCalendarDataRaw} />
        )}
        {modalType === MODALS.EDIT_BLOCKER && (
          <BlockerModal
            isEdit
            blocker={props?.blocker}
            calendarFetch={props?.calendarFetch}
            setCalendarDataRaw={props?.setCalendarDataRaw}
          />
        )}
        {modalType === MODALS.ADD_HOLDER && <HolderModal />}
        {modalType === MODALS.DEFAULT_TEXT && <DefaultTextModal />}
        {modalType === MODALS.IMAGE_EDITOR && <ImageEditorModal />}
        {modalType === MODALS.EDIT_LOCATIONS && <EditLocationsModal />}
        {modalType === MODALS.FEEDBACK && <FeedbackModal />}
        {modalType === MODALS.TEMPLATE_HEADER && <TemplateHeaderModal />}
      </>
    </MaterialModal>
  );
};
