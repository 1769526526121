import * as yup from 'yup';
import moment from 'moment';

export const AddHolderValidation = yup.object().shape({
  inspectionTypeId: yup.string().required('Inspection type is required field'),
  date: yup
    .mixed()
    .test('valid-date', 'Date is invalid', (val) => moment(val, 'DD/MM/YYYY').isValid())
    .required('Date is required field'),
  time: yup.mixed().when('date', (date) => {
    if (date && date.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
      return yup
        .mixed()
        .test('valid-time', 'Time is invalid', (time) => moment(time, 'hh:mm A').isValid())
        .test(
          'future-time',
          'Should be future time',
          (time) =>
            time && moment(time.format('hh:mm A'), 'hh:mm A').isAfter(moment(moment().format('hh:mm A'), 'hh:mm A')),
        )
        .required('Time is required field');
    }
    return yup
      .mixed()
      .test('valid-time', 'Time is invalid', (val) => moment(val, 'hh:mm A').isValid())
      .required('Time is required field');
  }),

  duration: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Only digits are allowed')
    .required('Duration is required field'),
  inspectors: yup
    .array()
    .of(
      yup.object().shape({
        userId: yup.string().required('Inspector is required field'),
        // templates: yup
        //   .array()
        //   .of(yup.string().required())
        //   .min(1, 'At least one report required')
        //   .required('Report is required field'),
      }),
    )
    .min(1, 'At least one inspector required')
    .required('At least one inspector required'),
  addressFirstLine: yup.string().trim().required('Address first line is required field'),
  state: yup.string().trim().required('State is required field'),
  city: yup.string().trim().required('City is required field'),
  zipCode: yup.string().trim().required('Zip code is required field'),
});
