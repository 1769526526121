import { closeModal } from 'redux/reducers/modals';
import { useDispatch, useSelector } from 'react-redux';
import { getModalProps, getModalType } from 'redux/selectors/modals';

export const useAddLocation = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { onChangeComments, setLocationValue, itemIdx, isDeficiencies, location } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const locationSelect = (setFieldValue, newLocation, currentLocations) =>
    setFieldValue('location', currentLocations ? `${currentLocations}, ${newLocation}` : newLocation);

  const submit = (values) => {
    onChangeComments({ location: values.location }, itemIdx, isDeficiencies);
    setLocationValue(values.location);
    onModalClose();
  };

  return { submit, onModalClose, locationSelect, location, isModalOpen };
};
