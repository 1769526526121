import { makeStyles } from '@material-ui/core';

import { Red, RoyalBlue } from 'styles/colors';

export default makeStyles({
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    color: RoyalBlue,
    textTransform: 'none',
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    color: Red,
    textTransform: 'none',
  },
  actionIcon: {
    marginRight: 8,
  },
});
