import localforage from 'localforage';

export const saveAuthData = ({ accessToken, refreshToken }) => {
  return sessionStorage.setItem('authData', JSON.stringify({ refreshToken, accessToken }));
};

export const saveAuthDataWithKeepLogin = ({ accessToken, refreshToken }) => {
  localStorage.setItem('keepLogin', JSON.stringify(true));
  return localStorage.setItem('authData', JSON.stringify({ accessToken, refreshToken }));
};

export const clearSavedUserData = () => {
  sessionStorage.removeItem('authData');
  localStorage.removeItem('authData');
  localStorage.removeItem('keepLogin');
  localforage.removeItem('editedReports'); // localforage
  localforage.removeItem('editedReportsSections'); // localforage
};

const AUTH_ACTION = {
  SET_AUTH: 'SET_AUTH',
  CLEAR_AUTH: 'CLEAR_AUTH',
  SET_USER: 'SET_USER',
};

export const getAuthData = () => {
  const sessionStorageData = JSON.parse(sessionStorage.getItem('authData' || '{}'));
  const localStorageData = JSON.parse(localStorage.getItem('authData') || '{}');
  return { ...sessionStorageData, ...localStorageData };
};

const initialState = {
  tokenData: getAuthData(),
  keepLogin: localStorage.getItem('keepLogin'),
  user: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ACTION.SET_AUTH: {
      if (action.payload.keepLogin || state.keepLogin) saveAuthDataWithKeepLogin(action.payload.tokenData);
      else saveAuthData(action.payload.tokenData);
      return { ...state, ...action.payload };
    }
    case AUTH_ACTION.SET_USER:
      return { ...state, user: action.payload };
    case AUTH_ACTION.CLEAR_AUTH: {
      clearSavedUserData();
      return initialState;
    }
    default:
      return state;
  }
};

export const setAuth = (payload) => ({
  type: AUTH_ACTION.SET_AUTH,
  payload,
});

export const setCurrentUser = (payload) => ({
  type: AUTH_ACTION.SET_USER,
  payload,
});

export const clearAuthData = () => ({
  type: AUTH_ACTION.CLEAR_AUTH,
});
