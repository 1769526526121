/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as RemoveLocationIcon } from 'assets/icons/removeLocationIcon.svg';
import { ReactComponent as LocationDragHandle } from 'assets/icons/locationDragHandle.svg';
import { Button, IconButton } from '@material-ui/core';
import useStyles from './useStyles';

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const inputRef = useRef();
  const classes = useStyles();
  const [inputVal, setInputVal] = useState(props.id);

  useEffect(() => {
    if (inputRef.current) inputRef.current = true;
  }, []);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className={props.className} ref={setNodeRef} style={style}>
      <Button {...attributes} {...listeners} style={{ touchAction: 'none' }}>
        <IconButton style={{ padding: 0 }}>
          <LocationDragHandle />
        </IconButton>
      </Button>
      <input
        className={classes.sotrableItemInput}
        // onFocus={() => (inputRef.current = false)}
        value={inputVal ?? props.id}
        defaultValue={props.id}
        // onBlur={() => (inputRef.current = true)}
        // ref={inputRef}
        // readOnly={inputRef?.current}
        onChange={(e) => {
          setInputVal(e.target.value);
        }}
        onBlur={(e) => props.handleOnChangeText(e.target.value)}
        style={{ marginLeft: '6.5px', fontSize: 12, color: '#5B5F83' }}
      />
      <IconButton color='error' style={{ marginLeft: 'auto' }} onClick={props.onRemoveLocationLocal}>
        <RemoveLocationIcon />
      </IconButton>
    </div>
  );
}
