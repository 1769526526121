import { makeStyles } from '@material-ui/core';

export default makeStyles({
  fileList: {
    paddingLeft: 0,
    listStyle: 'none',
    margin: 0,
  },
  fileListItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,

    '&:not(:first-child)': {
      marginTop: 8,
    },
  },
  previewWrapper: {
    maxWidth: '50%',
  },
  preview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  actionButton: {
    padding: 4,
    marginLeft: 8,
  },
});
