import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue, White } from 'styles/colors';

export default makeStyles({
  sectionsMenuButton: {
    position: 'fixed',
    top: 47,
    color: Comet,
    textTransform: 'none',
    width: '100%',
    padding: '16px 24px',
    zIndex: 999,
    backgroundColor: White,
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    '&>.MuiButton-label': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&:hover': {
      backgroundColor: White,
    },
  },
  menuItem: {
    color: Comet,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
  },
  menuItemActive: {
    color: RoyalBlue,
  },
  menuItemTitle: {
    paddingLeft: 8,
  },
  arrowIcon: {
    fill: Comet,
  },
});
