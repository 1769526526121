import React from 'react';
import { Button, Slide } from '@material-ui/core';

import { noop } from 'utils/noop';
import Header from './Header';
import { useGallery } from './useGallery';
import Loader from '../Loader';
import PhotoPreview from './PhotoPreview';
import { GALLERY_SECTIONS } from './options';
import VideoPreview from './VideoPreview';
import useStyles from './useStyles';
import Masonry from 'react-masonry-component';

const Gallery = () => {
  const classes = useStyles();
  const {
    onModalClose,
    selectSection,
    selectPhoto,
    selectVideo,
    submit,
    fetchGallery,
    selectedVideos,
    selectedPhotos,
    photos,
    videos,
    selectedSection,
    isModalOpen,
    isLoading,
    isViewMode,
  } = useGallery();

  return (
    <Slide direction='up' in={isModalOpen} mountOnEnter unmountOnExit>
      <div className={classes.galleryModal}>
        <div className={classes.galleryModalWrapper}>
          <Header
            onClose={onModalClose}
            onSectionSelect={selectSection}
            selectedSection={selectedSection}
            fetchGallery={fetchGallery}
          />
          {!isViewMode && (
            <Button className={classes.submitButton} onClick={submit}>
              Submit
            </Button>
          )}

          <div className={classes.modalContent}>
            {isLoading && <Loader />}
            {!isLoading && (
              <>
                {selectedSection === GALLERY_SECTIONS.PHOTO && photos?.length > 0 && (
                  <CenteredMasonry options={{ horizontalOrder: true, columnWidth: 320, gutter: 30 }}>
                    {photos?.map((photo) => (
                      <PhotoPreview
                        key={photo.uploadedFile}
                        onSelectPhoto={isViewMode ? noop : selectPhoto}
                        photo={photo}
                        isSelected={selectedPhotos.find((item) => item?.uploadedFile === photo.uploadedFile)}
                      />
                    ))}
                  </CenteredMasonry>
                )}
                {selectedSection === GALLERY_SECTIONS.PHOTO && !photos?.length && (
                  <h3 className={classes.emptyTitle}>No saved photos</h3>
                )}
                {selectedSection === GALLERY_SECTIONS.VIDEO && videos?.length > 0 && (
                  <CenteredMasonry options={{ horizontalOrder: true, columnWidth: 320, gutter: 30 }}>
                    {videos?.map((video) => (
                      <VideoPreview
                        key={video.uploadedFile}
                        onSelectVideo={isViewMode ? noop : selectVideo}
                        video={video}
                        isSelected={selectedVideos.find((item) => item?.uploadedFile === video.uploadedFile)}
                      />
                    ))}
                  </CenteredMasonry>
                )}
                {selectedSection === GALLERY_SECTIONS.VIDEO && !videos?.length && (
                  <h3 className={classes.emptyTitle}>No saved videos</h3>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default Gallery;
function CenteredMasonry({ options, className, children }) {
  const [offset, setOffset] = React.useState(0);

  const onLayoutComplete = (layout) => {
    const info = layout[0]?.layout || {};
    const { cols = 0, columnWidth = 0 } = info;
    setOffset((columnWidth * cols) / 2);
  };

  return (
    <div style={offset ? { marginLeft: `calc(50% - ${offset}px)`, width: '100%' } : {}}>
      <Masonry options={options} onLayoutComplete={onLayoutComplete} updateOnEachImageLoad={true} className={className}>
        {children}
      </Masonry>
    </div>
  );
}
