import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useContext } from 'react';
import { StyleContext } from 'shared/contexts/StyleContext';

const AlertModal = ({ onOk, onCancel, text, handleClose, open }) => {
  const { isDarkMode } = useContext(StyleContext);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Are You Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>
            <span style={{ color: isDarkMode ? 'white' : 'black' }}>Cancel</span>
          </Button>
          <Button onClick={onOk} autoFocus>
            <span style={{ color: isDarkMode ? 'white' : 'black' }}>Confirm</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertModal;
