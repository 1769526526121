import React from 'react';
import { Button, Slide } from '@material-ui/core';
import { Field, Formik } from 'formik';

import TextEditor from 'Components/TextEditor';
import Header from './Header';
import { useDefaultTextModal } from './useDefaultTextModal';
import ImagesUpload from './ImagesUpload';
import useStyles from './useStyles';

const DefaultTextModal = () => {
  const classes = useStyles();
  const { onModalClose, submit, isModalOpen, initialValues, isLoading } = useDefaultTextModal();

  return (
    <Slide direction='up' in={isModalOpen} mountOnEnter unmountOnExit>
      <div data-cy-container='default-text' className={classes.defaultTextModal}>
        <div className={classes.defaultTextModalWrapper}>
          <Header onClose={onModalClose} />
          <div className={classes.modalContent}>
            <Formik initialValues={initialValues} onSubmit={submit}>
              {({ handleSubmit, dirty }) => (
                <div>
                  <h5 className={classes.sectionTitle}>Important Information About The Inspection Performed</h5>
                  <TextEditor fieldName='importantInformation' id='important-information' />
                  <Field name='importantInformation' type='text' hidden />
                  <h5 className={classes.sectionTitle}>
                    You Have Responsibilities To Be Addressed Before The Property Transfers To You
                  </h5>
                  <TextEditor fieldName='importantInformation2' id='legal-information' />
                  <Field name='importantInformation2' type='text' hidden />
                  <h5 className={classes.sectionTitle}>Important Images</h5>
                  <ImagesUpload />
                  <h5 className={classes.sectionTitle}>Reportable Conditions</h5>
                  <div className={classes.categoriesWrapper}>
                    <h6 className={classes.minorSubtitle}>Recommendation</h6>
                    <TextEditor fieldName='minorConcern' id='minor-concern' />
                    <Field name='minorConcern' type='text' hidden />
                    <h6 className={classes.mediumSubtitle}>Maintain/Minor Concern</h6>
                    <TextEditor fieldName='mediumConcern' id='medium-concern' />
                    <Field name='mediumConcern' type='text' hidden />
                    <h6 className={classes.majorSubtitle}>Significant Concern</h6>
                    <TextEditor fieldName='majorConcern' id='major-concern' />
                    <Field name='majorConcern' type='text' hidden />
                  </div>
                  <Button
                    disabled={!dirty || isLoading}
                    className={classes.submitButton}
                    onClick={handleSubmit}
                    data-cy-button='submit-default-text'
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default DefaultTextModal;
