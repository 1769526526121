import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';

export const initialValues = {
  commentName: '',
  type: DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR,
  recommendedExpert: 1,
  estimation: 1,
  estimationType: 1,
  estimationFrom: '',
  estimationTo: '',
  location: '',
  notes: '',
  photos: [],
  videos: [],
};
