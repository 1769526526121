import { MODALS } from 'constants/modals';

const MODALS_ACTION = {
  CLEAR_MODAL: 'CLEAR_MODAL',
  SET_MODAL: 'SET_MODAL',
};

const initialState = {
  modalType: null,
  props: {},
};

export const reducerModal = (state = initialState, action) => {
  switch (action.type) {
    case MODALS_ACTION.CLEAR_MODAL:
      return initialState;
    case MODALS_ACTION.SET_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export const closeModal = () => ({ type: MODALS_ACTION.CLEAR_MODAL });

export const openUserMenuModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.USER_MENU, props },
});
export const openAddSectionModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ADD_SECTION, props },
});
export const openAddItemModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ADD_ITEM, props },
});
export const openAddCommentModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ADD_COMMENT, props },
});
export const openConfirmationModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.CONFIRMATION, props },
});
export const openSectionInfoModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.SECTION_INFO, props },
});
export const openItemInfoModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ITEM_INFO, props },
});
export const openCommentInfoModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.COMMENT_INFO, props },
});
export const openAddLocationModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ADD_LOCATION, props },
});
export const openAddDeficienciesCommentModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ADD_DEFICIENCIES_COMMENT, props },
});
export const openReplaceItemModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.REPLACE_ITEM, props },
});
export const openGalleryModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.GALLERY, props },
});
export const openPreviewModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.PREVIEW, props },
});
export const openCalendarInspectionsModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.CALENDAR_INSPECTIONS, props },
});
export const openAddBlockerModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ADD_BLOCKER, props },
});
export const openEditBlockerModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.EDIT_BLOCKER, props },
});
export const openAddHolderModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.ADD_HOLDER, props },
});
export const openDefaultTextModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.DEFAULT_TEXT, props },
});
export const openImageEditorModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.IMAGE_EDITOR, props },
});
export const openEditLocationsModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.EDIT_LOCATIONS, props },
});
export const openFeedbackModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.FEEDBACK, props },
});
export const openTemplateHeaderModal = (props = {}) => ({
  type: MODALS_ACTION.SET_MODAL,
  payload: { modalType: MODALS.TEMPLATE_HEADER, props },
});
