import React from 'react';
import { IconButton } from '@material-ui/core';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';

import { fileUrl } from 'config/config';
import { closeModal } from 'redux/reducers/modals';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './useStyles';
import { getModalProps } from 'redux/selectors/modals';

const ImagePreview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { image } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  return (
    <div className={classes.previewWrapper}>
      <div className={classes.imageWrapper}>
        <img className={classes.image} src={`${fileUrl}${image}`} alt='Preview' />
        <IconButton className={classes.closeButton} onClick={onModalClose}>
          <PhotoSizeSelectSmallIcon className={classes.closeIcon} />
        </IconButton>
      </div>
    </div>
  );
};

export default ImagePreview;
