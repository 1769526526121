import { noop } from 'lodash';
import React, { useState, createContext } from 'react';

let LOCATIONS = [
  '1ST FLOOR',
  '2ND FLOOR',
  '3RD FLOOR',
  'BASEMENT',
  'CRAWLSPACE',
  'ATTIC',
  'MASTER',
  'NORTH',
  'SOUTH',
  'WEST',
  'EAST',
  'NORTHWEST',
  'NORTHEAST',
  'SOUTHWEST',
  'SOUTHEAST',
  'KITCHEN',
  'DINING ROOM',
  'LIVING ROOM',
  'BEDROOM',
  'BATHROOM',
  'GARAGE',
];

export const LocationsContext = createContext({
  locations: [...LOCATIONS],
  updateLocations: noop,
});

export const LocationsContextProvider = ({ children }) => {
  const [locations, setLocations] = useState([...LOCATIONS]);

  const handleUpdateLocations = (changes) => {
    setLocations(changes);
    LOCATIONS = changes;
  };

  const contextValue = {
    locations,
    updateLocations: handleUpdateLocations,
  };

  return <LocationsContext.Provider value={contextValue}>{children}</LocationsContext.Provider>;
};
