import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ children, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (isAuth ? children : <Redirect to={`/login?returnUrl=${document.location.pathname}`} />)}
    />
  );
};

export default PrivateRoute;
