import React, { useContext } from 'react';
import { Switch } from '@material-ui/core';
import { IsOnlineContext } from 'shared/IsOnlineContext';
import useStyles from './useStyles';

function OnlineSwitch() {
  const classes = useStyles();
  const { isOnline, setIsOnline, canBeChangedMannually } = useContext(IsOnlineContext);
  return (
    <div className={classes.switchWrapper}>
      <div className={classes.switchText}>{isOnline ? 'Online' : 'Offline'}</div>
      <Switch
        size='small'
        color={isOnline ? 'default' : 'primary'}
        disabled={!canBeChangedMannually}
        checked={isOnline}
        onChange={() => setIsOnline(!isOnline)}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </div>
  );
}

export default OnlineSwitch;
