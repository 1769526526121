import * as yup from 'yup';
import { DEFICIENCIES_COMMENT_TYPES } from '../../../../constants/deficienciesCommentTypes';

export const CommentValidation = yup.object().shape({
  defaultValue: yup.array().min(1, 'At least one value should be selected').required(),
});

const EditReportCommentValidation = yup.object().shape({
  defaultValue: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup.array().when(['location', 'notes', 'photos', 'videos'], (location, notes, photos, videos) => {
          if (!location && !notes && !photos?.length && !videos?.length) {
            return yup
              .array()
              .min(1, 'At least one field should be filled for submit')
              .required('At least one field should be filled for submit');
          }
          return yup.array();
        });
      case 'string':
        return yup.string().when(['location', 'notes', 'photos', 'videos'], (location, notes, photos, videos) => {
          if (!location && !notes && !photos?.length && !videos?.length) {
            try {
              JSON.parse(value);
              return yup
                .string()
                .min(3, 'At least one field should be filled for submit')
                .required('At least one field should be filled for submit');
            } catch {
              return yup
                .string()
                .min(1, 'At least one field should be filled for submit')
                .required('At least one field should be filled for submit');
            }
          }
          return yup.string();
        });
      default:
        return yup.mixed();
    }
  }),
});

const EditReportDeficienciesCommentValidation = yup.object().shape({
  estimationTypeId: yup.number().when(['estimationId', 'type'], (estimationId, type) => {
    if (+estimationId === 2 && type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) {
      return yup.number().required('Estimation type field is required');
    }
    return yup.number();
  }),
  estimationFrom: yup.number().when(['estimationId', 'type'], (estimationId, type) => {
    if (+estimationId === 2 && type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) {
      return yup.number().required('From field is required');
    }
    return yup.number();
  }),
  estimationTo: yup.number().when(['estimationId', 'type'], (estimationId, type) => {
    if (+estimationId === 2 && type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) {
      return yup.number().required('To field is required');
    }
    return yup.number();
  }),
  notes: yup
    .string()
    .when(['photos', 'videos', 'location', 'estimationId', 'type'], (photos, videos, location, estimationId, type) => {
      if (
        type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY &&
        +estimationId === 1 &&
        !location &&
        !photos?.length &&
        !videos?.length
      ) {
        return yup
          .string()
          .min(1, 'At least one field should be filled for submit')
          .required('At least one field should be filled for submit');
      }
      if (type !== DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && !location && !photos?.length && !videos?.length) {
        return yup
          .string()
          .min(1, 'At least one field should be filled for submit')
          .required('At least one field should be filled for submit');
      }
      return yup.string();
    }),
});

const EditDeficienciesCommentValidation = yup.object().shape({
  estimationId: yup.number().when('type', (type) => {
    if (type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) {
      return yup.number().required('Estimation field is required');
    }
    return yup.number();
  }),
  estimationTypeId: yup.number().when(['type', 'estimationId'], (type, estimationId) => {
    if (type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && +estimationId === 2) {
      return yup.number().required('Estimation type field is required');
    }
    return yup.number().nullable();
  }),
  estimationFrom: yup.number().when(['type', 'estimationId'], (type, estimationId) => {
    if (type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && +estimationId === 2) {
      return yup.number().required('From field is required');
    }
    return yup.number().nullable();
  }),
  estimationTo: yup.number().when(['type', 'estimationId'], (type, estimationId) => {
    if (type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && +estimationId === 2) {
      return yup.number().required('To field is required');
    }
    return yup.number().nullable();
  }),
});

export const getEditReportCommentValidation = (isDeficiencies) => {
  if (!isDeficiencies) {
    return EditReportCommentValidation;
  }
  return EditReportDeficienciesCommentValidation;
};

export const getEditCommentValidation = (isDeficiencies) => {
  if (isDeficiencies) {
    return EditDeficienciesCommentValidation;
  }
  return false;
};
