import { MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useDropdownMenu } from 'Components/DorpdownMenu/useDropdownMenu';
import { useContext } from 'react';
import { ConstantsContext } from 'shared/contexts/ConstantsContext';

export const useCommentsColumn = ({ onChangeComments, selectedItem, isMobile, isReport, section }) => {
  const { estimations, estimationsTypes } = useContext(ConstantsContext);
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { delay: 300, tolerance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 300, tolerance: 5 } }),
  );

  const { onDropdownOpen, onDropdownClose, anchorEl } = useDropdownMenu();

  const hasItem = selectedItem !== null;

  const title = isMobile ? section?.items?.[selectedItem]?.name : 'OBSERVATIONS';

  const editCommentModalProps = {
    onChangeComment: onChangeComments,
    isCreating: false,
    idx: +anchorEl?.dataset?.idx,
    itemIdx: selectedItem,
    isReport,
    section,
  };

  const editDeficienciesCommentModalProps = {
    onChangeComment: onChangeComments,
    isCreating: false,
    idx: +anchorEl?.dataset?.idx,
    itemIdx: selectedItem,
    isReport,
    section,
  };

  return {
    onDropdownOpen,
    onDropdownClose,
    editCommentModalProps,
    editDeficienciesCommentModalProps,
    anchorEl,
    title,
    sensors,
    estimations,
    estimationsTypes,
    hasItem,
  };
};
