import { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { AlertContext } from 'Components/Alert/alertContext';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import CalendarAPI from 'api/calendar';

export const useBlockerModal = ({ blocker, setCalendarDataRaw }) => {
  const { handleSetMessage } = useContext(AlertContext);
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { selectedDate, isMonth } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const [isFullDay, setIsFullDay] = useState(!!(blocker?.duration >= 12 || blocker?.isAllDay));

  const initialValues = useMemo(
    () => ({
      date: isMonth ? moment(selectedDate).add(1, 'day').utc() : moment(selectedDate).utc(),
      time: moment(selectedDate).utc(true),
      duration: 3,
      frequency: 'SINGLE',
      isAllDay: false,
      isEndless: false,
      isRecurring: false,
      recurrencePattern: null,
      recurringEventId: null,
      reminders: null,
      dateEnd: isMonth ? moment(selectedDate).add(1, 'day').utc() : moment(selectedDate).utc(),
    }),
    [],
  );

  const prioritizeBlockerKeys = ({ frequency, isEndless, dateEnd }) => {
    if (frequency === 'SINGLE') return { isEndless: false, frequency };
    if (isEndless) return { isEndless, frequency };
    return { frequency, isEndless: false, endDate: dateEnd };
  };

  const addBlocker = async ({ date, time, duration, frequency, isEndless, reminders, dateEnd }) => {
    const newBlocker = {
      blockedAt: moment.utc(
        `${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`,
        'YYYY-MM-DD  HH:mm',
      ),
      isAllDay: isFullDay,
      reminders,
      ...(isFullDay &&
        prioritizeBlockerKeys({
          frequency,
          isEndless,
          dateEnd: moment.utc(
            `${moment(dateEnd).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`,
            'YYYY-MM-DD  HH:mm',
          ),
        })),
      // eslint-disable-next-line
      ...(!isFullDay && { duration: parseInt(duration), time: moment(time).utc(true), frequency: 'SINGLE' }),
    };
    try {
      const res = await CalendarAPI.postBlocker(newBlocker);
      setCalendarDataRaw((prev) => ({ ...prev, blockers: [...prev.blockers, res.data] }));
      await handleSetMessage('Blocker added', 'success');
      onModalClose();
    } catch (error) {
      handleSetMessage(error?.response?.data?.message, 'error');
    }
  };
  const handleEditBlocker = async ({ date, time, duration, frequency, isEndless, reminders, dateEnd }) => {
    const updatedBlocker = {
      blockedAt: moment.utc(
        `${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`,
        'YYYY-MM-DD  HH:mm',
      ),
      id: blocker?.recurringEventId ? blocker?.recurringEventId : blocker.id,
      isAllDay: isFullDay,
      reminders,
      ...(isFullDay &&
        prioritizeBlockerKeys({
          frequency,
          isEndless,
          dateEnd: moment.utc(
            `${moment(dateEnd).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`,
            'YYYY-MM-DD  HH:mm',
          ),
        })),
      // eslint-disable-next-line
      ...(!isFullDay && { duration: parseInt(duration), time: moment(time).utc(true), frequency: 'SINGLE' }),
    };
    try {
      const res = await CalendarAPI.patchBlocker(updatedBlocker);
      setCalendarDataRaw((prev) => {
        const newBlockers = [...prev.blockers];
        const blockerIndx = newBlockers.findIndex((block) => block.id === updatedBlocker.id);
        newBlockers[blockerIndx] = { ...newBlockers[blockerIndx], ...res.data };
        return { ...prev, blockers: newBlockers };
      });

      await handleSetMessage('Blocker changed', 'success');
      onModalClose();
    } catch (error) {
      console.log({ error });
      handleSetMessage(error?.response?.data?.message, 'error');
    }
  };

  return { onModalClose, addBlocker, isModalOpen, initialValues, handleEditBlocker, isFullDay, setIsFullDay };
};
