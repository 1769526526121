import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import Sidebar from '../Sidebar';
import useStyles from './useStyles';

const ResultInspectionsLayout = ({
  onSelectSection,
  onInactiveSection,
  selectedSection,
  report,
  reportId,
  reportSection,
  hasPdf,
  isShown,
  children,
}) => {
  const classes = useStyles({ hasPdf });
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.layout}>
      {isTablet && !hasPdf && isShown && (
        <Sidebar
          onSelectSection={onSelectSection}
          onInactiveSection={onInactiveSection}
          selectedSection={selectedSection}
          sections={report?.sections}
          reportSection={reportSection}
          reportId={reportId}
        />
      )}
      <div className={classes.centerContent}>{children}</div>
    </div>
  );
};

export default ResultInspectionsLayout;
