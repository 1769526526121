import { makeStyles } from '@material-ui/core';

import { Comet, JungleGreen, Red } from 'styles/colors';

export default makeStyles({
  timeInfoWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  inspectionTimeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:first-child)': {
      textAlign: 'right',
    },
  },
  inspectionTimeLabel: {
    color: Comet,
    fontSize: 12,
  },
  inspectorInTime: {
    color: JungleGreen,
    fontSize: 12,
  },
  inspectorDelay: {
    color: Red,
    fontSize: 12,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});
