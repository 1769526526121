import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue, White } from 'styles/colors';

export default makeStyles({
  list: {
    maxWidth: '18%',
    position: 'fixed',
    maxHeight: 'calc(100vh - 74px)',
    overflowY: 'auto',
    bottom: 16,
    top: 124,
  },
  listItem: {
    color: Comet,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: 16,
    },
  },
  disabledItem: {
    color: rgba(Comet, 0.5),
    cursor: 'default',
  },
  listItemTitle: {
    flex: 1,
    paddingLeft: 8,
  },
  activeListItem: {
    color: RoyalBlue,
    fontWeight: 700,
  },
  commentCount: {
    width: 20,
    height: 20,
    backgroundColor: RoyalBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: White,
    fontSize: 10,
    borderRadius: '50%',
    marginLeft: 8,
  },
});
