import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  formWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: 24,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 40,
    },
  },
  formRow: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  formRowHalf: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
    marginBottom: 16,
  },
  textField: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: RoyalBlue,
      },
    },
  },
  selectField: {
    '& > .MuiSelect-select': {
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      '& > .MuiSelect-select': {
        maxWidth: '30vw',
        paddingRight: '10%',
      },
    },
  },
  dateField: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  inputButton: {
    padding: 0,
  },
  validTextField: {
    '& fieldset': {
      borderColor: rgba(Comet, 0.25),
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        backgroundColor: rgba(RoyalBlue, 0.05),
      },
      '&.Mui-focused fieldset': {
        borderColor: RoyalBlue,
      },
    },
    '&.MuiOutlinedInput-root': {
      backgroundColor: rgba(RoyalBlue, 0.05),
    },
  },
  formItem: {
    width: '100%',
    marginTop: 24,
  },
  addButton: {
    color: RoyalBlue,
    fontSize: 12,
    borderBottom: `1px dashed ${RoyalBlue}`,
    borderRadius: 0,
    paddingBottom: 4,
    textTransform: 'none',
    marginLeft: 'auto',
  },
  inspectorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mapWrapper: {
    height: 378,
    marginTop: 24,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  dateIcon: {
    fill: rgba(Comet, 0.7),
  },
}));
