import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue, White } from 'styles/colors';

export default makeStyles((theme) => ({
  headerTopWrapper: {
    display: 'flex',
    padding: '0 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: White,
  },
  closeButton: {
    padding: 0,
  },
  tabsWrapper: {
    width: '100%',
    paddingBottom: 9,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 24,
      justifyContent: 'center',
    },
  },
  tabLink: {
    position: 'relative',
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 400,
    color: Comet,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      '&:first-child': {
        marginRight: 24,
      },
    },
  },
  selectedTabLink: {
    color: RoyalBlue,
    textTransform: 'none',
    fontWeight: 700,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: 3,
      bottom: -11,
      borderRadius: 5,
      backgroundColor: RoyalBlue,
    },
    [theme.breakpoints.up('sm')]: {
      '&::after': {
        bottom: -26,
      },
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    color: Comet,
    margin: 0,
  },
}));
