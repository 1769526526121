import moment from 'moment';

const formBookedBlockers = (blockers) => {
  return (
    blockers?.map((blocker) => {
      if (blocker?.duration === 24) {
        return {
          ...blocker,
          endDate: moment(blocker.endDate).utc().add(-1, 'seconds').toISOString(true).split('+')[0],
        };
      }
      return blocker;
    }) || []
  );
};

export default formBookedBlockers;
