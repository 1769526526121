import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermission } from 'utils/permissions';
import { PERMISSIONS } from 'constants/permissions';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import { useContext } from 'react';
import { NotificationContext } from 'Components/Notification/notificationContext';

export const useUserMenuModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { user } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const { notifications, deleteNotification, deleteAllNotifications } = useContext(NotificationContext);

  const hasTemplatePermissions = isUserHasPermission(user, [
    PERMISSIONS.ADD_AND_SAVE_CONNECT_TEMPLATE,
    PERMISSIONS.VIEW_TEMPLATES_PAGE,
  ]);
  const hasUserAdministratorPermissions = isUserHasPermission(user, [PERMISSIONS.USER_ADMINISTRATOR]);

  const fullName = `${user?.firstName} ${user?.lastName}`;

  const deleteNotificationFunc = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    deleteNotification(id);
  };
  const onDeleteAllNotifications = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    deleteAllNotifications();
  };

  return {
    onModalClose,
    deleteNotification: deleteNotificationFunc,
    onDeleteAllNotifications,
    hasTemplatePermissions,
    hasUserAdministratorPermissions,
    fullName,
    notifications,
    user,
    isModalOpen,
  };
};
