import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as HashIcon } from 'assets/icons/hash.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as RadioIcon } from './assets/checkbox.svg';
import { ReactComponent as MultipleIcon } from './assets/multiple.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as NumListIcon } from './assets/numList.svg';
import { ReactComponent as TextIcon } from './assets/text.svg';

export const commentIcons = {
  RADIO: RadioIcon,
  CHOICES: MultipleIcon,
  NUMBER: HashIcon,
  DATE: CalendarIcon,
  RANGE: NumListIcon,
  TEXT: TextIcon,
  MAINTAIN_MONITOR: KeyIcon,
  ATTANCHENED_NEEDED: MinusIcon,
  ACTION_NECESSARY: WarningIcon,
};
