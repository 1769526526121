import mainInstance from 'api/mainInstance';

const ReportsAPI = {
  getReport: (id) => mainInstance.get(`/reports/${id}`),
  patchReport: (data) => mainInstance.patch(`/reports/${data.id}`, data),
  deleteReport: (id) => mainInstance.delete(`/reports/${id}`),
  completeReport: (id) => mainInstance.patch(`/reports/${id}/complete`),
  changePublicReport: ({ id, isPublic }) => mainInstance.patch(`/reports/${id}/public`, { id, isPublic }),
  getReportSection: (id) => mainInstance.get(`/report-sections/${id}`),
  postReportSection: (data) => mainInstance.post(`/report-sections/`, data),
  patchReportSection: (data) => mainInstance.patch(`/report-sections/${data.id}`, data),
  copyReportSection: ({ id, reportId }) => mainInstance.post(`/report-sections/${id}/copy`, { id, reportId }),
  deleteReportSection: (id) => mainInstance.delete(`/report-sections/${id}`),
  moveSectionInReport: ({ sectionId, position }) =>
    mainInstance.patch(`/report-sections/${sectionId}/move`, { position }),
  getGallery: (id) => mainInstance.get(`/reports/${id}/gallery`),
  deletePhoto: (id) => mainInstance.delete(`/reports/${id}/photo`),
  deleteReportPdf: (id) => mainInstance.delete(`/reports/${id}/pdf`),
};

export default ReportsAPI;
