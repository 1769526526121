import { makeStyles } from '@material-ui/core';

import { RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  locationsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '8px 16px',
    marginBottom: 24,

    [theme.breakpoints.up('sm')]: {
      gridGap: '16px 24px',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  locationOption: {
    border: `1px solid ${RoyalBlue}`,
    borderRadius: 5,
    padding: '8px 0',
    color: RoyalBlue,
  },
}));
