import NotificationsAPI from 'api/notifications';
import { socketUrl } from 'config/config';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import React, { createContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';

import { noop } from 'utils/noop';

export const NotificationContext = createContext({
  notifications: [],
  notificationMessage: '',
  notificationVariant: '',
  notificationLink: '',
  handleClose: noop,
  handleSetNotification: noop,
  deleteNotification: noop,
  deleteAllNotifications: noop,
  addOnNotificationCallback: noop,
  removeOnNotificationCallback: noop,
});

export const NotificationContextProvider = ({ children }) => {
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationVariant, setNotificationVariant] = useState('');
  const [notificationLink, setNotificationLink] = useState('');
  const [notifications, setNotifications] = useState([]);
  const notificationCallbacksRef = useRef([]);

  const socketRef = React.useRef();
  const { accessToken } = useSelector((state) => state.user.tokenData);
  const token = encodeURIComponent(accessToken);

  const handleSetNotification = React.useCallback((message, variant, link) => {
    setNotificationMessage(message);
    setNotificationVariant(variant);
    setNotificationLink(link);
  }, []);

  const handleClose = React.useCallback(() => {
    setNotificationMessage('');
    setNotificationVariant('');
    setNotificationLink('');
  }, []);

  const addOnNotificationCallback = (newCallback) => {
    notificationCallbacksRef.current = [...notificationCallbacksRef.current, newCallback];
  };

  const removeOnNotificationCallback = (oldCallback) => {
    notificationCallbacksRef.current = notificationCallbacksRef.current.filter((x) => x !== oldCallback);
  };

  const onConnect = () => {
    console.log('connected');
  };

  const onException = (data) => {
    console.log(data);
    handleSetNotification('Error', 'error');
  };

  const onNotification = (other) => {
    notificationCallbacksRef.current.forEach((func) => func(other.notification));
    if (
      other.notification.type !== NOTIFICATION_TYPES.PASSWORD_CHANGED.type &&
      other.notification.type !== NOTIFICATION_TYPES.JOB_STATUS_UPDATED.type
    ) {
      setNotifications((p) => (p.find((x) => x.id === other.notification.id) ? p : [other.notification, ...p]));
      return handleSetNotification(
        other.notification.message,
        'success',
        NOTIFICATION_TYPES[other.notification.type].link(other.notification),
      );
    }
  };

  const deleteNotification = React.useCallback(
    (id) => {
      NotificationsAPI.deleteNotification(id);
      setNotifications(notifications.filter((not) => not.id !== id));
    },
    [notifications],
  );

  const deleteAllNotifications = React.useCallback(() => {
    NotificationsAPI.deleteNotifications();
    NotificationsAPI.getNotifications().then((res) => setNotifications(res.data.data));
  }, []);

  const onDisconnect = () => console.log('Disconnected');

  React.useEffect(() => {
    if (token) {
      NotificationsAPI.getNotifications().then((res) => setNotifications(res.data.data));

      socketRef.current = io(socketUrl, {
        transports: ['websocket'],
        query: `token=${token.replace('JWT%20', '')}`,
        path: '/sockets',
        reconnectionDelay: 1000,
        reconnection: true,
        reconnectionAttempts: Infinity,
        jsonp: false,
      });

      socketRef.current.on('connect', onConnect);
      socketRef.current.on('exception', onException);
      socketRef.current.on('disconnect', onDisconnect);
      socketRef.current.on('notification', onNotification);
    }
    return () => {
      socketRef.current.off('connect', onConnect);
      socketRef.current.off('exception', onException);
      socketRef.current.off('disconnect', onDisconnect);
      socketRef.current.off('notification', onNotification);
    };
  }, [token]);

  const contextValue = {
    notifications,
    notificationMessage,
    notificationVariant,
    notificationLink,
    handleClose,
    handleSetNotification,
    deleteNotification,
    deleteAllNotifications,
    addOnNotificationCallback,
    removeOnNotificationCallback,
  };

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};
