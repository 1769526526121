const isLocal = process.env.NODE_ENV === 'development';
const isStage = /staging/.test(document.location.hostname);
export const syncfusionKey = 'ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFhWX9YdHBXQ2JaUkM=';

const localCfg = {
  siteUrl: 'http://localhost:3000',
  socketUrl: 'https://staging-api.inspect-hq.com',
  apiUrl: 'https://staging-api.inspect-hq.com/api',
  fileUrl: 'https://staging-api.inspect-hq.com/uploads/',
  // apiUrl: 'http://localhost:3306/api',
  // fileUrl: 'http://localhost:3306/uploads/',
};

const stageCfg = {
  siteUrl: 'https://staging.inspect-hq.com',
  socketUrl: 'https://staging-api.inspect-hq.com',
  apiUrl: 'https://staging-api.inspect-hq.com/api',
  fileUrl: 'https://staging-api.inspect-hq.com/uploads/',
};

const prodCfg = {
  siteUrl: 'https://inspect-hq.com',
  socketUrl: 'https://api.inspect-hq.com',
  apiUrl: 'https://api.inspect-hq.com/api',
  fileUrl: 'https://api.inspect-hq.com/uploads/',
};

const getCurrentConfig = () => {
  if (isLocal) {
    return localCfg;
  }
  if (isStage) {
    return stageCfg;
  }
  return prodCfg;
};

const { apiUrl, fileUrl, siteUrl, socketUrl } = getCurrentConfig();

export { apiUrl, fileUrl, siteUrl, socketUrl };
