import { makeStyles } from '@material-ui/core';

export default makeStyles({
  radioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  column: { flexDirection: 'column', alignItems: 'start' },
});
