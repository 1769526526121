import React from 'react';
import { MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import useStyles from './useStyles';

const SelectField = ({ labelId, label, placeholder, helperText: message, error, required, children, ...props }) => {
  const classes = useStyles(!!error)();
  const helperText = message || (required ? '* validation.required' : null);
  return (
    <FormControl variant='outlined' className={classes.formControl} error={error}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select
        {...props}
        labelId={labelId && labelId}
        label={label && label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        inputProps={{ className: classes.input }}
      >
        {placeholder && (
          <MenuItem value='' disabled>
            {placeholder}
          </MenuItem>
        )}
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;
