import { NOTIFICATION_TYPES } from 'constants/notifications';
import { noop } from 'utils/noop';
import { store } from 'redux/store';
import { clearAuthData } from 'redux/user/reducer';
import mainInstance from 'api/mainInstance';

export const MENU_LINKS = {
  dashboards: [
    { link: '/system/dashboard/schedule/day', title: 'Schedule' },
    { link: '/system/dashboard/map', title: 'Map' },
    { link: '/system/dashboard/home', title: 'Home' },
  ],
  inspections: [
    { link: '/system/inspections/all', title: 'View Inspections' },
    { link: '/system/inspections/add/subject-property', title: 'Add New Inspection' },
  ],
  templates: [
    { link: '/system/templates/active', title: 'Templates', isLink: true },
    { link: '/system/templates/add', title: 'Add New Template', isLink: true },
  ],
  user: [
    { link: '/system/user-administration/my-profile', title: 'Profile' },
    { link: '/system/settings/user', title: 'Settings' },
    { link: '/system/db-edit', title: 'Edit Database', isLink: true },
    // { title: 'Feedback', trigger: 'FEEDBACK' },
    { title: 'Logout', action: () => store.dispatch(clearAuthData()) },
  ],
};

export const NOTIFICATION_ACTIONS = {
  [NOTIFICATION_TYPES.INSPECTION_CREATED.type]: (notification, history, callback = noop) =>
    notification?.inspectionId
      ? () => {
          history.push(NOTIFICATION_TYPES.INSPECTION_CREATED.link(notification));
          callback();
        }
      : () => noop,
  [NOTIFICATION_TYPES.ISN_INSPECTION_CREATED.type]: (notification, history, callback = noop) =>
    notification?.inspectionId
      ? () => {
          history.push(NOTIFICATION_TYPES.ISN_INSPECTION_CREATED.link(notification));
          callback();
        }
      : () => noop,
  [NOTIFICATION_TYPES.INSPECTION_CHANGE_DATE_AND_TIME.type]: (notification, history, callback = noop) =>
    notification?.inspectionId
      ? () => {
          history.push(NOTIFICATION_TYPES.INSPECTION_CHANGE_DATE_AND_TIME.link(notification));
          callback();
        }
      : () => noop,
  [NOTIFICATION_TYPES.INSPECTION_STARTS_IN_NEXT_HOUR.type]: (notification, history, callback = noop) =>
    notification?.inspectionId
      ? () => {
          history.push(NOTIFICATION_TYPES.INSPECTION_STARTS_IN_NEXT_HOUR.link(notification));
          callback();
        }
      : () => noop,
  [NOTIFICATION_TYPES.INSPECTION_STARTS_IN_NEXT_24_HOURS.type]: (notification, history, callback = noop) =>
    notification?.inspectionId
      ? () => {
          history.push(NOTIFICATION_TYPES.INSPECTION_STARTS_IN_NEXT_24_HOURS.link(notification));
          callback();
        }
      : () => noop,
  [NOTIFICATION_TYPES.REPORT_COMPLETED.type]: (notification, history, callback = noop) =>
    notification?.inspectionId
      ? () => {
          history.push(NOTIFICATION_TYPES.REPORT_COMPLETED.link(notification));
          callback();
        }
      : () => noop,
  [NOTIFICATION_TYPES.INSPECTION_DELETED.type]: () => NOTIFICATION_TYPES.INSPECTION_DELETED.link,
  [NOTIFICATION_TYPES.HOLDER_CREATED.type]: () => NOTIFICATION_TYPES.HOLDER_CREATED.link,
  [NOTIFICATION_TYPES.PASSWORD_CHANGED.type]: () => NOTIFICATION_TYPES.PASSWORD_CHANGED.link,
  [NOTIFICATION_TYPES.ISN_INSPECTION_CANCELLED.type]: () => NOTIFICATION_TYPES.ISN_INSPECTION_CANCELLED.link,
  [NOTIFICATION_TYPES.REPORT_FILE_CREATED.type]: (notification) =>
    notification?.additionalParams?.link
      ? () => {
          const url = notification?.additionalParams?.link
            .replaceAll('https://api.inspect-hq.com/api', '')
            .replaceAll('https://staging-api.inspect-hq.com/api', '');
          mainInstance.get(url, { responseType: 'arraybuffer' }).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const messageArray = notification.message.split('generated. ');
            link.download = messageArray[1];
            link.click();
          });
        }
      : () => noop,
  [NOTIFICATION_TYPES.REPORT_MEDIA_ARCHIVED.type]: (notification) =>
    notification?.additionalParams?.link
      ? () => {
          const url = notification?.additionalParams?.link
            .replaceAll('https://api.inspect-hq.com/api', '')
            .replaceAll('https://staging-api.inspect-hq.com/api', '');
          mainInstance
            .get(url, {
              responseType: 'arraybuffer',

              onDownloadProgress(progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                document.getElementById(`notificationLoader-${notification.id}`).style.width = `${percentCompleted}%`;
              },
            })
            .then((res) => {
              const blob = new Blob([res.data], { type: 'application/zip' });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              const messageArray = notification.message.split('created. ');
              link.download = `${messageArray[1]} Media Archive`;
              link.click();
              document.getElementById(`notificationLoader-${notification.id}`).style.width = `${0}%`;
            });
        }
      : () => noop,
};
