import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Red } from 'styles/colors';

export default makeStyles({
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  time: {
    fontSize: 12,
    color: rgba(Comet, 0.5),
    fontFamily: 'RobotoMono, sans-serif',
  },
  card: {
    position: 'relative',
    minHeight: 60,
    flex: 1,
    marginLeft: 16,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderLeft: `1px solid ${Red}`,
    backgroundColor: rgba(Red, 0.05),
    '&::after': {
      position: 'absolute',
      content: '""',
      width: '100%',
      bottom: -16,
      left: 0,
      borderBottom: `1px dashed ${rgba(Comet, 0.15)}`,
    },
  },
  title: {
    color: Red,
    fontSize: 14,
    fontWeight: 600,
  },
  contentLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  timeRange: {
    color: Red,
    fontSize: 12,
    fontFamily: 'RobotoMono, sans-serif',
  },
  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    color: Red,
  },
  userAvatar: {
    border: `1px solid ${Red}`,
    borderRadius: '50%',
    width: 48,
    height: 48,
    objectFit: 'cover',
    margin: '0 4px',
  },
  defaultAvatar: {
    width: 26,
    height: 26,
    margin: '0 4px',
  },
});
