import { useMemo, useState } from 'react';

import { COMMENT_TYPES } from '../options';

export const useCommentTabs = (section) => {
  const [selectedCommentType, setCommentType] = useState(
    section?.standarts ? COMMENT_TYPES.STANDARDS : COMMENT_TYPES.INFORMATIONAL,
  );

  const { comments, photos, videos } = useMemo(() => {
    const filteredComments = [];
    const commentsPhoto = [];
    const commentsVideo = [];
    if (selectedCommentType !== COMMENT_TYPES.STANDARDS) {
      section?.items?.forEach((item) =>
        item?.comments?.forEach((comment) => {
          if (comment.block === selectedCommentType) {
            filteredComments.push(comment);
            const photosWithName = (comment.photos || []).map((photo) => ({ ...photo, name: comment.name }));
            commentsPhoto.push(...photosWithName);
            const videosWithName = (comment.videos || []).map((video) => ({ ...video, name: comment.name }));
            commentsVideo.push(...videosWithName);
          }
        }),
      );
    }
    return { comments: filteredComments, photos: commentsPhoto, videos: commentsVideo };
  }, [selectedCommentType]);

  const selectCommentType = (type) => () => setCommentType(type);

  return { selectCommentType, selectedCommentType, comments, photos, videos };
};
