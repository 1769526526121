import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, Jaffa, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  accordionWrapper: {
    position: 'static',
    overflow: 'visible !important',
    boxShadow: 'none',
    borderRadius: 5,
    background: 'transparent',
    overflowX: 'scroll',
    '&:not(:first-child)': {
      marginTop: 8,
    },
    maxWidth: '100%',
  },
  accordionWrapperSelected: {
    border: theme.palette.isDark && `1px solid ${theme.palette.primary.contrastText} !important`,
  },
  accordionContentWrapper: {
    background: theme.palette.primary.main,
    borderRadius: '0 0 5px 5px',
    borderTop: 'none',
    padding: 0,
    border: `1px solid ${rgba(theme.palette.primary.contrastText, 0.25)}`,
    maxWidth: '100%',
    fontSize: '12px !important',
    flexDirection: 'column',
  },
  columnItem: {
    borderRadius: 5,
    minHeight: '0 !important',
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${rgba(theme.palette.primary.contrastText, 0.25)}`,
    padding: 16,
    marginTop: 8,

    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      maxWidth: '95%',
      '& > div': {
        minWidth: '70px',
      },
    },

    '&.MuiAccordionSummary-root.Mui-expanded': {
      borderRadius: '5px 5px 0 0',
    },

    '& > .MuiAccordionSummary-expandIcon': {
      padding: 0,
      margin: 0,
    },
  },
  selectedColumnItem: {
    borderColor: RoyalBlue,
    backgroundColor: rgba(RoyalBlue, 0.05),
  },
  itemTitleWrapper: {
    color: Comet,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    width: '62%',
    marginLeft: 8,
    // [theme.breakpoints.up('sm')]: {
    //   width: '85%',
    // },
    '& svg': {
      minWidth: 28,
    },
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    paddingLeft: 8,
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 32px)',
    overflow: 'hidden',
  },
  selectedItemButton: {
    color: RoyalBlue,
    fontWeight: 700,
  },
  actionButton: {
    position: 'relative',
    padding: 0,
    '&:not(:first-child)': {
      marginLeft: 10,
    },
    [theme.breakpoints.up('lg')]: {
      '&:not(:first-child)': {
        marginLeft: 10,
        marginRight: 16,
        '&::after': {
          right: -8,
          position: 'absolute',
          content: '""',
          display: 'block',
          height: '100%',
          width: 1,
          backgroundColor: rgba(Comet, 0.15),
        },
      },
    },
  },
  moreIcon: {
    fill: Comet,
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px 16px 16px',
  },
  submitButton: {
    background: BlueGradient,
    padding: '12px 0',
    width: '100%',
    fontWeight: 700,
    transition: '250ms',
    '&:hover': {
      opacity: 0.8,
    },
  },
  disableButton: {
    background: rgba(Jaffa, 0.1),
    color: Jaffa,
    padding: '12px 0',
    width: '100%',
    fontWeight: 700,
    marginLeft: 8,
    '&:hover': {
      backgroundColor: rgba(Jaffa, 0.3),
    },
  },
  completedIcon: {
    position: 'absolute',
    left: -8,
    top: -8,
  },
}));
