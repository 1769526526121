import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
  },
  contentWrapper: {
    // boxSizing: 'border-box',
    flex: 1,
    padding: '0 16px ',
    marginTop: '56px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginTop: '62px',
      padding: '0 24px ',
      width: '90%',
      overflowX: 'hidden',
    },
    [theme.breakpoints.up('md')]: {
      margin: '62px 54px 0 54px',
      padding: '0 40px',
    },
  },
}));
