import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import CircularLoader from 'Components/CircularLoader';
import { fileUrl } from 'config/config';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useStyles from './useStyles';
import { ReactComponent as LocationDragHandle } from '../../../../../assets/icons/locationDragHandle.svg';

const SortingImageItem = ({ id, image, onDeleteImage, onOpenImageEdit, editorLoading }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const classes = useStyles();
  return (
    <li key={image.uploadedFile} style={style} className={classes.fileListItem}>
      <Button
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        className={classes.fileDragButton}
        style={{ touchAction: 'none' }}
      >
        <IconButton style={{ padding: 0 }}>
          <LocationDragHandle />
        </IconButton>
      </Button>
      <div>
        <div className={classes.filePreviewWrapper}>
          <img
            className={classes.filePreview}
            src={image.uploadedFile ? `${fileUrl}${image.uploadedFile}` : URL.createObjectURL(image.file)}
            data-cy-image='comment-image'
            alt='preview'
          />
        </div>
        <span className={classes.fileListItemTitle} data-cy-text='image-name'>
          {image.file.path}
        </span>
      </div>
      <IconButton onClick={onDeleteImage} data-cy-button='delete-comment-image'>
        <TrashIcon />
      </IconButton>
      <IconButton onClick={onOpenImageEdit}>{editorLoading ? <CircularLoader size={16} /> : <EditIcon />}</IconButton>
    </li>
  );
};

export default SortingImageItem;
