import { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { AlertContext } from 'Components/Alert/alertContext';
import mainInstance from 'api/mainInstance';
import { useParams } from 'react-router-dom';
import ReportsAPI from 'api/reports';
import InspectionsAPI from 'api/inspections';
import { IsOnlineContext } from 'shared/IsOnlineContext';

export const useFileUpload = (
  local,
  uploadCallback,
  acceptedTypes,
  sizeLimit,
  onFileUploaded,
  onFileRejected,
  onStartUploading,
  onEndUploading,
  uploadRoute,
  uploadField,
  isDisabled = false,
  updateReportPhoto = false,
  updateReportAndInspectionPhoto = false,
  multiple = false,
  limit,
  clientSide = false,
  galleryUpload,
) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = useState({ from: 0, to: 0 });
  const { id, reportId } = useParams();
  const { handleSetMessage } = useContext(AlertContext);
  const { isOnline } = useContext(IsOnlineContext);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
    maxSize: sizeLimit,
    disabled: loading || isDisabled,
    // eslint-disable-next-line consistent-return
    onDrop: async (acceptedFiles, fileRejections) => {
      if (clientSide) return onFileUploaded(acceptedFiles);
      if (fileRejections.length) {
        return onFileRejected(fileRejections);
      }

      if (!local) {
        setLoading(true);
        onStartUploading(true);
        if (multiple && acceptedFiles.length <= limit) {
          setProgress({ from: 0, to: acceptedFiles?.length });
          const resultArray = [];
          // eslint-disable-next-line
          for (const file of acceptedFiles) {
            setProgress({ from: resultArray.length, to: acceptedFiles?.length });
            // eslint-disable-next-line
            const data = new FormData();
            data.append(!galleryUpload ? uploadField : 'file', file);
            if (galleryUpload) data.append('templateId', (reportId || id).toString());
            try {
              const uploadUrl = !galleryUpload ? `/admin/${uploadRoute}` : '/template-files';
              // eslint-disable-next-line
              const uploadResponse = await mainInstance.post(uploadUrl, data);

              resultArray.push({ file, uploadedFile: uploadResponse?.data?.name || uploadResponse?.data });
            } catch {
              setLoading(false);
              if (!isOnline) {
                resultArray.push({ file, uploadedFile: '' });
              }
            }
          }
          await onFileUploaded(resultArray);
          setLoading(false);
          onEndUploading(false);
        } else if (multiple && acceptedFiles.length > limit) {
          handleSetMessage(`Attachments count limit reached, you can only upload ${limit} more image(s)`, 'error');
          setLoading(false);
          onEndUploading(false);
        } else {
          const data = new FormData();
          data.append(!galleryUpload ? uploadField : 'file', acceptedFiles[0]);
          if (galleryUpload) data.append('templateId', (reportId || id).toString());
          try {
            const uploadUrl = !galleryUpload ? `/admin/${uploadRoute}` : '/template-files';
            const uploadResponse = await mainInstance.post(uploadUrl, data);

            if (updateReportPhoto) {
              try {
                handleSetMessage('photo uploaded', 'success');
              } catch (error) {
                handleSetMessage(error?.response?.data?.message, 'error');
              }
            }
            if (updateReportAndInspectionPhoto) {
              try {
                const report = await ReportsAPI.getReport(reportId);
                await InspectionsAPI.patchInspection({
                  photo: uploadResponse?.data?.name || uploadResponse?.data,
                  id: report?.data?.Inspection?.id,
                });
                handleSetMessage('photo uploaded', 'success');
              } catch (error) {
                handleSetMessage(error?.response?.data?.message, 'error');
              }
            }
            await onFileUploaded({
              file: multiple ? acceptedFiles : acceptedFiles[0],
              uploadedFile: uploadResponse?.data?.name || uploadResponse?.data,
            });
            onEndUploading(false);
            setLoading(false);
          } catch {
            onEndUploading(false);
            setLoading(false);
            if (!isOnline) {
              onFileUploaded({ file: multiple ? acceptedFiles : acceptedFiles[0], uploadedFile: '' });
            }
          }
        }
      } else {
        uploadCallback(acceptedFiles[0]);
      }
    },
  });

  return { getRootProps, getInputProps, loading };
};
