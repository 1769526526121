import { makeStyles } from '@material-ui/core';

import { Comet, RoyalBlue } from 'styles/colors';
import { rgba } from 'polished';

export default makeStyles({
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    position: 'relative',
    boxShadow: `0 0 0 2px ${RoyalBlue}`,
  },
  avatarImage: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  defaultIcon: {
    fill: rgba(Comet, 0.4),
    width: '100%',
    height: '100%',
  },
});
