import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { closeModal } from 'redux/reducers/modals';
import CalendarAPI from 'api/calendar';
import { getCurrentUser } from 'redux/selectors/user';
import { isUserHasPermission } from 'utils/permissions';
import { PERMISSIONS } from 'constants/permissions';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import formBookedBlockers from '../utils/formBookedBlockers';

export const useDetailsModal = () => {
  const history = useHistory();

  const { date, isMonth = false, setCalendarDataRaw } = useSelector(getModalProps);
  const user = useSelector(getCurrentUser);
  const [blockersRaw, setBlockersRaw] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const inspections = blockersRaw?.inspections || [];
  const blockers = formBookedBlockers(blockersRaw?.blockers);
  const holders = blockersRaw?.holders || [];

  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const onModalClose = () => dispatch(closeModal());

  const hasCreateHolderPermission = isUserHasPermission(user, [PERMISSIONS.CREATE_INSPECTION]);

  const addInspection = () => {
    const defaultDate = isMonth ? moment(date).add(1, 'day').utc() : moment(date).utc();
    const defaultTime = isMonth ? moment('10000-01-01 09:00:00').utc(true) : moment(date).utc(true);
    onModalClose();
    history.push({
      pathname: '/system/inspections/add/subject-property',
      state: { defaultTime: defaultTime.format('YYYY-MM-DD'), defaultDate: defaultDate.format('YYYY-MM-DD') },
    });
  };

  const createInspectionFromHolder = (holder) => async () => {
    onModalClose();
    history.push({ pathname: '/system/inspections/add/subject-property', state: { ...holder } });
  };

  useEffect(() => {
    setIsLoading(true);
    if (!isMonth && moment(date).format('HH:mm:ss') !== '00:00:00')
      CalendarAPI.getCalendarBooked(moment(date).utc().toISOString())
        .then((res) => setBlockersRaw(res.data))
        .finally(() => setIsLoading(false));
    if (isMonth || moment(date).format('HH:mm:ss') === '00:00:00') {
      const startOfDay = moment(date).utc().toISOString();
      const endOfDay = moment(date).add(1, 'day').utc().toISOString();
      CalendarAPI.getCalendarData({ from: startOfDay, to: endOfDay })
        .then((res) => setBlockersRaw(res.data))
        .finally(() => setIsLoading(false));
    }
  }, [date]);

  return {
    onModalClose,
    addInspection,
    createInspectionFromHolder,
    date,
    inspections,
    blockers,
    holders,
    isMonth,
    isModalOpen,
    isLoading,
    hasCreateHolderPermission,
    setCalendarDataRaw,
  };
};
