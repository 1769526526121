import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import React from 'react';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import TemplatesAPI from 'api/templates';

export const useAddSection = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const {
    index,
    onEditDone,
    isCreating,
    isReport,
    thisSection,
    isMasterTemplate,
    sections = [],
    assignSectionsToTemplate,
  } = useSelector(getModalProps);
  const [isLoading, setIsLoading] = React.useState(false);
  const [availableSections, setAvailableSections] = React.useState([]);
  const [hasFetchedSections, setHasFetchedSections] = React.useState(false);

  React.useEffect(async () => {
    if (!isReport) {
      setIsLoading(true);
      const allSections = await TemplatesAPI.getAllTemplateSections();
      const data = sections
        ? allSections.data.data.filter((sect) => sections.find((sec) => sec.id === sect.id) === undefined)
        : allSections.data.data;
      setAvailableSections(data);
      setIsLoading(false);
    }
    setHasFetchedSections(true);
  }, []);

  const onModalClose = () => dispatch(closeModal());

  const hasIndex = index !== undefined || thisSection;

  const section = sections?.[index] || thisSection;

  const templateSection = hasIndex && {
    sectionName: section.name,
    sectionIcon: section.icon,
    standarts: section.standarts,
    notes: section.reminders,
  };

  const submit = async (values) => {
    setIsLoading(true);
    const data = {
      name: values.sectionName,
      icon: values.sectionIcon,
      standarts: values.standarts,
      reminders: values.notes,
      position: 0,
      items: hasIndex ? section.items : [],
    };
    if (values?.selectedSections?.length > 0) {
      assignSectionsToTemplate(values?.selectedSections);
    } else await onEditDone(data, { sectionIndx: index, isCreating });
    setIsLoading(false);
    onModalClose();
  };

  return {
    availableSections,
    submit,
    onModalClose,
    templateSection,
    hasIndex,
    isCreating,
    isModalOpen,
    isLoading,
    isReport,
    hasFetchedSections,
    isMasterTemplate,
  };
};
