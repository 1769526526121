import mainInstance from 'api/mainInstance';

const TemplatesAPI = {
  getTemplates: ({ page, limit, type, isArchived }) =>
    mainInstance.get(`/admin/templates?page=${page}&limit=${limit}&type=${type}&isArchived=${isArchived}`),
  getAvailableTemplates: ({ inspectionTypeId }) =>
    mainInstance.get(`/templates?inspectionTypeId=${inspectionTypeId}&limit=99`),
  getOtherAvailableTemplates: ({ inspectionTypeId, other }) =>
    mainInstance.get(`/templates?inspectionTypeId=${inspectionTypeId}&other=${other}&limit=99`),
  getTemplate: (id) => mainInstance.get(`/admin/templates/${id}`),
  deleteTemplate: (id) => mainInstance.delete(`/admin/templates/${id}`),
  postTemplate: (data) => mainInstance.post('/admin/templates/', data),
  lockTemplate: (data) => mainInstance.patch(`/admin/templates/${data.id}/locked`, data),
  patchTemplate: (data) => mainInstance.patch(`/admin/templates/${data.id}`, data),
  getTemplateSection: (id) => mainInstance.get(`/admin/template-sections/${id}`),

  getAllTemplateSections: () => mainInstance.get('/admin/template-sections?limit=1000'),
  deleteTemplateSections: (id) => mainInstance.delete(`/admin/template-sections/${id}`),
  postTemplateSection: (data) => mainInstance.post('/admin/template-sections', data),
  patchTemplateSection: (data) => mainInstance.patch(`/admin/template-sections/${data.id}`, data),
  copyTemplateSection: (id) => mainInstance.post(`/admin/template-sections//${id}/copy`),

  assignSectionToTemplate: ({ templateId, sectionId }) =>
    mainInstance.post(`/admin/templates/${templateId}/sections/${sectionId}`),
  assignSectionsToTemplate: ({ templateId, sectionIds }) =>
    mainInstance.post(`/admin/templates/${templateId}/sections`, { sectionIds }),
  moveSectionInTemplate: ({ templateId, sectionId, position }) =>
    mainInstance.patch(`/admin/templates/${templateId}/sections/${sectionId}/move`, { position }),
  deleteSectionInTemplate: ({ templateId, sectionId }) =>
    mainInstance.delete(`/admin/templates/${templateId}/sections/${sectionId}/`),
  copyTemplate: (id) => mainInstance.post(`/admin/templates/${id}/copy`),
};

export default TemplatesAPI;
