import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    right: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    padding: '0 24px',

    backgroundColor: 'white',
    zIndex: 999,
    [theme.breakpoints.up('md')]: {
      padding: '0 55px',
    },
  },
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 24,
      justifyContent: 'center',
    },
  },
  tabLink: {
    position: 'relative',
    fontSize: 12,
    fontWeight: 400,
    color: Comet,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      '&:not(:first-child)': {
        marginLeft: 24,
      },
    },
  },
  selectedTabLink: {
    color: RoyalBlue,
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: 3,
        bottom: -26,
        borderRadius: 5,
        backgroundColor: RoyalBlue,
      },
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    color: Comet,
    margin: 0,
  },
  logoWrapper: {
    padding: '4px 8px',
    background: BlueGradient,
    '& .MuiButton-label': {
      fontSize: 12,
      fontWeight: 700,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '8px 16px',
      position: 'absolute',
      right: 0,
      bottom: -50,
    },
  },
  reportsMenuButton: {
    color: Comet,
    textTransform: 'none',
    '&>.MuiButton-label': {
      alignItems: 'center',
    },
  },
  arrowIcon: {
    fill: Comet,
  },
}));
