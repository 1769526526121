import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Red } from 'styles/colors';

export default makeStyles((theme) => ({
  addLocationModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    margin: '5% 24px',

    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      width: '100%',
      maxWidth: 700,
      height: 'fit-content',
      borderRadius: 16,
    },
  },
  addLocationModalWrapper: {
    maxHeight: '100%',
    minHeight: '50vh',
    height: 'fit-content',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80vh',
    },
  },
  modalHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    zIndex: 999,
    borderRadius: '16px 16px 0 0',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginTop: 73,
  },
  locationsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '8px 16px',
    marginBottom: 24,

    [theme.breakpoints.up('sm')]: {
      gridGap: '16px 24px',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  locationOption: {
    border: '1px solid rgba(91, 95, 131, 0.5)',
    borderRadius: 5,
    padding: '0px 12px',
    color: Comet,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: 44,
    height: 44,
  },
  modalFooter: {
    paddingTop: 24,
    marginTop: 24,
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 700,
    width: 153,
    padding: '12px 0',
    margin: '0 24px 0 25px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addLocationButton: {
    backgroundColor: '#3E6FDF !important',
    width: 48,
    height: 48,
    minWidth: 48,
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#3E6FDF !important',
    },
  },
  cancelButton: {
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  submitButton: {
    // background: BlueGradient,
    background: 'linear-gradient(272.18deg, #3E6FDF 0%, #56CCF2 100%)',
  },
  addNewLocationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  input: {
    maxHeight: 48,
    height: 48,
    width: 'auto',
    padding: '14.5px 14px',
    flex: 1,
  },
  sotrableItemInput: {
    '&:focus': {
      outline: 'none',
      borderBottom: `1px solid ${Comet}`,
    },
    border: 0,
    borderBottom: '1px solid transparent',
    width: '40%',
    padding: '0 10',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingTop: 2,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    '& $notchedOutline': {
      borderColor: 'rgba(91, 95, 131, 0.25)',
    },
    '&:hover $notchedOutline': {
      borderColor: 'transparent',
    },
    '&$focused $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  focused: {},
  notchedOutline: {},
}));
