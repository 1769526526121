import React from 'react';
import { Button, ButtonGroup, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import cls from 'classnames';

import { COMMENT_TYPES } from '../options';
import { useCommentTabs } from './useCommentTabs';
import useStyles from './useStyles';
import Slider from '../Slider';
import DeficienciesComments from '../DeficienciesComments';

const CommentsTabs = ({ section, reportSection }) => {
  const classes = useStyles();
  const { selectCommentType, selectedCommentType, comments, photos, videos } = useCommentTabs(section);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {!isDesktop && section?.standarts && (
        <Button
          className={cls(classes.tabsButton, classes.standardsButton, {
            [classes.tabsButtonActive]: selectedCommentType === COMMENT_TYPES.STANDARDS,
          })}
          onClick={selectCommentType(COMMENT_TYPES.STANDARDS)}
        >
          STANDARDS
        </Button>
      )}
      <ButtonGroup className={classes.tabsButtonsWrapper}>
        {isDesktop && section?.standarts && (
          <Button
            className={cls(classes.tabsButton, {
              [classes.tabsButtonActive]: selectedCommentType === COMMENT_TYPES.STANDARDS,
            })}
            onClick={selectCommentType(COMMENT_TYPES.STANDARDS)}
          >
            STANDARDS
          </Button>
        )}
        <Button
          className={cls(classes.tabsButton, {
            [classes.tabsButtonActive]: selectedCommentType === COMMENT_TYPES.INFORMATIONAL,
          })}
          onClick={selectCommentType(COMMENT_TYPES.INFORMATIONAL)}
        >
          {isDesktop ? 'GENERAL INFORMATION' : 'GENERAL'}
        </Button>
        <Button
          className={cls(classes.tabsButton, {
            [classes.tabsButtonActive]: selectedCommentType === COMMENT_TYPES.LIMITATIONS,
          })}
          onClick={selectCommentType(COMMENT_TYPES.LIMITATIONS)}
        >
          {isDesktop ? 'LIMITATIONS TO INSPECTION' : 'LIMITATIONS'}
        </Button>
        <Button
          className={cls(classes.tabsButton, {
            [classes.tabsButtonActive]: selectedCommentType === COMMENT_TYPES.PRIORITY,
          })}
          onClick={selectCommentType(COMMENT_TYPES.PRIORITY)}
        >
          {isDesktop ? 'REPORTABLE CONDITIONS' : 'PRIORITY'}
        </Button>
      </ButtonGroup>
      {selectedCommentType === COMMENT_TYPES.STANDARDS && (
        <div dangerouslySetInnerHTML={{ __html: section?.standarts }} />
      )}
      {[COMMENT_TYPES.INFORMATIONAL, COMMENT_TYPES.LIMITATIONS].includes(selectedCommentType) && (
        <>
          <div className={classes.commentsWrapper}>
            {comments?.map((comment, idx) => (
              <div key={`${comment.name}${idx + 1}`} className={classes.commentWrapper}>
                <span className={classes.commentTitle} dangerouslySetInnerHTML={{ __html: comment.name }} />
                <div>
                  <span className={classes.commentValue}>
                    {!isEmpty(comment.defaultValue) && (
                      <>
                        {comment.type === 'RADIO' && comment.defaultValue}
                        {comment.type === 'TEXT' && <div dangerouslySetInnerHTML={{ __html: comment.defaultValue }} />}
                        {(comment.type === 'CHOICES' || comment.type === 'NUMBER') &&
                          comment?.defaultValue?.map((value, i) =>
                            comment.defaultValue?.length !== i + 1 ? `${value}, ` : value,
                          )}
                        {comment.type === 'DATE' && moment(comment.defaultValue).format('MM/DD/YYYY')}
                        {comment.type === 'RANGE' &&
                          `From ${comment?.defaultValue.from}, To ${comment?.defaultValue.to}`}
                      </>
                    )}
                  </span>
                  {comment?.notes && <div dangerouslySetInnerHTML={{ __html: comment.notes }} />}
                </div>
              </div>
            ))}
          </div>
          <Slider photos={photos} videos={videos} />
        </>
      )}
      {selectedCommentType === COMMENT_TYPES.PRIORITY && (
        <DeficienciesComments section={section} reportSection={reportSection} />
      )}
    </>
  );
};

export default CommentsTabs;
