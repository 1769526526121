import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import zipcodes from 'zipcodes';
import moment from 'moment';

import { STATES } from 'constants/location';
import { useMapWithGeocoder } from 'hooks/useMapWithGeocoder';
import { ConstantsContext } from 'shared/contexts/ConstantsContext';
import InspectionsAPI from 'api/inspections';

export const useHolderForm = () => {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  const { getAddressFromCoordinates, getCoordinatesFromAddress } = useMapWithGeocoder(setFieldValue);

  const [availableInspectors, setAvailableInspectors] = useState([]);

  const { inspectionTypes } = useContext(ConstantsContext);

  const selectedInspectorsId = values.inspectors.map((selectedInspector) => selectedInspector.userId);

  const [stateValue, setStateValue] = useState({ title: '', value: '' });

  const changeState = (e, state) => setFieldValue('state', state ? state.value : '');

  const setCoordinates = (
    address = values.addressFirstLine,
    state = values.state,
    city = values.city,
    zipCode = values.zipCode,
  ) => {
    if (address && state && city && zipCode) {
      getCoordinatesFromAddress(address, state, city, zipCode);
    }
  };

  const changeCity = (e) => {
    setFieldValue('city', e?.target.value || '');
    setCoordinates(undefined, undefined, e.target.value);
  };

  const blurZip = (e) => {
    const zipData = zipcodes.lookup(e.target.value);
    if (zipData !== undefined) {
      if (!values.state) setFieldValue('state', zipData.state);
      if (!values.city) setFieldValue('city', zipData.city);
      setCoordinates(undefined, zipData.state, zipData.city, e.target.value);
    }
  };

  useEffect(() => {
    const newStateValue = STATES.find((state) => state.value === values.state);
    setStateValue(newStateValue || { title: '', value: '' });
  }, [values.state]);

  useEffect(async () => {
    if (values.date && values.time && values.duration) {
      const inspectionAt = moment(
        `${moment(values.date).format('YYYY-MM-DD')} ${moment(values.time).format('HH:mm')}`,
        'YYYY-MM-DD  HH:mm',
      )
        .utc()
        .toISOString();

      if (values.lat && values.lon) {
        InspectionsAPI.getAvailableInspections({
          inspectionAt,
          duration: values.duration,
        }).then((res) => {
          setAvailableInspectors(res.data);
          InspectionsAPI.getInspectorsAvailability({
            destinationLat: values.lat,
            destinationLon: values.lon,
            inspectionAt,
            duration: values.duration,
            inspectors: (res.data || []).map((inspector) => inspector?.id).join(','),
          }).then((resx) => {
            const newInspectors = resx.data.map((inspector) => ({
              ...inspector,
              ...(res.data.find((insp) => insp.id === inspector.userId) || {}),
            }));
            setAvailableInspectors(newInspectors);
          });
        });
      }
    }
  }, [values.date, values.time, values.duration, values.lat, values.lon]);

  return {
    setFieldValue,
    changeState,
    changeCity,
    blurZip,
    getAddressFromCoordinates,
    setCoordinates,
    stateValue,
    availableInspectors,
    selectedInspectorsId,
    inspectionTypes,
    values,
    errors,
    touched,
  };
};
