import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    marginBottom: 24,
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  tabLink: {
    color: rgba(Comet, 0.75),
    fontWight: 400,
    fontSize: 12,
    '&:not(:first-child)': {
      marginLeft: 20,
    },
    [theme.breakpoints.up('md')]: {
      textTransform: 'uppercase',
      fontSize: 14,
      '&:not(:first-child)': {
        marginLeft: 40,
      },
    },
  },
  pdfStatus: {
    display: 'flex',
    width: '32%',
    justifyContent: 'flex-end',
    color: rgba(Comet, 0.75),
    fontWight: 400,
    fontSize: 12,
    marginRight: 40,
    '&:not(:first-child)': {
      marginLeft: 20,
    },
    [theme.breakpoints.up('md')]: {
      textTransform: 'uppercase',
      fontSize: 14,
      '&:not(:first-child)': {
        marginLeft: 40,
      },
    },
  },
  selectedTabLink: {
    color: RoyalBlue,
    fontWeight: 700,
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: 3,
      bottom: -17,
      borderRadius: 5,
      backgroundColor: RoyalBlue,
    },
  },
  downloadPdfButton: {
    color: RoyalBlue,
    textTransform: 'none',
    fontSize: 12,
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px dashed ${RoyalBlue}`,
    },
  },
}));
