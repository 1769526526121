import { useState } from 'react';

const defaultsConfig = {
  Rect: {
    fill: '#ff0000',
    stroke: '#ff0000',
    strokeWidth: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowBlur: 0,
    shadowColor: '#ff0000',
    shadowOpacity: 1,
    opacity: 0.31,
    cornerRadius: 0,
  },
  Text: {
    fill: '#ff0000',
    stroke: '#ff0000',
    strokeWidth: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowBlur: 0,
    shadowColor: '#ff0000',
    shadowOpacity: 1,
    opacity: 1,
    text: ' ',
    fontFamily: 'Arial',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: 1,
    align: 'left',
    fontStyle: 'normal',
  },
  Polygon: {
    fill: '#ff0000',
    stroke: '#ff0000',
    strokeWidth: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowBlur: 0,
    shadowColor: '#ff0000',
    shadowOpacity: 1,
    opacity: 1,
    sides: 3,
  },
  Ellipse: {
    fill: '#ff0000',
    stroke: '#ff0000',
    strokeWidth: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowBlur: 0,
    shadowColor: '#ff0000',
    shadowOpacity: 1,
    opacity: 1,
  },
  Arrow: {
    fill: '#ff0000',
    stroke: '#ff0000',
    strokeWidth: 6,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowBlur: 0,
    shadowColor: '#ff0000',
    shadowOpacity: 1,
    opacity: 1,
    lineCap: 'butt',
  },
  Pen: {
    fill: '#ff0000',
    stroke: '#ff0000',
    strokeWidth: 1,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowBlur: 0,
    shadowColor: '#ff0000',
    shadowOpacity: 1,
    opacity: 1,
    tension: 0.5,
    lineCap: 'round',
  },
  Line: {
    fill: '#ff0000',
    stroke: '#ff0000',
    strokeWidth: 1,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowBlur: 0,
    shadowColor: '#ff0000',
    shadowOpacity: 1,
    opacity: 1,
    tension: 0.5,
    lineCap: 'round',
  },
};
const validShapeKeys = [
  'fill',
  'stroke',
  'strokeWidth',
  'shadowOffsetX',
  'shadowOffsetY',
  'shadowBlur',
  'shadowColor',
  'shadowOpacity',
  'opacity',
  'cornerRadius',
  // 'text',
  'fontFamily',
  'fontSize',
  'letterSpacing',
  'lineHeight',
  'align',
  'fontStyle',
  'sides',
  'tension',
];

export const useImageEditor = () => {
  const [defaultSettings, setDefaultSettings] = useState(
    {
      ...defaultsConfig,
      ...JSON.parse(localStorage.getItem('editorSettings2')),
    } || defaultsConfig,
  );
  const onChangeSettings = ({ annotations, selectionsIds }) => {
    if (selectionsIds?.length === 1) {
      const shapeName = selectionsIds[0].split('-')[0];
      if (shapeName !== 'Image') {
        const newSettings = { ...defaultSettings };
        Object.keys(annotations[selectionsIds[0]]).forEach((key) => {
          if (validShapeKeys.includes(key)) {
            newSettings[shapeName][key] = annotations[selectionsIds[0]][key];
          }
        });
        localStorage.setItem('editorSettings2', JSON.stringify(newSettings));
        setDefaultSettings(newSettings);
      }
    }
  };

  return {
    defaultSettings,
    onChangeSettings,
  };
};
