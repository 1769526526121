import { makeStyles } from '@material-ui/core';

import { Comet } from 'styles/colors';

export default makeStyles((theme) => ({
  formRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  formRowWithTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 2,
    color: '#5B5F83',
    fontSize: 16,
    fontWeight: 500,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionSelectField: {
    flex: 1,
    marginTop: 24,

    [theme.breakpoints.up('sm')]: {
      marginLeft: 16,
      marginTop: 0,
      maxWidth: '33%',
    },
  },
  formItem: {
    width: '100%',
    marginTop: 24,
  },
  nameField: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  iconSelectField: {
    flex: 1,
    marginTop: 24,

    [theme.breakpoints.up('sm')]: {
      marginLeft: 16,
      marginTop: 0,
    },
  },
  textBlockLabel: {
    display: 'block',
    color: Comet,
    paddingBottom: 8,
  },
  selectMenuIcon: {
    width: 32,
    height: 32,
  },
}));
