import { useContext } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AlertContext } from 'Components/Alert/alertContext';
import UserAPI from 'api/user';
import { setCurrentUser } from 'redux/user/reducer';

export const useResetPasswordByToken = () => {
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();
  const { handleSetMessage } = useContext(AlertContext);

  const cancel = () => history.push('/login');

  const reset = async ({ password }) => {
    try {
      await UserAPI.resetPasswordByToken({ password, token });
      handleSetMessage('Password changed', 'success');
    } catch (error) {
      handleSetMessage(error?.response?.data?.message, 'error');
    }
  };

  useAsyncEffect(async () => {
    try {
      const res = await UserAPI.verifyResetPasswordToken(token);
      dispatch(setCurrentUser(res.data.user));
    } catch (error) {
      handleSetMessage('Link is invalid or expired', 'error');
      history.push('/login');
    }
  }, []);

  return { cancel, reset };
};
