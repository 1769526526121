import React from 'react';

import useStyles from './useStyles';

const TokenExpiredException = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <h2 className={classes.exceptionText}>
        Your link is expired. Please, ask Real Estate Inspections, to send a new link
      </h2>
    </div>
  );
};

export default TokenExpiredException;
