import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { fileUrl } from 'config/config';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useStyles from './useStyles';
import { ReactComponent as LocationDragHandle } from '../../../../../assets/icons/locationDragHandle.svg';

const SortingVideoItem = ({ id, video, onDeleteVideo }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const classes = useStyles();
  return (
    <li key={video.uploadedFile} style={style} className={classes.fileListItem}>
      <Button
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        className={classes.fileDragButton}
        style={{ touchAction: 'none' }}
      >
        <IconButton style={{ padding: 0 }}>
          <LocationDragHandle />
        </IconButton>
      </Button>
      <div>
        <div className={classes.filePreviewWrapper}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video className={classes.filePreview} data-cy-video='comment-video' controls>
            <source src={video.uploadedFile ? `${fileUrl}${video.uploadedFile}` : URL.createObjectURL(video.file)} />
          </video>
        </div>
        <span className={classes.fileListItemTitle}>{video.file.path}</span>
      </div>
      <IconButton onClick={onDeleteVideo} data-cy-button='delete-comment-video'>
        <TrashIcon />
      </IconButton>
    </li>
  );
};

export default SortingVideoItem;
