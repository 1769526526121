import { useSelector } from 'react-redux';
import { useDropdownMenu } from 'Components/DorpdownMenu/useDropdownMenu';
import { PERMISSIONS } from 'constants/permissions';
import { getCurrentUser } from 'redux/selectors/user';
import { isUserHasPermission } from 'utils/permissions';
import { useContext } from 'react';
import { NotificationContext } from 'Components/Notification/notificationContext';

export const useHeader = () => {
  const { onDropdownOpen, onDropdownClose, onCloseWithAction, anchorEl, menuId } = useDropdownMenu();

  const user = useSelector(getCurrentUser);
  const { notifications, deleteNotification, deleteAllNotifications } = useContext(NotificationContext);

  const onDeleteAllNotifications = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    deleteAllNotifications();
  };
  const fullName = `${user?.firstName} ${user?.lastName}`;
  const hasTemplatePermissions = isUserHasPermission(user, [PERMISSIONS.VIEW_TEMPLATES_PAGE]);
  const hasUserAdministratorPermissions = !!(user?.roles || []).find((role) => role.name === 'Admin/Owner');
  const hasUserCreateInspectionsPermissions = isUserHasPermission(user, [PERMISSIONS.CREATE_INSPECTION]);

  const deleteNotificationFunc = async (e, notification) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    deleteNotification(notification?.id);
  };

  return {
    onDropdownOpen,
    onDropdownClose,
    onCloseWithAction,
    deleteNotification: deleteNotificationFunc,
    handleDeleteAllNotifications: onDeleteAllNotifications,
    user,
    anchorEl,
    menuId,
    notifications,
    fullName,
    hasTemplatePermissions,
    hasUserAdministratorPermissions,
    hasUserCreateInspectionsPermissions,
  };
};
