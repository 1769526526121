import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles({
  imagePreviewButton: {
    all: 'unset',
    width: 'min(320px, 100%)',
    height: 'auto',
    minHeight: 192,
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 30,
  },
  imagePreview: {
    width: '100%',
    boxShadow: `0px 2px 13px -10px ${rgba(Comet, 0.5)}`,
    borderRadius: 5,
  },
  selectedImage: {
    boxShadow: `0px 2px 10px 5px ${rgba(RoyalBlue, 0.5)}`,
  },
});
