import React, { useState, useContext } from 'react';
// import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  Button,
  IconButton,
  Input,
  Slide,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import cls from 'classnames';
import { noop } from 'utils/noop';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import TextField from 'Components/TextField';
import useStyles from '../useStyles';
import { useDropzone } from 'react-dropzone';
import FileUpload from 'Components/FileUpload';
import CircularLoader from 'Components/CircularLoader';
import { AlertContext } from 'Components/Alert/alertContext';

const ACCEPTED_TYPES = 'image/png, image/jpg, image/jpeg';

const maxSize = 20000000; // 20mb

const FeedbackForm = ({ photos, setPhotos, videos, setVideos }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setFieldValue, touched, values, errors } = useFormikContext();
  const { handleSetMessage } = useContext(AlertContext);
  const [fileSize, setFileSize] = useState(0);
  const { open } = useDropzone();

  const renderPreview = (loading) => {
    return loading ? (
      <div className={classes.loaderWrapper}>
        <CircularLoader />
      </div>
    ) : (
      <>
        <Button
          onClick={open}
          style={{
            color: 'blue',
            width: '96%',
            padding: '10px 0px',
            border: '1px blue solid',
            borderRadius: '5px',
            margin: '10px 2%',
          }}
        >
          Upload Image
        </Button>

        {photos.length > 0 && (
          <>
            <div className={classes.mediaRow}>
              {photos.map((photo, i) => (
                <span>
                  <img src={URL.createObjectURL(photo)} alt='preview' />
                  <IconButton onClick={handleDelete('photo', i)}>
                    <TrashIcon />
                  </IconButton>
                </span>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  const renderVideoPreview = (loading) => {
    return loading ? (
      <div className={classes.loaderWrapper}>
        <CircularLoader />
      </div>
    ) : (
      <>
        <Button
          onClick={open}
          style={{
            color: 'blue',
            width: '96%',
            padding: '10px 0px',
            border: '1px blue solid',
            borderRadius: '5px',
            margin: '10px 2%',
          }}
        >
          Upload Video
        </Button>

        {videos.length > 0 && (
          <>
            <div className={classes.mediaRow}>
              {videos.map((video, i) => (
                <>
                  <span>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video data-cy-video='comment-video' controls>
                      <source src={URL.createObjectURL(video)} />
                    </video>
                    <IconButton onClick={handleDelete('video', i)}>
                      <TrashIcon />
                    </IconButton>
                  </span>
                </>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  const addPhoto = (photo) => {
    if (fileSize + photo.size < maxSize) {
      setPhotos((old) => [...old, photo]);
      setFileSize((old) => old + photo.size);
    } else {
      // eslint-disable-next-line
      handleSetMessage("Can't add an attachment, size limit exceeded", 'error');
    }
  };

  const addVideo = (video) => {
    if (fileSize + video.size < maxSize) {
      setVideos((old) => [...old, video]);
      setFileSize((old) => old + video.size);
    } else {
      // eslint-disable-next-line
      handleSetMessage("Can't add an attachment, size limit exceeded", 'error');
    }
  };

  const handleDelete = (type, i) => (e) => {
    e.stopPropagation();
    if (type === 'photo') {
      const newPhotos = [...photos];
      setFileSize((old) => old - photos[i].size);
      newPhotos.splice(i, 1);
      setPhotos(newPhotos);
    } else {
      const newVideos = [...videos];
      setFileSize((old) => old - videos[i].size);
      newVideos.splice(i, 1);
      setVideos(newVideos);
    }
  };

  return (
    <>
      <div className={classes.formRow}>
        <div className={classes.fieldWrapper} style={{ width: '100%' }}>
          <Field name='subject'>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={touched.subject && !!meta.error}
                helperText={touched.subject && meta.error}
                variant='outlined'
                type='text'
                label='SUBJECT'
                className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
              />
            )}
          </Field>

          <Field name='email'>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={touched.email && !!meta.error}
                helperText={touched.email && meta.error}
                variant='outlined'
                type='text'
                label='YOUR EMAIL'
                className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
              />
            )}
          </Field>
        </div>
      </div>
      <div className={classes.formRow}>
        <Field name='message'>
          {({ field, meta }) => (
            <TextField
              {...field}
              error={touched.message && !!meta.error}
              helperText={touched.message && meta.error}
              variant='outlined'
              type='text'
              label='MESSAGE'
              multiline
              className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
            />
          )}
        </Field>
      </div>
      <div className={classes.formRow}>
        <FileUpload
          local
          uploadCallback={addPhoto}
          acceptedTypes={ACCEPTED_TYPES}
          onFileUploaded={() => console.log('uplo')}
          onFileRejected={() =>
            handleSetMessage("Can't add an attachment, size limit exceeded or invalid format", 'error')
          }
          uploadRoute='images'
          uploadField='image'
          updateReportPhoto
          sizeLimit={maxSize}
        >
          {renderPreview}
        </FileUpload>
        {/* </div>
      <div className={classes.formRow}> */}
        <FileUpload
          local
          uploadCallback={addVideo}
          acceptedTypes={'video/*'}
          onFileUploaded={() => console.log('uplo')}
          onFileRejected={() =>
            handleSetMessage("Can't add an attachment, size limit exceeded or invalid format", 'error')
          }
          uploadRoute='images'
          uploadField='image'
          updateReportPhoto
          sizeLimit={maxSize}
        >
          {renderVideoPreview}
        </FileUpload>
      </div>
      <div className={classes.formRow} style={{ color: '#5B5F83', fontWeight: '500' }}>
        Attachment size limit: {Math.floor(fileSize / 1000000)}/{maxSize / 1000000}mb
      </div>
    </>
  );
};

export default FeedbackForm;
