import React from 'react';
import { IconButton, Slide } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import useStyles from './useStyles';
import { closeModal } from 'redux/reducers/modals';
import { useDispatch, useSelector } from 'react-redux';
import { getModalProps, getModalType } from 'redux/selectors/modals';

const SectionInfoModal = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { section } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.sectionInfoModal} data-cy-container='section-info'>
        <div className={classes.sectionInfoModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Report Module Information</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <h3 className={classes.sectionName} data-cy-text='section-name'>
              {section.name}
            </h3>
            <div>
              <h5 className={classes.infoTitle}>STANDARDS</h5>
              <div dangerouslySetInnerHTML={{ __html: section?.standarts }} />
            </div>
            <div>
              <h5 className={classes.infoTitle}>REMINDERS</h5>
              <div dangerouslySetInnerHTML={{ __html: section?.reminders }} />
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default SectionInfoModal;
