import React from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Button, IconButton } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import FileUpload from 'Components/FileUpload';
import CircularLoader from 'Components/CircularLoader';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { fileUrl } from 'config/config';
import useStyles from './useStyles';
import { useImagesUpload } from './useImagesUpload';

const ImagesUpload = () => {
  const classes = useStyles();
  const { addImage, deleteImage, values, isMaxLimit } = useImagesUpload();
  const { open } = useDropzone();

  return (
    <div className={classes.wrapper}>
      <Button
        disabled={isMaxLimit}
        onClick={open}
        classes={{ disabled: classes.disabledMediaButton }}
        className={classes.mediaButton}
      >
        <FileUpload onFileUploaded={addImage} uploadField='image' uploadRoute='images' isDisabled={isMaxLimit}>
          {(loading) => (
            <>
              {loading && (
                <div className={classes.fileUploadWrapper}>
                  <CircularLoader size={20} isWhite />
                </div>
              )}
              {!loading && (
                <div className={classes.fileUploadWrapper}>
                  <AddAPhotoIcon className={classes.mediaButtonIcon} />
                </div>
              )}
            </>
          )}
        </FileUpload>
      </Button>
      <div className={classes.previewsWrapper}>
        {values.templateDefaultImages.map((image, idx) => (
          <div className={classes.previewWrapper}>
            <IconButton onClick={deleteImage(idx)} className={classes.deleteButton}>
              <TrashIcon />
            </IconButton>
            <div className={classes.imageWrapper}>
              <img className={classes.preview} key={image} src={`${fileUrl}${image}`} alt='Important info' />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagesUpload;
