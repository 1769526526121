import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  logoWrapper: {
    height: 46,
  },
  logo: {
    height: '100%',
  },
}));
