import React from 'react';

import { fileUrl } from 'config/config';
import useStyles from './useStyles';

const sharedConcernHeaderStyles = {
  color: 'white',
  height: 40,
  borderTopRightRadius: '5px',
  borderTopLeftRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 10,
  fontSize: 15,
  fontWeight: 500,
};

const StaticInfo = ({ report }) => {
  const classes = useStyles();
  return (
    <div>
      {report?.importantInformation && (
        <>
          <h2 className={classes.title}>Important Information About The Inspection Performed</h2>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            dangerouslySetInnerHTML={{ __html: report.importantInformation }}
          />
        </>
      )}
      {report?.importantInformation2 && (
        <>
          <h2 className={classes.title}>
            You Have Responsibilities To Be Addressed Before The Property Transfers To You
          </h2>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            dangerouslySetInnerHTML={{ __html: report.importantInformation2 }}
          />
        </>
      )}

      {report?.templateDefaultImages && report?.templateDefaultImages.length > 0 && (
        <>
          <h2 className={classes.title}>Important Images</h2>
          <div className={classes.imagesWrapper}>
            {report.templateDefaultImages.map((image) => (
              <div className={classes.imageWrapper}>
                <img className={classes.image} src={`${fileUrl}${image}`} alt='Important Info' />
              </div>
            ))}
          </div>
        </>
      )}

      <h2 className={classes.titlePriorities}>Definition Of Reportable Conditions</h2>
      <h4 className={classes.subtitle} style={{ fontSize: 12 }}>
        Understanding how items that the Inspector wants to bring to your attention are prioritized in the report.
      </h4>
      {report?.majorConcern && (
        <>
          <h3
            className={classes.majorTitle}
            style={{
              ...sharedConcernHeaderStyles,
              background: '#f44336',
            }}
          >
            Significant Concern
          </h3>
          <div
            className={classes.pWrap}
            style={{
              paddingLeft: 0,
            }}
            dangerouslySetInnerHTML={{ __html: report.majorConcern }}
          />
        </>
      )}
      {report?.mediumConcern && (
        <>
          <h3
            className={classes.moderateTitle}
            style={{
              ...sharedConcernHeaderStyles,
              background: '#F2994A',
            }}
          >
            Maintain/Minor Concern
          </h3>
          <div
            className={classes.pWrap}
            style={{
              paddingLeft: 0,
            }}
            dangerouslySetInnerHTML={{ __html: report.mediumConcern }}
          />
        </>
      )}
      {report?.minorConcern && (
        <>
          <h3
            className={classes.minorTitle}
            style={{
              ...sharedConcernHeaderStyles,
              background: '#3E6FDF',
            }}
          >
            Recomendations
          </h3>
          <div
            className={classes.pWrap}
            style={{
              paddingLeft: 0,
            }}
            dangerouslySetInnerHTML={{ __html: report.minorConcern }}
          />
        </>
      )}
    </div>
  );
};

export default StaticInfo;
