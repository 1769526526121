import mainInstance from 'api/mainInstance';

const UserAPI = {
  getCurrentUser: () => mainInstance.get('/user'),
  getUser: (id) => mainInstance.get(`/admin/users/${id}`),
  postLogin: (data) => mainInstance.post('/login', data),
  postLoginAs: (id) => mainInstance.post(`/admin/users/${id}/tokens`),
  verifyResetPasswordToken: (token) => mainInstance.get(`/verify-reset-password-token/${token}`),
  resetPasswordByToken: (data) => mainInstance.post(`/reset-password-by-token`, { data }),
  resetPassword: (password) => mainInstance.post(`/user/change-password`, { password }),
};

export default UserAPI;
