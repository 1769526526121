import { makeStyles } from '@material-ui/core';

import { Comet } from 'styles/colors';

export default makeStyles((theme) => ({
  formRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  formItem: {
    width: '100%',
  },
  checkboxWrapper: {
    width: '100%',
    margin: '20px 0',
  },
  checkboxLabel: {
    color: Comet,
    fontSize: 14,
  },
}));
