import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Jaffa, JungleGreen, Red } from 'styles/colors';

export default makeStyles((theme) => ({
  columnWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
      borderLeft: `1px solid ${rgba(Comet, 0.1)}`,
      marginLeft: 24,
      paddingLeft: 24,
      paddingBottom: 0,
      marginTop: 0,
      maxHeight: 'calc(100vh - 262px)',
      overflowY: 'auto',
    },

    [theme.breakpoints.up('lg')]: {
      marginLeft: 40,
      borderLeft: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 114,
    },
  },
  columnHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    margin: 0,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: Comet,
    margin: 0,
  },
  generalTitle: {
    color: JungleGreen,
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 8,
      height: 8,
      backgroundColor: JungleGreen,
      borderRadius: '50%',
      marginRight: 8,
    },
  },
  limitationsTitle: {
    color: Jaffa,
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 8,
      height: 8,
      backgroundColor: Jaffa,
      borderRadius: '50%',
      marginRight: 8,
    },
  },
  priorityTitle: {
    color: Red,
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 8,
      height: 8,
      backgroundColor: Red,
      borderRadius: '50%',
      marginRight: 8,
    },
  },
  buttonAddNew: {
    fontSize: 12,
    borderBottom: '1px dashed',
    textTransform: 'none',
    borderRadius: 0,
  },
  buttonAddNewGeneral: {
    color: JungleGreen,
    borderColor: JungleGreen,
  },
  buttonAddNewLimitations: {
    color: Jaffa,
    borderColor: Jaffa,
  },
  buttonAddNewPriority: {
    color: Red,
    borderColor: Red,
  },
  buttonAddNewMobile: {
    padding: 0,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    padding: 16,
    borderRadius: 5,
    maxWidth: '100%',
  },
  generalColumn: {
    backgroundColor: rgba(JungleGreen, theme.palette.isDark ? 0.3 : 0.05),
    marginTop: 24,
  },
  limitationsColumn: {
    backgroundColor: rgba(Jaffa, theme.palette.isDark ? 0.3 : 0.05),
  },
  priorityColumn: {
    backgroundColor: rgba(Red, theme.palette.isDark ? 0.25 : 0.1),
  },
  commentIcon: {
    fill: rgba(Comet, 0.25),
  },
}));
