import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';

export const useDefaultTextModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { template, changeTemplate } = useSelector(getModalProps);
  const [isLoading, setIsLoading] = useState(false);
  const onModalClose = () => dispatch(closeModal());

  const initialValues = {
    importantInformation: template?.importantInformation || '',
    importantInformation2: template?.importantInformation2 || '',
    minorConcern: template?.minorConcern || '',
    mediumConcern: template?.mediumConcern || '',
    majorConcern: template?.majorConcern || '',
    templateDefaultImages: template?.templateDefaultImages || [],
  };

  const submit = async (values) => {
    setIsLoading(true);
    await changeTemplate(values);
    setIsLoading(false);
    onModalClose();
  };

  return { onModalClose, submit, isModalOpen, initialValues, isLoading };
};
