import { GOOGLE_ADDRESS_PARTS } from 'constants/googleAddressParts';

export const useMapWithGeocoder = (setFieldValue) => {
  // eslint-disable-next-line no-undef
  const geocoder = new google.maps.Geocoder();

  const getAddressFromCoordinates = ({ lat, lng }) => {
    geocoder.geocode({ location: { lat, lng } }, (res, status) => {
      if (status === 'OK') {
        const addressData = res[0].address_components;
        let address = '';
        addressData.forEach((item) => {
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.STREET_NUMBER)) {
            address += item.short_name;
          }
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.ROUTE)) {
            address += ` ${item.short_name}`;
          }
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.STATE)) {
            setFieldValue('state', item.short_name);
          }
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.CITY)) {
            setFieldValue('city', item.short_name);
          }
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.ZIP_CODE)) {
            setFieldValue('zipCode', item.short_name);
          }
        });
        setFieldValue('addressFirstLine', address);
        setFieldValue('lat', lat);
        setFieldValue('lon', lng);
      }
    });
  };

  const getCoordinatesFromAddress = (address, state, city, zipCode) => {
    geocoder.geocode({ address: `${address}, ${city}, ${state} ${zipCode}` }, (res, status) => {
      if (status === 'OK') {
        setFieldValue('lat', res[0].geometry.location.lat());
        setFieldValue('lon', res[0].geometry.location.lng());
      }
    });
  };

  return { getAddressFromCoordinates, getCoordinatesFromAddress };
};
