import { makeStyles } from '@material-ui/core';

export default makeStyles({
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switchText: {
    color: '#5B5F83',
  },
});
