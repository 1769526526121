import { CSS } from '@dnd-kit/utilities';

import { White } from 'styles/colors';

export const draggableItemStyles = (isDragging, transition, transform) => ({
  zIndex: isDragging ? 1 : undefined,
  backgroundColor: isDragging && White,
  boxShadow: isDragging ? '0 0 0 1px rgba(63, 63, 68, 0.05), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)' : undefined,
  transform: isDragging
    ? CSS.Transform.toString({ ...transform, scaleY: 1.05, scaleX: 1.05 })
    : CSS.Transform.toString(transform),
  transition,
  cursor: isDragging ? 'grabbing' : 'pointer',
});
