import React from 'react';
import { Field, useFormikContext } from 'formik';
import { MenuItem } from '@material-ui/core';

import SelectField from 'Components/SelectField';
import { COMMENT_TYPES } from 'constants/commentTypes';
import {
  typesWithCalendar,
  typesWithOptions,
  typesWithOptionsAndDefault,
  typesWithOptionsRange,
  typesWithText,
} from '../../options';
import { commentIcons } from '../../icons';
import useStyles from './useStyles';

const SelectType = () => {
  const classes = useStyles();
  const { handleChange, setFieldValue, setErrors, setTouched, touched, errors } = useFormikContext();

  const changeType = (e) => {
    handleChange(e);
    setFieldValue('defaultValue', []);
    if (typesWithOptions.includes(e.target.value) || typesWithOptionsAndDefault.includes(e.target.value))
      setFieldValue('values', ['']);
    if (typesWithOptionsRange.includes(e.target.value))
      setFieldValue('values', [
        {
          from: '',
          to: '',
        },
      ]);
    if (typesWithText.includes(e.target.value) || typesWithCalendar.includes(e.target.value))
      setFieldValue('values', '');
    setErrors({ ...errors, values: undefined });
    setTouched({ ...touched, values: undefined });
  };

  return (
    <Field name='commentType'>
      {({ field, meta }) => (
        <SelectField
          {...field}
          error={touched.commentType && !!meta.error}
          helperText={touched.commentType && meta.error}
          labelId='commentType'
          label='ANSWER TYPE'
          placeholder='ANSWER TYPE'
          onChange={changeType}
          data-cy-select='comment-type'
        >
          {COMMENT_TYPES.map((type) => {
            const Icon = commentIcons[type.icon];
            return (
              <MenuItem key={type.icon} value={type.icon} data-cy-item={type.title}>
                <Icon />
                <span className={classes.selectItemTitle}>{type.title}</span>
              </MenuItem>
            );
          })}
        </SelectField>
      )}
    </Field>
  );
};

export default SelectType;
