import * as yup from 'yup';

import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import { INSPECTOR_ESTIMATION_ID } from './options';

export const AddDeficienciesCommentValidation = yup.object().shape({
  commentName: yup.string().trim().required('Comment name is required field'),
  recommendedExpert: yup.number(),
  estimation: yup.number(),
  estimationType: yup.number(),
  estimationFrom: yup
    .number()
    .when(['estimation', 'type'], (estimation, type) =>
      +estimation === INSPECTOR_ESTIMATION_ID && type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY
        ? yup.number().typeError('Only number allowed').required('From field is required')
        : yup.number().nullable(),
    ),
  estimationTo: yup
    .number()
    .when(['estimation', 'type'], (estimation, type) =>
      +estimation === INSPECTOR_ESTIMATION_ID && type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY
        ? yup.number().typeError('Only number allowed').required('To field is required')
        : yup.number().nullable(),
    ),
  location: yup.string(),
  notes: yup.string(),
});
