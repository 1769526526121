import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { useSortable } from '@dnd-kit/sortable';
import cls from 'classnames';

import { draggableItemStyles } from 'utils/dndItemStyle';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { useDispatch } from 'react-redux';
import { openItemInfoModal } from 'redux/reducers/modals';
import { ReactComponent as CompletedIcon } from '../../assets/completed.svg';
import { ReactComponent as NextIcon } from '../assets/next.svg';
import useStyles from './useStyles';

const Item = ({
  onItemSelect,
  onDropdownOpen,
  selectedItem,
  item,
  idx,
  isReport,
  shouldRenderNextButton,
  isOutlinedItem,
}) => {
  const classes = useStyles({ isOutlinedItem });
  const dispatch = useDispatch();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: idx + 1 });
  const style = draggableItemStyles(isDragging, transition, transform);

  return (
    <Button
      onClick={() => onItemSelect(idx)}
      className={cls(classes.columnItem, { [classes.selectedColumnItem]: selectedItem === idx })}
      ref={setNodeRef}
      style={style}
      data-cy-button={`item-${item.name}`}
      {...attributes}
      {...listeners}
    >
      <div className={classes.itemTitleWrapper}>
        {isReport && item.isDone && <CompletedIcon className={classes.completedIcon} />}
        <span
          className={cls(classes.itemTitle, { [classes.selectedItemTitle]: selectedItem === idx })}
          data-cy-text={`item-${item.name}`}
        >
          {item.name}
        </span>
      </div>
      <div className={classes.actionButtonsWrapper}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            dispatch(openItemInfoModal({ item }));
          }}
          className={classes.actionButton}
          data-cy-button={`item-info-${item.name}`}
        >
          <InfoIcon />
        </IconButton>
        <IconButton
          id={`item${idx}`}
          data-idx={idx}
          onClick={onDropdownOpen}
          className={classes.actionButton}
          data-cy-button={`item-menu-${item.name}`}
        >
          <MoreIcon className={classes.moreIcon} />
        </IconButton>
        {shouldRenderNextButton && (
          <IconButton onClick={onItemSelect(idx)} className={cls(classes.actionButton, classes.nextButton)}>
            <NextIcon />
          </IconButton>
        )}
      </div>
    </Button>
  );
};

export default Item;
