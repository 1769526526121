import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';
import { Comet, RoyalBlue } from '../../../styles/colors';

export default makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 1px 0 0 ${rgba(Comet, 0.1)}`,
    position: 'fixed',
  },
  toolbar: {
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 55px',
    },
  },
  navigationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  navigationItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: Comet,
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    transition: 'color .3s',
    '&:not(:first-child)': {
      marginLeft: 32,
      [theme.breakpoints.up('lg')]: {
        marginLeft: 48,
      },
    },
    '&:hover': {
      color: rgba(RoyalBlue, 0.7),
    },
  },
  navigationItemDropdownItem: {
    color: theme.palette.primary.contrastText,
  },
  selected: {
    color: RoyalBlue,
    fontWeight: 700,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 9,
      height: 5,
      left: '50%',
      right: 'auto',
      bottom: -25,
      borderRadius: '5px 5px 0 0',
      backgroundColor: RoyalBlue,
    },
  },
  mobileMenuButton: {
    padding: 0,
  },
  userButton: {
    padding: 0,
    textTransform: 'none',
  },
  avatarWrapper: {
    width: 32,
    height: 32,
  },
  userName: {
    fontSize: 14,
    color: Comet,
    paddingLeft: 8,
  },
  notificationButton: {
    padding: 0,
    marginRight: 54,
    '&::after': {
      content: '""',
      position: 'absolute',
      height: 12,
      width: 1,
      backgroundColor: rgba(Comet, 0.25),
      right: -27,
      cursor: 'default',
    },
  },
  hasNotificationIcon: {
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 8,
      width: 8,
      borderRadius: '50%',
      backgroundColor: RoyalBlue,
      boxShadow: `0 0 4px 4px ${rgba(RoyalBlue, 0.35)}`,
      right: 0,
      top: 0,
    },
  },
  notificationItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 500,
    color: Comet,
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${rgba(Comet, 0.3)}`,
    },
  },
  emptyNotifications: {
    padding: '4px 16px',
    color: Comet,
  },
  notificationLoadingProggress: {
    backgroundColor: RoyalBlue,
    height: 4,
    position: 'absolute',
    width: 0,
    bottom: 0,
    left: 0,
  },
}));
