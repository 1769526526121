import { fileUrl } from 'config/config';
import { useMakeScreenshot } from 'hooks/useMakeScreenshot';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import { noop } from 'utils/noop';

const trimCanvas = async (c) => {
  // Usage example:

  const ctx = c.getContext('2d');
  const copy = document.createElement('canvas').getContext('2d');
  const pixels = ctx.getImageData(0, 0, c.width, c.height);
  const l = pixels.data.length;
  let i;
  const bound = {
    top: null,
    left: null,
    right: null,
    bottom: null,
  };
  let x;
  let y;
  ctx.imageSmoothingEnabled = false;

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % c.width;
      // eslint-disable-next-line
      y = ~~(i / 4 / c.width);

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }

  // Calculate the height and width of the content
  const trimHeight = bound.bottom - bound.top;
  const trimWidth = bound.right - bound.left;
  const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
  console.log({ bound, trimWidth, trimHeight });

  copy.canvas.width = trimWidth;
  copy.canvas.height = trimHeight;
  copy.putImageData(trimmed, 0, 0);
  // Return trimmed canvas
  return copy.canvas;
};

const getMeta = async (url) => {
  const img = new Image();
  img.src = url;
  await img.decode();
  return img;
};

function cloneCanvasAndEnlarge(oldCanvas) {
  // create a new canvas
  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');

  // set dimensions
  newCanvas.width = oldCanvas.width * 2;
  newCanvas.height = oldCanvas.height * 2;

  // apply the old canvas to the new one
  context.drawImage(oldCanvas, 0, 0, newCanvas.width, newCanvas.height);

  // return the new canvas
  return newCanvas;
}

function cloneCanvasAndFixSize(oldCanvas, originalImage) {
  const resizedCanvas = document.createElement('canvas');
  const resizedContext = resizedCanvas.getContext('2d');

  resizedCanvas.width = originalImage.naturalWidth;
  resizedCanvas.height = originalImage.naturalHeight;

  resizedContext.drawImage(oldCanvas, 0, 0, originalImage.naturalWidth, originalImage.naturalHeight);
  const myResizedData = resizedCanvas.toDataURL();

  return myResizedData;
}

export const useImageEditorModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { onSaveEditedImage, photo, setEditorLoading = noop, isReport } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const { readCanvas, screenRef } = useMakeScreenshot(isReport);

  const onBeforeSubmit = async (x, c) => {
    console.log({ x, c });
    const canvasParent = document.getElementsByClassName('konvajs-content')[0];
    onModalClose();
    setEditorLoading(true);
    const BIG_CANVAS = cloneCanvasAndEnlarge(canvasParent.childNodes[0]);

    const trimmedCanvas = await trimCanvas(BIG_CANVAS);

    const image = await getMeta(`${fileUrl}${photo.uploadedFile}`);

    const imgSrc = cloneCanvasAndFixSize(trimmedCanvas, image);

    const parsedResponse = await readCanvas(imgSrc);
    const savedFile = { file: photo.file, uploadedFile: parsedResponse?.name || parsedResponse };
    await onSaveEditedImage(savedFile);
    setEditorLoading(false);
  };

  return { onModalClose, photo, isModalOpen, screenRef, onBeforeSubmit };
};
