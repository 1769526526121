import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, useTheme, useMediaQuery, Button, MenuItem, IconButton } from '@material-ui/core';
import cls from 'classnames';

import Logo from 'Components/Logo';
import Avatar from 'Components/Avatar';
import DropdownMenu from 'Components/DorpdownMenu';
import { Comment } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { openFeedbackModal, openUserMenuModal } from 'redux/reducers/modals';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { MENU_LINKS, NOTIFICATION_ACTIONS } from './options';
import { useHeader } from './useHeader';
import useStyles from './useStyles';
import OnlineSwitch from 'Components/OnlineSwitch';

const MENU_ID = {
  DASHBOARD: 'dashboard-dropdown',
  INSPECTIONS: 'inspections-dropdown',
  TEMPLATES: 'templates-dropdown',
  USER: 'user-dropdown',
  NOTIFICATIONS: 'notifications',
  FEEDBACK: 'feedback',
};

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    onDropdownOpen,
    onDropdownClose,
    onCloseWithAction,
    deleteNotification,
    handleDeleteAllNotifications,
    user,
    notifications,
    menuId,
    anchorEl,
    fullName,
    hasUserAdministratorPermissions,
    hasTemplatePermissions,
    hasUserCreateInspectionsPermissions,
  } = useHeader();

  const renderMenuItems = (item) => {
    if (!hasUserAdministratorPermissions && item.title === 'Edit Database') return null;
    if (!hasUserCreateInspectionsPermissions && item.title === 'Add New Inspection') return null;
    if (!isEmpty(item.trigger)) {
      switch (item.trigger) {
        case 'FEEDBACK':
          return (
            <MenuItem
              data-cy-button={item.title}
              key={item.title}
              onClick={onCloseWithAction(() => dispatch(openFeedbackModal({})))}
              className={classes.navigationItemDropdownItem}
            >
              {item.title}
            </MenuItem>
          );
        default:
          break;
      }
    }
    if (isEmpty(item.link)) {
      return (
        <MenuItem
          data-cy-button={item.title}
          key={item.title}
          onClick={onCloseWithAction(item.action)}
          className={classes.navigationItemDropdownItem}
        >
          {item.title}
        </MenuItem>
      );
    }
    return (
      <MenuItem
        key={item.title}
        onClick={() => {
          history.push(item.link);
          onDropdownClose();
        }}
        className={classes.navigationItemDropdownItem}
        data-cy-button={item.title}
      >
        {item.title}
      </MenuItem>
    );
  };

  const renderNotifications = (notification) => (
    <MenuItem
      onClick={NOTIFICATION_ACTIONS[notification.type](notification, history)}
      className={classes.notificationItem}
      key={notification.id}
    >
      <span>{notification.message}</span>
      <IconButton onClick={(e) => deleteNotification(e, notification)}>
        <Trash />
      </IconButton>
      <div className={classes.notificationLoadingProggress} id={`notificationLoader-${notification.id}`} />
    </MenuItem>
  );

  return (
    <AppBar className={classes.header} position='static'>
      <Toolbar className={classes.toolbar}>
        <NavLink to='/system/dashboard/home'>
          <Logo isMobile={isMobile} />
        </NavLink>
        {!isMobile && (
          <div className={classes.navigationWrapper}>
            <span
              id={MENU_ID.DASHBOARD}
              className={cls(classes.navigationItem, {
                [classes.selected]: location.pathname.includes('/system/dashboard'),
              })}
              onClick={onDropdownOpen}
            >
              <span>DASHBOARDS</span>
              <ArrowDown />
            </span>
            <span
              id={MENU_ID.INSPECTIONS}
              className={cls(classes.navigationItem, {
                [classes.selected]: location.pathname.includes('/system/inspections'),
              })}
              onClick={onDropdownOpen}
              data-cy-button='inspections'
            >
              <span>INSPECTIONS</span>
              <ArrowDown />
            </span>
            {hasTemplatePermissions && (
              <span
                id={MENU_ID.TEMPLATES}
                className={cls(classes.navigationItem, {
                  [classes.selected]: location.pathname.includes('/system/templates'),
                })}
                data-cy-button='templates'
                onClick={onDropdownOpen}
              >
                <span>TEMPLATES</span>
                <ArrowDown />
              </span>
            )}
            {hasUserAdministratorPermissions && (
              <NavLink
                className={classes.navigationItem}
                to='/system/user-administration/enabled-users'
                activeClassName={classes.selected}
                isActive={(match, locations) => locations?.pathname.includes('/system/user-administration/')}
                data-cy-link='enabled-users'
              >
                USER ADMINISTRATION
              </NavLink>
            )}
          </div>
        )}
        {isTablet && (
          <React.Fragment>
            <div style={{ marginRight: 24 }}>
              <OnlineSwitch />
            </div>
            <IconButton
              className={classes.mobileMenuButton}
              onClick={() => dispatch(openUserMenuModal({ user }))}
              data-cy-button='menu'
              disableRipple
            >
              <Menu />
            </IconButton>
          </React.Fragment>
        )}
        {!isTablet && (
          <div>
            <Button
              id={MENU_ID.NOTIFICATIONS}
              onClick={onDropdownOpen}
              className={cls(classes.notificationButton, { [classes.hasNotificationIcon]: notifications.length })}
              disableRipple
            >
              <Bell />
            </Button>
            <Button
              id={MENU_ID.FEEDBACK}
              onClick={() => dispatch(openFeedbackModal({}))}
              className={classes.notificationButton}
              disableRipple
            >
              <Comment style={{ color: 'rgba(91, 95, 131,0.5)' }} />
            </Button>
            <Button
              data-cy-button='menu'
              id={MENU_ID.USER}
              className={classes.userButton}
              onClick={onDropdownOpen}
              disableRipple
            >
              <div className={classes.avatarWrapper}>
                <Avatar path={user?.avatar} />
              </div>
              <span className={classes.userName} data-cy-text='current-user'>
                {fullName}
              </span>
              <ArrowDown />
            </Button>
          </div>
        )}
      </Toolbar>
      <DropdownMenu onClose={onDropdownClose} anchorEl={anchorEl}>
        {menuId === MENU_ID.DASHBOARD && MENU_LINKS.dashboards.map(renderMenuItems)}
        {menuId === MENU_ID.INSPECTIONS && MENU_LINKS.inspections.map(renderMenuItems)}
        {menuId === MENU_ID.TEMPLATES && MENU_LINKS.templates.map(renderMenuItems)}
        {menuId === MENU_ID.USER && MENU_LINKS.user.map(renderMenuItems)}
        {menuId === MENU_ID.NOTIFICATIONS && notifications.length > 0 && (
          <>
            {' '}
            {notifications.length > 1 && (
              <Button
                style={{
                  color: ' #ffffff',
                  width: ' 60%',
                  marginLeft: '20%',
                  padding: ' 4px 0',
                  background: 'linear-gradient(272.18deg, #004278 0%, #3E6FDF 100%)',
                  marginBottom: '16px',
                }}
                onClick={handleDeleteAllNotifications}
              >
                Delete all
              </Button>
            )}
            {notifications?.map(renderNotifications)}
          </>
        )}
        {menuId === MENU_ID.NOTIFICATIONS && !notifications.length && (
          <span className={classes.emptyNotifications}>No Notifications</span>
        )}
      </DropdownMenu>
    </AppBar>
  );
};

export default Header;
