import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';

import { store } from 'redux/store';
import Routes from 'routes';
import { Modal } from 'Components/Modal';
import 'App.css';
import { getTheme } from './styles/theme';
import { AlertContextProvider } from './Components/Alert/alertContext';
import Alert from './Components/Alert';
import { NotificationContextProvider } from './Components/Notification/notificationContext';
import Notification from './Components/Notification';
import { LocationsContextProvider } from 'Components/template/CommentsColumn/AddLocationModal/LocationsContext';
import { registerLicense } from '@syncfusion/ej2-base';
import { syncfusionKey } from 'config/config';
import { IsOnlineContextProvider } from 'shared/IsOnlineContext';
import { ConstantsContextProvider } from 'shared/contexts/ConstantsContext';
import { GlobalLoaderContextProvider } from 'shared/contexts/GlobalLoaderContext';
import GlobalLoader from 'shared/GlobalLoader';
import ModeStylesFixes from 'shared/ModeStylesFixes';
import { StyleContext, StyleContextProvider } from 'shared/contexts/StyleContext';

registerLicense(syncfusionKey);

function App() {
  const { isDarkMode } = React.useContext(StyleContext);
  const theme = getTheme(isDarkMode);
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ModeStylesFixes theme={theme} />
        <IsOnlineContextProvider>
          <GlobalLoaderContextProvider>
            <NotificationContextProvider>
              <AlertContextProvider>
                <ConstantsContextProvider>
                  <LocationsContextProvider>
                    <GlobalLoader />
                    <Routes>
                      <Modal />
                      <Alert />
                      <Notification />
                    </Routes>
                  </LocationsContextProvider>
                </ConstantsContextProvider>
              </AlertContextProvider>
            </NotificationContextProvider>
          </GlobalLoaderContextProvider>
        </IsOnlineContextProvider>
      </MuiThemeProvider>
    </Provider>
  );
}

export default () => (
  <StyleContextProvider>
    <App />
  </StyleContextProvider>
);
