import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useTextEditor } from './useTextEditor';
import useStyles from './useStyles';

const TextEditor = ({ fieldName, valueForRerender = '', placeholder = '', id = 'text-editor', lightMode = false }) => {
  const classes = useStyles();

  const { changeEditorState, editorState } = useTextEditor(fieldName, valueForRerender);

  return (
    <Editor
      spellCheck='true'
      editorState={editorState}
      toolbarClassName={classes.customToolbar}
      wrapperClassName={classes.editorWrapper}
      editorClassName={classes.editor}
      onEditorStateChange={changeEditorState}
      placeholder={placeholder}
      wrapperId={id}
      toolbar={{
        options: lightMode ? ['inline', 'history'] : ['link', 'inline', 'fontSize', 'colorPicker'],
        history: {},
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        ...(!lightMode && {
          link: {
            options: ['link'],
          },
        }),
      }}
    />
  );
};

export default TextEditor;
