import React from 'react';
import { Button, IconButton, Slide } from '@material-ui/core';
import cls from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import useStyles from './useStyles';
import { getModalProps, getModalType } from 'redux/selectors/modals';

const ConfirmationModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { message, onConfirm } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.addSectionModal} data-cy-container='confirmation'>
        <div className={classes.addSectionModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Confirmation</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <p className={classes.confirmationText}>{message}</p>
            <div className={classes.modalFooter}>
              <Button
                onClick={onModalClose}
                className={cls(classes.actionButton, classes.cancelButton)}
                data-cy-button='cancel-confirmation'
              >
                Cancel
              </Button>
              <Button
                onClick={onConfirm}
                className={cls(classes.actionButton, classes.submitButton)}
                data-cy-button='submit-confirmation'
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default ConfirmationModal;
