import mainInstance from 'api/mainInstance';

const ConstantsAPI = {
  services: () => mainInstance.get('/constants/services'),
  inspectionTypes: () => mainInstance.get('/constants/inspection-types'),
  recommendedExperts: () => mainInstance.get('/constants/deficiency-recommendations'),
  poolSpas: () => mainInstance.get('/constants/pool-spas'),
  securitySystems: () => mainInstance.get('/constants/security-systems'),
  referrals: () => mainInstance.get('/constants/referrals'),
  estimations: () => mainInstance.get('/constants/deficiency-estimations'),
  estimationsTypes: () => mainInstance.get('/constants/deficiency-estimation-types'),
  licenses: () => mainInstance.get('/constants/licenses'),
  certificates: () => mainInstance.get('/constants/certificates'),
  locations: () => mainInstance.get('/admin/locations'),
};

export default ConstantsAPI;
