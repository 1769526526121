import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, JungleGreen, Red } from 'styles/colors';

export default makeStyles((theme) => ({
  title: {
    color: Comet,
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 40,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      boxShadow: `0 0 0 1px ${rgba(Comet, 0.1)}`,
      borderRadius: '5px',
      padding: 40,
      maxWidth: 330,
    },
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  resetButton: {
    width: '48%',
    backgroundColor: rgba(JungleGreen, 0.1),
    color: JungleGreen,
    padding: '8px 0',
    '&>.MuiButton-label': {
      fontWeight: 700,
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: rgba(JungleGreen, 0.3),
    },
  },
  cancelButton: {
    width: '48%',
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    padding: '8px 0',
    '&>.MuiButton-label': {
      fontWeight: 700,
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  input: {
    marginTop: 28,
  },
  errorMessage: {
    marginTop: 40,
  },
}));
