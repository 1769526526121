import mainInstance from 'api/mainInstance';

const InspectionsAPI = {
  createInspection: (data) => mainInstance.post(`/inspections?holderId=${data.holderId}`, data),
  getInspections: ({ page = '', limit = '', status = '', search = '', from = '', to = '' }) =>
    mainInstance.get(
      `/inspections?page=${page}&limit=${limit}&status=${status}&search=${search}&from=${from}&to=${to}`,
    ),
  getUrl: (id) => mainInstance.get(`/inspections/${id}/link`),
  getInspection: (id) => mainInstance.get(`/inspections/${id}`),
  patchInspection: (data) => mainInstance.patch(`/inspections/${data.id}`, data),
  deleteInspection: (id) => mainInstance.delete(`/inspections/${id}`),
  getAvailableInspections: ({ inspectionAt = '', duration = '', inspectionId = '', holderId = '' }) =>
    mainInstance.get(
      `/inspections/inspectors?inspectionAt=${inspectionAt}&duration=${duration}&inspectionId=${inspectionId}&holderId=${holderId}`,
    ),
  getInspectorsAvailability: ({
    destinationLat = '',
    destinationLon = '',
    duration = '',
    inspectionAt = '',
    inspectors = '',
  }) =>
    mainInstance.get(
      `/inspections/inspectors/availability?destinationLat=${destinationLat}&destinationLon=${destinationLon}&duration=${duration}&inspectors=${inspectors}&inspectionAt=${inspectionAt}`,
    ),
  getInspectionResult: (token) => mainInstance.get(`/public/inspections/${token}`),
  getInspectionResultsLink: (id) => mainInstance.get(`/inspections/${id}/link`),
  getAllInspectionReports: (id) => mainInstance.get(`/inspections/${id}/reports`),
  getInspectionReports: (id) => mainInstance.get(`/reports?inspectionId=${id}`),
};

export default InspectionsAPI;
