import { useFormikContext } from 'formik';

export const useViewModeFilesUpload = () => {
  const { setFieldValue, values } = useFormikContext();

  const savePhoto = (res) => {
    if (res instanceof Array) {
      setFieldValue('photos', [...values.photos, ...res]);
    } else setFieldValue('photos', [...values.photos, res]);
  };

  const saveVideo = (res) => setFieldValue('videos', [...values.videos, res]);

  const deletePhoto = (idx) => () =>
    setFieldValue('photos', [...values.photos.slice(0, idx), ...values.photos.slice(idx + 1)]);

  const deleteVideo = (idx) => () =>
    setFieldValue('videos', [...values.videos.slice(0, idx), ...values.videos.slice(idx + 1)]);

  const saveEditedImage = (idx) => (editedImage) =>
    setFieldValue('photos', [...values.photos.slice(0, idx), editedImage, ...values.photos.slice(idx + 1)]);

  return {
    savePhoto,
    saveVideo,
    deletePhoto,
    deleteVideo,
    saveEditedImage,
    photos: values.photos,
    videos: values.videos,
  };
};
