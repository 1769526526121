import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet } from 'styles/colors';

export default makeStyles((theme) => ({
  sectionInfoModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      width: '100%',
      maxWidth: 570,
      height: 'fit-content',
      borderRadius: 16,
    },
  },
  sectionInfoModalWrapper: {
    maxHeight: '100%',
    minHeight: '20vh',
    height: 'fit-content',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80vh',
    },
  },
  modalHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    zIndex: 999,
    borderRadius: '16px 16px 0 0',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginTop: 73,
  },
  sectionName: {
    fontSize: 16,
    color: Comet,
    fontWeight: 700,
    marginBottom: 8,
  },
  infoTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: Comet,
    margin: '4px 0',
  },
}));
