import { makeStyles } from '@material-ui/core';

import { Comet, Jaffa, Red, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 800,
    color: Comet,
    margin: '24px 0 16px 0',
  },
  titlePriorities: {
    fontSize: 18,
    fontWeight: 800,
    color: '#3E6FDF',
    margin: '24px 0 16px 0',
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: '15px !important',
    fontWeight: 600,
    color: Comet,
    margin: '24px 0 16px 0',
  },
  minorTitle: {
    fontSize: 15,
    fontWeight: 700,
    color: RoyalBlue,
    margin: '16px 0 8px 0',
  },
  moderateTitle: {
    fontSize: 15,
    fontWeight: 700,
    color: Jaffa,
    margin: '16px 0 8px 0',
  },
  majorTitle: {
    fontSize: 15,
    fontWeight: 700,
    color: Red,
    margin: '16px 0 8px 0',
  },
  pWrap: {
    '& > p': {
      fontSize: 12,
      color: '#5B5F83',
      fontWeight: 600,
      padding: '8px 0px',
    },
  },
  paragraph: {
    color: Comet,
    fontSize: 12,
  },
  imagesWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: 8,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: 8,
    },
  },
  imageWrapper: {
    maxHeight: 150,
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
}));
