import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles({
  optionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  optionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    fontSize: 14,
    fontWeight: 400,
    color: rgba(Comet, 0.5),
  },
  addButton: {
    color: RoyalBlue,
    fontSize: 12,
    borderBottom: `1px dashed ${RoyalBlue}`,
    textTransform: 'none',
    borderRadius: 0,
  },
  inputsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '24px 16px',
    backgroundColor: rgba(Comet, 0.03),
    padding: '24px 16px',
    borderRadius: 5,
  },
  rangeWrapper: {
    display: 'grid',
    gridTemplateColumns: '45% 45% 10%',
    gridGap: '24px 8px',
    backgroundColor: rgba(Comet, 0.03),
    padding: '24px 16px',
    borderRadius: 5,
  },
  deleteButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
});
