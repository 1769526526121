import React, { useContext } from 'react';
import { Icon, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { JungleGreen, Red } from 'styles/colors';
import { AlertContext } from './alertContext';
import useStyles from './useStyles';
import { useSelector } from 'react-redux';

function getColor(variant) {
  const colors = {
    success: JungleGreen,
    error: Red,
    warning: '#FF9800',
    info: '#FF9800',
  };
  return colors[variant];
}

const Alert = () => {
  const { handleClose } = useContext(AlertContext);
  const { alertMessage, alertVariant } = useSelector((state) => state.alert);
  const classes = useStyles({ bgc: getColor(alertVariant) });

  return (
    <Snackbar
      className={classes.container}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={!!alertMessage}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby='client-snackbar'
      message={
        <span data-cy-alert={alertVariant} id='client-snackbar'>
          {alertMessage}
        </span>
      }
      action={[
        <IconButton key='close' aria-label='close' color='inherit' onClick={handleClose} data-cy-button='close-alert'>
          <Icon component={CloseIcon} />
        </IconButton>,
      ]}
    />
  );
};

export default React.memo(Alert);
