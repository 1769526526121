import { useContext, useState } from 'react';

import { AlertContext } from 'Components/Alert/alertContext';
import { useFormikContext } from 'formik';
import { useMakeScreenshot } from 'hooks/useMakeScreenshot';

export const useCommentFilesUpload = () => {
  const { setFieldValue, values } = useFormikContext();
  const { handleSetMessage } = useContext(AlertContext);
  const { readCanvas, screenRef } = useMakeScreenshot();

  const [isEditImageOpen, setEditImageOpen] = useState(false);
  const [editorLoading, setEditorLoading] = useState(false);

  const [imageForEdit, setImageForEdit] = useState(null);
  const [imageIdx, setImageIdx] = useState(null);

  const uploadImage = (res) => {
    if (res instanceof Array) {
      setFieldValue('photos', [...values.photos, ...res]);
    } else setFieldValue('photos', [...values.photos, res]);
    handleSetMessage('Image successfully uploaded', 'success');
  };

  const resetUploadedImages = (res) => {
    setFieldValue('photos', [...res]);
  };
  const resetUploadedVideos = (res) => {
    setFieldValue('videos', [...res]);
  };

  const uploadVideo = (res) => {
    setFieldValue('videos', [...values.videos, res]);
    handleSetMessage('Video successfully uploaded', 'success');
  };

  const deleteImage = (index) => () => {
    setFieldValue('photos', [...values.photos.slice(0, index), ...values.photos.slice(index + 1)]);
  };

  const deleteVideo = (index) => () => {
    setFieldValue('videos', [...values.videos.slice(0, index), ...values.videos.slice(index + 1)]);
  };

  const fileReject = (rejectedFile) => {
    handleSetMessage(rejectedFile[0].errors[0].message, 'error');
  };

  const openImageEdit = (image, idx) => () => {
    setImageForEdit(image);
    setImageIdx(idx);
    setEditImageOpen(true);
  };

  const closeImageEdit = () => {
    setImageForEdit('');
    setImageIdx('');
    setEditImageOpen(false);
  };

  const saveEditedImage = async ({ canvas }) => {
    closeImageEdit();
    setEditorLoading(true);
    const parsedResponse = await readCanvas(canvas);
    const savedFile = { file: imageForEdit.file, uploadedFile: parsedResponse };
    setFieldValue('photos', [...values.photos.slice(0, imageIdx), savedFile, ...values.photos.slice(imageIdx + 1)]);
    setEditorLoading(false);
  };

  return {
    uploadImage,
    uploadVideo,
    deleteImage,
    deleteVideo,
    fileReject,
    saveEditedImage,
    openImageEdit,
    closeImageEdit,
    resetUploadedImages,
    resetUploadedVideos,
    uploadedImages: values.photos,
    uploadedVideo: values.videos,
    isEditImageOpen,
    imageForEdit,
    screenRef,
    editorLoading,
  };
};
