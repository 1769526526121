import React from 'react';
import { Field } from 'formik';
import { FormHelperText } from '@material-ui/core';

import TextEditor from 'Components/TextEditor';

const Text = ({ touched, error }) => (
  <>
    <TextEditor fieldName='values' placeholder='Comment text' id='text' />
    <Field name='values' type='text' hidden />
    {touched && !!error && <FormHelperText error>{error}</FormHelperText>}
  </>
);

export default Text;
