import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  cameraFullscreenWrap: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: 10000,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'grid',
    gridTemplateRows: '50px 88fr 12fr 10fr',

    '& .react-html5-camera-photo': {
      maxHeight: 'calc(88vh - 150px)',
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      // overflowY: 'clip',
      '& > video': {
        maxHeight: 'calc(88vh - 150px)',
      },
      '& > div': {
        maxHeight: 'calc(88vh - 150px)',
      },
      '& > img': {
        maxHeight: 'calc(88vh - 150px)',
      },
      '& #container-circles': {
        '@media (min-width: 500px)': {
          // '@media (max-height: 500px)': {
          left: 'auto',
          right: '45px',
          bottom: 'calc(50% + 37.5px)',
          // },
        },
      },
    },
  },
  flashIcon: {
    position: 'absolute',
    zIndex: 10,
    left: 10,
    stroke: 'white',
    fill: 'white',
  },
  cameraFullscreenHeader: {
    height: '50px',
    // backgroundColor: 'lightgray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  fullscreenPhoto: {
    width: '100vw',
    height: 'calc(88vh - 150px)',
    objectFit: 'contain',
  },
  galleryWrap: {
    display: 'flex',
    alignItems: 'center',
    // flexDirection: 'reverse',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '10vh',
    gap: '12px',
    margin: '1vh',
    width: '98vw',
  },
  galleryPhoto: {
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      animation: '$appearFromCenter 0.3s',
    },
    flexShrink: 0,
    width: '10vh',
    height: '10vh',
  },
  galleryCamera: {
    width: '10vh',
    flexShrink: 0,
    height: '10vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e0e0e0',
    borderRadius: '20px',
  },
  cameraButtonDisabled: {
    backgroundColor: 'rgba(91,95,131,0.3) !important',
  },
  '@keyframes appearFromCenter': {
    from: {
      transform: 'scale(0)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
}));
