import React from 'react';
import moment from 'moment';

import CircularLoader from 'Components/CircularLoader';
import useStyles from './useStyles';

const InspectorAvailability = ({ inspector }) => {
  const classes = useStyles();
  return (
    <>
      {!inspector?.userId && (
        <div className={classes.loaderWrapper}>
          <CircularLoader />
        </div>
      )}
      {!inspector?.isFetching && !inspector?.hasError && inspector.newInspectionData && inspector.nextInspectionData && (
        <div className={classes.timeInfoWrapper}>
          <div className={classes.inspectionTimeWrapper}>
            <span className={classes.inspectionTimeLabel}>This inspection</span>
            {inspector?.newInspectionData?.isInspectorInTime && (
              <span className={classes.inspectorInTime}>
                {`Arrival time ${moment.utc(inspector?.newInspectionData?.arrivalTime).format('MM/DD/YYYY hh:mm A')}`}
              </span>
            )}
            {!inspector?.newInspectionData?.isInspectorInTime && (
              <span className={classes.inspectorDelay}>{`Delay time ${inspector?.newInspectionData?.delayTime}`}</span>
            )}
          </div>
          <div className={classes.inspectionTimeWrapper}>
            <span className={classes.inspectionTimeLabel}>Next inspection</span>
            {inspector?.nextInspectionData?.isInspectorInTime && inspector?.nextInspectionData?.arrivalTime && (
              <span className={classes.inspectorInTime}>
                {`Arrival time ${moment.utc(inspector?.nextInspectionData?.arrivalTime).format('MM/DD/YYYY hh:mm A')}`}
              </span>
            )}
            {inspector?.nextInspectionData?.isInspectorInTime && !inspector?.nextInspectionData?.arrivalTime && (
              <span className={classes.inspectorInTime}>Don`t have next inspection</span>
            )}
            {!inspector?.nextInspectionData?.isInspectorInTime && (
              <span className={classes.inspectorDelay}>{`Delay time ${inspector?.nextInspectionData?.delayTime}`}</span>
            )}
          </div>
        </div>
      )}
      {!inspector?.isFetching && inspector?.hasError && (
        <span className={classes.inspectorDelay}>{inspector?.errorMessage}</span>
      )}
    </>
  );
};

export default InspectorAvailability;
