import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import mainInstance from 'api/mainInstance';
import { useState } from 'react';

export const useReplace = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const {
    onReplace,
    index,
    selectedSection,
    selectedItem,
    isComments,
    isDeficiencies = false,
    sections,
  } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const [isLoading, setIsLoading] = useState();

  const sectionsx = isComments
    ? sections
    : sections.map((section, idx) => ({ ...section, idx })).filter((section) => section.idx !== selectedSection);

  const getAvailableItems = async (sectionIdx) => {
    if (sections?.length > 0 && (sectionIdx || sectionIdx === 0)) {
      setIsLoading(true);
      const sect = await mainInstance.get(`/admin/template-sections/${sections?.[sectionIdx].id}`);
      setIsLoading(false);
      return (
        sect.data?.items
          ?.map((item, idx) => ({ ...item, idx }))
          .filter((item) => {
            if (isDeficiencies) {
              return sectionIdx === selectedSection
                ? item.idx !== selectedItem && item.isDeficiencies
                : item.isDeficiencies;
            }
            return sectionIdx === selectedSection ? item.idx !== selectedItem : true;
          }) || []
      );
    }
    return (
      sections?.[sectionIdx]?.items
        ?.map((item, idx) => ({ ...item, idx }))
        .filter((item) => {
          if (isDeficiencies) {
            return sectionIdx === selectedSection
              ? item.idx !== selectedItem && item.isDeficiencies
              : item.isDeficiencies;
          }
          return sectionIdx === selectedSection ? item.idx !== selectedItem : true;
        }) || []
    );
  };

  const submit = async (values) => {
    if (isComments) {
      await onReplace(values.sectionIdx, values.itemIdx, index);
    } else {
      await onReplace(values.sectionIdx, index);
    }
    onModalClose();
  };

  return {
    submit,
    onModalClose,
    getAvailableItems,
    sections: sectionsx,
    selectedItem,
    isModalOpen,
    isComments,
    isLoading,
  };
};
