const ALERT_ACTION = {
  CLEAR_ALERT: 'CLEAR_ALERT',
  SET_ALERT: 'SET_ALERT',
};

const initialState = {
  alertMessage: '',
  alertVariant: '',
};

export const reducerAlert = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_ACTION.CLEAR_ALERT:
      return initialState;
    case ALERT_ACTION.SET_ALERT:
      return action.payload;
    default:
      return state;
  }
};

export const setAlert = (payload) => ({
  type: ALERT_ACTION.SET_ALERT,
  payload,
});

export const clearAlert = () => ({
  type: ALERT_ACTION.CLEAR_ALERT,
});
