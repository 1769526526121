import React from 'react';
import { Button, Modal } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import FileUpload from 'Components/FileUpload';
import ImageEditorModalContent from 'Components/ImageEditorModal/ImageEditorModalContent';
import CircularLoader from 'Components/CircularLoader';
import CameraComponent from 'Components/Camera';
import { useCameraComponent } from 'Components/Camera/useCameraComponent';
import SortingImageItem from './SortingImageItem';
import SortingVideoItem from './SortingVideoItem';
import { useCommentFilesUpload } from './useCommentFilesUpload';
import useStyles from './useStyles';

const ATTACHMENT_LIMIT = 12;

const CommentFilesUpload = () => {
  const classes = useStyles();
  const {
    uploadImage,
    uploadVideo,
    deleteVideo,
    deleteImage, //
    saveEditedImage,
    fileReject,
    openImageEdit, //
    closeImageEdit,
    uploadedVideo,
    uploadedImages,
    resetUploadedImages,
    resetUploadedVideos,
    isEditImageOpen,
    imageForEdit,
    screenRef,
    editorLoading,
  } = useCommentFilesUpload();
  const { uploadPhotos, loadingCamera, cameraProgress } = useCameraComponent({
    onFileUploaded: uploadImage,
    onFileRejected: fileReject,
    uploadField: 'image',
    uploadRoute: 'images',
  });
  const { open } = useDropzone();

  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = uploadedImages.map(({ uploadedFile }) => uploadedFile).indexOf(active.id);
      const newIndex = uploadedImages.map(({ uploadedFile }) => uploadedFile).indexOf(over.id);
      const sortedArray = arrayMove(uploadedImages, oldIndex, newIndex);
      resetUploadedImages(sortedArray);
    }
  }
  function handleVideoDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = uploadedVideo.map(({ uploadedFile }) => uploadedFile).indexOf(active.id);
      const newIndex = uploadedVideo.map(({ uploadedFile }) => uploadedFile).indexOf(over.id);
      const sortedArray = arrayMove(uploadedVideo, oldIndex, newIndex);
      resetUploadedVideos(sortedArray);
    }
  }

  function deleteImageHandler(idx) {
    const imagesArray = uploadedImages.filter((image, i) => i !== idx);
    resetUploadedImages(imagesArray);
  }
  function deleteVideoHandler(idx) {
    const imagesArray = uploadedVideo.filter((image, i) => i !== idx);
    resetUploadedVideos(imagesArray);
  }

  return (
    <div className={classes.uploadFileWrapper}>
      <div className={classes.uploadFileColumn}>
        <FileUpload
          uploadField='image'
          uploadRoute='images'
          multiple
          limit={ATTACHMENT_LIMIT - uploadedImages.length}
          onFileUploaded={uploadImage}
          onFileRejected={fileReject}
          isDisabled={uploadedImages.length >= ATTACHMENT_LIMIT}
          dataCy='comment-image'
        >
          {(loading) => (
            <div style={{ display: 'flex', gap: 5 }}>
              <Button
                disabled={loading || uploadedImages.length >= ATTACHMENT_LIMIT}
                className={classes.uploadFileButton}
                onClick={open}
                data-cy-button='upload-image'
              >
                {loading ? <CircularLoader size={20} isWhite /> : 'Upload image'}
              </Button>
              <CameraComponent
                disabled={loading || uploadedImages.length >= ATTACHMENT_LIMIT}
                onSubmit={uploadPhotos}
                onCancel={() => {}}
                limit={ATTACHMENT_LIMIT - uploadedImages.length}
              >
                <Button
                  className={classes.uploadFileButton}
                  disabled={loading || uploadedImages.length >= ATTACHMENT_LIMIT}
                >
                  {loadingCamera ? (
                    <>
                      <CircularLoader size={20} isWhite /> {cameraProgress.from}/{cameraProgress.to}
                    </>
                  ) : (
                    <CameraAltIcon />
                  )}
                </Button>
              </CameraComponent>
            </div>
          )}
        </FileUpload>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={uploadedImages?.map(({ uploadedFile }) => uploadedFile)}
            strategy={rectSortingStrategy}
          >
            <ul className={classes.fileList}>
              {uploadedImages.map((image, idx) => (
                <SortingImageItem
                  editorLoading={editorLoading}
                  image={image}
                  key={image.uploadedFile}
                  id={image.uploadedFile}
                  onDeleteImage={() => deleteImageHandler(idx)}
                  onOpenImageEdit={openImageEdit(image, idx)}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      </div>
      <div className={classes.uploadFileColumn}>
        <FileUpload
          acceptedTypes='video/*'
          uploadField='video'
          uploadRoute='videos'
          sizeLimit={50000000}
          onFileUploaded={uploadVideo}
          onFileRejected={fileReject}
          isDisabled={uploadedVideo.length >= ATTACHMENT_LIMIT}
          dataCy='comment-video'
        >
          {(loading) => (
            <Button
              disabled={loading || uploadedVideo.length >= ATTACHMENT_LIMIT}
              className={classes.uploadFileButton}
              onClick={open}
              data-cy-button='upload-video'
            >
              {loading ? <CircularLoader size={20} isWhite /> : 'Upload video'}
            </Button>
          )}
        </FileUpload>

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleVideoDragEnd}>
          <SortableContext
            items={uploadedVideo?.map(({ uploadedFile }) => uploadedFile)}
            strategy={rectSortingStrategy}
          >
            <ul className={classes.fileList}>
              {uploadedVideo.map((video, index) => (
                <li key={video.uploadedFile} className={classes.fileListItem}>
                  <SortingVideoItem
                    video={video}
                    key={video.uploadedFile}
                    id={video.uploadedFile}
                    onDeleteVideo={() => {
                      deleteVideoHandler(index);
                      deleteVideo(index);
                    }}
                  />
                </li>
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      </div>

      <Modal onClose={closeImageEdit} open={isEditImageOpen}>
        <ImageEditorModalContent
          onModalClose={closeImageEdit}
          onSubmit={saveEditedImage}
          photo={imageForEdit?.uploadedFile}
          isModalOpen={isEditImageOpen}
          stageRef={screenRef}
        />
      </Modal>
    </div>
  );
};

export default CommentFilesUpload;
