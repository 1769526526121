import { arrayMove } from '@dnd-kit/sortable';
import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import useDebounce from 'hooks/useDebounce';
import _ from 'lodash';
import React from 'react';

const getIsDone = (prev, next) => {
  if (prev === true && next === false) return false;
  if (prev === undefined || prev === false) return true;
  if (prev === true && next === true) return true;
  if (next === undefined) return true;
  return next;
};

function useDebouncedSubmit({ saveSection, section, selectedItem, isReport }) {
  const [currentSection, setCurrentSection] = React.useState(section);

  React.useEffect(() => {
    setCurrentSection(section);
  }, [section]);

  const debouncedSection = useDebounce(currentSection, 600);

  React.useEffect(() => {
    if (debouncedSection && !_.isEqual(debouncedSection, section)) saveSection(debouncedSection);
  }, [debouncedSection]);

  const changeComments = (values, itemIndx, isDeficiencies = false) => {
    console.log(values);
    const commentType = isDeficiencies ? 'deficiencies' : 'comments';
    const isEditing = itemIndx !== null;
    /* eslint-disable-next-line */
    let updatedSection = _.cloneDeep(currentSection);
    const additionalValues = {};
    if (isEditing) {
      if (isDeficiencies) {
        if (values.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY) {
          additionalValues.estimationTypeId = values.estimationId === 2 ? values.estimationTypeId : null;
          additionalValues.estimationValue =
            values.estimationId === 2
              ? {
                  from: values?.estimationValue?.from || values?.estimationFrom,
                  to: values?.estimationValue?.to || values?.estimationTo,
                }
              : null;
        } else {
          additionalValues.estimationId = 1;
          additionalValues.estimationTypeId = null;
          additionalValues.estimationValue = null;
        }
      } else if (
        updatedSection.items[selectedItem][commentType][itemIndx].type === 'RANGE' &&
        typeof values?.defaultValue === 'string'
      )
        additionalValues.defaultValue = JSON.parse(values?.defaultValue);
      const prevIsDone = section.items[selectedItem][commentType][itemIndx]?.isDone;
      updatedSection.items[selectedItem][commentType][itemIndx] = {
        ...updatedSection.items[selectedItem][commentType][itemIndx],
        ...values,
        ...additionalValues,
        isDone: isReport ? getIsDone(prevIsDone, values.isDone) && !values?.values : undefined,
      };
    } else if (updatedSection.items[selectedItem][commentType] !== undefined)
      updatedSection.items[selectedItem][commentType].push(values);
    else updatedSection.items[selectedItem][commentType] = [values];

    let countedDone = 0;
    updatedSection.items?.forEach((e, i) => {
      if ([...(e?.comments || []), ...(e?.deficiencies || [])].find((c) => c.isDone)) {
        countedDone += 1;
        updatedSection.items[i].isDone = true;
      } else {
        updatedSection.items[i].isDone = false;
      }
    });
    const countedPercents = (countedDone / updatedSection.items?.length) * 100;
    updatedSection = {
      ...updatedSection,
      percents: countedPercents,
    };

    setCurrentSection(updatedSection);
  };

  const copyComment = (idx, isDeficiencies = false) => {
    const commentType = isDeficiencies ? 'deficiencies' : 'comments';
    /* eslint-disable-next-line */
    const updatedSection = _.cloneDeep(currentSection);
    const copiedComment = {
      ...updatedSection.items[selectedItem][commentType][idx],
      name: `Copy of ${updatedSection.items[selectedItem][commentType][idx].name}`,
    };

    updatedSection.items[selectedItem][commentType].push(copiedComment);

    saveSection(updatedSection);
  };

  const deleteComment = (idx, isDeficiencies = false) => {
    const commentType = isDeficiencies ? 'deficiencies' : 'comments';
    /* eslint-disable-next-line */
    const updatedSection = _.cloneDeep(currentSection);
    updatedSection.items[selectedItem][commentType] = [
      ...updatedSection.items[selectedItem][commentType].slice(0, idx),
      ...updatedSection.items[selectedItem][commentType].slice(idx + 1),
    ];

    saveSection(updatedSection);
  };

  const reorderComments = ({ active, over }, isDeficiencies = false) => {
    const commentType = isDeficiencies ? 'deficiencies' : 'comments';
    if (over && active.id !== over.id) {
      /* eslint-disable-next-line */
      const updatedSection = _.cloneDeep(currentSection);
      const oldIndex = active.id - 1;
      const newIndex = over.id - 1;
      const updatedComments = arrayMove(updatedSection.items[selectedItem][commentType], oldIndex, newIndex);
      updatedSection.items[selectedItem][commentType] = updatedComments;

      saveSection(updatedSection);
    }
  };

  return { changeComments, copyComment, deleteComment, reorderComments };
}

export default useDebouncedSubmit;
