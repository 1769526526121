import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  image: {
    width: '100%',
    height: 160,
    objectFit: 'cover',
    borderRadius: 10,
    [theme.breakpoints.up('sm')]: {
      height: 320,
    },
  },
  imageDefault: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 160,
    borderRadius: 10,
    backgroundColor: rgba(Comet, 0.1),
    [theme.breakpoints.up('sm')]: {
      height: 320,
    },
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0 14px 0',
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: 12,
    color: Comet,
    paddingLeft: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: RoyalBlue,
    margin: '0',
  },
  accordionWrapper: {
    overflow: 'visible',
    background: theme.palette.primary.main,
    boxShadow: 'none',
    '&::before': {
      content: 'none',
    },

    '&.Mui-expanded': {
      margin: '0 !important',
    },
  },
  accordionSummary: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 48,
    },
    '&>.MuiAccordionSummary-content.Mui-expanded': {
      minHeight: 'auto !important',
      margin: '0 !important',
    },
  },
  accordionContent: {
    padding: '8px 0 16px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  accordionContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  houseInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
  },
  houseInfoName: {
    fontSize: 12,
    color: Comet,
    '&:not(:first-child)': {
      paddingLeft: 8,
    },
  },
  houseInfo: {
    fontWeight: 700,
  },
  usersTitle: {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    color: rgba(Comet, 0.5),
  },
  usersWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    margin: '16px 0',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  userWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatarWrapper: {
    width: 32,
    height: 32,
    marginLeft: 2,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    fontSize: 12,
    color: Comet,
    fontWeight: 700,
    paddingLeft: 8,
  },
  license: {
    padding: 16,
    marginTop: 8,
    border: `1px solid ${rgba(Comet, 0.1)}`,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: 24,
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'start',
    },
  },
  licenseName: {
    flex: 2,
    color: Comet,
    fontSize: 14,
    fontWeight: 400,
  },
  licenseNumber: {
    color: Comet,
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'end',
    [theme.breakpoints.up('md')]: {
      marginTop: 8,
    },
  },
}));
