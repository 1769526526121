import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { fileUrl } from 'config/config';
import Loader from 'Components/Loader';
import Header from './Header';
import ResultInspectionsLayout from './RsultInspectionLayout';
import { useResultInspection } from './useResultInspection';
import InspectionInfo from './InspectionInfo';
import ReportTabs from './ReportTabs';
import StaticInfo from './StaticInfo';
import CommentsTabs from './CommentsTabs';
import DeficienciesComments from './DeficienciesComments';
import SectionsMenu from './SectionsMenu';
import { REPORT_SECTION } from './options';
import useStyles from './useStyles';
import TokenExpiredException from './TokenExpiredException';

const ResultInspection = () => {
  const {
    selectSection,
    inactiveSection,
    downloadPdf,
    downloadThermitePdf,
    isTokenExpired,
    inspectionResult,
    report,
    selectedSection,
    clients,
    reportId,
    reportSection,
    isLoading,
    hasPdf,
    hasTermite,
    sortedReports,
  } = useResultInspection();
  const classes = useStyles({ hasPdf });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classes.container}>
          <Header
            reports={sortedReports}
            hasTermite={hasTermite}
            currentReport={report}
            downloadThermitePdf={downloadThermitePdf}
          />
          {isTokenExpired && <TokenExpiredException />}
          {!isTokenExpired && (
            <>
              {isMobile && !hasPdf && !hasTermite && inspectionResult?.templates?.length > 0 && (
                <SectionsMenu
                  onSelectSection={selectSection}
                  onInactiveSection={inactiveSection}
                  selectedSection={selectedSection}
                  sections={report?.sections}
                />
              )}
              <div className={classes.contentWrapper}>
                <ResultInspectionsLayout
                  onSelectSection={selectSection}
                  onInactiveSection={inactiveSection}
                  selectedSection={selectedSection}
                  report={report}
                  reportId={reportId}
                  clients={clients}
                  reportSection={reportSection}
                  hasPdf={hasPdf}
                  isShown={inspectionResult?.templates?.length > 0}
                >
                  <div style={{ paddingBottom: 444 }}>
                    <InspectionInfo inspection={inspectionResult} clients={clients} />
                    {hasPdf && (
                      <iframe title='Report pdf' src={`${fileUrl}${report.pdf}`} width='100%' height='600px' />
                    )}
                    <ReportTabs onDownloadPdf={downloadPdf} hasTermite={hasTermite} report={report} />
                    {!hasPdf && inspectionResult?.templates?.length > 0 && (
                      <>
                        {reportSection === REPORT_SECTION.FULL_REPORTS && report?.isDefaultText && (
                          <StaticInfo report={report} />
                        )}
                        <div>
                          {inspectionResult?.templates?.length > 0 &&
                            report?.sections?.map((section, idx) => (
                              <div key={`${section.name}${idx + 1}`} id={`${idx}`}>
                                <h2 className={classes.sectionTitle}>{`${idx + 1}. ${section.name}`}</h2>
                                {reportSection === REPORT_SECTION.FULL_REPORTS && (
                                  <CommentsTabs section={section} reportSection={reportSection} />
                                )}
                                {reportSection !== REPORT_SECTION.FULL_REPORTS && (
                                  <DeficienciesComments section={section} reportSection={reportSection} />
                                )}
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                </ResultInspectionsLayout>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ResultInspection;
