import { lazy } from 'react';

import { PERMISSIONS } from 'constants/permissions';
import DbEdit from 'pages/DbEdit';

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const UserAdministration = lazyWithRetry(() => import('pages/UserAdministration'));
const UserArchive = lazyWithRetry(() => import('pages/UserArchive'));
const CreateUser = lazyWithRetry(() => import('pages/CreateUser'));
const EditUser = lazyWithRetry(() => import('pages/EditUser'));
const UserProfile = lazyWithRetry(() => import('pages/UserProfile'));
const UserSettings = lazyWithRetry(() => import('pages/UserSettings'));
const ResetUserPassword = lazyWithRetry(() => import('pages/ResetUserPassword'));
const ResetMyPassword = lazyWithRetry(() => import('pages/ResetMyPassword'));
const Templates = lazyWithRetry(() => import('pages/Templates'));
const TemplatePage = lazyWithRetry(() => import('pages/TemplatePage'));
const ReportPage = lazyWithRetry(() => import('pages/ReportPage'));
const MyInspections = lazyWithRetry(() => import('pages/MyInspections'));
const AddInspection = lazyWithRetry(() => import('pages/AddInspection'));
const EditInspection = lazyWithRetry(() => import('pages/EditInspection'));
const InspectionView = lazyWithRetry(() => import('pages/InspectionView'));
const Reports = lazyWithRetry(() => import('pages/Reports'));
const Calendar = lazyWithRetry(() => import('pages/Calendar'));
const Map = lazyWithRetry(() => import('pages/Map'));
const Home = lazyWithRetry(() => import('pages/Home'));
const NotFound = lazyWithRetry(() => import('pages/NotFound'));

export default [
  { path: '/404', exact: true, Component: NotFound },
  { path: '/system/dashboard/schedule/:view', exact: true, Component: Calendar },
  { path: '/system/dashboard/map', exact: true, Component: Map },
  { path: '/system/dashboard/home', exact: true, Component: Home },
  {
    path: '/system/user-administration/my-profile',
    exact: true,
    Component: UserProfile,
  },
  {
    path: '/system/settings/:step',
    exact: true,
    Component: UserSettings,
  },
  {
    path: '/system/settings/security/reset-password',
    exact: true,
    Component: ResetMyPassword,
  },
  {
    path: '/system/user-administration/enabled-users',
    exact: true,
    Component: UserAdministration,
    permission: PERMISSIONS.USER_ADMINISTRATOR,
  },
  {
    path: '/system/user-administration/disabled-users',
    exact: true,
    Component: UserArchive,
    permission: PERMISSIONS.USER_ADMINISTRATOR,
  },
  {
    path: '/system/user-administration/create-user/:step',
    exact: true,
    Component: CreateUser,
    permission: PERMISSIONS.ADD_USER,
  },
  {
    path: '/system/user-administration/edit-user/:id/:step',
    exact: true,
    Component: EditUser,
    permission: PERMISSIONS.USER_ADMINISTRATOR,
  },
  {
    path: '/system/user-administration/reset-password/:id',
    exact: true,
    Component: ResetUserPassword,
    permission: PERMISSIONS.USER_ADMINISTRATOR,
  },
  {
    path: '/system/templates/add',
    exact: true,
    Component: TemplatePage,
    permission: PERMISSIONS.ADD_AND_PRESAVE_TEMPLATE,
  },
  {
    path: '/system/templates/edit/:id',
    exact: true,
    Component: TemplatePage,
    permission: PERMISSIONS.EDIT_TEMPLATE,
  },
  { path: '/system/templates/:status', exact: true, Component: Templates, permission: PERMISSIONS.VIEW_TEMPLATES_PAGE },
  {
    path: '/system/user-administration/profile/:id',
    exact: true,
    Component: UserProfile,
    permission: PERMISSIONS.USER_ADMINISTRATOR,
  },
  {
    path: '/system/inspections/add/:step',
    exact: true,
    Component: AddInspection,
    permission: PERMISSIONS.CREATE_INSPECTION,
  },
  {
    path: '/system/inspections/:id/edit/:step',
    exact: true,
    Component: EditInspection,
    permission: PERMISSIONS.CREATE_INSPECTION,
  },
  {
    path: '/system/inspections/:id/view/:step',
    exact: true,
    Component: InspectionView,
    permission: PERMISSIONS.CREATE_INSPECTION,
  },
  {
    path: '/system/inspections/:id/reports',
    exact: true,
    Component: Reports,
  },
  {
    path: '/system/inspections/:status',
    exact: true,
    Component: MyInspections,
  },
  {
    path: '/system/inspections/:id/reports/:reportId',
    exact: true,
    Component: ReportPage,
  },
  {
    path: '/system/db-edit',
    exact: true,
    Component: DbEdit,
    permission: PERMISSIONS.VIEW_INSPECTION_TYPE,
  },
];
