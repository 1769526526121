import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'redux/selectors/user';
import routes from 'routes/routes';
import { getItemsAllowedByUserPermissions } from 'utils/permissions';

export const usePermissions = () => {
  const user = useSelector(getCurrentUser);

  const userPermissions = useMemo(() => {
    const permissions = [];
    (user?.roles || []).forEach((role) => {
      (role?.permissions || []).forEach((permission) => {
        if (permissions.findIndex((permissionx) => permissionx?.id === permission?.id) === -1)
          permissions.push(permission);
      });
    });
    return permissions;
  }, [user]);

  const allowedRoutes = getItemsAllowedByUserPermissions(routes, userPermissions);

  return allowedRoutes;
};
