import React from 'react';
import { IconButton } from '@material-ui/core';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import useStyles from './useStyles';

const Header = ({ onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerTopWrapper}>
      <h2 data-cy-text='default-text-title' className={classes.title}>
        Default Text
      </h2>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </div>
  );
};

export default Header;
