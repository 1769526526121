import React from 'react';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as LogoMobileIcon } from 'assets/icons/logoMobile.svg';
import { ReactComponent as LogoLoginIcon } from 'assets/icons/logoLogin.svg';
import useStyles from './useStyles';

const Logo = ({ isMobile = false, isLogin = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.logoWrapper}>
      {!isLogin && !isMobile && <LogoIcon className={classes.logo} />}
      {!isLogin && isMobile && <LogoMobileIcon className={classes.logo} />}
      {isLogin && <LogoLoginIcon className={classes.logo} />}
    </div>
  );
};

export default Logo;
