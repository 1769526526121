export const White = '#ffffff';
export const Black = '#000000';
export const Red = '#f44336';

export const Comet = '#5B5F83';
export const Manatee = '#8487A2';
export const AthensGray = '#F1F1F4';
export const Mercury = '#E5E5E5';
export const RoyalBlue = '#3E6FDF';
export const JungleGreen = '#27AE60';
export const Jaffa = '#F2994A';

export const BlueGradient = 'linear-gradient(272.18deg, #004278 0%, #3E6FDF 100%)';
