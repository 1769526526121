import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  Button,
  IconButton,
  Input,
  Slide,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Form, Formik, Field, useFormikContext } from 'formik';
import cls from 'classnames';

import TextField from 'Components/TextField';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useFeedbackModal } from './useFeedbackModal';
import useStyles from './useStyles';
import FeedbackForm from './FeedbackForm';
import { getFeedbackFormValidation } from './validation';

const FeedbackModal = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { onModalClose, isModalOpen, handleSendFeedback, photos, setPhotos, videos, setVideos } = useFeedbackModal();

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.blockerModal}>
        <div className={classes.blockerModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Feedback</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik
              onSubmit={handleSendFeedback}
              initialValues={{
                subject: '',
                email: '',
                message: '',
              }}
              validationSchema={getFeedbackFormValidation()}
            >
              <Form style={{ width: '100%' }}>
                <FeedbackForm photos={photos} setPhotos={setPhotos} videos={videos} setVideos={setVideos} />
                <div className={classes.modalFooter}>
                  <Button type='submit' className={cls(classes.actionButton, classes.addInspectionButton)}>
                    Send
                  </Button>
                  <Button onClick={onModalClose} className={cls(classes.actionButton, classes.addBlockerButton)}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default FeedbackModal;
