import React from 'react';
import { IconButton } from '@material-ui/core';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { ReactComponent as ArrowLeftIcon } from './assets/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from './assets/arrowRight.svg';
import useStyles from './useStyles';

const Navbar = ({ onPreviousClick, onNextClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.calendarNavbar}>
      <IconButton className={classes.navButton} onClick={() => onPreviousClick()} data-cy-button='prev-month'>
        <ArrowLeftIcon />
      </IconButton>
      <IconButton className={classes.navButton} onClick={() => onNextClick()} data-cy-button='next-month'>
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

const Calendar = ({ onDayClick, selectedDate }) => {
  const classes = useStyles();

  return (
    <DayPicker
      showOutsideDays
      selectedDays={selectedDate}
      onDayClick={onDayClick}
      className={'comment-date-picker ' + classes.calendarWrapper}
      navbarElement={<Navbar />}
    />
  );
};

export default Calendar;
