import React from 'react';
import { Form, Formik } from 'formik';
import { Button, IconButton, Slide } from '@material-ui/core';
import cls from 'classnames';

import CircularLoader from 'Components/CircularLoader';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { initialValues } from './initialValues';
import { AddItemFormValidation } from './validation';
import { useAddItem } from './useAddItem';
import useStyles from './useStyles';
import AddItemForm from './AddItemForm';

const AddItemModal = () => {
  const classes = useStyles();
  const {
    submit,
    toggleDeficiencies,
    onModalClose,
    templateItem,
    deficiencies,
    hasIndex,
    isModalOpen,
    isCreating,
    isLoading,
  } = useAddItem();

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.addItemModal} data-cy-container='add-item'>
        <div className={classes.addItemModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{isCreating ? 'Add a New Item' : 'Edit a Item'}</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik
              initialValues={hasIndex ? templateItem : initialValues}
              validationSchema={AddItemFormValidation}
              onSubmit={submit}
            >
              {({ handleSubmit }) => (
                <Form>
                  <AddItemForm onToggleDeficiencies={toggleDeficiencies} isDeficiencies={deficiencies} />
                  <div className={classes.modalFooter}>
                    <Button
                      onClick={onModalClose}
                      className={cls(classes.actionButton, classes.cancelButton)}
                      data-cy-button='cancel-add-item'
                    >
                      Cancel
                    </Button>
                    <Button
                      className={cls(classes.actionButton, classes.submitButton)}
                      onClick={() => setTimeout(handleSubmit, 100)}
                      data-cy-button='submit-add-item'
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularLoader size={20} isWhite /> : 'Submit'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default AddItemModal;
