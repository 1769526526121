import { makeStyles } from '@material-ui/core';
import { BlueGradient, White } from 'styles/colors';

export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 24,
    right: 32,
    width: 260,
    marginRight: 50,
    zIndex: 100,
  },
  galleryControlButton: {
    color: White,
    padding: '4px 8px',
    width: '100%',
    background: BlueGradient,
    '& .MuiButton-label': {
      fontSize: 12,
      fontWeight: 700,
    },
  },
}));
