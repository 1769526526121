import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Jaffa, Red, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  commentWrapper: {
    '&:not(:first-child)': {
      marginTop: 8,
    },
  },
  accordionWrapper: {
    boxShadow: 'none',
  },
  commentSummary: {
    backgroundColor: rgba(theme.palette.primary.contrastText, 0.03),
    borderRadius: 5,

    '&>.MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-expanded': {
      borderRadius: '5px 5px 0 0',
    },
  },
  commentTitle: {
    fontSize: 16,
    fontWeight: 700,
    paddingLeft: 8,
  },
  minorComment: {
    color: RoyalBlue,
  },
  minorIcon: {
    fill: RoyalBlue,
  },
  moderateComment: {
    color: Jaffa,
  },
  moderateIcon: {
    fill: Jaffa,
  },
  majorComment: {
    color: Red,
  },
  majorIcon: {
    fill: Red,
  },
  details: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 0 5px 5px',
  },
  additionalInfoWrapper: {
    backgroundColor: rgba(Comet, 0.03),
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,

    '&:last-child': {
      borderRadius: '0 0 5px 5px',
    },
  },
  additionalInfoTitle: {
    fontSize: 14,
    color: Comet,
  },
  additionalInfoValue: {
    fontWeight: 600,
    color: RoyalBlue,
  },
  location: {
    fontWeight: 600,
  },
  expertIcon: {
    marginRight: 8,
  },
}));
