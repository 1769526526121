import React from 'react';
import { Button } from '@material-ui/core';
import useStyles from './useStyles';
import mainInstance from 'api/mainInstance';
import CircularLoader from 'Components/CircularLoader';
import localforage from 'localforage';
import { IsOnlineContext } from 'shared/IsOnlineContext';

const VIDEO_TYPES = 'video/*';
const IMAGE_TYPES = 'image/png, image/jpg, image/jpeg';
const ACCEPTED_TYPES = `${VIDEO_TYPES}, ${IMAGE_TYPES}`;

const getIds = () => {
  const r = document.location.pathname.split('/');
  let reportId, id;
  r.forEach((x, i) => {
    if (x === 'inspections') id = r[i + 1];
    if (x === 'reports') reportId = r[i + 1];
  });
  return { reportId, id };
};

const offlineSaveSection = async (files) => {
  const savedUploadedGalleryMedia = await localforage.getItem('savedUploadedGalleryMedia'); // localforage
  if (savedUploadedGalleryMedia) {
    files.forEach(async (file) => {
      const updatedReportsSections = [...savedUploadedGalleryMedia, file];
      await localforage.setItem('savedUploadedGalleryMedia', updatedReportsSections); // localforage
    });
  } else {
    await localforage.setItem('savedUploadedGalleryMedia', files); // localforage
  }
};

function GalleryControls({ fetchGallery, onClose }) {
  const classes = useStyles();
  const inputRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOnline } = React.useContext(IsOnlineContext);
  const { reportId, id } = getIds();

  const uploadMedia = (event) => {
    const files = Array.from(event.target.files);
    if (isOnline) {
      setIsLoading(true);
      Promise.all(
        files.map(async (file) => {
          const data = new FormData();
          data.append('file', file);
          data.append('templateId', (reportId || id).toString());
          return mainInstance.post('/template-files', data).catch(() => {
            return null;
          });
        }),
      ).finally(() => {
        setIsLoading(false);
        if (fetchGallery) fetchGallery();
      });
    } else {
      offlineSaveSection(files.map((x) => ({ file: x, reportId, id })));
    }
  };

  const onUploadButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const exportGallery = () => {
    mainInstance.post('/media-archives', { reportId }).then(() => {
      onClose();
    });
  };

  return (
    <div className={classes.wrapper}>
      <div>
        <div id={'uploadToGalleryBtn'}>
          <Button onClick={onUploadButtonClick} className={classes.galleryControlButton}>
            {isLoading ? <CircularLoader isWhite style={{ maxHeight: 22, maxWidth: 22 }} /> : 'Upload Media'}
          </Button>
        </div>
        <input type='file' hidden multiple accept={ACCEPTED_TYPES} ref={inputRef} onChange={uploadMedia} />
      </div>
      <div id={'exportToGalleryBtn'}>
        <Button onClick={exportGallery} className={classes.galleryControlButton}>
          {isLoading ? <CircularLoader isWhite style={{ maxHeight: 22, maxWidth: 22 }} /> : 'Export Gallery'}
        </Button>
      </div>
    </div>
  );
}

export default GalleryControls;
