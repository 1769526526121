import React from 'react';
import { Formik, Form } from 'formik';
import { Button, IconButton, Slide } from '@material-ui/core';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import CircularLoader from 'Components/CircularLoader';
import { initialValues } from './initialValues';
import CommentFilesUpload from '../CommentFilesUpload';
import { AddCommentValidation } from './validation';
import { useAddComment } from './useAddComment';
import AddCommentForm from './AddCommentForm';
import useStyles from './useStyles';

const AddCommentModal = () => {
  const classes = useStyles();
  const { submit, onModalClose, comment, hasIndex, isModalOpen, isCreating, isReport } = useAddComment();

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.addCommentModal} data-cy-container='add-comment'>
        <div className={classes.addCommentModalWrapper} data-cy-container='add-comment-wrapper'>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{isCreating ? 'Add a New Comment' : 'Edit comment'}</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik
              onSubmit={submit}
              initialValues={hasIndex ? comment : initialValues}
              validationSchema={AddCommentValidation}
            >
              {({ handleSubmit }) => (
                <Form>
                  <AddCommentForm isReport={isReport} />
                  <CommentFilesUpload />
                  <div className={classes.modalFooter}>
                    <Button
                      onClick={onModalClose}
                      className={`${classes.actionButton} ${classes.cancelButton}`}
                      data-cy-button='cancel-add-comment'
                    >
                      Cancel
                    </Button>
                    <Button
                      className={`${classes.actionButton} ${classes.submitButton}`}
                      onClick={() => setTimeout(handleSubmit, 100)}
                      data-cy-button='submit-add-comment'
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default AddCommentModal;
