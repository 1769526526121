import React from 'react';
import { Button, Fade, IconButton } from '@material-ui/core';
import { Form, Formik } from 'formik';
import cls from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useHolderModal } from './useHolderModal';
import { AddHolderValidation } from './validation';
import HolderForm from './HolderForm';
import useStyles from './useStyles';

const HolderModal = () => {
  const classes = useStyles();
  const { onModalClose, addHolder, isModalOpen, initialValues } = useHolderModal();

  return (
    <Fade in={isModalOpen}>
      <div className={classes.holderModal}>
        <div className={classes.holderModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Holder</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik onSubmit={addHolder} initialValues={initialValues} validationSchema={AddHolderValidation}>
              <Form>
                <HolderForm />
                <div className={classes.modalFooter}>
                  <Button type='submit' className={cls(classes.actionButton, classes.addHolderButton)}>
                    Add
                  </Button>
                  <Button onClick={onModalClose} className={cls(classes.actionButton, classes.cancelButton)}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default HolderModal;
