import React, { useContext } from 'react';
import { Field, useFormikContext } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import TextField from 'Components/TextField';
import useStyles from './useStyles';
import { StyleContext } from 'shared/contexts/StyleContext';

const LoginForm = ({ onToggleChecked, shouldKeepLogin }) => {
  const classes = useStyles();
  const { touched } = useFormikContext();
  const { isDarkMode } = useContext(StyleContext);

  return (
    <>
      <Field name='email'>
        {({ field, meta }) => (
          <TextField
            {...field}
            error={touched.email && !!meta.error}
            helperText={touched.email && meta.error}
            className={classes.input}
            variant='outlined'
            type='email'
            label='Email'
            name='email'
            data-cy-input='email'
            inputProps={{ style: { color: isDarkMode ? 'white' : 'black' } }}
          />
        )}
      </Field>
      <Field name='password'>
        {({ field, meta }) => (
          <TextField
            {...field}
            error={touched.password && !!meta.error}
            helperText={touched.password && meta.error}
            className={classes.input}
            variant='outlined'
            type='password'
            label='Password'
            name='password'
            data-cy-input='password'
            style={{ color: isDarkMode ? 'white' : 'black' }}
            inputProps={{ style: { color: isDarkMode ? 'white' : 'black !important' } }}
          />
        )}
      </Field>
      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            checked={shouldKeepLogin}
            className={classes.checkbox}
            onChange={onToggleChecked}
            color='primary'
            data-cy-checkbox='keep-login'
          />
        }
        label='Keep me logged in'
      />
    </>
  );
};

export default LoginForm;
