import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, Red, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  holderModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      width: '100%',
      maxWidth: 900,
      height: 'fit-content',
      borderRadius: 16,
    },
  },
  holderModalWrapper: {
    maxWidth: '100%',
    minHeight: '30vh',
    maxHeight: '100%',
    height: 'fit-content',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80vh',
    },
  },
  modalHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    zIndex: 999,
    borderRadius: '16px 16px 0 0',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginTop: 73,
  },
  formRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  timeWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
    marginBottom: 24,
  },
  textField: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: RoyalBlue,
      },
    },
  },
  dateField: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  inputButton: {
    padding: 0,
  },
  validTextField: {
    '& fieldset': {
      borderColor: rgba(Comet, 0.25),
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        backgroundColor: rgba(RoyalBlue, 0.05),
      },
      '&.Mui-focused fieldset': {
        borderColor: RoyalBlue,
      },
    },
    '&.MuiOutlinedInput-root': {
      backgroundColor: rgba(RoyalBlue, 0.05),
    },
  },
  formItem: {
    width: '100%',
    marginTop: 24,
  },
  modalFooter: {
    paddingTop: 24,
    marginTop: 24,
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 700,
    width: '48%',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cancelButton: {
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  addHolderButton: {
    background: BlueGradient,
  },
}));
