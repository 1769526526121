import React from 'react';

import { ReactComponent as MarkerIcon } from 'assets/icons/marker.svg';
import useStyles from './useStyles';

const AddressMarker = () => {
  const classes = useStyles();
  return (
    <div className={classes.markerWrapper} data-cy-item='marker'>
      <MarkerIcon />
    </div>
  );
};

export default AddressMarker;
