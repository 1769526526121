import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Jaffa, Red, RoyalBlue, White } from 'styles/colors';

export default makeStyles((theme) => ({
  formItem: {
    width: '100%',
    marginTop: 24,
  },
  estimationValuesWrapper: {
    display: 'flex',
    marginTop: 24,
  },
  estimationValueWrapper: {
    width: '100%',

    '&:not(:first-child)': {
      marginLeft: 16,
    },
  },
  typeButtonsWrapper: {
    display: 'flex',
    marginTop: 16,
  },
  typeButton: {
    width: '100%',
    backgroundColor: '#EFEFF3',
    color: Comet,
    border: 'none',
    margin: '0 !important',
    padding: '10px 0',
    '&:first-child': {
      borderRight: `1px solid ${rgba(Comet, 0.1)}`,
    },
    '&:last-child': {
      borderLeft: `1px solid ${rgba(Comet, 0.1)}`,
    },
  },
  maintainButton: {
    backgroundColor: RoyalBlue,
    color: White,
    '&:hover': {
      backgroundColor: rgba(RoyalBlue, 0.7),
    },
  },
  attachedButton: {
    backgroundColor: Jaffa,
    color: White,
    '&:hover': {
      backgroundColor: rgba(Jaffa, 0.7),
    },
  },
  actionNecessaryButton: {
    backgroundColor: Red,
    color: White,
    '&:hover': {
      backgroundColor: rgba(Red, 0.7),
    },
  },
  typoButtonIcon: {
    fill: Comet,
  },
  typeButtonSelectedIcon: {
    fill: White,
  },
  typeButtonTitle: {
    paddingLeft: 8,
  },
  typeButtonTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
  },
  uploadFileWrapper: {
    display: 'flex',
    marginTop: 16,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
  },
  uploadFileColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      '&:not(:first-child)': {
        paddingLeft: 8,
        '&>.dropzone': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'start',
        },
      },
    },
  },
  fileList: {
    padding: 0,
    listStyle: 'none',
  },
  fileListItem: {
    display: 'flex',
    alignItems: 'center',
  },
  filePreviewWrapper: {
    width: 200,
    height: 80,
    borderRadius: 5,
    border: `1px solid ${rgba(Comet, 0.5)}`,
  },
  filePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  fileListItemTitle: {
    fontSize: 12,
    color: Comet,
    display: 'inline-block',
    textOverflow: 'ellipsis',
    width: 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  uploadFileButton: {
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: RoyalBlue,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: rgba(RoyalBlue, 0.7),
    },
  },
}));
