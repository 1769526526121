import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  layout: {
    position: 'relative',
    display: 'flex',
  },
  centerContent: {
    width: '100%',
    paddingBottom: 300,

    [theme.breakpoints.up('sm')]: {
      width: ({ hasPdf }) => !hasPdf && '76%',
      marginLeft: ({ hasPdf }) => !hasPdf && 'auto',
      paddingBottom: 400,
    },
  },
}));
