import { makeStyles } from '@material-ui/core';

import { Comet, RoyalBlue, White } from 'styles/colors';
import { rgba } from 'polished';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mediaButton: {
    width: '100%',
    backgroundColor: RoyalBlue,
    color: Comet,
    border: 'none',
    margin: '0 !important',
    padding: 0,
    minHeight: 40,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: rgba(RoyalBlue, 0.7),
    },
  },
  disabledMediaButton: {
    backgroundColor: rgba(RoyalBlue, 0.3),
  },
  mediaButtonIcon: {
    fill: White,
    width: 20,
    height: 20,
  },
  fileUploadWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: 8,
    },
  },
  previewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
  deleteButton: {
    padding: 0,
    maxWidth: 36,
    marginLeft: 'auto',
  },
  imageWrapper: {
    maxHeight: 150,
  },
  preview: {
    objectFit: 'cover',
    maxHeight: 150,
    width: '100%',
    height: '100%',
  },
}));
