import { makeStyles } from '@material-ui/core';
import { AthensGray } from 'styles/colors';

export default makeStyles((theme) => ({
  editorWrapper: {
    borderRadius: 5,
    boxShadow: `0 0 0 2px ${theme.palette.isDark ? theme.palette.primary.contrastText : AthensGray}`,
  },
  editor: {
    minHeight: 150,
    padding: '0 10px',
    color: theme.palette.primary.contrastText,

    '& a': {
      textDecoration: 'underline',
    },
  },
  customToolbar: {
    width: 'fit-content',
    backgroundColor: `${theme.palette.isDark ? theme.palette.primary.main : AthensGray} !important`,
    borderRadius: '5px 0 5px 0 !important',
    borderTop: 'none',
    borderLeft: 'none',
    borderColor: theme.palette.isDark && theme.palette.primary.contrastText,
  },
}));
