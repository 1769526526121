import { createTheme } from '@material-ui/core/styles';
import { White } from './colors';

// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

export const getTheme = (isDark = false) =>
  createTheme({
    palette: {
      type: isDark ? 'dark' : undefined,
      primary: { main: isDark ? '#14161a' : '#fff', contrastText: isDark ? '#3E6FDF' : '#5B5F83' },
      isDark,
    },
    overrides: {
      MuiButton: {
        text: {
          color: White,
          padding: 0,
          minWidth: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiMenuItem: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
  });
