import { useState } from 'react';
import { noop } from 'utils/noop';

export const useComment = (saveChanges, comment) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const [isVideoLoading, setVideoLoading] = useState(false);

  const toggleIsImageLoading = (isLoading) => setImageLoading(isLoading);
  const toggleIsVideoLoading = (isLoading) => setVideoLoading(isLoading);

  const toggleIsExpanded = () => setExpanded(!isExpanded);

  const submit = (val = '') => {
    let res = val;
    if (comment.type === 'text') {
      if (!val.includes('<p>')) res = ''.concat('<p>', res);
      if (!val.includes('</p>')) res = ''.concat(res, '</p>');
    }

    saveChanges(res);
  };

  const setAllDeafaultValues = noop;

  return {
    toggleIsImageLoading,
    toggleIsVideoLoading,
    toggleIsExpanded,
    submit,
    isImageLoading,
    isVideoLoading,
    isExpanded,
    setAllDeafaultValues,
  };
};
