export const isPermissionsMatch = (requiredPermission, currentPermissions) =>
  currentPermissions?.includes(requiredPermission);

export const getItemsAllowedByUserPermissions = (routes, userPermissions) => {
  const currentPermissions = userPermissions?.map((permission) => permission.type);

  return routes.filter((route) => {
    return !route.permission || isPermissionsMatch(route.permission, currentPermissions);
  });
};

export const isUserHasPermission = (user, neededPermissions) => {
  const permissions = [];
  (user?.roles || []).forEach((role) => {
    (role?.permissions || []).forEach((permission) => {
      if (permissions.findIndex((permissionx) => permissionx?.id === permission?.id) === -1)
        permissions.push(permission);
    });
  });
  const hasPermissions = permissions?.filter((permission) => neededPermissions.includes(permission.type));
  return hasPermissions?.length === neededPermissions?.length;
};
