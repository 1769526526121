import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';

import TextField from 'Components/TextField';

import useStyles from './useStyles';
import { ConstantsContext } from 'shared/contexts/ConstantsContext';

const AddLocationForm = ({ onLocationSelect }) => {
  const classes = useStyles();
  const { locations } = useContext(ConstantsContext);
  const { setFieldValue, values } = useFormikContext();

  return (
    <>
      <div className={classes.locationsWrapper}>
        {locations &&
          Array.isArray(locations[0].locations) &&
          locations[0].locations.map((item) => (
            <Button
              className={classes.locationOption}
              onClick={() => onLocationSelect(setFieldValue, item, values.location)}
            >
              {item}
            </Button>
          ))}
      </div>
      <Field name='location'>{({ field }) => <TextField {...field} label='LOCATIONS' />}</Field>
    </>
  );
};

export default AddLocationForm;
