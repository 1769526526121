import React, { useContext, useState } from 'react';
import { IconButton } from '@material-ui/core';

import { fileUrl } from 'config/config';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import MediaButtons from './MediaButtons';
import { useViewModeFilesUpload } from './useViewModalFilesUpload';
import useStyles from './useStyles';
import CircularLoader from 'Components/CircularLoader';
import { useDispatch } from 'react-redux';
import { openImageEditorModal } from 'redux/reducers/modals';

const ViewModeFilesUpload = ({ onToggleImageLoading, onToggleVideoLoading, isReport, isShortened }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { saveVideo, savePhoto, deletePhoto, deleteVideo, saveEditedImage, photos, videos } = useViewModeFilesUpload();
  const [editorLoading, setEditorLoading] = useState(false);

  const createPhoto = (photo) => {
    if (photo?.uploadedFile) {
      return `${fileUrl}${photo.uploadedFile?.name || photo.uploadedFile}`;
    }
    if (photo?.file) {
      return URL.createObjectURL(photo.path);
    }
    return '';
  };

  return (
    <>
      <MediaButtons
        onToggleImageLoading={onToggleImageLoading}
        onToggleVideoLoading={onToggleVideoLoading}
        onSavePhoto={savePhoto}
        onSaveVideo={saveVideo}
        photos={photos}
        videos={videos}
        isReport={isReport}
      />
      {!isShortened && (
        <ul className={classes.fileList}>
          {photos.map((photo, idx) => (
            <li className={classes.fileListItem} key={photo.uploadedFile}>
              <div className={classes.previewWrapper}>
                <img
                  className={classes.preview}
                  src={createPhoto(photo)}
                  alt='Additional media'
                  data-cy-image='comment-view-mode-image'
                />
              </div>
              <IconButton
                onClick={deletePhoto(idx)}
                className={classes.actionButton}
                data-cy-button='delete-comment-image-view-mode'
              >
                <TrashIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  dispatch(
                    openImageEditorModal({
                      setEditorLoading,
                      onSaveEditedImage: saveEditedImage(idx),
                      photo,
                      isReport,
                    }),
                  )
                }
                className={classes.actionButton}
              >
                {editorLoading ? <CircularLoader size={16} /> : <EditIcon />}
              </IconButton>
            </li>
          ))}
          {videos.map((video, idx) => (
            <li className={classes.fileListItem} key={video.uploadedFile}>
              <div className={classes.previewWrapper}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video className={classes.preview} data-cy-video='comment-view-mode-video' controls>
                  <source src={`${fileUrl}${video.uploadedFile}`} />
                </video>
              </div>
              <IconButton
                onClick={deleteVideo(idx)}
                className={classes.deleteButton}
                data-cy-button='delete-comment-video-view-mode'
              >
                <TrashIcon />
              </IconButton>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ViewModeFilesUpload;
