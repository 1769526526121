import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, Red, White } from 'styles/colors';

export default makeStyles({
  imageEditorModal: {
    height: '100vh',
    backgroundColor: White,
  },
  imageEditorModalWrapper: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100vh',
    overflowY: ({ isBlockedScroll }) => (isBlockedScroll ? 'hidden' : 'scroll'),
  },
  modalContent: {
    height: '-webkit-fill-available; height: -moz-available; height: stretch;',
    maxHeight: '84%',
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    flexGrow: 1,
  },
  modalHeader: {
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    backgroundColor: White,
    zIndex: 1400,
  },
  closeButton: {
    padding: 0,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  modalFooter: {
    paddingTop: 24,
    marginTop: 24,
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 700,
    width: '48%',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cancelButton: {
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  submitButton: {
    background: BlueGradient,
  },
});
