import React from 'react';

import CircularLoader from 'Components/CircularLoader';
import useStyles from './useStyles';

const Loader = ({ isSendingData = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularLoader />
      {isSendingData && <h3 className={classes.message}>Sending data saved in offline</h3>}
    </div>
  );
};

export default Loader;
