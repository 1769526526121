import React from 'react';

import { useDeficienciesComments } from './useDeficienciesComments';
import Comment from './Comment';
import useStyles from './useStyles';

const orderOfComments = {
  ACTION_NECESSARY: 3,
  ATTANCHENED_NEEDED: 2,
  MAINTAIN_MONITOR: 1,
};

const DeficienciesComments = ({ section, reportSection }) => {
  const classes = useStyles();
  const { deficienciesComments } = useDeficienciesComments(section, reportSection);
  return (
    <div className={classes.commentsWrapper}>
      {deficienciesComments
        .sort((a, b) => orderOfComments[b.type] - orderOfComments[a.type])
        .map((comment, idx) => (
          <Comment key={`${comment.name}${idx + 1}`} comment={comment} />
        ))}
    </div>
  );
};

export default DeficienciesComments;
