import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet } from 'styles/colors';

export default makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exceptionText: {
    fontSize: 24,
    fontWeight: 700,
    color: rgba(Comet, 0.5),
    textAlign: 'center',
    padding: '0 16px',
  },
});
