import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, Red, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  blockerModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      width: '100%',
      maxWidth: 500,
      height: 'fit-content',
      borderRadius: 16,
    },
  },
  blockerModalWrapper: {
    maxHeight: '100%',
    minHeight: '20vh',
    height: 'fit-content',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80vh',
    },
  },
  modalHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    zIndex: 999,
    borderRadius: '16px 16px 0 0',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  modalContent: {
    display: 'flex',
    padding: 24,
    marginTop: 73,
  },
  modalFooter: {
    paddingTop: 24,
    marginTop: 24,
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 700,
    width: '48%',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addBlockerButton: {
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  addInspectionButton: {
    background: BlueGradient,
  },
  formRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  fieldWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
    marginBottom: 24,
  },
  textField: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: RoyalBlue,
      },
    },
  },
  dateField: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  inputButton: {
    padding: 0,
  },
  validTextField: {
    '& fieldset': {
      borderColor: rgba(Comet, 0.25),
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        backgroundColor: rgba(RoyalBlue, 0.05),
      },
      '&.Mui-focused fieldset': {
        borderColor: RoyalBlue,
      },
    },
    '&.MuiOutlinedInput-root': {
      backgroundColor: rgba(RoyalBlue, 0.05),
    },
  },
  formItem: {
    width: '100%',
    marginTop: 24,
  },
  dateIcon: {
    fill: rgba(Comet, 0.7),
  },
  mediaButtonsWrapper: {
    display: 'flex',
  },
  mediaButton: {
    width: '100%',
    backgroundColor: rgba(Comet, 0.1),
    color: Comet,
    border: 'none',
    margin: '0 !important',
    padding: 0,
    minHeight: 40,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: rgba(Comet, 0.2),
    },
    '&:disabled': {
      backgroundColor: rgba(Comet, 0.3),
    },
  },
  uploadImageButton: {
    borderRight: `1px solid ${rgba(Comet, 0.1)}`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  uploadVideoButton: {
    borderLeft: `1px solid ${rgba(Comet, 0.1)}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  uploadReportVideoButton: {
    borderRadius: 0,
  },
  mediaButtonIcon: {
    fill: Comet,
    width: 20,
    height: 20,
  },
  fileUploadWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediaRow: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > span': {
      '& > button': {
        color: 'transparent',
        '& svg': {
          display: 'none',
        },
        width: '100%',
        height: '30px',
        textAlign: 'end',
      },
      '&:hover': {
        '& > button': {
          position: 'relative',
          top: '-127px',
          left: '10px',
          textAlign: 'center',
          borderRadius: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          color: 'white',
          '& svg': {
            display: 'block',
          },
        },
      },
      '& > img': {
        width: '100%',
        height: '120px',
        borderRadius: '5px',
      },
      '& > video': {
        width: '100%',
        height: '120px',
        borderRadius: '5px',
      },
      width: '96%',
      borderRadius: '5px',
      margin: '0px 2%',
      marginBottom: '-25px',
    },
  },
}));
