import React from 'react';
import { Field } from 'formik';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const Checkboxes = ({ values }) => {
  return (
    <div>
      <FormGroup>
        {values.map((option) => (
          <Field key={option} type='checkbox' name='defaultValue' value={option}>
            {({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox {...field} className={'checkBoxWithSvg'} color={'primary'} />}
                label={option}
                data-cy-checkbox='option'
              />
            )}
          </Field>
        ))}
      </FormGroup>
    </div>
  );
};

export default Checkboxes;
