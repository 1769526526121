import { IconButton, Slide } from '@material-ui/core';
import React from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import useStyles from './useStyles';
import { closeModal } from 'redux/reducers/modals';
import { useDispatch, useSelector } from 'react-redux';
import { getModalProps, getModalType } from 'redux/selectors/modals';

const ItemInfoModal = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { item } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.sectionInfoModal} data-cy-container='item-info'>
        <div className={classes.sectionInfoModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Inspected Element Information </h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <h3 className={classes.sectionName} data-cy-text='item-name'>
              {item.name}
            </h3>
            <div>
              <h5 className={classes.infoTitle}>REMINDERS</h5>
              <div dangerouslySetInnerHTML={{ __html: item?.reminders }} />
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default ItemInfoModal;
