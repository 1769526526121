import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { IconButton } from '@material-ui/core';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import { fileUrl } from 'config/config';
import { useDispatch } from 'react-redux';
import { openPreviewModal } from 'redux/reducers/modals';
import useStyles from './useStyles';

const Slider = ({ photos, videos }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const renderItems = () => [
    ...photos.map((photo) => (
      <React.Fragment key={photo.uploadedFile}>
        <img
          className={classes.image}
          key={photo.uploadedFile}
          src={`${fileUrl}${photo.uploadedFile}`}
          alt='Slide preview'
        />
        {photo?.name && <span className={classes.commentNameChip}>{photo.name}</span>}
        <IconButton
          className={classes.imagePreviewButton}
          onClick={() => dispatch(openPreviewModal({ image: photo.uploadedFile }))}
        >
          <AspectRatioIcon className={classes.imagePreviewIcon} />
        </IconButton>
      </React.Fragment>
    )),
    ...videos.map((video) => (
      <React.Fragment key={video.uploadedFile}>
        {video?.name && <span className={classes.commentNameChip}>{video.name}</span>}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video className={classes.video} key={video.uploadedFile} width='100%' height='100%' controls>
          <source src={`${fileUrl}${video.uploadedFile}`} />
        </video>
      </React.Fragment>
    )),
  ];
  return (
    <Carousel
      animation='slide'
      autoPlay={false}
      className={classes.sliderWrapper}
      indicatorContainerProps={{ className: classes.indicators }}
      activeIndicatorProps={{ className: classes.activeIndicator }}
      navButtonsAlwaysVisible
      swipe
    >
      {renderItems()}
    </Carousel>
  );
};

export default Slider;
