import Loader from 'Components/Loader';
import React, { useContext } from 'react';
import { GlobalLoaderContext } from './contexts/GlobalLoaderContext';

function GlobalLoader() {
  const { isLoading } = useContext(GlobalLoaderContext);
  if (isLoading) return <Loader />;
  return null;
}

export default GlobalLoader;
