import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Black } from 'styles/colors';

export default makeStyles({
  dropdown: {
    '&>.MuiPaper-root': {
      boxShadow: `0 0 10px ${rgba(Black, 0.5)}`,
    },
  },
});
