import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';

export const COMMENT_TYPES = [
  { type: 'MAINTAIN_MONITOR', tittle: 'Maintain/Monitor', icon: KeyIcon },
  { type: 'ATTANCHENED_NEEDED', tittle: 'Attached needed', icon: MinusIcon },
  { type: 'ACTION_NECESSARY', tittle: 'Action necessary', icon: WarningIcon },
];

export const INSPECTOR_ESTIMATION_ID = 2;
