import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Black, Comet, Red, RoyalBlue } from 'styles/colors';

export default (hasError) =>
  makeStyles({
    formControl: {
      width: '100%',
      '& fieldset': {
        borderColor: rgba(Comet, 0.25),
      },
      '& .MuiOutlinedInput-root': {
        '& > fieldset > legend': {
          fontSize: '0.80em',
        },
        '&.Mui-disabled:hover > fieldset': {
          borderColor: hasError ? Red : 'rgba(0, 0, 0, 0.26)',
        },
        '&:hover > fieldset': {
          borderColor: hasError ? Red : RoyalBlue,
        },
        '&.Mui-focused fieldset': {
          borderColor: hasError ? Red : RoyalBlue,
        },
        '&.Mui-focused': {
          backgroundColor: rgba(hasError ? Red : RoyalBlue, 0.05),
          borderColor: hasError ? Red : RoyalBlue,
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: hasError && Red,
        },
      },
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      '&:-webkit-autofill': {
        WebkitTextFillColor: Black,
      },
      '&:focus': {
        borderColor: RoyalBlue,
        color: hasError ? Red : RoyalBlue,
        backgroundColor: 'transparent',
        '&:-webkit-autofill': {
          WebkitTextFillColor: hasError ? Red : RoyalBlue,
        },
      },
    },
  });
