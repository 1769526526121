import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AlertContext } from 'Components/Alert/alertContext';
import { GlobalLoaderContext } from 'shared/contexts/GlobalLoaderContext';
import loginThunk from '../../redux/user/thunk/loginThunk';

export const useLogin = () => {
  const [shouldKeepLogin, setKeepLogin] = useState(false);

  const { handleSetMessage } = useContext(AlertContext);
  const { setIsGlobalLoading } = useContext(GlobalLoaderContext);
  const dispatch = useDispatch();

  const toggleChecked = () => setKeepLogin(!shouldKeepLogin);

  const login = async ({ email, password }) => {
    const data = {
      email,
      password,
      shouldKeepLogin,
    };
    try {
      setIsGlobalLoading(true);
      await dispatch(loginThunk(data));
    } catch (error) {
      handleSetMessage(error?.response?.data?.message, 'error');
    } finally {
      setIsGlobalLoading(false);
    }
  };

  return { toggleChecked, login, shouldKeepLogin };
};
