import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, FormControl, MenuItem, Select } from '@material-ui/core';

import { REPORT_SECTION } from '../options';
import useStyles from './useStyles';

const ReportTabs = ({ onDownloadPdf, hasTermite, report }) => {
  const classes = useStyles();
  const { token, reportId } = useParams();

  const getReportStatus = () => {
    if (report?.type === 'COMPLETED') {
      if (report?.pdfJob?.status === 'FAILED') return `🔴 PDF reports creation failed.`;
      if (report?.pdfJob?.status === 'COMPLETED') return `🟢 PDF reports are ready.`;
      if (report?.pdfJobId && !report?.pdfJob) return `🟡 PDF reports are in queue, please wait.`;
      if (report?.pdfJob)
        return `🔵 PDF reports is in progress ${report?.pdfFiles?.length || 0} / ${report?.types?.length || 1}.`;
    } else return `Report is not completed.`;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.tabsWrapper}>
        {!hasTermite && (
          <>
            <NavLink
              className={classes.tabLink}
              activeClassName={classes.selectedTabLink}
              to={`/result-inspection/${token}/${reportId}/${REPORT_SECTION.FULL_REPORTS}`}
            >
              Full report
            </NavLink>
            <NavLink
              className={classes.tabLink}
              activeClassName={classes.selectedTabLink}
              to={`/result-inspection/${token}/${reportId}/${REPORT_SECTION.SUMMARY}`}
            >
              Summary
            </NavLink>
            <NavLink
              className={classes.tabLink}
              activeClassName={classes.selectedTabLink}
              to={`/result-inspection/${token}/${reportId}/${REPORT_SECTION.MAJOR_CONCERN}`}
            >
              Major concerns
            </NavLink>
          </>
        )}
        <span className={classes.pdfStatus}>{getReportStatus()}</span>
      </div>
      <FormControl>
        <Select
          value='Download'
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          label='DOWNLOAD'
          style={{ width: 100, color: 'rgba(0, 0, 0, 0.87)' }}
          className={'download-select'}
        >
          <MenuItem disabled value='Download'>
            Download
          </MenuItem>

          {hasTermite ? (
            <MenuItem>
              <Button
                onClick={onDownloadPdf('TERMITE_REPORT')}
                className={classes.downloadPdfButton}
                disabled={!report?.pdfFiles.find((x) => x.type.name === 'TERMITE_REPORT')}
              >
                WDI Report
              </Button>
            </MenuItem>
          ) : (
            <>
              {' '}
              <MenuItem>
                <Button
                  onClick={onDownloadPdf('FULL_REPORT')}
                  className={classes.downloadPdfButton}
                  disabled={!report?.pdfFiles.find((x) => x.type.name === 'FULL_REPORT')}
                >
                  Full Report
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={onDownloadPdf('SUMMARY')}
                  className={classes.downloadPdfButton}
                  disabled={!report?.pdfFiles.find((x) => x.type.name === 'SUMMARY')}
                >
                  Summary
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={onDownloadPdf('MAJOR')}
                  className={classes.downloadPdfButton}
                  disabled={!report?.pdfFiles.find((x) => x.type.name === 'MAJOR')}
                >
                  Major Concerns
                </Button>
              </MenuItem>
            </>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default ReportTabs;
