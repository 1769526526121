import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';
import { Comet } from '../../../../styles/colors';

export default makeStyles((theme) => ({
  dateRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:not(:first-child)': {
      borderTop: `1px solid ${rgba(Comet, 0.05)}`,
    },
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fill: theme.palette.primary.contrastText,
    },
  },
  isnLogo: {
    height: 'auto',
    fill: '#ADAFC1',
    width: 20,
  },
  contentText: {
    color: ({ inspectorColor }) => inspectorColor,
    paddingLeft: 8,
    fontSize: 12,
  },
}));
