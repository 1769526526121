import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, White } from 'styles/colors';

export default makeStyles((theme) => ({
  loginHeader: {
    padding: '24px 0',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: `0 1px 0 0 ${rgba(theme.palette.primary.contrastText, 0.1)}`,
    background: theme.palette.primary.main,
  },
  title: {
    color: Comet,
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 40,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 40px',
    paddingTop: '15vh',
    height: '100%',
    background: theme.palette.primary.main,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 40,
    marginTop: 36,
    [theme.breakpoints.up('sm')]: {
      boxShadow: `0 0 0 1px ${rgba(Comet, 0.1)}`,
      borderRadius: '5px',
      maxWidth: 330,
    },
  },
  loginButton: {
    marginTop: 40,
    padding: '16px 0',
    background: BlueGradient,
    minHeight: 56,
    '& .MuiButton-label': {
      color: White,
      fontWeight: 700,
    },
    '&:disabled': {
      opacity: 0.5,
    },
  },
  iconWrapper: {
    marginLeft: 12,
  },
  errorMessage: {
    marginTop: 40,
  },
}));
