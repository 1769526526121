import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: rgba(Comet, 0.5),
  },
  goBack: {
    fontSize: 18,
    fontWeight: 400,
    color: RoyalBlue,
  },
});
