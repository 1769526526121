import { makeStyles } from '@material-ui/core';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles({
  input: {
    marginTop: 28,
  },
  checkboxLabel: {
    marginTop: 24,
    color: Comet,
  },
  checkbox: {
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: RoyalBlue,
    },
  },
});
