import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import mainInstance from 'api/mainInstance';
import { IsOnlineContext } from 'shared/IsOnlineContext';

export async function dataUrlToFile(dataUrl, fileName) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpeg' });
}

export const useCameraComponent = ({ onFileUploaded, uploadRoute, uploadField, galleryUpload }) => {
  const [loading, setLoading] = useState(false);
  const { isOnline } = useContext(IsOnlineContext);
  const [cameraProgress, setCameraProgress] = useState({ from: 0, to: 0 });
  const { id, reportId } = useParams();

  const uploadPhotos = async (photos) => {
    setLoading(true);
    setCameraProgress({ from: 0, to: photos?.length });
    const resultArray = [];
    // eslint-disable-next-line
    for (const photo of photos) {
      setCameraProgress({ from: resultArray.length, to: photos?.length });
      // eslint-disable-next-line
      const file = await dataUrlToFile(photo, `photo.jpeg`);
      const data = new FormData();
      data.append(!galleryUpload ? uploadField : 'file', file);
      if (galleryUpload) data.append('templateId', (reportId || id).toString());
      try {
        const uploadUrl = !galleryUpload ? `/admin/${uploadRoute}` : '/template-files';
        // eslint-disable-next-line
        const uploadResponse = await mainInstance.post(uploadUrl, data);
        resultArray.push({
          file: { path: 'photo.jpg' },
          uploadedFile: uploadResponse.data?.name || uploadResponse.data,
        });
      } catch (e) {
        setLoading(false);
        if (!isOnline) {
          resultArray.push({ file, uploadedFile: '' });
        }
      }
    }
    setLoading(false);
    await onFileUploaded(resultArray);
  };

  return { uploadPhotos, loadingCamera: loading, cameraProgress };
};
