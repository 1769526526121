export const REPORT_SECTION = {
  FULL_REPORTS: 'full-report',
  SUMMARY: 'summary',
  MAJOR_CONCERN: 'major-concern',
};

export const COMMENT_TYPES = {
  INFORMATIONAL: 'INFORMATIONAL',
  LIMITATIONS: 'LIMITATIONS',
  STANDARDS: 'STANDARDS',
  PRIORITY: 'PRIORITY',
};

export const PDF_TYPES = {
  [REPORT_SECTION.FULL_REPORTS]: 'FULL_REPORT',
  [REPORT_SECTION.SUMMARY]: 'SUMMARY',
  [REPORT_SECTION.MAJOR_CONCERN]: 'MAJOR',
};
