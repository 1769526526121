import React from 'react';
import { ReactComponent as IsnLogoIcon } from '../../../../assets/icons/isnLogo.svg';
import { ReactComponent as HashIcon } from '../../../../assets/icons/hash.svg';
import useStyles from './useStyles';

const InspectionIdLine = ({ isnOid, id, inspectorColor }) => {
  const classes = useStyles({ inspectorColor });
  const idTemplate = isnOid ? (
    <div className={classes.dateRow}>
      <div className={classes.contentWrapper}>
        <IsnLogoIcon className={classes.isnLogo} />
        <span className={classes.contentText}>{isnOid}</span>
      </div>
    </div>
  ) : (
    <div className={classes.dateRow}>
      <div className={classes.contentWrapper}>
        <HashIcon className={classes.isnLogo} />
        <span className={classes.contentText}>{id}</span>
      </div>
    </div>
  );

  return <div>{idTemplate}</div>;
};

export default InspectionIdLine;
