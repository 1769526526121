import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  columnItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
    marginBottom: 24,

    [theme.breakpoints.up('md')]: {
      marginBottom: 8,
    },

    border: ({ isOutlinedItem }) => isOutlinedItem && `1px solid ${rgba(theme.palette.primary.contrastText, 0.25)}`,
    padding: ({ isOutlinedItem }) => (isOutlinedItem ? 16 : 8),
  },
  selectedColumnItem: ({ isOutlinedItem }) =>
    isOutlinedItem && {
      borderColor: RoyalBlue,
      backgroundColor: rgba(RoyalBlue, 0.05),
      '&:hover': {
        backgroundColor: rgba(RoyalBlue, 0.05),
      },
    },
  itemTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      minWidth: 28,
    },
  },
  completedIcon: {
    paddingRight: 4,
  },
  itemTitle: {
    color: '#5B5F83',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 400,
  },
  selectedItemTitle: {
    color: RoyalBlue,
    fontWeight: 700,
  },
  actionButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    padding: 0,
    '&:not(:first-child)': {
      marginLeft: 10,
    },
  },
  nextButton: {
    paddingLeft: 8,
    borderLeft: `1px solid ${rgba(Comet, 0.15)}`,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  moreIcon: {
    fill: Comet,
  },
}));
