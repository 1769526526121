import { useContext, useState } from 'react';
import { AlertContext } from 'Components/Alert/alertContext';
import { closeModal } from 'redux/reducers/modals';
import { useDispatch, useSelector } from 'react-redux';
import { getModalType } from 'redux/selectors/modals';
import mainInstance from 'api/mainInstance';

export const useFeedbackModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const onModalClose = () => dispatch(closeModal());

  const { handleSetMessage } = useContext(AlertContext);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);

  const handleSendFeedback = (form) => {
    const data = { ...form };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const files = [...photos, ...videos];
    files.forEach((file) => {
      formData.append('files', file);
    });
    mainInstance
      .post('/feedbacks', formData)
      .then(() => {
        onModalClose();
        handleSetMessage('Thanks For Your Feedback!', 'success');
      })
      .catch(() => handleSetMessage('Falied to send', 'error'));
  };

  return { onModalClose, isModalOpen, handleSendFeedback, photos, setPhotos, videos, setVideos };
};
