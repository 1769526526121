import React, { useEffect, useContext, useState, useRef, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertContext } from 'Components/Alert/alertContext';
import { getCurrentUser } from 'redux/selectors/user';
import { isUserHasPermission } from 'utils/permissions';
import { createDbPermissions, deleteDbPermissions, editDbPermissions, viewDbPermissions } from 'constants/permissions';
import { useSortable, arrayMove } from '@dnd-kit/sortable';
import { PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { openAddSectionModal } from 'redux/reducers/modals';
import useDebounce from 'hooks/useDebounce';
import { TemplateSectionContext } from 'shared/contexts/TemplateSectionsContext';
import OtherAPI from 'api/other';

const dbTables = [
  { label: 'Services', name: 'services', keys: ['id', 'name', 'position', 'isArchived', 'isnId'] },
  { label: 'Security Systems', name: 'security-systems', keys: ['id', 'name'] },
  { label: 'Referrals', name: 'referrals', keys: ['id', 'name'] },
  { label: 'Pools', name: 'pools', keys: ['id', 'name'] },
  { label: 'Inspection Types', name: 'inspection-types', keys: ['id', 'name', 'position', 'isArchived'] },
  { label: 'Master Template', name: 'template-sections', keys: [] },
  { label: 'Licenses', name: 'licenses', keys: ['id', 'name', 'state'] },
  { label: 'Certificates', name: 'certificates', keys: ['id', 'name'] },
];

export const Item = forwardRef(({ id, children, ...props }, ref) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <tr {...props} ref={ref}>
      {children}
    </tr>
  );
});

export function SortableItem({ id, children }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </Item>
  );
}

export const useEditDb = () => {
  const dispatch = useDispatch();
  const { handleSetMessage } = useContext(AlertContext);
  const tableRef = useRef(null);
  const [table, setTable] = useState({ data: [] });
  const [selectedTable, setSelectedTable] = useState(dbTables[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const user = useSelector(getCurrentUser);
  const { sections, createOrUpdateSection } = useContext(TemplateSectionContext);

  const hasViewPermissions = isUserHasPermission(user, viewDbPermissions);
  const hasEditPermissions = isUserHasPermission(user, editDbPermissions);
  const hasCreatePermissions = isUserHasPermission(user, createDbPermissions);
  const hasDeletePermissions = isUserHasPermission(user, deleteDbPermissions);

  if (!hasViewPermissions) {
    document.location.href = '/';
  }
  const fetchCurrentTab = () => {
    if (selectedTable?.name === 'template-sections') return setTable({ data: sections });
    setIsLoading(true);
    return OtherAPI.getInDB({ typeName: selectedTable?.name }).then(
      (res) => {
        setTable(res.data);
        setIsLoading(false);
      },
      (error) => {
        handleSetMessage(error?.response?.data?.message, 'error');
      },
    );
  };

  useEffect(() => {
    if (selectedTable?.name === 'template-sections') setTable({ data: sections });
  }, [sections]);

  const updateRow = (values) => {
    if (values?.id) {
      OtherAPI.patchInDB({ typeName: selectedTable?.name, ...values }).then(
        () => {
          handleSetMessage('Successfully updated', 'success');
          fetchCurrentTab();
        },
        (error) => {
          handleSetMessage(error?.response?.data?.message, 'error');
        },
      );
    } else {
      OtherAPI.postInDB({ typeName: selectedTable?.name, ...values }).then(
        () => {
          handleSetMessage('Successfully created', 'success');
          fetchCurrentTab();
        },
        (error) => {
          handleSetMessage(error?.response?.data?.message, 'error');
        },
      );
    }
  };

  const deleteRow = (id) => {
    if (id === undefined) setTable((p) => ({ data: p.data.filter((x) => !x.isNew) }));
    else
      OtherAPI.deleteInDB({ typeName: selectedTable?.name, id }).then(
        () => {
          handleSetMessage('Successfully deleted', 'success');
          fetchCurrentTab();
        },
        (error) => {
          const serverMessage = error?.response?.data?.message;
          const errorMessage =
            serverMessage === 'Cannot delete linked resource'
              ? 'Cannot delete row assigned to inspections'
              : serverMessage;
          handleSetMessage(errorMessage, 'error');
        },
      );
  };

  const addRow = () => {
    if (selectedTable.name !== 'template-sections') {
      setTable((old) => ({ ...old, data: [{ isNew: true }, ...old?.data] }));
      tableRef.current.scrollTo(0, 0);
    } else {
      dispatch(
        openAddSectionModal({
          isCreating: true,
          isReport: false,
          isMasterTemplate: true,
          onEditDone: createOrUpdateSection,
        }),
      );
    }
  };

  const handleChangeTable = (newTable) => () => {
    setSelectedTable(newTable);
  };

  const handleDragStart = (event) => {
    const { active } = event;
    if (selectedTable?.name === 'inspection-types' || selectedTable?.name === 'services') setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (activeId && active && over && active.id !== over.id) {
      let prevPos = null;
      let req = false;

      setTable(({ data }) => {
        const oldIndex = data.findIndex((row) => row.id === active.id);
        const newIndex = data.findIndex((row) => row.id === over.id);
        const newArray = arrayMove(data, oldIndex, newIndex);
        prevPos = data[newIndex].position;
        newArray[oldIndex].position = data[oldIndex].position;
        newArray[newIndex].position = prevPos;
        if ((prevPos || prevPos === 0) && req === false) {
          req = true;
          OtherAPI.moveInDB({
            typeName: selectedTable?.name,
            id: activeId,
            position: newArray[newIndex].position,
          }).then(() => {
            OtherAPI.getInDB({ typeName: selectedTable?.name }).then(
              (res) => {
                setTable(res.data);
              },
              (error) => {
                handleSetMessage(error?.response?.data?.message, 'error');
              },
            );
          });
        }
        return { data: newArray };
      });
    }
    setActiveId(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  useEffect(() => {
    fetchCurrentTab();
  }, [selectedTable]);

  const [filterName, setFilterName] = React.useState('');
  const [shownData, setShownData] = React.useState([]);
  const debouncedName = useDebounce(filterName, 300);
  React.useEffect(() => {
    const shown =
      filterName.trim().length === 0
        ? table.data
        : table.data.filter((row) => row.name.toLowerCase().indexOf(filterName.toLowerCase()) > -1);
    setShownData(shown);
  }, [debouncedName, table.data, selectedTable.name]);

  return {
    fetchCurrentTab,
    isLoading,
    dbTables,
    data: shownData,
    selectedTable,
    handleChangeTable,
    updateRow,
    deleteRow,
    addRow,
    handleDragEnd,
    handleDragStart,
    activeId,
    sensors,
    tableRef,
    hasEditPermissions,
    hasCreatePermissions,
    hasDeletePermissions,
    filterName,
    setFilterName,
  };
};
