import CommentsColumn from 'Components/template/CommentsColumn';
import ItemsColumn from 'Components/template/ItemsColumn';
import React, { useContext } from 'react';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrowBack.svg';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from '../../../../shared/template/commonStyles';
import ActionsMenu from 'Components/template/ActionsMenu';
import { useDropdownMenu } from 'Components/DorpdownMenu/useDropdownMenu';
import { openAddSectionModal } from 'redux/reducers/modals';
import { useDispatch } from 'react-redux';
import { TemplateSectionContext } from 'shared/contexts/TemplateSectionsContext';
import TemplatesAPI from 'api/templates';

function TemplateRow({ row, indx, rowClasses, open, setOpen }) {
  const isReport = false;
  const theme = useTheme();
  const [selectedItem, selectItem] = React.useState(null);
  const {
    sections,
    deleteSection,
    copySection,
    editSection,
    replaceComment,
    replaceItem,
    deleteItem,
    copyItem,
    reorderItems,
    createOrUpdateSection,
    isTermite,
    setSections,
  } = useContext(TemplateSectionContext);

  const classes = useStyles();

  React.useEffect(() => {
    if (open && !row?.items)
      TemplatesAPI.getTemplateSection(row.id).then((res) => {
        setSections((p) => {
          const newSections = [...p];
          const index = newSections.findIndex((d) => d.id === row.id);
          newSections[index] = {
            ...row,
            ...res.data,
          };
          return newSections;
        });
      });
  }, [row, open]);

  const dispatch = useDispatch();

  const { onDropdownOpen, onDropdownClose, anchorEl } = useDropdownMenu();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const isItemSelected = selectedItem || selectedItem === 0;

  const realIndex = (sections || []).findIndex((sect) => sect.id === row.id);

  return (
    <Table>
      <TableRow className={rowClasses.root}>
        <TableCell className={rowClasses.firstCell}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row' className={rowClasses.dots}>
          {row.isNew ? (
            <span style={{ color: 'orange', fontWeight: 'bold' }}>New Row, please fill and save</span>
          ) : (
            row.name
          )}
        </TableCell>
        <TableCell align='right' style={{ width: 180 }}>
          <Tooltip
            title={
              row?.templates?.length > 0
                ? `Used in: ${(row?.templates || []).map((tem) => tem?.name || '').join(', ')}`
                : 'Not used in any template.'
            }
          >
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Used in {row?.templates?.length || 0} templates{' '}
                <InfoIcon style={{ marginLeft: 8, cursor: 'pointer' }} />
              </div>
            </div>
          </Tooltip>
        </TableCell>
        <TableCell align='right' style={{ width: 46 }}>
          <IconButton
            onClick={onDropdownOpen}
            className={classes.actionButton}
            data-cy-button={`section-menu-${row?.name}`}
          >
            <MoreIcon className={classes.moreIcon} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {open && (
              <div>
                {isItemSelected && isMobile && (
                  <IconButton onClick={() => selectItem(null)} className={classes.backButton}>
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <div className={classes.columnsWrapper}>
                  {(!isMobile || (!isItemSelected && isMobile)) && (
                    <ItemsColumn
                      items={row?.items}
                      onItemSelect={selectItem}
                      onEditSection={editSection}
                      sections={sections}
                      onReplaceItem={replaceItem}
                      selectedSection={realIndex}
                      selectedItem={selectedItem}
                      isReport={false}
                      isOutlinedItem
                      onCopyItem={copyItem}
                      onDeleteItem={deleteItem}
                      onReorderItems={reorderItems}
                    />
                  )}
                  {(!isMobile || (isItemSelected && isMobile)) && (
                    <CommentsColumn
                      onEditSection={editSection}
                      onReplaceComment={replaceComment}
                      selectedItem={selectedItem}
                      selectedSection={realIndex}
                      sections={sections}
                      isReport={false}
                      isTermite={isTermite}
                    />
                  )}
                </div>
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <ActionsMenu
        onClose={onDropdownClose}
        onEdit={() =>
          dispatch(
            openAddSectionModal({
              onEditDone: createOrUpdateSection,
              isCreating: false,
              index: realIndex,
              isReport,
              thisSection: row,
              isMasterTemplate: true,
              sections,
            }),
          )
        }
        onCopy={() => copySection(realIndex)}
        onDelete={() => deleteSection(realIndex)}
        anchorEl={anchorEl}
        confirmMessage='Are you sure you want to delete section ?'
        dataCy='section'
      />
    </Table>
  );
}

export default TemplateRow;
