import React from 'react';
import { Button, Slide, IconButton } from '@material-ui/core';
import moment from 'moment';
import cls from 'classnames';
import CircularLoader from 'Components/CircularLoader';
import { useDispatch } from 'react-redux';
import { openAddBlockerModal, openAddHolderModal } from 'redux/reducers/modals';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useDetailsModal } from './useDetailsModal';
import DetailsCard from './DetailsCard';
import BlockerCard from './BlockerCard';
import useStyles from './useStyles';
import HolderCard from './HolderCard';

const DetailsModal = () => {
  const {
    onModalClose,
    addInspection,
    createInspectionFromHolder,
    date,
    inspections,
    blockers,
    holders,
    isMonth,
    isModalOpen,
    isLoading,
    hasCreateHolderPermission,
    setCalendarDataRaw,
  } = useDetailsModal();

  const dispatch = useDispatch();
  const filteredBlockers = blockers.filter((blocker) => moment(blocker.endDate) > moment(date));

  const classes = useStyles({ hasCreateHolderPermission });
  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.detailsModal}>
        <div className={classes.detailsModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{moment(date).format('DD MMMM, dddd')}</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            {isLoading && (
              <div className={classes.loaderWrapper}>
                <CircularLoader />
              </div>
            )}
            {!isLoading && (
              <>
                {inspections?.map((inspection) => (
                  <DetailsCard key={inspection.id} inspection={inspection} onClose={onModalClose} />
                ))}
                {holders?.map((holder) => (
                  <HolderCard key={holder.id} holder={holder} onCreateInspection={createInspectionFromHolder} />
                ))}
                {filteredBlockers?.map((blocker) => {
                  return <BlockerCard key={blocker.id} blocker={{ ...blocker }} />;
                })}
                {!inspections.length && !holders.length && !filteredBlockers.length && (
                  <h3 className={classes.emptyTitle}>No events</h3>
                )}
              </>
            )}
          </div>
          <div className={classes.modalFooter}>
            <Button onClick={addInspection} className={cls(classes.actionButton, classes.addInspectionButton)}>
              <span>Add Inspection</span>
            </Button>
            {hasCreateHolderPermission && (
              <Button
                onClick={() => dispatch(openAddHolderModal({ selectedDate: date, isMonth, setCalendarDataRaw }))}
                className={cls(classes.actionButton, classes.addHolderButton)}
              >
                Place A Hold On Time Slot
              </Button>
            )}
            <Button
              onClick={() => dispatch(openAddBlockerModal({ selectedDate: date, isMonth, setCalendarDataRaw }))}
              className={cls(classes.actionButton, classes.addBlockerButton)}
            >
              Adjust My Availability
            </Button>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default DetailsModal;
