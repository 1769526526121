import React from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DeleteOutlined } from '@material-ui/icons';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import useStyles from '../../useStyles';
import AlertModal from '../AlertModal';
import TemplateRow from '../TemplateRow';

function Row({ row, keys, deleteRow, updateRow, hasEditPermissions, hasDeletePermissions, isMasterTemplate, indx }) {
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const classes = useStyles();

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <td colSpan={4} style={{ width: '70vw' }}>
      <TableContainer component={Paper}>
        {!isMasterTemplate ? (
          <Table>
            <TableRow className={classes.root}>
              <TableCell className={classes.firstCell}>
                <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell component='th' scope='row' className={classes.dots}>
                {row.isNew ? (
                  <span style={{ color: 'orange', fontWeight: 'bold' }}>New Row, please fill and save</span>
                ) : (
                  row.name
                )}
              </TableCell>
              <TableCell align='right'>
                {keys.includes('isArchived') && <Checkbox disabled checked={row.isArchived} />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout='auto' unmountOnExit>
                  <div style={{ width: '100%', display: 'flex', gap: 10 }}>
                    <Formik initialValues={row} onSubmit={updateRow} enableReinitialize>
                      <Form style={{ width: 'calc(100% - 60px)' }} className={classes.expandedForm}>
                        {keys.includes('name') && (
                          <Field name='name'>
                            {({ field, meta }) => (
                              <TextField
                                {...field}
                                disabled={!hasEditPermissions}
                                variant='outlined'
                                type='text'
                                label='Name'
                                data-cy-input='name'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          </Field>
                        )}
                        {keys.includes('state') && (
                          <Field name='state'>
                            {({ field, meta }) => (
                              <TextField
                                {...field}
                                disabled={!hasEditPermissions}
                                variant='outlined'
                                type='text'
                                label='State'
                                data-cy-input='state'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          </Field>
                        )}
                        {keys.includes('position') && (
                          <Field name='position'>
                            {({ field, meta }) => (
                              <TextField
                                {...field}
                                disabled={!hasEditPermissions}
                                variant='outlined'
                                type='number'
                                label='Position'
                                data-cy-input='position'
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              />
                            )}
                          </Field>
                        )}
                        {keys.includes('isArchived') && (
                          <Field name='isArchived'>
                            {({ field, meta }) => {
                              const { values } = useFormikContext();
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      checked={values['isArchived']}
                                      disabled={!hasEditPermissions}
                                    />
                                  }
                                  label='Archived'
                                />
                              );
                            }}
                          </Field>
                        )}
                        <Field>
                          {() => {
                            const { dirty, resetForm } = useFormikContext();
                            return (
                              <div style={{ display: 'flex', width: '100%', gap: 10, alignItems: 'center' }}>
                                <Button
                                  style={{ background: '#3f51b5', height: 32, flexGrow: 1 }}
                                  disabled={!dirty || !hasEditPermissions}
                                  appearance='primary'
                                  type='submit'
                                >
                                  Submit
                                </Button>
                                <Button
                                  style={{
                                    background: 'rgba(244,67,54,0.1)',
                                    color: '#f44336',
                                    height: 32,
                                    flexGrow: 1,
                                  }}
                                  disabled={!dirty}
                                  appearance='primary'
                                  onClick={resetForm}
                                >
                                  Reset
                                </Button>
                              </div>
                            );
                          }}
                        </Field>
                      </Form>
                    </Formik>
                    <div className={classes.rightSide}>
                      <IconButton
                        // variant='contained'
                        style={{
                          width: '50px',
                          color: '#f44336',
                        }}
                        onClick={handleOpenModal}
                        disabled={!hasDeletePermissions}
                      >
                        <DeleteOutlined />
                      </IconButton>
                      <AlertModal
                        onOk={() => deleteRow(row.id)}
                        onCancel={handleCloseModal}
                        open={modalOpen}
                        text='Are you sure to delete this row?'
                      />
                      ID: {row.id}
                    </div>
                  </div>
                </Collapse>
              </TableCell>
            </TableRow>
          </Table>
        ) : (
          <TemplateRow rowClasses={classes} row={row} setOpen={setOpen} open={open} indx={indx} />
        )}
      </TableContainer>
    </td>
  );
}

export default Row;
