import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, Red, RoyalBlue, White } from 'styles/colors';

export default makeStyles((theme) => ({
  uploadFileWrapper: {
    display: 'flex',
    marginTop: 16,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
  },
  uploadFileColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:not(:first-child)': {
      marginTop: 8,
    },
    [theme.breakpoints.up('sm')]: {
      '&:not(:first-child)': {
        paddingLeft: 8,
        marginTop: 0,
        '&>.dropzone': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'start',
        },
      },
    },
  },
  fileList: {
    padding: 0,
    listStyle: 'none',
    margin: 0,
  },
  fileListItem: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      marginTop: 16,
    },
  },
  filePreviewWrapper: {
    width: 200,
    height: 80,
    borderRadius: 5,
    border: `1px solid ${rgba(Comet, 0.5)}`,
  },
  filePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  fileListItemTitle: {
    fontSize: 12,
    color: Comet,
    display: 'inline-block',
    textOverflow: 'ellipsis',
    width: 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  uploadFileButton: {
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: RoyalBlue,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: rgba(RoyalBlue, 0.7),
    },
  },
  imageEditorModal: {
    height: '100%',
    backgroundColor: White,
  },
  imageEditorModalWrapper: {
    // maxWidth: "100%",
    // maxHeight: "inherit",
    // overflowY: "scroll",
    maxWidth: '100%',
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
  },
  modalHeader: {
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    backgroundColor: White,
    zIndex: 999,
  },
  closeButton: {
    padding: 0,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  modalFooter: {
    paddingTop: 24,
    marginTop: 24,
    borderTop: `1px solid ${rgba(Comet, 0.1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 700,
    width: '48%',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cancelButton: {
    backgroundColor: rgba(Red, 0.1),
    color: Red,
    '&:hover': {
      backgroundColor: rgba(Red, 0.3),
    },
  },
  submitButton: {
    background: BlueGradient,
  },
}));
