import mainInstance from 'api/mainInstance';

const CalendarAPI = {
  getCalendarData: ({ from = '', to = '' }) => mainInstance.get(`/calendar?from=${from}&to=${to}&limit=99`),
  getCalendarBooked: (date) => mainInstance.get(`/calendar/booked?date=${date}`),
  postBlocker: (data) => mainInstance.post(`/calendar/blockers`, data),
  patchBlocker: (data) => mainInstance.patch(`/calendar/blockers/${data.id}`, data),
  deleteBlocker: (id) => mainInstance.delete(`/calendar/blockers/blockers/${id}`),
  postHolder: (data) => mainInstance.post(`/calendar/holders`, data),
  deleteHolder: (id) => mainInstance.delete(`/calendar/holders/${id}`),
};

export default CalendarAPI;
