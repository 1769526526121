import ConstantsAPI from 'api/constants';
import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/user';
import { noop } from 'utils/noop';

const initialConstants = {
  services: [],
  inspectionTypes: [],
  recommendedExperts: [],
  poolSpas: [],
  securitySystems: [],
  referrals: [],
  estimations: [],
  estimationsTypes: [],
  licenses: [],
  certificates: [],
  locations: [],
  changeConstant: noop,
};

export const ConstantsContext = createContext({
  constants: initialConstants,
});

export const ConstantsContextProvider = ({ children }) => {
  const isAuth = !!useSelector(getCurrentUser);
  const [constants, setConstants] = React.useState(initialConstants);

  React.useEffect(() => {
    if (isAuth) {
      const constantsPromises = Object.entries(ConstantsAPI).map(([key, fetchConstant]) => {
        return new Promise((resolve, reject) => {
          fetchConstant()
            .then((res) => resolve({ [key]: res.data }))
            .catch(() => reject({ [key]: [] }));
        });
      });

      Promise.all(constantsPromises).then((promises) => {
        let con = {};
        promises.forEach((promise) => {
          const key = Object.keys(promise)[0];
          const value = Object.values(promise)[0];
          con[key] = value;
        });
        setConstants(con);
      });
    }
  }, [isAuth]);

  const changeConstant = (key, value) => {
    setConstants((con) => ({ ...con, [key]: value }));
  };

  const contextValue = {
    ...constants,
    changeConstant,
  };

  return <ConstantsContext.Provider value={contextValue}>{children}</ConstantsContext.Provider>;
};
