import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { MenuItem } from '@material-ui/core';

import SelectField from 'Components/SelectField';
import useStyles from './useStyles';

const ReplaceForm = ({ getAvailableItems, sections, isComments }) => {
  const classes = useStyles();
  const { touched, values } = useFormikContext();
  const [availableItems, setAvailableItems] = useState([]);
  React.useEffect(async () => {
    const result = await getAvailableItems(values.sectionIdx);
    setAvailableItems(result);
  }, [values.sectionIdx]);
  return (
    <>
      <Field name='sectionIdx'>
        {({ field, meta }) => (
          <SelectField
            {...field}
            error={touched.sectionIdx && !!meta.error}
            helperText={touched.sectionIdx && meta.error}
            labelId='sectionIdx'
            label='SECTION'
            placeholder='SECTION'
            data-cy-select='template-section'
          >
            {sections?.map((section, idx) => (
              <MenuItem key={section.name} value={section?.idx || idx} data-cy-item={section.name}>
                <span>{section.name}</span>
              </MenuItem>
            ))}
          </SelectField>
        )}
      </Field>
      {isComments && (
        <div className={classes.formItem}>
          <Field name='itemIdx'>
            {({ field, meta }) => (
              <SelectField
                {...field}
                error={touched.itemIdx && !!meta.error}
                helperText={touched.itemIdx && meta.error}
                labelId='itemIdx'
                label='ITEM'
                placeholder='ITEM'
                disabled={values.sectionIdx === ''}
                data-cy-select='template-item'
              >
                {(availableItems || [])?.map((item) => (
                  <MenuItem key={item.name} value={item.idx} data-cy-item={item.name}>
                    <span>{item.name}</span>
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Field>
        </div>
      )}
    </>
  );
};

export default ReplaceForm;
