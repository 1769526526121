import React, { useEffect, useRef } from 'react';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import cls from 'classnames';

import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { openAddCommentModal, openAddDeficienciesCommentModal, openReplaceItemModal } from 'redux/reducers/modals';
import { useDispatch } from 'react-redux';
import { commentIcons } from './icons';
import ActionsMenu from '../ActionsMenu';
import Comment from './Comment';
import { useCommentsColumn } from './useCommentsColumn';
import useStyles from './useStyles';
import useDebouncedSubmit from './useDebouncedSubmit';

const CommentsColumn = ({
  onEditSection,
  onReplaceComment,
  selectedSection,
  selectedItem,
  isReport,
  sections,
  isTermite,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const section = sections?.[selectedSection];
  const hasDeficienciesComments = section?.items?.[selectedItem]?.isDeficiencies;

  const commentsColumn = useRef(null);

  useEffect(() => {
    if (commentsColumn.current) commentsColumn.current.scrollTo(0, 0);
  }, [selectedItem]);

  const { changeComments, copyComment, deleteComment, reorderComments } = useDebouncedSubmit({
    saveSection: (data) => onEditSection(data, selectedSection),
    section,
    selectedItem,
    isReport,
  });

  const {
    onDropdownOpen,
    onDropdownClose,
    editCommentModalProps,
    editDeficienciesCommentModalProps,
    anchorEl,
    title,
    sensors,
    estimations,
    estimationsTypes,
    hasItem,
  } = useCommentsColumn({
    onChangeComments: changeComments,
    selectedSection,
    selectedItem,
    isMobile,
    isReport,
    section,
  });

  const informationalItems =
    section?.items?.[selectedItem]?.comments
      ?.map((comment, idx) => ({ ...comment, idx }))
      ?.filter((comment) => comment.block === 'INFORMATIONAL') || [];
  const limitationsItems =
    section?.items?.[selectedItem]?.comments
      ?.map((comment, idx) => ({ ...comment, idx }))
      ?.filter((comment) => comment.block === 'LIMITATIONS') || [];
  const deficienciesItems = section?.items?.[selectedItem]?.deficiencies || [];

  const dispatch = useDispatch();

  const renderColumn = (items) =>
    items.map((item) => {
      const Icon = commentIcons[item.type];
      return (
        <Comment
          key={`{${item.name}${item.idx}`}
          onOpenMenu={onDropdownOpen}
          onChangeComments={(values) => changeComments(values, item.idx)}
          item={item}
          idx={item.idx}
          selectedItem={selectedItem}
          selectedSection={selectedSection}
          initialValues={{
            defaultValue: item.type === 'RANGE' ? JSON.stringify(item.defaultValue) : item.defaultValue,
            notes: item.notes,
            location: item?.location || '',
            photos: item?.photos || [],
            videos: item?.videos || [],
            isDone: item?.isDone,
          }}
          isReport={isReport}
          isTermite={isTermite}
        >
          <Icon />
        </Comment>
      );
    });

  const renderDeficienciesColumn = () =>
    deficienciesItems.map((item, indx) => {
      const Icon = commentIcons[item.type];
      return (
        <Comment
          key={`${item.name}${indx + 1}`}
          onOpenMenu={onDropdownOpen}
          onChangeComments={(values) => changeComments(values, indx, true)}
          item={item}
          idx={indx}
          selectedItem={selectedItem}
          selectedSection={selectedSection}
          initialValues={{
            notes: item.notes,
            location: item?.location || '',
            type: item?.type || DEFICIENCIES_COMMENT_TYPES.MAINTAIN_MONITOR,
            estimationId: item?.estimationId || 1,
            estimationTypeId: item?.estimationTypeId || 1,
            estimationFrom: item?.estimationValue?.from,
            estimationTo: item?.estimationValue?.to,
            photos: item?.photos || [],
            videos: item?.videos || [],
            isDone: item?.isDone,
          }}
          estimations={estimations}
          estimationTypes={estimationsTypes}
          isReport={isReport}
          isDeficiencies
          isTermite={isTermite}
        >
          <Icon className={classes.commentIcon} />
        </Comment>
      );
    });

  return (
    <div className={classes.columnWrapper} ref={commentsColumn}>
      <h3 className={classes.title}>{title}</h3>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={reorderComments}>
        <div className={`${classes.column} ${classes.generalColumn}`} data-cy-container='general-comments'>
          <div className={classes.columnHeader}>
            <h4 className={cls(classes.sectionTitle, classes.generalTitle)}>General Information</h4>
            {isDesktop && (
              <Button
                onClick={() =>
                  dispatch(
                    openAddCommentModal({
                      isCreating: true,
                      onChangeComment: changeComments,
                      commentBlock: 'INFORMATIONAL',
                      itemIdx: selectedItem,
                      isReport,
                      section,
                    }),
                  )
                }
                disabled={!hasItem}
                className={cls(classes.buttonAddNew, classes.buttonAddNewGeneral)}
                data-cy-button='add-general-comment'
              >
                + Add New
              </Button>
            )}
            {!isDesktop && hasItem && (
              <IconButton
                onClick={() =>
                  dispatch(
                    openAddCommentModal({
                      isCreating: true,
                      onChangeComment: changeComments,
                      commentBlock: 'INFORMATIONAL',
                      itemIdx: selectedItem,
                      isReport,
                      section,
                    }),
                  )
                }
                disabled={!hasItem}
                className={classes.buttonAddNewMobile}
                data-cy-button='add-general-comment'
              >
                <PlusIcon />
              </IconButton>
            )}
          </div>
          <SortableContext
            items={informationalItems.map((comment) => comment.idx + 1)}
            strategy={verticalListSortingStrategy}
          >
            {renderColumn(informationalItems)}
          </SortableContext>
        </div>
        <div className={cls(classes.column, classes.limitationsColumn)} data-cy-container='limitation-comments'>
          <div className={classes.columnHeader}>
            <h4 className={cls(classes.sectionTitle, classes.limitationsTitle)}>Limitations to Inspection</h4>
            {isDesktop && (
              <Button
                onClick={() =>
                  dispatch(
                    openAddCommentModal({
                      isCreating: true,
                      onChangeComment: changeComments,
                      commentBlock: 'LIMITATIONS',
                      itemIdx: selectedItem,
                      isReport,
                      section,
                    }),
                  )
                }
                disabled={!hasItem}
                className={cls(classes.buttonAddNew, classes.buttonAddNewLimitations)}
                data-cy-button='add-limitations-comment'
              >
                + Add New
              </Button>
            )}
            {!isDesktop && hasItem && (
              <IconButton
                onClick={() =>
                  dispatch(
                    openAddCommentModal({
                      isCreating: true,
                      onChangeComment: changeComments,
                      commentBlock: 'LIMITATIONS',
                      itemIdx: selectedItem,
                      isReport,
                      section,
                    }),
                  )
                }
                disabled={!hasItem}
                className={classes.buttonAddNewMobile}
                data-cy-button='add-limitations-comment'
              >
                <PlusIcon />
              </IconButton>
            )}
          </div>
          <SortableContext
            items={limitationsItems.map((comment) => comment.idx + 1)}
            strategy={verticalListSortingStrategy}
          >
            {renderColumn(limitationsItems)}
          </SortableContext>
        </div>
      </DndContext>
      {hasDeficienciesComments && hasItem && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(data) => reorderComments(data, true)}
        >
          <div className={cls(classes.column, classes.priorityColumn)} data-cy-container='deficiencies-comments'>
            <div className={classes.columnHeader}>
              <h4 className={cls(classes.sectionTitle, classes.priorityTitle)}>Reportable Conditions</h4>
              {isDesktop && (
                <Button
                  onClick={() =>
                    dispatch(
                      openAddDeficienciesCommentModal({
                        isCreating: true,
                        onChangeComment: changeComments,
                        itemIdx: selectedItem,
                        isReport,
                        section,
                      }),
                    )
                  }
                  disabled={!hasItem}
                  className={cls(classes.buttonAddNew, classes.buttonAddNewPriority)}
                  data-cy-button='add-deficiency-comment'
                >
                  + Add New
                </Button>
              )}
              {!isDesktop && (
                <IconButton
                  onClick={() =>
                    dispatch(
                      openAddDeficienciesCommentModal({
                        isCreating: true,
                        onChangeComment: changeComments,
                        itemIdx: selectedItem,
                        isReport,
                        section,
                      }),
                    )
                  }
                  disabled={!hasItem}
                  className={classes.buttonAddNewMobile}
                  data-cy-button='add-deficiency-comment'
                >
                  <PlusIcon />
                </IconButton>
              )}
            </div>
            <SortableContext
              items={deficienciesItems.map((comment, i) => i + 1)}
              strategy={verticalListSortingStrategy}
            >
              {renderDeficienciesColumn()}
            </SortableContext>
          </div>
        </DndContext>
      )}
      <ActionsMenu
        onClose={onDropdownClose}
        onClick={() =>
          dispatch(
            openAddDeficienciesCommentModal({
              isCreating: true,
              onChangeComment: changeComments,
              itemIdx: selectedItem,
              isReport,
              section,
            }),
          )
        }
        onEdit={
          anchorEl?.dataset?.deficiencies === 'true'
            ? () => dispatch(openAddDeficienciesCommentModal(editDeficienciesCommentModalProps))
            : () => dispatch(openAddCommentModal({ ...editCommentModalProps, isTermite }))
        }
        onCopy={() => copyComment(+anchorEl?.dataset?.idx, anchorEl?.dataset?.deficiencies === 'true')}
        onDelete={() => deleteComment(+anchorEl?.dataset?.idx, anchorEl?.dataset?.deficiencies === 'true')}
        onReplace={() =>
          dispatch(
            openReplaceItemModal({
              onReplace: (sectionIdx, itemIdx, index) =>
                onReplaceComment(
                  sectionIdx,
                  itemIdx,
                  index,
                  anchorEl?.dataset?.deficiencies === 'true',
                  selectedSection,
                  selectedItem,
                ),
              index: +anchorEl?.dataset?.idx,
              selectedSection,
              selectedItem,
              isComments: true,
              isDeficiencies: anchorEl?.dataset?.deficiencies === 'true',
              sections,
            }),
          )
        }
        anchorEl={anchorEl}
        confirmMessage='Are you sure you want to delete comment ?'
        dataCy='comment'
      />
    </div>
  );
};

export default CommentsColumn;
