import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Black, RoyalBlue, Red, Comet } from 'styles/colors';

export default (hasError) =>
  makeStyles({
    input: {
      '&:-webkit-autofill': {
        WebkitTextFillColor: Black,
      },
      '&:focus': {
        borderColor: RoyalBlue,
        color: hasError ? Black : RoyalBlue,
        '&:-webkit-autofill': {
          WebkitTextFillColor: hasError ? Black : RoyalBlue,
        },
      },
    },
    textField: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '& > fieldset > legend': {
          fontSize: '0.80em',
        },
        '&.Mui-disabled:hover > fieldset': {
          borderColor: hasError ? Red : 'rgba(0, 0, 0, 0.26)',
        },
        '&:hover fieldset': {
          borderColor: hasError ? Red : RoyalBlue,
        },
      },
    },
    validTextField: {
      '& fieldset': {
        borderColor: rgba(Comet, 0.25),
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused': {
          backgroundColor: rgba(hasError ? Red : RoyalBlue, 0.05),
        },
        '&.Mui-focused fieldset': {
          borderColor: RoyalBlue,
        },
      },
      '&.MuiOutlinedInput-root': {
        backgroundColor: rgba(RoyalBlue, 0.05),
      },
    },
  });
