import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  commentTypeFields: {
    padding: 16,
    fontSize: '12px !important',
    '& span': {
      fontSize: '12px !important',
    },
  },
  commentTextField: {
    // marginLeft: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px !important',
    '& input': {
      fontSize: '12px !important',
    },
  },
  commentAdditionalFields: {
    padding: 16,
    borderTop: `1px solid ${rgba(Comet, 0.15)}`,
  },
  estimationFieldWrapper: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: 16,
    },
  },
  estimationValueField: {
    width: '100%',

    '&:not(:first-child)': {
      marginLeft: 8,
    },
  },
  select: {
    '& > .MuiSelect-select': {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      '& > .MuiSelect-select': {
        width: '30vw',
        paddingRight: '10%',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > .MuiSelect-select': {
        width: '23vw',
      },
    },
  },
  addLocationWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: 16,
    fontSize: '12px !important',
    '& input': {
      fontSize: '12px !important',
    },
  },
  addLocationButton: {
    backgroundColor: RoyalBlue,
    padding: '0 18px',
    marginLeft: 8,
    '&:hover': {
      backgroundColor: rgba(RoyalBlue, 0.7),
    },
  },
}));
