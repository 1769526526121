import React from 'react';
import { Formik, Form } from 'formik';
import { Button, IconButton, Slide } from '@material-ui/core';
import cls from 'classnames';

import CircularLoader from 'Components/CircularLoader';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useReplace } from './useReplace';
import { getReplaceFormValidation } from './validation';
import ReplaceForm from './ReplaceForm';
import useStyles from './useStyles';

const ReplaceModal = () => {
  const classes = useStyles();
  const { submit, onModalClose, getAvailableItems, isModalOpen, isComments, isLoading, sections } = useReplace();

  return (
    <Slide direction='up' in={isModalOpen}>
      <div className={classes.addItemModal} data-cy-container='move-item'>
        <div className={classes.addItemModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Replace</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <Formik
              onSubmit={submit}
              initialValues={{ sectionIdx: '', itemIdx: '' }}
              validationSchema={getReplaceFormValidation(isComments)}
            >
              <Form>
                <ReplaceForm getAvailableItems={getAvailableItems} sections={sections} isComments={isComments} />
                <div className={classes.modalFooter}>
                  <Button
                    onClick={onModalClose}
                    className={cls(classes.actionButton, classes.cancelButton)}
                    data-cy-button='cancel-move'
                  >
                    Cancel
                  </Button>
                  <Button
                    className={cls(classes.actionButton, classes.submitButton)}
                    type='submit'
                    data-cy-button='submit-move'
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularLoader size={20} isWhite /> : 'Submit'}
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default ReplaceModal;
