import React, { useState } from 'react';
import { Button, Fade, IconButton } from '@material-ui/core';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import ImageEditor from 'Components/ImageEditor';
import useStyles from './useStyles';

const ImageEditorModalContent = ({ onModalClose, onSubmit, isModalOpen, photo, stageRef, handleBeforeSubmitImage }) => {
  const [isMoving, setIsMoving] = useState(false);
  const classes = useStyles({ isBlockedScroll: isMoving });
  return (
    <Fade in={isModalOpen}>
      <div className={classes.imageEditorModal}>
        <div className={classes.imageEditorModalWrapper}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Image Editor</h2>
            <IconButton className={classes.closeButton} onClick={onModalClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>
            <ImageEditor
              imageName={photo}
              stageRef={stageRef}
              setIsMoving={(currentState) => setIsMoving(currentState)}
              handleSubmitImage={onSubmit}
              handleCancel={onModalClose}
              isModalOpen={isModalOpen}
              handleBeforeSubmitImage={handleBeforeSubmitImage}
            />

            {/* <div className={classes.modalFooter}>
              <Button onClick={onModalClose} className={`${classes.actionButton} ${classes.cancelButton}`}>
                Cancel
              </Button>
              <Button onClick={onSubmit} className={`${classes.actionButton} ${classes.submitButton}`}>
                Submit
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default ImageEditorModalContent;
