import React, { createContext } from 'react';
import { useDispatch } from 'react-redux';
import { clearAlert, setAlert } from 'redux/reducers/alert';

import { noop } from 'utils/noop';

export const AlertContext = createContext({
  handleSetMessage: noop,
  handleClose: noop,
});

export const AlertContextProvider = ({ children }) => {
  const dispatch = useDispatch();

  const handleSetMessage = React.useCallback((message = 'Something went wrong.', variant) => {
    dispatch(setAlert({ alertMessage: message, alertVariant: variant }));
  }, []);

  const handleClose = React.useCallback(() => {
    dispatch(clearAlert());
  }, []);

  const contextValue = {
    handleClose,
    handleSetMessage,
  };

  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
};
