import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  pageWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    maxHeight: 'calc(100vh - 64px)',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '250px 1fr',
    },
  },
  table: {
    paddingTop: 0,
    maxHeight: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: 20,
    },
    '& > .MuiTableContainer-root': {
      maxHeight: 'calc(100% - 52px)',
      overflowY: 'auto',
    },
  },
  tablesTabs: {
    display: 'flex',
    gap: 10,
    overflowX: 'auto',
    height: 60,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      overflowX: 'visible',
      height: 'auto',
    },
    padding: 20,
  },
  tableTab: {
    minWidth: 130,
    [theme.breakpoints.up('md')]: {
      minWidth: 200,
    },
    borderRadius: 8,
    border: 'lightgray 1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
  },
  expandedForm: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 15,
    padding: '15px 0px',
  },
  firstCell: {
    width: 30,
  },
  secondCell: {
    width: 48,
  },
  rightSide: {
    width: 50,
    paddingTop: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 24,
  },
  dots: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '40vw',
    whiteSpace: 'nowrap',
  },
  search: {
    width: '50%!important',
  },
  tableHeader: {
    marginBottom: 12,
  },
}));
