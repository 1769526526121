import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, Jaffa, Red, RoyalBlue, White } from 'styles/colors';

export default makeStyles({
  typeButtonsWrapper: {
    display: 'flex',
  },
  typeButton: {
    width: '100%',
    backgroundColor: '#EFEFF3',
    color: Comet,
    border: 'none',
    margin: '0 !important',
    padding: '10px 0',
    '&:first-child': {
      borderRight: `1px solid ${rgba(Comet, 0.1)}`,
    },
    '&:last-child': {
      borderLeft: `1px solid ${rgba(Comet, 0.1)}`,
    },
  },
  maintainButton: {
    backgroundColor: RoyalBlue,
    color: White,
    '&:hover': {
      backgroundColor: rgba(RoyalBlue, 0.7),
    },
  },
  attachedButton: {
    backgroundColor: Jaffa,
    color: White,
    '&:hover': {
      backgroundColor: rgba(Jaffa, 0.7),
    },
  },
  actionNecessaryButton: {
    backgroundColor: Red,
    color: White,
    '&:hover': {
      backgroundColor: rgba(Red, 0.7),
    },
  },
  typoButtonIcon: {
    fill: Comet,
  },
  typeButtonSelectedIcon: {
    fill: White,
  },
});
