export const crashReporter = () => (next) => (action) => {
  if (action.type.startsWith('@@resource')) {
    if (action.code === 404) {
      let text = 'Entity not found.';
      if (action.body && action.body.error_description) text = action.body.error_description;
      throw new Error(text);
    }
    if (action.code === 404 || action.code >= 500) {
      throw new Error('Server error.');
    }
    if (action.code === 403) {
      throw new Error('You have no permission for this action.');
    }
  }
  return next(action);
};
