import UserAPI from 'api/user';
import { setAuth } from '../reducer';
import fetchCurrentUserThunk from './fetchCurrentUserThunk';

const loginThunk = (data) => (dispatch) => {
  return UserAPI.postLogin(data).then((res) => {
    dispatch(setAuth({ ...res.data, keepLogin: data.shouldKeepLogin }));
    return dispatch(fetchCurrentUserThunk());
  });
};

export default loginThunk;
