import { noop } from 'lodash';
import React, { useState, createContext } from 'react';

const initialOnline = navigator.onLine || navigator.onLine === undefined;

export const IsOnlineContext = createContext({
  isOnline: initialOnline,
  setIsOnline: noop,
});

export const IsOnlineContextProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(initialOnline);
  const [canBeChangedMannually, setCanBeChangedMannually] = useState(initialOnline);
  const [lastChangedMannully, setLastChangedMannually] = useState(initialOnline);

  const turnOnOnlineMode = () => {
    if (lastChangedMannully === undefined) {
      setIsOnline(true);
    } else setIsOnline(lastChangedMannully);
    setCanBeChangedMannually(true);
  };

  const turnOnOfflineMode = () => {
    setIsOnline(false);
    setCanBeChangedMannually(false);
  };

  React.useEffect(() => {
    window.addEventListener('online', turnOnOnlineMode);
    window.addEventListener('offline', turnOnOfflineMode);
    return () => {
      window.removeEventListener('online', turnOnOnlineMode);
      window.removeEventListener('offline', turnOnOfflineMode);
    };
  }, [lastChangedMannully]);

  const changeIsOnline = (value) => {
    if (canBeChangedMannually) {
      setIsOnline(value);
      setLastChangedMannually(value);
    }
  };

  const contextValue = {
    isOnline,
    setIsOnline: changeIsOnline,
    canBeChangedMannually,
  };

  return <IsOnlineContext.Provider value={contextValue}>{children}</IsOnlineContext.Provider>;
};
