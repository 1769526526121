import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, White } from 'styles/colors';

export default makeStyles((theme) => ({
  header: {
    backgroundColor: White,
    boxShadow: `0 1px 0 0 ${rgba(Comet, 0.1)}`,
    padding: '8px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '8px 24px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '8px 55px',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    padding: '0 16px',
  },
}));
