import TemplatesAPI from 'api/templates';
import { AlertContext } from 'Components/Alert/alertContext';
import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { noop } from 'utils/noop';

export const TemplateContext = createContext({
  changeTemplate: noop,
  createTemplateIfEmpty: noop,
  template: null,
  setTemplate: noop,
});

export const TemplateContextProvider = ({ children }) => {
  const { id } = useParams();
  const [template, setTemplate] = React.useState(null);
  const [isTemplateLoading, setIsTemplateLoading] = React.useState(false);
  const { handleSetMessage } = useContext(AlertContext);

  React.useEffect(() => {
    if (id) TemplatesAPI.getTemplate(id).then((res) => setTemplate(res.data));

    return () => setTemplate(null);
  }, [id]);

  const createTemplateIfEmpty = async (newTemplate = {}) => {
    if (!template) {
      setIsTemplateLoading(true);
      const createdTemplate = await TemplatesAPI.postTemplate(newTemplate);
      setIsTemplateLoading(false);
      return createdTemplate.data;
    }
    return template;
  };

  const changeTemplate = async (newTemplate) => {
    const updatedTemplate = {
      ...(template || {}),
      ...newTemplate,
      inspectionTypes: ((newTemplate || template).inspectionTypes || []).map((i) => (Number.isInteger(i) ? i : i?.id)),
    };
    handleSetMessage('Changes saved', 'success');
    if (template) {
      setTemplate(updatedTemplate);
      if (template && updatedTemplate?.isLocked !== template?.isLocked)
        TemplatesAPI.lockTemplate(updatedTemplate).then((res) => setTemplate(res.data));
      return TemplatesAPI.patchTemplate(updatedTemplate).then((res) => setTemplate(res.data));
    }
    return createTemplateIfEmpty(updatedTemplate).then(setTemplate);
  };

  const contextValue = {
    changeTemplate,
    createTemplateIfEmpty,
    template,
    setTemplate,
    isTemplateLoading,
  };

  return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
};
