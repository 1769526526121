import { useContext, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AlertContext } from 'Components/Alert/alertContext';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import CalendarAPI from 'api/calendar';

export const useHolderModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { selectedDate, isMonth, setCalendarDataRaw } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const { handleSetMessage } = useContext(AlertContext);

  const initialValues = useMemo(
    () => ({
      date: isMonth ? moment(selectedDate).add(1, 'day').utc() : moment(selectedDate).utc(),
      time: moment(selectedDate).utc(true),
      duration: 3,
      inspectionTypeId: '',
      addressFirstLine: '',
      state: '',
      city: '',
      zipCode: '',
      inspectors: [{ userId: '' }],
      lat: '',
      lon: '',
    }),
    [],
  );

  const addHolder = async ({ date, time, ...restValues }) => {
    const inspectionAt = moment(
      `${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`,
      'YYYY-MM-DD  HH:mm',
    )
      .utc()
      .toDate()
      .toISOString();
    try {
      const res = await CalendarAPI.postHolder({ ...restValues, inspectionAt });
      setCalendarDataRaw((prev) => ({ ...prev, holders: [...prev.holders, res.data] }));
      onModalClose();
      handleSetMessage('Holder created', 'success');
    } catch (error) {
      handleSetMessage(error?.response?.data?.message, 'error');
    }
  };

  return { onModalClose, addHolder, isModalOpen, initialValues };
};
