import React from 'react';
import cls from 'classnames';

import { fileUrl } from 'config/config';
import useStyles from './useStyles';

const PhotoPreview = ({ onSelectPhoto, photo, isSelected = false }) => {
  const classes = useStyles();

  return (
    <button
      onClick={() => onSelectPhoto(photo)}
      type='button'
      className={cls(classes.imagePreviewButton, { [classes.selectedImage]: isSelected })}
    >
      <img className={classes.imagePreview} src={`${fileUrl}${photo.uploadedFile}`} alt='preview' />
    </button>
  );
};

export default PhotoPreview;
