import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-scroll';

import DropdownMenu from 'Components/DorpdownMenu';
import { useDropdownMenu } from 'Components/DorpdownMenu/useDropdownMenu';
import { ReactComponent as DefaultActiveIcon } from 'assets/icons/defaultIconActive.svg';
import { ReactComponent as DefaultIcon } from 'assets/icons/defaultIcon.svg';
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import useStyles from './useStyles';

const SectionsMenu = ({ onSelectSection, onInactiveSection, selectedSection, sections }) => {
  const classes = useStyles();
  const { onDropdownOpen, onDropdownClose, anchorEl } = useDropdownMenu();
  return (
    <>
      <Button className={classes.sectionsMenuButton} onClick={onDropdownOpen}>
        <span> Sections</span>
        <ArrowIcon className={classes.arrowIcon} />
      </Button>
      <DropdownMenu anchorEl={anchorEl} onClose={onDropdownClose}>
        {sections?.map((section, idx) => (
          <Link
            key={`${section.name}${idx + 1}`}
            className={classes.menuItem}
            activeClass={classes.menuItemActive}
            onSetActive={onSelectSection}
            onSetInactive={onInactiveSection}
            to={`${idx}`}
            offset={-120}
            spy
            smooth
            isDynamic
          >
            {selectedSection === `${idx}` && <DefaultActiveIcon />}
            {selectedSection !== `${idx}` && <DefaultIcon />}
            <span className={classes.menuItemTitle}>{section.name}</span>
          </Link>
        ))}
      </DropdownMenu>
    </>
  );
};

export default SectionsMenu;
