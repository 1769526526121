import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { AccountCircle } from '@material-ui/icons';

import { fileUrl } from 'config/config';
import useStyles from './useStyles';

const Avatar = ({ path }) => {
  const classes = useStyles();
  const hasImage = !isEmpty(path);

  return (
    <>
      {hasImage && (
        <div className={classes.avatar}>
          <img src={`${fileUrl}${path}`} alt='avatar' className={classes.avatarImage} />
        </div>
      )}
      {!hasImage && <AccountCircle className={classes.defaultIcon} />}
    </>
  );
};

export default Avatar;
