import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';

export const useAddComment = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { onChangeComment, isCreating, commentBlock, itemIdx, idx, isReport, section } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const hasIndex = idx !== undefined;

  const comment = hasIndex && {
    commentName: section.items[itemIdx].comments[idx].name,
    commentType: section.items[itemIdx].comments[idx].type,
    notes: section.items[itemIdx].comments[idx].notes,
    reminders: section.items[itemIdx].comments[idx].reminders,
    block: section.items[itemIdx].comments[idx].block,
    values: section.items[itemIdx].comments[idx].values,
    defaultValue: section.items[itemIdx].comments[idx].defaultValue,
    selectedValue: section.items[itemIdx].comments[idx].selectedValue,
    photos: section.items[itemIdx].comments[idx]?.photos || [],
    videos: section.items[itemIdx].comments[idx]?.videos || [],
  };

  const submit = async (values) => {
    const data = {
      name: values.commentName,
      type: values.commentType,
      notes: values.notes,
      reminders: values.reminders,
      block: commentBlock || values.block,
      values: values.values,
      defaultValue: '',
      selectedValue: '',
      photos: values.photos,
      videos: values.videos,
    };

    if (values.defaultValue?.length || values.defaultValue) {
      data.defaultValue = values.defaultValue;
    }

    if (values.commentType === 'TEXT') {
      data.defaultValue = values.values;
    }
    await onChangeComment(data, isCreating ? null : idx);
    onModalClose();
  };

  return { submit, onModalClose, comment, hasIndex, isModalOpen, isCreating, isReport };
};
