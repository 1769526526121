import { ReactComponent as HomeIcon } from 'assets/icons/sectionIcons/home.svg';
import { ReactComponent as AirConditionHeatingIcon } from 'assets/icons/sectionIcons/air conditioning-heating.svg';
import { ReactComponent as AirConditionIcon } from 'assets/icons/sectionIcons/airCondition.svg';
import { ReactComponent as AirTempControlIcon } from 'assets/icons/sectionIcons/airTempControl.svg';
import { ReactComponent as ApartmentsIcon } from 'assets/icons/sectionIcons/apartments.svg';
import { ReactComponent as BathIcon } from 'assets/icons/sectionIcons/bath.svg';
import { ReactComponent as BathtubBathIcon } from 'assets/icons/sectionIcons/bathtub-bath.svg';
import { ReactComponent as BedroomBedIcon } from 'assets/icons/sectionIcons/bedroom-bed.svg';
import { ReactComponent as BlockStructureIcon } from 'assets/icons/sectionIcons/blockStructure.svg';
import { ReactComponent as BrightHouseIcon } from 'assets/icons/sectionIcons/bright house.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/sectionIcons/camera.svg';
import { ReactComponent as CarpentryWoodIcon } from 'assets/icons/sectionIcons/carpentry-carpenter-wood work.svg';
import { ReactComponent as DoorsWindowsIcon } from 'assets/icons/sectionIcons/carpentry-doors-windows.svg';
import { ReactComponent as CarpetIcon } from 'assets/icons/sectionIcons/carpet.svg';
import { ReactComponent as ChimneyIcon } from 'assets/icons/sectionIcons/chimney.svg';
import { ReactComponent as CloseFanIcon } from 'assets/icons/sectionIcons/closeFan.svg';
import { ReactComponent as ColdAirConditionIcon } from 'assets/icons/sectionIcons/coldAirCondition.svg';
import { ReactComponent as ColdBlowingIcon } from 'assets/icons/sectionIcons/coldBlowing.svg';
import { ReactComponent as ColorSwatchIcon } from 'assets/icons/sectionIcons/color swatch.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/sectionIcons/contact.svg';
import { ReactComponent as CountriHouseIcon } from 'assets/icons/sectionIcons/countri house.svg';
import { ReactComponent as CurtainsDraperyIcon } from 'assets/icons/sectionIcons/curtains-drapery.svg';
import { ReactComponent as DesignArchitectureIcon } from 'assets/icons/sectionIcons/design-architecture.svg';
import { ReactComponent as DesignIcon } from 'assets/icons/sectionIcons/design.svg';
import { ReactComponent as DiscountIcon } from 'assets/icons/sectionIcons/discount.svg';
import { ReactComponent as DoorSolidIcon } from 'assets/icons/sectionIcons/doors-solid wood door.svg';
import { ReactComponent as DrillElectricIcon } from 'assets/icons/sectionIcons/drill-electric tools-installations.svg';
import { ReactComponent as EcoEnergyIcon } from 'assets/icons/sectionIcons/eco-energy efficiency-passive house.svg';
import { ReactComponent as ElectricianElectricityIcon } from 'assets/icons/sectionIcons/electrician-electricity.svg';
import { ReactComponent as FanIcon } from 'assets/icons/sectionIcons/fan.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/sectionIcons/filter.svg';
import { ReactComponent as FlashIcon } from 'assets/icons/sectionIcons/flash.svg';
import { ReactComponent as FocusIcon } from 'assets/icons/sectionIcons/focus.svg';
import { ReactComponent as FrameIcon } from 'assets/icons/sectionIcons/frame.svg';
import { ReactComponent as FurnitureLivingIcon } from 'assets/icons/sectionIcons/furniture-living room.svg';
import { ReactComponent as GarageIcon } from 'assets/icons/sectionIcons/garage.svg';
import { ReactComponent as GardeningIcon } from 'assets/icons/sectionIcons/gardening.svg';
import { ReactComponent as GuaranteeIcon } from 'assets/icons/sectionIcons/guarantee.svg';
import { ReactComponent as HomeDecorIcon } from 'assets/icons/sectionIcons/home decor.svg';
import { ReactComponent as HomeRenovationIcon } from 'assets/icons/sectionIcons/home renovation-1.svg';
import { ReactComponent as HomeRenovationNewIcon } from 'assets/icons/sectionIcons/home renovation.svg';
import { ReactComponent as HandymanRepairIcon } from 'assets/icons/sectionIcons/handyman-repair-maintenance-technician.svg';
import { ReactComponent as HandHoldingIcon } from 'assets/icons/sectionIcons/hand holding key.svg';
import { ReactComponent as HeightTempIcon } from 'assets/icons/sectionIcons/heightTemp.svg';
import { ReactComponent as WorkerIcon } from 'assets/icons/sectionIcons/worker.svg';
import { ReactComponent as WoodFlooringIcon } from 'assets/icons/sectionIcons/wood flooring-parquet-floors.svg';
import { ReactComponent as WindowBrokeIcon } from 'assets/icons/sectionIcons/windowBroke.svg';
import { ReactComponent as WindowIcon } from 'assets/icons/sectionIcons/window.svg';
import { ReactComponent as WifiInternetIcon } from 'assets/icons/sectionIcons/wifi-internet connection-smart home.svg';
import { ReactComponent as WaterResistantIcon } from 'assets/icons/sectionIcons/waterResistant.svg';
import { ReactComponent as WallpaperIcon } from 'assets/icons/sectionIcons/wallpaper.svg';
import { ReactComponent as ToolsMaintenanceIcon } from 'assets/icons/sectionIcons/tools-maintenance-repairs.svg';
import { ReactComponent as TapeMeasureIcon } from 'assets/icons/sectionIcons/tape measure.svg';
import { ReactComponent as StoreIcon } from 'assets/icons/sectionIcons/store.svg';
import { ReactComponent as SolidWoodDoorIcon } from 'assets/icons/sectionIcons/solid wood door.svg';
import { ReactComponent as SmartHomeIcon } from 'assets/icons/sectionIcons/smart home.svg';
import { ReactComponent as SmartHomeAutomationIcon } from 'assets/icons/sectionIcons/smart home-home automation.svg';
import { ReactComponent as SlateIcon } from 'assets/icons/sectionIcons/slate.svg';
import { ReactComponent as SinkIcon } from 'assets/icons/sectionIcons/sink.svg';
import { ReactComponent as SinkNewIcon } from 'assets/icons/sectionIcons/sink-1.svg';
import { ReactComponent as SucurutyIcon } from 'assets/icons/sectionIcons/securuty.svg';
import { ReactComponent as SystemAlarmIcon } from 'assets/icons/sectionIcons/security system-alarm.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/sectionIcons/search.svg';
import { ReactComponent as RoofTopIcon } from 'assets/icons/sectionIcons/roofTop.svg';
import { ReactComponent as RoofSideIcon } from 'assets/icons/sectionIcons/roofSide.svg';
import { ReactComponent as RoofPiesIcon } from 'assets/icons/sectionIcons/roofPiec.svg';
import { ReactComponent as RoofingRoofIcon } from 'assets/icons/sectionIcons/roofing-roof.svg';
import { ReactComponent as RoofFrontIcon } from 'assets/icons/sectionIcons/roofFront.svg';
import { ReactComponent as RoofIcon } from 'assets/icons/sectionIcons/roof.svg';
import { ReactComponent as RollerBlindIcon } from 'assets/icons/sectionIcons/roller blind.svg';
import { ReactComponent as RollerBlindNewIcon } from 'assets/icons/sectionIcons/roller blind-1.svg';
import { ReactComponent as RolledCarpetIcon } from 'assets/icons/sectionIcons/rolledCarpet.svg';
import { ReactComponent as RepairsIcon } from 'assets/icons/sectionIcons/repairs.svg';
import { ReactComponent as RepairIcon } from 'assets/icons/sectionIcons/repair.svg';
import { ReactComponent as PlusMinusIcon } from 'assets/icons/sectionIcons/plusMinus.svg';
import { ReactComponent as PlumbingSanitaryIcon } from 'assets/icons/sectionIcons/plumbing-sanitary.svg';
import { ReactComponent as PlanIcon } from 'assets/icons/sectionIcons/plan.svg';
import { ReactComponent as PlanBlueprintIcon } from 'assets/icons/sectionIcons/plan-blueprint-construction.svg';
import { ReactComponent as PipeLeakIcon } from 'assets/icons/sectionIcons/pipeLeak.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/sectionIcons/phone.svg';
import { ReactComponent as PersonsIcon } from 'assets/icons/sectionIcons/persons.svg';
import { ReactComponent as PainterPaintIcon } from 'assets/icons/sectionIcons/painter-paint works.svg';
import { ReactComponent as OpenFanIcon } from 'assets/icons/sectionIcons/openFan.svg';
import { ReactComponent as NailIcon } from 'assets/icons/sectionIcons/nail.svg';
import { ReactComponent as MoistureIcon } from 'assets/icons/sectionIcons/moisture.svg';
import { ReactComponent as MasonryBuilderIcon } from 'assets/icons/sectionIcons/masonry-builder.svg';
import { ReactComponent as LowTempIcon } from 'assets/icons/sectionIcons/lowTemp.svg';
import { ReactComponent as LowHouseIcon } from 'assets/icons/sectionIcons/lowHouse.svg';
import { ReactComponent as LocksmithDoorIcon } from 'assets/icons/sectionIcons/locksmith-door lock.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/sectionIcons/location.svg';
import { ReactComponent as LightingIcon } from 'assets/icons/sectionIcons/lighting-interior illumination-lamp.svg';
import { ReactComponent as LaptopIcon } from 'assets/icons/sectionIcons/laptop.svg';
import { ReactComponent as KitchenIcon } from 'assets/icons/sectionIcons/kitchen.svg';
import { ReactComponent as InsuranceIcon } from 'assets/icons/sectionIcons/insurance.svg';
import { ReactComponent as HouseWithWindowIcon } from 'assets/icons/sectionIcons/houseWithWindow.svg';
import { ReactComponent as HouseWaterResistantIcon } from 'assets/icons/sectionIcons/houseWaretResistant.svg';
import { ReactComponent as HouseTopIcon } from 'assets/icons/sectionIcons/houseTop.svg';
import { ReactComponent as HouseTempIcon } from 'assets/icons/sectionIcons/houseTemp.svg';
import { ReactComponent as HouseBlowingIcon } from 'assets/icons/sectionIcons/houseBlowing.svg';
import { ReactComponent as HouseAirIcon } from 'assets/icons/sectionIcons/houseAir.svg';
import { ReactComponent as SweetHomeIcon } from 'assets/icons/sectionIcons/home sweet home.svg';
import { ReactComponent as EngineerContractorIcon } from 'assets/icons/sectionIcons/engineer-contractor.svg';
import { ReactComponent as SpiderIcon } from 'assets/icons/sectionIcons/spider.svg';
import { ReactComponent as SunIcon } from 'assets/icons/sectionIcons/sun.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/sectionIcons/timer.svg';
import { ReactComponent as ShotIcon } from 'assets/icons/sectionIcons/shot.svg';
import { ReactComponent as ShieldIcon } from 'assets/icons/sectionIcons/shield.svg';
import { ReactComponent as SdCardIcon } from 'assets/icons/sectionIcons/sdCard.svg';
import { ReactComponent as RocketIcon } from 'assets/icons/sectionIcons/rocket.svg';
import { ReactComponent as PictureIcon } from 'assets/icons/sectionIcons/picture.svg';
import { ReactComponent as PhotosIcon } from 'assets/icons/sectionIcons/photos.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/sectionIcons/photo.svg';
import { ReactComponent as PaintIcon } from 'assets/icons/sectionIcons/paint.svg';
import { ReactComponent as ZoomIcon } from 'assets/icons/sectionIcons/zoom.svg';
import { ReactComponent as MouseIcon } from 'assets/icons/sectionIcons/mouse.svg';
import { ReactComponent as MosquitoIcon } from 'assets/icons/sectionIcons/mosquito.svg';
import { ReactComponent as MiteIcon } from 'assets/icons/sectionIcons/mite.svg';
import { ReactComponent as LayersIcon } from 'assets/icons/sectionIcons/layers.svg';
import { ReactComponent as LampIcon } from 'assets/icons/sectionIcons/lamp.svg';
import { ReactComponent as FlyIcon } from 'assets/icons/sectionIcons/fly.svg';
import { ReactComponent as FlowerIcon } from 'assets/icons/sectionIcons/flower.svg';
import { ReactComponent as FilmIcon } from 'assets/icons/sectionIcons/film.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/sectionIcons/eye.svg';
import { ReactComponent as DisinfectorIcon } from 'assets/icons/sectionIcons/disinfector.svg';
import { ReactComponent as CockroachIcon } from 'assets/icons/sectionIcons/cockroach.svg';
import { ReactComponent as CanIcon } from 'assets/icons/sectionIcons/can.svg';
import { ReactComponent as BulbIcon } from 'assets/icons/sectionIcons/bulb.svg';
import { ReactComponent as BrightnessIcon } from 'assets/icons/sectionIcons/brightnes.svg';
import { ReactComponent as BigRoachIcon } from 'assets/icons/sectionIcons/bigRoach.svg';
import { ReactComponent as BatteryIcon } from 'assets/icons/sectionIcons/battery.svg';
import { ReactComponent as AntIcon } from 'assets/icons/sectionIcons/ant.svg';

export const ICONS = {
  HOME: HomeIcon,
  AIR_CONDITION_HEADING: AirConditionHeatingIcon,
  AIR_CONDITION: AirConditionIcon,
  AIR_TEMP_CONTROL: AirTempControlIcon,
  APARTMENTS: ApartmentsIcon,
  BATH: BathIcon,
  BATHTUB_BATH: BathtubBathIcon,
  BEDROOM_BED: BedroomBedIcon,
  BLOCK_STRUCTURE: BlockStructureIcon,
  BRIGHT_HOUSE: BrightHouseIcon,
  CAMERA: CameraIcon,
  CARPENTRY_WOOD: CarpentryWoodIcon,
  DOOR_WINDOWS: DoorsWindowsIcon,
  CARPET: CarpetIcon,
  CHIMNEY: ChimneyIcon,
  CLOSE_FAN: CloseFanIcon,
  COLD_AIR_CONDITION: ColdAirConditionIcon,
  COLD_BLOW: ColdBlowingIcon,
  COLOR_SWATCH: ColorSwatchIcon,
  CONTACT: ContactIcon,
  COUNTRI_HOUSE: CountriHouseIcon,
  CURTAINS_DRAPERY: CurtainsDraperyIcon,
  DESIGN_ARCHITECTURE: DesignArchitectureIcon,
  DESIGN: DesignIcon,
  DISCOUNT: DiscountIcon,
  DOOR_SOLID: DoorSolidIcon,
  DRILL_ELECTRIC: DrillElectricIcon,
  ECO_ENERGY: EcoEnergyIcon,
  ELELCTICIAN_ELECTICITY: ElectricianElectricityIcon,
  FAN: FanIcon,
  FILTER: FilterIcon,
  FLASH: FlashIcon,
  FOCUS: FocusIcon,
  FRAME: FrameIcon,
  FURNITURE_LIVING: FurnitureLivingIcon,
  GARAGE: GarageIcon,
  GARDENING: GardeningIcon,
  GUARANTEE: GuaranteeIcon,
  HOME_DECOR: HomeDecorIcon,
  HOME_RENOVATION: HomeRenovationIcon,
  HOME_RENOVATION_NEW: HomeRenovationNewIcon,
  HANDYMAN_REPAIR: HandymanRepairIcon,
  HAND_HOLDING: HandHoldingIcon,
  HEIGHT_TEMP: HeightTempIcon,
  WORKER: WorkerIcon,
  WOOD_FLOORING: WoodFlooringIcon,
  WINDOW_BROKE: WindowBrokeIcon,
  WINDOW: WindowIcon,
  WIFI_INTERNET: WifiInternetIcon,
  WATER_RESISTANT: WaterResistantIcon,
  WALLPAPER: WallpaperIcon,
  TOOLS_MAINTENANCE: ToolsMaintenanceIcon,
  TAPE_MEASURE: TapeMeasureIcon,
  STORE: StoreIcon,
  SOLID_WOOD_DOOR: SolidWoodDoorIcon,
  SMART_HOME: SmartHomeIcon,
  SMART_HOME_AUTOMATION: SmartHomeAutomationIcon,
  SLATE: SlateIcon,
  SLINK: SinkIcon,
  SLINK_NEW: SinkNewIcon,
  SUCURUCTY: SucurutyIcon,
  SYSTEM_ALARM: SystemAlarmIcon,
  SEARCH: SearchIcon,
  ROOF_TOP: RoofTopIcon,
  ROOF_SIDE: RoofSideIcon,
  ROOF_PIES: RoofPiesIcon,
  ROOFING_ROOF: RoofingRoofIcon,
  ROOF_FRONT: RoofFrontIcon,
  ROOF: RoofIcon,
  ROLLER_BLIND: RollerBlindIcon,
  ROLLER_BLIND_NEW: RollerBlindNewIcon,
  ROLLER_CARPET: RolledCarpetIcon,
  REPAIRS: RepairsIcon,
  REPAIR: RepairIcon,
  PLUS_MINUS: PlusMinusIcon,
  PLUMBING_SANITARY: PlumbingSanitaryIcon,
  PLAN: PlanIcon,
  PLAN_BLUEPRINT: PlanBlueprintIcon,
  PIPE_LEAK: PipeLeakIcon,
  PHONE: PhoneIcon,
  PERSONS: PersonsIcon,
  PAINTER_PAINT: PainterPaintIcon,
  OPEN_FAN: OpenFanIcon,
  NAIL: NailIcon,
  MOISTURE: MoistureIcon,
  MASONRY_BUILDER: MasonryBuilderIcon,
  LOW_TEMP: LowTempIcon,
  LOW_HOUSE: LowHouseIcon,
  LOCKSMITH_DOOR: LocksmithDoorIcon,
  LOCATION: LocationIcon,
  LIGHTING: LightingIcon,
  LAPTOP: LaptopIcon,
  KITCHEN: KitchenIcon,
  INSURANCE: InsuranceIcon,
  HOUSE_WITH_WINDOW: HouseWithWindowIcon,
  HOUSE_WATER_RESISTANT: HouseWaterResistantIcon,
  HOUSE_TOP: HouseTopIcon,
  HOUSE_TEMP: HouseTempIcon,
  HOUSE_BLOWING: HouseBlowingIcon,
  HOUSE_AIR: HouseAirIcon,
  SWEET_HOME: SweetHomeIcon,
  ENGINEER_CONTRACTOR: EngineerContractorIcon,
  SPIDER: SpiderIcon,
  SUN: SunIcon,
  TIMER: TimerIcon,
  SHOT: ShotIcon,
  SHIELD: ShieldIcon,
  SD_CARD: SdCardIcon,
  ROCKET: RocketIcon,
  PICTURE: PictureIcon,
  PHOTOS: PhotosIcon,
  PHOTO: PhotoIcon,
  PAINT: PaintIcon,
  ZOOM: ZoomIcon,
  MOUSE: MouseIcon,
  MOSQUITO: MosquitoIcon,
  MITE: MiteIcon,
  LAYERS: LayersIcon,
  LAMP: LampIcon,
  FLY: FlyIcon,
  FLOWER: FlowerIcon,
  FILM: FilmIcon,
  EYE: EyeIcon,
  DISINFECTOR: DisinfectorIcon,
  COCKROACH: CockroachIcon,
  CAN: CanIcon,
  BULB: BulbIcon,
  BRIGHTNESS: BrightnessIcon,
  BIG_COACH: BigRoachIcon,
  BATTERY: BatteryIcon,
  ANT: AntIcon,
};

export const ICONS_LIST = [
  { name: 'HOME', icon: ICONS.HOME },
  { name: 'AIR_CONDITION', icon: ICONS.AIR_CONDITION },
  { name: 'WORKER', icon: ICONS.WORKER },
  { name: 'WOOD_FLOORING', icon: ICONS.WOOD_FLOORING },
  { name: 'WINDOW_BROKE', icon: ICONS.WINDOW_BROKE },
  { name: 'WINDOW', icon: ICONS.WINDOW },
  { name: 'WIFI_INTERNET', icon: ICONS.WIFI_INTERNET },
  { name: 'WATER_RESISTANT', icon: ICONS.WATER_RESISTANT },
  { name: 'WALLPAPER', icon: ICONS.WALLPAPER },
  { name: 'TOOLS_MAINTENANCE', icon: ICONS.TOOLS_MAINTENANCE },
  { name: 'TAPE_MEASURE', icon: ICONS.TAPE_MEASURE },
  { name: 'SYSTEM_ALARM', icon: ICONS.SYSTEM_ALARM },
  { name: 'SWEET_HOME', icon: ICONS.SWEET_HOME },
  { name: 'SUCURUCTY', icon: ICONS.SUCURUCTY },
  { name: 'STORE', icon: ICONS.STORE },
  { name: 'SOLID_WOOD_DOOR', icon: ICONS.SOLID_WOOD_DOOR },
  { name: 'SMART_HOME_AUTOMATION', icon: ICONS.SMART_HOME_AUTOMATION },
  { name: 'SMART_HOME', icon: ICONS.SMART_HOME },
  { name: 'SLINK_NEW', icon: ICONS.SLINK_NEW },
  { name: 'SLINK', icon: ICONS.SLINK },
  { name: 'SLATE', icon: ICONS.SLATE },
  { name: 'SEARCH', icon: ICONS.SEARCH },
  { name: 'ROOFING_ROOF', icon: ICONS.ROOFING_ROOF },
  { name: 'ROOF_TOP', icon: ICONS.ROOF_TOP },
  { name: 'ROOF_SIDE', icon: ICONS.ROOF_SIDE },
  { name: 'ROOF_PIES', icon: ICONS.ROOF_PIES },
  { name: 'ROOF_FRONT', icon: ICONS.ROOF_FRONT },
  { name: 'ROOF', icon: ICONS.ROOF },
  { name: 'ROLLER_CARPET', icon: ICONS.ROLLER_CARPET },
  { name: 'ROLLER_BLIND_NEW', icon: ICONS.ROLLER_BLIND_NEW },
  { name: 'ROLLER_BLIND', icon: ICONS.ROLLER_BLIND },
  { name: 'REPAIRS', icon: ICONS.REPAIRS },
  { name: 'REPAIR', icon: ICONS.REPAIR },
  { name: 'PLUS_MINUS', icon: ICONS.PLUS_MINUS },
  { name: 'PLUMBING_SANITARY', icon: ICONS.PLUMBING_SANITARY },
  { name: 'PLAN_BLUEPRINT', icon: ICONS.PLAN_BLUEPRINT },
  { name: 'PLAN', icon: ICONS.PLAN },
  { name: 'PIPE_LEAK', icon: ICONS.PIPE_LEAK },
  { name: 'PHONE', icon: ICONS.PHONE },
  { name: 'PERSONS', icon: ICONS.PERSONS },
  { name: 'PAINTER_PAINT', icon: ICONS.PAINTER_PAINT },
  { name: 'OPEN_FAN', icon: ICONS.OPEN_FAN },
  { name: 'NAIL', icon: ICONS.NAIL },
  { name: 'MOISTURE', icon: ICONS.MOISTURE },
  { name: 'MASONRY_BUILDER', icon: ICONS.MASONRY_BUILDER },
  { name: 'LOW_TEMP', icon: ICONS.LOW_TEMP },
  { name: 'LOW_HOUSE', icon: ICONS.LOW_HOUSE },
  { name: 'LOCKSMITH_DOOR', icon: ICONS.LOCKSMITH_DOOR },
  { name: 'LOCATION', icon: ICONS.LOCATION },
  { name: 'LIGHTING', icon: ICONS.LIGHTING },
  { name: 'LAPTOP', icon: ICONS.LAPTOP },
  { name: 'KITCHEN', icon: ICONS.KITCHEN },
  { name: 'INSURANCE', icon: ICONS.INSURANCE },
  { name: 'HOUSE_WITH_WINDOW', icon: ICONS.HOUSE_WITH_WINDOW },
  { name: 'HOUSE_WATER_RESISTANT', icon: ICONS.HOUSE_WATER_RESISTANT },
  { name: 'HOUSE_TOP', icon: ICONS.HOUSE_TOP },
  { name: 'HOUSE_TEMP', icon: ICONS.HOUSE_TEMP },
  { name: 'HOUSE_BLOWING', icon: ICONS.HOUSE_BLOWING },
  { name: 'HOUSE_AIR', icon: ICONS.HOUSE_AIR },
  { name: 'HOME_RENOVATION_NEW', icon: ICONS.HOME_RENOVATION_NEW },
  { name: 'HOME_RENOVATION', icon: ICONS.HOME_RENOVATION },
  { name: 'HOME_DECOR', icon: ICONS.HOME_DECOR },
  { name: 'HEIGHT_TEMP', icon: ICONS.HEIGHT_TEMP },
  { name: 'HANDYMAN_REPAIR', icon: ICONS.HANDYMAN_REPAIR },
  { name: 'HAND_HOLDING', icon: ICONS.HAND_HOLDING },
  { name: 'GUARANTEE', icon: ICONS.GUARANTEE },
  { name: 'GARDENING', icon: ICONS.GARDENING },
  { name: 'GARAGE', icon: ICONS.GARAGE },
  { name: 'FURNITURE_LIVING', icon: ICONS.FURNITURE_LIVING },
  { name: 'FRAME', icon: ICONS.FRAME },
  { name: 'FOCUS', icon: ICONS.FOCUS },
  { name: 'FLASH', icon: ICONS.FLASH },
  { name: 'FILTER', icon: ICONS.FILTER },
  { name: 'FAN', icon: ICONS.FAN },
  { name: 'ENGINEER_CONTRACTOR', icon: ICONS.ENGINEER_CONTRACTOR },
  { name: 'ELELCTICIAN_ELECTICITY', icon: ICONS.ELELCTICIAN_ELECTICITY },
  { name: 'ECO_ENERGY', icon: ICONS.ECO_ENERGY },
  { name: 'DRILL_ELECTRIC', icon: ICONS.DRILL_ELECTRIC },
  { name: 'DOOR_WINDOWS', icon: ICONS.DOOR_WINDOWS },
  { name: 'DOOR_SOLID', icon: ICONS.DOOR_SOLID },
  { name: 'DISCOUNT', icon: ICONS.DISCOUNT },
  { name: 'DESIGN_ARCHITECTURE', icon: ICONS.DESIGN_ARCHITECTURE },
  { name: 'DESIGN', icon: ICONS.DESIGN },
  { name: 'CURTAINS_DRAPERY', icon: ICONS.CURTAINS_DRAPERY },
  { name: 'COUNTRI_HOUSE', icon: ICONS.COUNTRI_HOUSE },
  { name: 'CONTACT', icon: ICONS.CONTACT },
  { name: 'COLOR_SWATCH', icon: ICONS.COLOR_SWATCH },
  { name: 'COLD_BLOW', icon: ICONS.COLD_BLOW },
  { name: 'COLD_AIR_CONDITION', icon: ICONS.COLD_AIR_CONDITION },
  { name: 'CLOSE_FAN', icon: ICONS.CLOSE_FAN },
  { name: 'CHIMNEY', icon: ICONS.CHIMNEY },
  { name: 'CARPET', icon: ICONS.CARPET },
  { name: 'CARPENTRY_WOOD', icon: ICONS.CARPENTRY_WOOD },
  { name: 'CAMERA', icon: ICONS.CAMERA },
  { name: 'BRIGHT_HOUSE', icon: ICONS.BRIGHT_HOUSE },
  { name: 'BLOCK_STRUCTURE', icon: ICONS.BLOCK_STRUCTURE },
  { name: 'BEDROOM_BED', icon: ICONS.BEDROOM_BED },
  { name: 'BATHTUB_BATH', icon: ICONS.BATHTUB_BATH },
  { name: 'BATH', icon: ICONS.BATH },
  { name: 'APARTMENTS', icon: ICONS.APARTMENTS },
  { name: 'AIR_TEMP_CONTROL', icon: ICONS.AIR_TEMP_CONTROL },
  { name: 'ZOOM', icon: ICONS.ZOOM },
  { name: 'TIMER', icon: ICONS.TIMER },
  { name: 'SUN', icon: ICONS.SUN },
  { name: 'SPIDER', icon: ICONS.SPIDER },
  { name: 'SHOT', icon: ICONS.SHOT },
  { name: 'SHIELD', icon: ICONS.SHIELD },
  { name: 'SD_CARD', icon: ICONS.SD_CARD },
  { name: 'ROCKET', icon: ICONS.ROCKET },
  { name: 'PICTURE', icon: ICONS.PICTURE },
  { name: 'PHOTOS', icon: ICONS.PHOTOS },
  { name: 'PHOTO', icon: ICONS.PHOTO },
  { name: 'PAINT', icon: ICONS.PAINT },
  { name: 'MOUSE', icon: ICONS.MOUSE },
  { name: 'MOSQUITO', icon: ICONS.MOSQUITO },
  { name: 'MITE', icon: ICONS.MITE },
  { name: 'LAYERS', icon: ICONS.LAYERS },
  { name: 'LAMP', icon: ICONS.LAMP },
  { name: 'FLY', icon: ICONS.FLY },
  { name: 'FLOWER', icon: ICONS.FLOWER },
  { name: 'FILM', icon: ICONS.FILM },
  { name: 'EYE', icon: ICONS.EYE },
  { name: 'DISINFECTOR', icon: ICONS.DISINFECTOR },
  { name: 'COCKROACH', icon: ICONS.COCKROACH },
  { name: 'CAN', icon: ICONS.CAN },
  { name: 'BULB', icon: ICONS.BULB },
  { name: 'BRIGHTNESS', icon: ICONS.BRIGHTNESS },
  { name: 'BIG_COACH', icon: ICONS.BIG_COACH },
  { name: 'BATTERY', icon: ICONS.BATTERY },
  { name: 'ANT', icon: ICONS.ANT },
];
