import { useFormikContext } from 'formik';

export const useImagesUpload = () => {
  const { setFieldValue, values } = useFormikContext();

  const isMaxLimit = values.templateDefaultImages.length >= 6;

  const addImage = (res) => setFieldValue('templateDefaultImages', [...values.templateDefaultImages, res.uploadedFile]);

  const deleteImage = (idx) => () =>
    setFieldValue('templateDefaultImages', [
      ...values.templateDefaultImages.slice(0, idx),
      ...values.templateDefaultImages.slice(idx + 1),
    ]);

  return { addImage, deleteImage, setFieldValue, values, isMaxLimit };
};
