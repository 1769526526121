import React from 'react';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, IconButton, InputAdornment, MenuItem, useTheme } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { Field, FieldArray } from 'formik';
import cls from 'classnames';
import GoogleMapReact from 'google-map-react';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';

import { noop } from 'utils/noop';
import { CITIES, STATES } from 'constants/location';
import { GOOGLE_MAPS_KEY } from 'constants/googleApi';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import TextField from 'Components/TextField';
import SelectField from 'Components/SelectField';
import InspectorAvailability from 'Components/InspectorAvailability';
import AddressMarker from 'Components/AddressMarker';
import { useHolderForm } from './useHolderForm';
import useStyles from './useStyles';

const HolderForm = () => {
  const classes = useStyles();
  const {
    setFieldValue,
    changeState,
    changeCity,
    blurZip,
    getAddressFromCoordinates,
    setCoordinates,
    stateValue,
    availableInspectors,
    selectedInspectorsId,
    inspectionTypes,
    values,
    errors,
    touched,
  } = useHolderForm();
  const center = { lat: 37.93623963558978, lng: -98.4206148674322 };
  const theme = useTheme();

  return (
    <div className={classes.formWrapper}>
      <div>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <div className={classes.formRowHalf}>
            <Field name='date'>
              {({ field, meta }) => (
                <DatePicker
                  {...field}
                  autoOk
                  error={touched.date && !!meta.error}
                  helperText={touched.date && meta.error}
                  showTodayButton
                  value={values.date}
                  format='MM/DD/YYYY'
                  label='DATE'
                  onChange={(value) => setFieldValue('date', value)}
                  inputVariant='outlined'
                  className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <EventIcon className={classes.dateIcon} />
                      </InputAdornment>
                    ),
                  }}
                  KeyboardButtonProps={{ className: classes.inputButton }}
                />
              )}
            </Field>
            <Field name='time'>
              {({ field, meta }) => (
                <TimePicker
                  {...field}
                  autoOk
                  margin='normal'
                  label='TIME'
                  value={values.time}
                  error={touched.time && !!meta.error}
                  helperText={touched.time && meta.error}
                  onChange={(value) => setFieldValue('time', value)}
                  inputVariant='outlined'
                  keyboardIcon={<AccessTimeIcon />}
                  className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <ScheduleIcon className={classes.dateIcon} />
                      </InputAdornment>
                    ),
                  }}
                  KeyboardButtonProps={{ className: classes.inputButton }}
                />
              )}
            </Field>
          </div>
        </MuiPickersUtilsProvider>
        <div className={classes.formRow}>
          <Field name='duration'>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={touched.duration && !!meta.error}
                helperText={touched.duration && meta.error}
                variant='outlined'
                type='text'
                label='DURATION'
              />
            )}
          </Field>
        </div>
        <div className={classes.formRow}>
          <Field name='inspectionTypeId'>
            {({ field, meta }) => (
              <SelectField
                {...field}
                error={touched.inspectionTypeId && !!meta.error}
                helperText={touched.inspectionTypeId && meta.error}
                className={classes.selectField}
                label='INSPECTION TYPE'
                labelId='inspectionTypeId'
                placeholder='INSPECTION TYPE'
              >
                {inspectionTypes?.map((type) => (
                  <MenuItem key={type.name} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Field>
        </div>
        <div className={classes.formRow}>
          <Field name='addressFirstLine'>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={touched.addressFirstLine && !!meta.error}
                helperText={touched.addressFirstLine && meta.error}
                onBlur={(e) => setCoordinates(e.target.value)}
                variant='outlined'
                type='text'
                label='ADDRESS 1'
              />
            )}
          </Field>
        </div>
        <div className={classes.formRow}>
          <Field name='zipCode'>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={touched.zipCode && !!meta.error}
                helperText={touched.zipCode && meta.error}
                variant='outlined'
                type='text'
                label='ZIP CODE'
                onBlur={blurZip}
              />
            )}
          </Field>
        </div>
        <div className={classes.formRowHalf}>
          <Field name='city'>
            {({ field, meta }) => (
              <Autocomplete
                {...field}
                options={CITIES}
                autoComplete
                getOptionLabel={(option) => option || ''}
                onBlur={changeCity}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    error={touched.city && !!meta.error}
                    helperText={touched.city && meta.error}
                    onChange={noop}
                    variant='outlined'
                    type='text'
                    label='CITY'
                  />
                )}
              />
            )}
          </Field>
          <Field name='state'>
            {({ field, meta }) => (
              <Autocomplete
                options={STATES}
                autoComplete
                value={stateValue}
                getOptionLabel={(option) => (option ? option.title : '')}
                onBlur={() => setCoordinates()}
                onChange={(e, state) => changeState(e, state)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    error={touched.state && !!meta.error}
                    helperText={touched.state && meta.error}
                    onChange={noop}
                    variant='outlined'
                    type='text'
                    label='STATE'
                  />
                )}
              />
            )}
          </Field>
        </div>
        <FieldArray
          name='inspectors'
          render={({ push, remove }) => (
            <>
              {values.inspectors.length > 0 &&
                values.inspectors.map((inspector, idx) => (
                  <>
                    <div className={classes.formRow}>
                      {idx !== 0 && (
                        <IconButton onClick={() => remove(idx)} className={classes.deleteButton}>
                          <TrashIcon />
                        </IconButton>
                      )}
                      <Field name={`inspectors.${idx}.userId`}>
                        {({ field, meta }) => (
                          <SelectField
                            {...field}
                            error={touched.inspectors !== undefined && touched.inspectors[idx]?.userId && !!meta.error}
                            helperText={
                              touched.inspectors !== undefined && touched.inspectors[idx]?.userId && meta.error
                            }
                            disabled={!values.date || !values.time || !values.duration}
                            className={classes.selectField}
                            label={idx !== 0 ? 'ADDITIONAL INSPECTOR' : 'PRIMARY INSPECTOR'}
                            labelId='userId'
                            defaultValue={values.inspectors[idx]}
                            renderValue={(id) => {
                              if (availableInspectors.length) {
                                const selectedInspector = availableInspectors.find((insp) => insp.id === id);
                                return `${selectedInspector?.firstName} ${selectedInspector?.lastName}`;
                              }
                              return '';
                            }}
                            placeholder={idx !== 0 ? 'ADDITIONAL INSPECTOR' : 'PRIMARY INSPECTOR'}
                          >
                            {availableInspectors.map((availableInspector) => (
                              <MenuItem
                                disabled={selectedInspectorsId?.includes(availableInspector.id)}
                                value={availableInspector.id}
                              >
                                <div className={classes.inspectorWrapper}>
                                  {`${availableInspector.firstName} ${availableInspector.lastName}`}
                                  <InspectorAvailability inspector={availableInspector} />
                                </div>
                              </MenuItem>
                            ))}
                          </SelectField>
                        )}
                      </Field>
                    </div>
                  </>
                ))}
              <Button
                disabled={values.inspectors.length === 6}
                className={classes.addButton}
                onClick={() => push({ userId: '', templates: [] })}
                disableRipple
              >
                + Add Additional Inspector
              </Button>
            </>
          )}
        />
      </div>
      <div className={classes.mapWrapper}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
          options={{
            clickableIcons: false,
            fullscreenControl: false,
            styles: theme.palette.isDark && [
              { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
              { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
              { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
              {
                featureType: 'administrative.locality',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#d59563' }],
              },
              {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#d59563' }],
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{ color: '#263c3f' }],
              },
              {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#6b9a76' }],
              },
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: '#38414e' }],
              },
              {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [{ color: '#212a37' }],
              },
              {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#9ca5b3' }],
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{ color: '#746855' }],
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [{ color: '#1f2835' }],
              },
              {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#f3d19c' }],
              },
              {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [{ color: '#2f3948' }],
              },
              {
                featureType: 'transit.station',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#d59563' }],
              },
              {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: '#17263c' }],
              },
              {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#515c6d' }],
              },
              {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [{ color: '#17263c' }],
              },
            ],
          }}
          defaultCenter={center}
          center={center}
          defaultZoom={3}
          onClick={getAddressFromCoordinates}
        >
          {values.lat && values.lon && <AddressMarker lat={values.lat} lng={values.lon} />}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default HolderForm;
