import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertContext } from 'Components/Alert/alertContext';
import { closeModal } from 'redux/reducers/modals';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import ReportsAPI from 'api/reports';
import { GALLERY_SECTIONS } from './options';

export const useGallery = () => {
  const [gallery, setGallery] = useState({ photos: [], videos: [] });
  const [isLoading, setIsLoading] = useState();

  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const {
    id,
    setFieldValue,
    currentCommentPhotos,
    currentCommentVideos,
    isViewMode = false,
    limitVideos = 99,
    limitPhotos = 99,
  } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const { handleSetMessage } = useContext(AlertContext);

  const [selectedSection, setSelectedSection] = useState(GALLERY_SECTIONS.PHOTO);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const selectSection = (section) => setSelectedSection(section);
  const selectPhoto = (file) => {
    const duplicate = selectedPhotos.find((item) => item?.uploadedFile === file.uploadedFile);
    if (duplicate) {
      setSelectedPhotos((old) => old.filter((item) => item.uploadedFile !== duplicate.uploadedFile));
    } else if (selectedPhotos.length >= limitPhotos) {
      handleSetMessage('Image Limit Reached', 'error');
    } else setSelectedPhotos((old) => [...old, file]);
  };
  const selectVideo = (file) => {
    const duplicate = selectedVideos.find((item) => item?.uploadedFile === file.uploadedFile);
    if (duplicate) {
      setSelectedVideos((old) => old.filter((item) => item.uploadedFile !== duplicate.uploadedFile));
    } else if (selectedVideos.length >= limitVideos) {
      handleSetMessage('Video Limit Reached', 'error');
    } else setSelectedVideos((old) => [...old, file]);
  };
  const submit = () => {
    if (selectedPhotos.length) setFieldValue('photos', [...currentCommentPhotos, ...selectedPhotos]);
    if (selectedVideos.length) setFieldValue('videos', [...currentCommentVideos, ...selectedVideos]);
    onModalClose();
  };

  const fetchGallery = () => {
    return ReportsAPI.getGallery(id).then((res) => setGallery(res.data));
  };

  useEffect(async () => {
    setIsLoading(true);
    await fetchGallery();
    setIsLoading(false);
  }, []);

  return {
    onModalClose,
    selectSection,
    selectPhoto,
    selectVideo,
    submit,
    fetchGallery,
    selectedSection,
    selectedPhotos,
    selectedVideos,
    photos: (gallery.photos || []).filter(
      (value, index, self) =>
        index === self.findIndex((t) => t?.uploadedFile === value?.uploadedFile && t?.file?.path === value?.file?.path),
    ),
    videos: gallery.videos,
    isModalOpen,
    isLoading,
    isViewMode,
  };
};
