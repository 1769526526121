import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { BlueGradient, Comet, White } from 'styles/colors';

export default makeStyles((theme) => ({
  galleryModal: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: White,
    overflow: 'hidden',
  },
  galleryModalWrapper: {
    maxWidth: '100%',
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  modalHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    backgroundColor: White,
    zIndex: 999,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: Comet,
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginTop: 72,
    [theme.breakpoints.up('sm')]: {
      marginTop: 96,
      height: 'calc(100vh - 78px)',
    },
  },
  previewsWrapper: {
    margin: '24px 0',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '16px 0',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridGap: 24,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: 30,
    },
  },
  emptyTitle: {
    textAlign: 'center',
    color: rgba(Comet, 0.5),
  },
  submitButton: {
    background: BlueGradient,
    textTransform: 'none',
    position: 'absolute',
    right: 24,
    top: 72,
    fontWeight: 700,
    zIndex: 2000,
    padding: '4px 16px',
    '&:hover': {
      background: BlueGradient,
      opacity: '0.7',
    },
    [theme.breakpoints.up('sm')]: {
      top: 96,
    },
  },
}));
