import React from 'react';
import moment from 'moment';

import { fileUrl } from 'config/config';
import { ReactComponent as DefaultAvatarIcon } from '../../assets/defaultAvatar.svg';
import useStyles from './useStyles';

const HolderCard = ({ onCreateInspection, holder }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardWrapper}>
      <span className={classes.time}>{moment(holder.inspectionAt).utc().local().format('hh:mm A')}</span>
      <div onClick={onCreateInspection(holder)} className={classes.card}>
        <div className={classes.contentLine}>
          <div className={classes.addressWrapper}>
            <span className={classes.title}>Holder</span>
            <span className={classes.address}>
              {`${holder.addressFirstLine}, ${holder.city}, ${holder.state} ${holder.zipCode}`}
            </span>
          </div>

          <span className={classes.timeRange}>
            {`${moment(holder.inspectionAt).utc().local().format('hh:mm A')} - ${moment(holder.inspectionAt)
              .utc()
              .local()
              .add(holder.duration, 'hours')
              .format('hh:mm A')}`}
          </span>
        </div>
        <div className={classes.contentLine}>
          <div className={classes.inspectorsWrapper}>
            <span className={classes.inspectorsTitle}>Inspectors:</span>
            {holder.inspectors.map((inspector) =>
              inspector?.avatar ? (
                <img
                  key={inspector.id}
                  className={classes.inspectorAvatar}
                  src={`${fileUrl}${inspector.avatar}`}
                  alt=''
                />
              ) : (
                <DefaultAvatarIcon key={inspector.id} className={classes.defaultAvatar} />
              ),
            )}
          </div>
          <span className={classes.reports}>Reports: {holder.templatesCount}</span>
        </div>
      </div>
    </div>
  );
};

export default HolderCard;
