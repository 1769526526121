import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  formRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  timeWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
    marginBottom: 24,
  },
  textField: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: RoyalBlue,
      },
    },
  },
  dateField: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  inputButton: {
    padding: 0,
  },
  validTextField: {
    '& fieldset': {
      borderColor: rgba(Comet, 0.25),
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        backgroundColor: rgba(RoyalBlue, 0.05),
      },
      '&.Mui-focused fieldset': {
        borderColor: RoyalBlue,
      },
    },
    '&.MuiOutlinedInput-root': {
      backgroundColor: rgba(RoyalBlue, 0.05),
    },
  },
  formItem: {
    width: '100%',
    marginTop: 24,
  },
  dateIcon: {
    fill: rgba(Comet, 0.7),
  },
}));
