import React from 'react';
import { FieldArray, Field, useFormikContext } from 'formik';
import { IconButton, Button } from '@material-ui/core';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import TextField from 'Components/TextField';
import useStyles from './useStyles';

const Options = ({ optionValues, isRange = false }) => {
  const classes = useStyles();
  const { touched } = useFormikContext();

  const renderTextFields = (remove) =>
    optionValues.map((option, idx) => (
      <div key={`values.${idx + 1}`}>
        <Field name={`values.${idx}`}>
          {({ field, meta }) => (
            <TextField
              {...field}
              error={touched.values !== undefined && touched.values[idx] && !!meta.error}
              helperText={touched.values !== undefined && touched.values[idx] && meta.error}
              variant='outlined'
              type='text'
              label={`OPTION ${idx + 1}`}
              data-cy-input={`option-${idx}`}
              InputProps={{
                endAdornment: optionValues.length > 1 && (
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => remove(idx)}
                    data-cy-button={`delete-option-${idx}`}
                  >
                    <TrashIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        </Field>
      </div>
    ));

  const renderRangeField = (remove) =>
    optionValues.map((option, idx) => (
      <div className={classes.rangeWrapper} key={`values.${idx + 1}`}>
        <Field name={`values.${idx}.from`}>
          {({ field, meta }) => (
            <TextField
              {...field}
              error={touched.values !== undefined && touched.values[idx]?.from && !!meta.error}
              helperText={touched.values !== undefined && touched.values[idx]?.from && meta.error}
              variant='outlined'
              type='text'
              label='FROM'
              data-cy-input={`from-${idx}`}
            />
          )}
        </Field>
        <Field name={`values.${idx}.to`}>
          {({ field, meta }) => (
            <TextField
              {...field}
              error={touched.values !== undefined && touched.values[idx]?.to && !!meta.error}
              helperText={touched.values !== undefined && touched.values[idx]?.to && meta.error}
              variant='outlined'
              type='text'
              label='TO'
              data-cy-input={`to-${idx}`}
            />
          )}
        </Field>
        {optionValues.length > 1 && (
          <IconButton
            className={classes.deleteButton}
            onClick={() => remove(idx)}
            data-cy-button={`delete-range-${idx}`}
          >
            <TrashIcon />
          </IconButton>
        )}
      </div>
    ));

  return (
    <FieldArray
      name='values'
      render={({ push, remove }) => (
        <div className={classes.optionsWrapper}>
          <div className={classes.optionsHeader}>
            <h5 className={classes.title}>ANSWER OPTIONS</h5>
            <Button
              onClick={() => push(isRange ? { from: '', to: '' } : '')}
              className={classes.addButton}
              data-cy-button='add-option'
            >
              + Add more
            </Button>
          </div>
          {!isRange && optionValues.length > 0 && (
            <div className={classes.inputsWrapper}>{renderTextFields(remove)}</div>
          )}
          {isRange && optionValues.length > 0 && renderRangeField(remove)}
        </div>
      )}
    />
  );
};

export default Options;
