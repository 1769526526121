import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Loader from 'Components/Loader';
import TextField from 'Components/TextField';
import { SortableItem, useEditDb } from './useEditDb';
import useStyles from './useStyles';
import Row from './components/DbTableRow';
import { TemplateSectionContextProvider } from 'shared/contexts/TemplateSectionsContext';

const DbEdit = () => {
  const classes = useStyles();
  const {
    isLoading,
    data,
    dbTables,
    selectedTable,
    handleChangeTable,
    updateRow,
    deleteRow,
    addRow,
    handleDragStart,
    handleDragEnd,
    sensors,
    activeId,
    tableRef,
    hasEditPermissions,
    hasCreatePermissions,
    hasDeletePermissions,
    filterName,
    setFilterName,
  } = useEditDb();
  const keys = selectedTable?.keys;
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classes.pageWrapper}>
          <div className={classes.tablesTabs}>
            {dbTables.map((table) => (
              <div
                style={{
                  ...(selectedTable?.name === table?.name && { background: '#3f51b5', color: 'white' }),
                }}
                className={classes.tableTab}
                onClick={handleChangeTable(table)}
              >
                {table?.label}
              </div>
            ))}
          </div>

          <div className={classes.table}>
            {/**
             * This input is here to prevent google autocomplete
             */}
            <input style={{ display: 'none' }} type='text' name='fakeusernameremembered' />
            <div className={classes.tableHeader}>
              <TextField
                className={classes.search}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                variant='outlined'
                type='text'
                label='Name'
              />
              <Button
                style={{
                  background: '#3f51b5',
                  height: 32,
                  margin: 10,
                  width: 80,
                  float: 'right',
                }}
                appearance='primary'
                onClick={addRow}
                disabled={!hasCreatePermissions}
              >
                ADD
              </Button>
            </div>
            <TableContainer component={Paper} ref={tableRef} style={{ borderRadius: 15 }}>
              <Table aria-label='collapsible table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.firstCell} />
                    <TableCell>Name</TableCell>
                    <TableCell align='right'> {keys.includes('isArchived') && 'Archived'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext items={data} strategy={verticalListSortingStrategy}>
                      {data.map((row, indx) => (
                        <SortableItem key={row.id} id={row.id}>
                          <Row
                            key={row.id}
                            row={row}
                            keys={keys}
                            updateRow={updateRow}
                            deleteRow={deleteRow}
                            hasEditPermissions={hasEditPermissions}
                            hasDeletePermissions={hasDeletePermissions}
                            isMasterTemplate={selectedTable.name === 'template-sections'}
                            indx={indx}
                          />
                        </SortableItem>
                      ))}
                    </SortableContext>
                    <DragOverlay>
                      {activeId ? (
                        <Row
                          key={activeId}
                          row={data.find((row) => row.id == activeId)}
                          keys={keys}
                          updateRow={updateRow}
                          deleteRow={deleteRow}
                          hasEditPermissions={hasEditPermissions}
                          hasDeletePermissions={hasDeletePermissions}
                        />
                      ) : null}
                    </DragOverlay>
                  </DndContext>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default () => (
  <TemplateSectionContextProvider>
    <DbEdit />
  </TemplateSectionContextProvider>
);
