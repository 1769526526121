import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import cls from 'classnames';

import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import useStyles from './useStyles';

const ButtonTypes = ({ selectedType, onTypeClick }) => {
  const classes = useStyles();

  return (
    <ButtonGroup className={classes.typeButtonsWrapper}>
      <Button
        className={cls(classes.typeButton, { [classes.actionNecessaryButton]: selectedType === 'ACTION_NECESSARY' })}
        onClick={onTypeClick('ACTION_NECESSARY')}
      >
        <WarningIcon
          className={cls({
            [classes.typeButtonSelectedIcon]: selectedType === 'ACTION_NECESSARY',
            [classes.typoButtonIcon]: selectedType !== 'ACTION_NECESSARY',
          })}
        />
      </Button>
      <Button
        className={cls(classes.typeButton, { [classes.attachedButton]: selectedType === 'ATTANCHENED_NEEDED' })}
        onClick={onTypeClick('ATTANCHENED_NEEDED')}
      >
        <MinusIcon
          className={cls({
            [classes.typeButtonSelectedIcon]: selectedType === 'ATTANCHENED_NEEDED',
            [classes.typoButtonIcon]: selectedType !== 'ATTANCHENED_NEEDED',
          })}
        />
      </Button>
      <Button
        className={cls(classes.typeButton, { [classes.maintainButton]: selectedType === 'MAINTAIN_MONITOR' })}
        onClick={onTypeClick('MAINTAIN_MONITOR')}
      >
        <KeyIcon
          className={cls({
            [classes.typeButtonSelectedIcon]: selectedType === 'MAINTAIN_MONITOR',
            [classes.typoButtonIcon]: selectedType !== 'MAINTAIN_MONITOR',
          })}
        />
      </Button>
    </ButtonGroup>
  );
};

export default ButtonTypes;
