import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';

import Logo from 'Components/Logo';
import DropdownMenu from 'Components/DorpdownMenu';
import { useDropdownMenu } from 'Components/DorpdownMenu/useDropdownMenu';
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import useStyles from './useStyles';

const Header = ({ reports = [], currentReport, hasTermite, downloadThermitePdf }) => {
  const classes = useStyles();
  const { token, reportId } = useParams();
  const { onDropdownOpen, onDropdownClose, anchorEl } = useDropdownMenu();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div className={classes.headerWrapper}>
      <style>
        {`
          .MuiAccordion-root {
            background: white;
          }
        `}
      </style>
      <NavLink to='/system/dashboard/home'>
        <Logo isMobile={!isDesktop} />
      </NavLink>
      {isDesktop && (
        <>
          <div className={classes.tabsWrapper}>
            {reports.map((report) => (
              <NavLink
                key={report.name}
                className={classes.tabLink}
                activeClassName={classes.selectedTabLink}
                to={`/result-inspection/${token}/${report.id}/full-report`}
                isActive={() => +reportId === report.id}
              >
                {report.name}
              </NavLink>
            ))}
            {/* {hasTermite && (
              <NavLink
                className={classes.tabLink}
                activeClassName={classes.selectedTabLink}
                to={`/report`}
                onClick={(e) => {
                  e.preventDefault();
                  downloadThermitePdf();
                }}
              >
                Termite report
              </NavLink>
            )} */}
          </div>
          <h2 className={classes.title}>Inspection Reports</h2>
        </>
      )}
      {!isDesktop && (
        <>
          <Button className={classes.reportsMenuButton} id='reportsMenu' onClick={onDropdownOpen}>
            <span>{currentReport?.name}</span>
            <ArrowIcon className={classes.arrowIcon} />
          </Button>
          <DropdownMenu anchorEl={anchorEl} onClose={onDropdownClose}>
            {reports.map((report) => (
              <MenuItem key={report.name}>
                <NavLink
                  className={classes.tabLink}
                  activeClassName={classes.selectedTabLink}
                  to={`/result-inspection/${token}/${report.id}/full-report`}
                  isActive={() => +reportId === report.id}
                >
                  {report.name}
                </NavLink>
              </MenuItem>
            ))}
          </DropdownMenu>
        </>
      )}
    </div>
  );
};

export default Header;
