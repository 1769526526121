import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, IconButton, Slide, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Jaffa, Red, RoyalBlue } from 'styles/colors';
import { NotificationContext } from './notificationContext';
import useStyles from './useStyles';

function getBorderColor(variant) {
  const colors = {
    success: RoyalBlue,
    error: Red,
    warning: Jaffa,
    info: '#FF9800',
  };
  return colors[variant];
}

function TransitionDown(props) {
  return <Slide {...props} direction='down' />;
}

const Notification = () => {
  const { handleClose, notificationMessage, notificationVariant, notificationLink } = useContext(NotificationContext);
  const classes = useStyles({ borderColor: getBorderColor(notificationVariant) });

  return (
    <Snackbar
      className={classes.container}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!notificationMessage}
      TransitionComponent={TransitionDown}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby='client-snackbar'
      message={
        notificationLink ? (
          <NavLink className={classes.notificationText} to={notificationLink}>
            {notificationMessage}
          </NavLink>
        ) : (
          <span className={classes.notificationText} id='client-snackbar'>
            {notificationMessage}
          </span>
        )
      }
      action={
        <div className={classes.headerWrapper}>
          <span className={classes.title}>New Notification</span>
          <IconButton key='close' aria-label='close' color='inherit' onClick={handleClose}>
            <Icon component={CloseIcon} />
          </IconButton>
        </div>
      }
    />
  );
};

export default Notification;
