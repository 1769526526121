import React from 'react';
import { Field } from 'formik';
import { FormControlLabel, RadioGroup, Radio, useTheme } from '@material-ui/core';
import cls from 'classnames';

import useStyles from './useStyles';
import { StyleContext } from 'shared/contexts/StyleContext';

const RadioButtons = ({ values, isRange = false }) => {
  const classes = useStyles();
  const { isDarkMode } = React.useContext(StyleContext);

  return (
    <RadioGroup className={cls(classes.radioWrapper, { [classes.column]: isRange })}>
      {values.map((option, idx) => (
        <Field key={`${idx + 1}`} type='radio' name='defaultValue' value={isRange ? JSON.stringify(option) : option}>
          {({ field }) => (
            <FormControlLabel
              {...field}
              control={<Radio color={isDarkMode ? 'default' : 'primary'} data-cy-checkbox='option' />}
              label={isRange ? `${option.from}-${option.to}` : option}
            />
          )}
        </Field>
      ))}
    </RadioGroup>
  );
};

export default RadioButtons;
