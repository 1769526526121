/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import localforage from 'localforage';

import { IsOnlineContext } from 'shared/IsOnlineContext';
import mainInstance from 'api/mainInstance';
import ReportsAPI from 'api/reports';

export const useUploadEditedReport = () => {
  const [isLoading, setLoading] = useState(false);
  const { isOnline } = useContext(IsOnlineContext);

  const uploadFile = async (uploadRoute, filedName, file) => {
    const data = new FormData();
    data.append(filedName, file);

    const res = await mainInstance.post(`/admin/${uploadRoute}`, data);

    return res.data;
  };

  const uploadOfflineActivity = async () => {
    const savedReports = await localforage.getItem('editedReports'); // localforage
    const savedReportsSections = await localforage.getItem('editedReportsSections'); // localforage
    const savedUploadedGalleryMedia = await localforage.getItem('savedUploadedGalleryMedia');
    if (isOnline && (savedReports || savedReportsSections || savedUploadedGalleryMedia)) {
      setLoading(true);
      try {
        savedReports && (await Promise.all(savedReports.map((report) => ReportsAPI.patchReport(report))));
        savedReportsSections &&
          (await Promise.all(
            savedReportsSections.map(async (section) => {
              const updatedSection = { ...section };

              const updatedItems = await Promise.all(
                (updatedSection.items || []).map(async (item) => {
                  const updatedItem = { ...item };
                  if (item.comments) {
                    updatedItem.comments = await Promise.all(
                      item.comments.map(async (comment) => {
                        const updatedComment = { ...comment };

                        const photos = comment?.photos?.length
                          ? Promise.all(
                              comment.photos.map(async (photo) => {
                                if (!photo.uploadedFile) {
                                  const uploadedFile = await uploadFile('images', 'image', photo.file);
                                  return { file: { path: photo.file.name }, uploadedFile };
                                }
                                return Promise.resolve(
                                  photo?.file?.path
                                    ? photo
                                    : { file: { path: photo.file.name }, uploadedFile: photo.uploadedFile },
                                );
                              }),
                            )
                          : [];

                        updatedComment.photos = await photos;

                        const videos = comment?.videos?.length
                          ? Promise.all(
                              comment.videos.map(async (video) => {
                                if (!video.uploadedFile) {
                                  const uploadedFile = await uploadFile('videos', 'video', video.file);
                                  return { file: { path: video.file.name }, uploadedFile };
                                }
                                return Promise.resolve(
                                  video?.file?.path
                                    ? video
                                    : { file: { path: video.file.name }, uploadedFile: video.uploadedFile },
                                );
                              }),
                            )
                          : [];

                        updatedComment.videos = await videos;

                        return updatedComment;
                      }),
                    );
                  }

                  if (item.isDeficiencies && item?.deficiencies) {
                    updatedItem.deficiencies = await Promise.all(
                      item.deficiencies.map(async (comment) => {
                        const updatedComment = { ...comment };

                        const photos = comment?.photos?.length
                          ? Promise.all(
                              comment.photos.map(async (photo) => {
                                if (!photo.uploadedFile) {
                                  const uploadedFile = await uploadFile('images', 'image', photo.file);
                                  return { file: { path: photo.file.name }, uploadedFile };
                                }
                                return Promise.resolve(
                                  photo?.file?.path
                                    ? photo
                                    : { file: { path: photo.file.name }, uploadedFile: photo.uploadedFile },
                                );
                              }),
                            )
                          : [];

                        updatedComment.photos = await photos;

                        const videos = comment?.videos?.length
                          ? Promise.all(
                              comment.videos.map(async (video) => {
                                if (!video.uploadedFile) {
                                  const uploadedFile = await uploadFile('videos', 'video', video.file);
                                  return { file: { path: video.file.name }, uploadedFile };
                                }
                                return Promise.resolve(
                                  video?.file?.path
                                    ? video
                                    : { file: { path: video.file.name }, uploadedFile: video.uploadedFile },
                                );
                              }),
                            )
                          : [];

                        updatedComment.videos = await videos;

                        return updatedComment;
                      }),
                    );
                    return updatedItem;
                  }
                  return updatedItem;
                }),
              );
              updatedSection.items = updatedItems;
              return ReportsAPI.patchReportSection(updatedSection);
            }),
          ));
        savedUploadedGalleryMedia &&
          (await Promise.all(
            savedUploadedGalleryMedia.map(async (file) => {
              const data = new FormData();
              data.append('file', file.file);
              data.append('templateId', (file.reportId || file.id).toString());
              return mainInstance.post('/template-files', data).catch(() => {
                return null;
              });
            }),
          ));
        await localforage.removeItem('editedReports'); // localforage
        await localforage.removeItem('editedReportsSections'); // localforage
        await localforage.removeItem('savedUploadedGalleryMedia');
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(async () => {
    window.addEventListener('online', await uploadOfflineActivity);
    if (isOnline) uploadOfflineActivity();
    return window.addEventListener('online', await uploadOfflineActivity);
  }, [isOnline]);

  return { isLoading };
};
/* eslint-disable */
