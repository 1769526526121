import React from 'react';
import { Menu } from '@material-ui/core';

import useStyles from './useStyles';

const DropdownMenu = ({ onClose, anchorEl, children, ...props }) => {
  const classes = useStyles();
  return (
    <Menu
      {...props}
      className={classes.dropdown}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {children}
    </Menu>
  );
};

export default DropdownMenu;
