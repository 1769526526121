import { makeStyles } from '@material-ui/core';

import { RoyalBlue, White } from 'styles/colors';

export default makeStyles({
  sliderWrapper: {
    width: '100%',
    maxHeight: 200,
    '&>div': {
      '&:not(:first-child):not(:last-child)': {
        height: '50px !important',
        bottom: 0,
        margin: 'auto',
      },
    },
  },
  image: {
    position: 'relative',
    width: '100%',
    height: 200,
    objectFit: 'cover',
  },
  imagePreviewButton: {
    position: 'absolute',
    right: 16,
    bottom: 0,
  },
  imagePreviewIcon: {
    fill: White,
  },
  video: {
    width: '100%',
    height: 200,
  },
  indicators: {
    position: 'absolute',
    bottom: 0,
  },
  activeIndicator: {
    fill: RoyalBlue,
  },
  commentNameChip: {
    padding: '8px 16px',
    borderRadius: 16,
    backgroundColor: RoyalBlue,
    color: White,
    fontSize: 12,
    fontWeight: 600,
    position: 'absolute',
    right: 16,
    top: 4,
  },
});
