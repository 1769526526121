import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';
import { Comet, RoyalBlue, White } from 'styles/colors';

export default makeStyles((theme) => ({
  tabsButtonsWrapper: {
    width: '100%',
  },
  standardsButton: {
    marginBottom: 8,
  },
  tabsButton: {
    width: '100%',
    backgroundColor: rgba(Comet, 0.1),
    border: 'none',
    color: rgba(Comet, 0.75),
    fontSize: 12,
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
  },
  tabsButtonActive: {
    backgroundColor: RoyalBlue,
    color: White,
    fontWeight: 600,

    '&:hover': {
      backgroundColor: rgba(RoyalBlue, 0.7),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      fontWeight: 700,
    },
  },
  commentsWrapper: {
    display: 'grid',
    gridGap: '24px 30px',
    marginTop: 16,
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  commentWrapper: {
    display: 'flex',
    borderBottom: `1px solid ${rgba(Comet, 0.1)}`,
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      border: 'none',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  commentTitle: {
    color: rgba(Comet, 0.5),
    fontSize: 14,
  },
  commentValue: {
    fontWeight: 600,
    color: Comet,
    fontSize: 12,
    marginTop: 8,
  },
}));
