import React, { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Field, useFormikContext } from 'formik';
import cls from 'classnames';
import { Box, Checkbox, FormControlLabel, InputAdornment, MenuItem, Select, Tab, Tabs } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import TextField from 'Components/TextField';
import useStyles from './useStyles';

const BlockerForm = ({ isFullDay, setIsFullDay }) => {
  const classes = useStyles();
  const { setFieldValue, touched, values, errors } = useFormikContext();

  const handleChangeTab = (e, value) => {
    setIsFullDay(value === 'true');
  };
  useEffect(() => {
    if (moment(values.date) > moment(values.dateEnd) || !values.dateEnd) {
      setFieldValue('dateEnd', moment(values.date).add(1, 'days'));
    }
  }, [values.date]);

  return (
    <>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <TabContext value={isFullDay.toString()}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab}>
              <Tab label='Hours' value={'false'} />
              <Tab label='Full day' value={'true'} />
            </TabList>
          </Box>
          <TabPanel value={'false'}>
            <div className={classes.timeWrapper}>
              <Field name='date'>
                {({ field, meta }) => (
                  <DatePicker
                    {...field}
                    autoOk
                    error={touched.date && !!meta.error}
                    helperText={touched.date && meta.error}
                    showTodayButton
                    value={values.date}
                    format='MM/DD/YYYY'
                    label='DATE START'
                    onChange={(value) => setFieldValue('date', value)}
                    inputVariant='outlined'
                    className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <EventIcon className={classes.dateIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
              <Field name='time'>
                {({ field, meta }) => (
                  <TimePicker
                    {...field}
                    autoOk
                    margin='normal'
                    label='TIME'
                    value={values.time.utc()}
                    error={touched.time && !!meta.error}
                    helperText={touched.time && meta.error}
                    onChange={(value) => setFieldValue('time', value)}
                    inputVariant='outlined'
                    keyboardIcon={<AccessTimeIcon />}
                    className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <ScheduleIcon className={classes.dateIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
            </div>

            <div className={classes.timeWrapper}>
              <Field name='duration'>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    error={touched.duration && !!meta.error}
                    helperText={touched.duration && meta.error}
                    variant='outlined'
                    type='text'
                    label='DURATION'
                    // disabled={!isSingleDay}
                  />
                )}
              </Field>
            </div>
          </TabPanel>
          <TabPanel value={'true'}>
            <div className={classes.timeWrapper}>
              <Field name='date'>
                {({ field, meta }) => (
                  <DatePicker
                    {...field}
                    autoOk
                    error={touched.date && !!meta.error}
                    helperText={touched.date && meta.error}
                    showTodayButton
                    value={values.date}
                    format='MM/DD/YYYY'
                    label='CHOSEN DAY'
                    // onChange={calculateStart}
                    onChange={(value) => setFieldValue('date', value)}
                    inputVariant='outlined'
                    className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <EventIcon className={classes.dateIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
              {values.frequency !== 'SINGLE' && (
                <Field name='dateEnd'>
                  {({ field, meta }) => (
                    <DatePicker
                      {...field}
                      autoOk
                      disabled={values.isEndless}
                      error={touched.date && !!meta.error}
                      helperText={touched.date && meta.error}
                      showTodayButton
                      // value={moment(values.date).add(1, 'days')}
                      format='MM/DD/YYYY'
                      label='DATE END'
                      onChange={(value) => setFieldValue('dateEnd', value)}
                      inputVariant='outlined'
                      className={cls(classes.textField, { [classes.validTextField]: !errors.date })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EventIcon className={classes.dateIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
              )}
            </div>
            <div className={classes.timeWrapper}>
              <Field name='frequency'>
                {({ field, meta }) => (
                  <FormControlLabel
                    labelPlacement='top'
                    control={
                      <Select
                        {...field}
                        error={touched.date && !!meta.error}
                        helperText={touched.date && meta.error}
                        label='Repeat Frequency'
                        inputVariant='outlined'
                        className={cls(classes.textField, { [classes.validTextField]: !errors.frequency })}
                      >
                        <MenuItem value='SINGLE'>Single Time</MenuItem>
                        <MenuItem value='DAILY'>Daily</MenuItem>
                        <MenuItem value='WEEKLY'>Weekly</MenuItem>
                        <MenuItem value='BIWEEKLY'>Bi-Weekly</MenuItem>
                        <MenuItem value='MONTHLY'>Monthly</MenuItem>
                      </Select>
                    }
                    label='Repeat Frequency'
                  />
                )}
              </Field>
              {values.frequency !== 'SINGLE' && (
                <Field name='isEndless'>
                  {({ field, meta }) => (
                    <FormControlLabel
                      disabled={values.frequency === 'SINGLE'}
                      labelPlacement='end'
                      control={<Checkbox {...field} checked={field.value} />}
                      label='Endless'
                    />
                  )}
                </Field>
              )}
            </div>
          </TabPanel>
        </TabContext>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default BlockerForm;
