import { makeStyles } from '@material-ui/core';
import { BlueGradient, White } from '../../styles/colors';

export default makeStyles({
  contentWrapper: {
    display: 'flex',
    // height: '100%',
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '62%',
    marginTop: 36,
    paddingBottom: 24,
  },
  columnsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    overflowX: 'hidden',
    marginTop: 44,
    gap: 8,
  },
  openGalleryButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  openGalleryButton: {
    color: White,
    padding: '4px 8px',
    background: BlueGradient,
    '& .MuiButton-label': {
      fontSize: 12,
      fontWeight: 700,
    },
  },
});
