import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

import { Comet } from 'styles/colors';

export default makeStyles({
  mediaButtonsWrapper: {
    display: 'flex',
  },
  mediaButton: {
    width: '100%',
    backgroundColor: rgba(Comet, 0.1),
    color: Comet,
    border: 'none',
    margin: '0 !important',
    padding: 0,
    minHeight: 40,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: rgba(Comet, 0.2),
    },
    '&:disabled': {
      backgroundColor: rgba(Comet, 0.3),
    },
  },
  uploadImageButton: {
    borderRight: `1px solid ${rgba(Comet, 0.1)}`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  uploadVideoButton: {
    borderLeft: `1px solid ${rgba(Comet, 0.1)}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  uploadReportVideoButton: {
    borderRadius: 0,
  },
  mediaButtonIcon: {
    fill: Comet,
    width: 20,
    height: 20,
  },
  fileUploadWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
