import { useState } from 'react';

export const useDropdownMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuId, setMenuId] = useState(null);

  const onDropdownOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setMenuId(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const onCloseWithAction = (action) => () => {
    onDropdownClose();
    action();
  };

  return { onDropdownOpen, onDropdownClose, onCloseWithAction, anchorEl, menuId };
};
