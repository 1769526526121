import mainInstance from 'api/mainInstance';

const OtherAPI = {
  getMapInspections: () => mainInstance.get('/inspections/map'),
  putLocations: (data) => mainInstance.put(`/admin/locations/${data.id}`, data),
  getRoles: () => mainInstance.get(`/admin/roles`),
  getPermissions: () => mainInstance.get(`/admin/permissions?limit=99`),
  getParentPermissions: () => mainInstance.get(`admin/permissions/parents`),
  getInDB: ({ typeName }) => mainInstance.get(`/admin/${typeName}?limit=1000`),
  getOneInDB: ({ typeName, id }) => mainInstance.get(`/admin/${typeName}/${id}`),
  postInDB: (data) => mainInstance.post(`/admin/${data.typeName}`, data),
  patchInDB: (data) => mainInstance.patch(`/admin/${data.typeName}/${data.id}`, data),
  deleteInDB: ({ typeName, id }) => mainInstance.delete(`/admin/${typeName}/${id}`),
  moveInDB: (data) => mainInstance.patch(`/admin/${data.typeName}/${data.id}/move`, data),
};

export default OtherAPI;
