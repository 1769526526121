import React from 'react';

function ModeStylesFixes({ theme }) {
  return (
    <style>
      {`
        .MuiOutlinedInput-input:-webkit-autofill {
          -webkit-text-fill-color: ${theme.palette.isDark ? 'white' : 'rgba(0, 0, 0, 0.78)'} !important;
          -webkit-box-shadow: unset;
        }
        .e-schedule,
        .e-toolbar-items.e-tbar-pos {
          background-color: ${theme.palette.primary.main} !important;
        }
        .e-toolbar-left * {
          color: #5B5F83 !important;
        }
        .e-schedule-table.e-outer-table *:not(.e-inner-wrap):not(.e-subject) {
          background-color: ${theme.palette.primary.main} !important;
          color: #5B5F83  !important;
          border-color: rgba(91, 95, 131, 0.25) !important;
        }

        .MuiCheckbox-colorPrimary.Mui-checked {
          color: unset
        }
        .MuiFormLabel-root.Mui-focused:not(.Mui-error) {
          color: ${theme.palette.isDark ? 'rgba(255, 255, 255, 0.7)' : 'unset'}
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
          background-color: #fff;
        }
        .MuiSwitch-colorPrimary.Mui-checked {
          color: #bdbdbd;
        }
        .checkBoxWithSvg.Mui-checked svg {
          fill: ${theme.palette.isDark ? 'white' : '#3f51b5'}
        }
        .MuiRadio-colorPrimary.Mui-checked {
          color: ${theme.palette.isDark ? 'white' : 'rgba(0, 0, 0, 0.54)'}
        }

        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
          background-color: ${theme.palette.primary.main};
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0,0,0,.5);
          -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
        }
      `}
    </style>
  );
}

export default ModeStylesFixes;
