import React, { useEffect, useState, useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import { Button, FormHelperText, MenuItem } from '@material-ui/core';

import SelectField from 'Components/SelectField';
import TextField from 'Components/TextField';
import TextEditor from 'Components/TextEditor';
import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import RadioButtons from '../../answerTypesViewMode/RadioButtons';
import Checkboxes from '../../answerTypesViewMode/Checkboxes';
import Calendar from '../../answerTypes/Calendar';
import { typesDeficiencies } from '../../options';
import ButtonTypes from '../../answerTypesViewMode/ButtonTypes';
import ViewModeFilesUpload from '../ViewModeFilesUpload';
import { ReactComponent as ListIcon } from '../assets/list.svg';
import useStyles from './useStyles';
import { useDispatch } from 'react-redux';
import { openAddLocationModal } from 'redux/reducers/modals';
import formatDate from 'utils/formatData';

const CommentForm = ({
  onToggleImageLoading,
  onToggleVideoLoading,
  onChangeComments,
  idx,
  selectedItem,
  selectedSection,
  commentRaw,
  estimations,
  estimationTypes,
  isReport,
  isDeficiencies,
  setAllDeafaultValues,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setFieldValue, values, touched, errors } = useFormikContext();

  function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);
    // eslint-disable-next-line
    useEffect(() => {
      if (didMountRef.current) return fn();
      // eslint-disable-next-line
      else didMountRef.current = true;
    }, inputs);
  }

  useDidUpdateEffect(() => {
    setAllDeafaultValues(values, selectedSection, selectedItem);
  }, [values.defaultValue]);

  const [comment, setComment] = useState(commentRaw);
  useEffect(() => {
    try {
      setComment({
        ...commentRaw,
        values: commentRaw?.values,
        // JSON.parse(JSON.stringify(commentRaw?.values).replace(/(<([^>]+)>)/gi, '')),
        defaultValue: commentRaw?.defaultValue,
        // JSON.parse(JSON.stringify(commentRaw?.defaultValue).replace(/(<([^>]+)>)/gi, '')),
      });
    } catch (err) {
      setComment(commentRaw);
    }
  }, [commentRaw.values, commentRaw.defaultValue]);

  return (
    <>
      <div className={classes.commentTypeFields}>
        {comment.type === 'RADIO' && <RadioButtons values={comment.values} />}
        {(comment.type === 'CHOICES' || comment.type === 'NUMBER') && <Checkboxes values={comment.values} />}
        {comment.type === 'TEXT' && (
          <div>
            <Field key={comment.values} type='text' name='defaultValue' value={comment.values} hidden>
              {({ field, meta }) => (
                <TextEditor
                  fieldName='defaultValue'
                  placeholder='TEXT'
                  spellCheck='true'
                  valueForRerender={comment?.defaultValue}
                  id='view-mode-text'
                  lightMode
                />
              )}
            </Field>
          </div>
        )}
        {comment.type === 'DATE' && (
          <Calendar
            selectedDate={new Date(formatDate(new Date(values.defaultValue)))}
            onDayClick={
              (day, { selected }) => {
                setFieldValue('defaultValue', selected ? '' : moment(day).format('YYYY-MM-DD'));
              }
              // eslint-disable-next-line
            }
          />
        )}
        {comment.type === 'RANGE' && <RadioButtons values={comment.values} isRange />}
        {typesDeficiencies.includes(comment.type) && (
          <ButtonTypes selectedType={values.type} onTypeClick={(newType) => () => setFieldValue('type', newType)} />
        )}
      </div>
      <div className={classes.commentAdditionalFields}>
        <ViewModeFilesUpload
          onToggleImageLoading={onToggleImageLoading}
          onToggleVideoLoading={onToggleVideoLoading}
          isReport={isReport}
        />
      </div>
      {isDeficiencies && values.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY && (
        <div className={classes.commentAdditionalFields}>
          <div className={classes.estimationFieldWrapper}>
            <Field name='estimationId'>
              {({ field }) => (
                <SelectField
                  {...field}
                  labelId='estimationId'
                  label='ESTIMATION'
                  placeholder='ESTIMATION'
                  className={classes.select}
                >
                  {estimations?.map((estimation) => (
                    <MenuItem key={estimation.id} value={estimation.id}>
                      {estimation.name}
                    </MenuItem>
                  ))}
                </SelectField>
              )}
            </Field>
          </div>
          {values.estimationId === 2 && (
            <>
              <div className={classes.estimationFieldWrapper}>
                <div className={classes.estimationValueField}>
                  <Field name='estimationFrom'>
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        error={touched.estimationFrom && !!meta.error}
                        helperText={touched.estimationFrom && meta.error}
                        variant='outlined'
                        type='text'
                        label='FROM'
                        placeholder='FROM'
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.estimationValueField}>
                  <Field name='estimationTo'>
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        error={touched.estimationTo && !!meta.error}
                        helperText={touched.estimationTo && meta.error}
                        variant='outlined'
                        type='text'
                        label='TO'
                        placeholder='TO'
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={classes.estimationFieldWrapper}>
                <Field name='estimationTypeId'>
                  {({ field }) => (
                    <SelectField
                      {...field}
                      labelId='estimationTypeId'
                      label='ESTIMATION TYPE'
                      placeholder='ESTIMATION TYPE'
                      className={classes.select}
                    >
                      {estimationTypes?.map((estimationType) => (
                        <MenuItem key={estimationType.id} value={estimationType.id}>
                          {estimationType.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                  )}
                </Field>
              </div>
            </>
          )}
        </div>
      )}
      <div className={classes.commentAdditionalFields}>
        <div className={classes.addLocationWrapper}>
          <Field
            name='location'
            as={TextField}
            label='LOCATION'
            placeholder='Specify the location'
            data-cy-input='view-mode-location'
          />
          <Button
            className={classes.addLocationButton}
            onClick={() =>
              dispatch(
                openAddLocationModal({
                  onChangeComments,
                  setLocationValue: (value) => setFieldValue('location', value),
                  idx,
                  itemIdx: selectedItem,
                  sectionIdx: selectedSection,
                  location: comment?.location,
                  isDeficiencies,
                }),
              )
            }
          >
            <ListIcon />
          </Button>
        </div>
        <TextEditor
          fieldName='notes'
          placeholder='Notes'
          spellCheck='true'
          valueForRerender={comment?.notes}
          id='view-mode-notes'
          lightMode
        />
        <Field name='notes' type='text' spellCheck='true' hidden />
        <FormHelperText>{isDeficiencies ? errors.notes : errors.defaultValue}</FormHelperText>
      </div>
    </>
  );
};

export default CommentForm;
