import { makeStyles } from '@material-ui/core';

import { Comet, RoyalBlue } from 'styles/colors';

export default makeStyles((theme) => ({
  calendarWrapper: {
    marginTop: 20,
    width: '100%',

    '& .e-control.e-schedule.e-lib.e-keyboard': {
      background: theme.palette.primary.main,
    },

    '& > .DayPicker-wrapper': {
      '& > .DayPicker-Months': {
        '& > .DayPicker-Month': {
          width: '100%',
          margin: 0,
          '& > .DayPicker-Caption': {
            textAlign: 'center',
            fontSize: 18,
            color: Comet,
            fontWeight: 600,
          },
          '& > .DayPicker-Weekdays': {
            '& > .DayPicker-WeekdaysRow': {
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& > .DayPicker-Weekday': {
                width: 20,
                height: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.up('sm')]: {
                  width: 30,
                  height: 30,
                },
              },
            },
          },
          '& > .DayPicker-Body': {
            '& > .DayPicker-Week': {
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& > .DayPicker-Day': {
                width: 20,
                height: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
                border: 'none',
                outline: 'none',
                [theme.breakpoints.up('sm')]: {
                  width: 30,
                  height: 30,
                },
                '&.DayPicker-Day--selected': {
                  backgroundColor: RoyalBlue,
                  fontSize: 15,
                  fontWeight: 700,
                },
              },
            },
          },
        },
      },
    },
  },
  calendarCaption: {
    textAlign: 'center',
  },
  calendarNavbar: {
    position: 'absolute',
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navButton: {
    padding: 0,
  },
}));
