import { useDispatch, useSelector } from 'react-redux';

import { DEFICIENCIES_COMMENT_TYPES } from 'constants/deficienciesCommentTypes';
import { closeModal } from 'redux/reducers/modals';
import { ConstantsContext } from 'shared/contexts/ConstantsContext';
import { getModalProps, getModalType } from 'redux/selectors/modals';
import { useContext } from 'react';
import { INSPECTOR_ESTIMATION_ID } from './options';

export const useAddDeficienciesComment = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getModalType);
  const { onChangeComment, isCreating, itemIdx, idx, isReport, section } = useSelector(getModalProps);
  const onModalClose = () => dispatch(closeModal());

  const { estimations, estimationsTypes, recommendedExperts } = useContext(ConstantsContext);

  const hasIndex = idx !== undefined || section;

  const comment = hasIndex && {
    commentName: section?.items?.[itemIdx]?.deficiencies?.[idx]?.name || '',
    type: section?.items?.[itemIdx]?.deficiencies?.[idx]?.type || '',
    estimation: section?.items?.[itemIdx]?.deficiencies?.[idx]?.estimationId || 1,
    estimationType: section?.items?.[itemIdx]?.deficiencies?.[idx]?.estimationTypeId || 1,
    estimationFrom: section?.items?.[itemIdx]?.deficiencies?.[idx]?.estimationValue?.from || '',
    estimationTo: section?.items?.[itemIdx]?.deficiencies?.[idx]?.estimationValue?.to || '',
    notes: section?.items?.[itemIdx]?.deficiencies?.[idx]?.notes || '',
    reminders: section?.items?.[itemIdx]?.deficiencies?.[idx]?.reminders || '',
    location: section?.items?.[itemIdx]?.deficiencies?.[idx]?.location || '',
    recommendedExpert: section?.items?.[itemIdx]?.deficiencies?.[idx]?.recommendationId || 1,
    photos: section?.items[itemIdx]?.deficiencies?.[idx]?.photos || [],
    videos: section?.items[itemIdx]?.deficiencies?.[idx]?.videos || [],
  };

  const submit = async (values) => {
    const data = {
      name: values.commentName,
      type: values.type,
      notes: values.notes,
      reminders: values.reminders,
      recommendationId: values.recommendedExpert,
      location: values.location,
      photos: values.photos,
      videos: values.videos,
      ...(values.type === DEFICIENCIES_COMMENT_TYPES.ACTION_NECESSARY
        ? {
            estimationId: values.estimation,
            estimationTypeId: values.estimation === INSPECTOR_ESTIMATION_ID ? values.estimationType : null,
            estimationValue:
              values.estimation === INSPECTOR_ESTIMATION_ID
                ? { from: values.estimationFrom, to: values.estimationTo }
                : null,
          }
        : {
            estimationId: 1,
            estimationTypeId: null,
            estimationValue: null,
          }),
    };

    await onChangeComment(data, isCreating ? null : idx, true);

    onModalClose();
  };

  return {
    submit,
    onModalClose,
    comment,
    isModalOpen,
    isCreating,
    hasIndex,
    recommendedExperts,
    estimations,
    estimationsTypes,
    isReport,
  };
};
